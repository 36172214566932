export const DicomTools = [
  {
    disabled: false,
    icon: "mdi-human",
    text: "presets",
    dropdown: [
      {
        text: "Brain",
        shortcut: "1",
        target: "regionBrain",
      },
      {
        text: "Lungs",
        shortcut: "2",
        target: "regionLungs",
      },
      {
        text: "Mediastinum",
        shortcut: "3",
        target: "regionMediastinum",
      },
      {
        text: "soft tissues",
        shortcut: "4",
        target: "regionSoftTissues",
      },
      {
        text: "liver",
        shortcut: "5",
        target: "regionLiver",
      },
      {
        text: "angio",
        shortcut: "6",
        target: "regionAngio",
      },
      {
        text: "bone",
        shortcut: "7",
        target: "regionBone",
      },
    ],
  },
  {
    disabled: false,
    icon: "mdi-octagram",
    text: "WW/WL",
    target: "toolbarWW-WC",
  },
  {
    disabled: false,
    icon: "mdi-vector-square",
    text: "region",
    target: "toolbarWW-WcRegion",
  },
  {
    disabled: false,
    icon: "mdi-circle-half-full",
    text: "invert",
    target: "toolbarInvert",
  },
  {
    disabled: false,
    icon: "mdi-magnify",
    text: "zoom",
    target: "toolbarZoom",
  },
  {
    disabled: false,
    icon: "mdi-magnify-plus-outline",
    text: "magnify",
    target: "toolbarMagnify",
  },
  {
    disabled: false,
    icon: "mdi-cursor-move",
    text: "pan",
    target: "toolbarPan",
  },
  {
    disabled: false,
    icon: "mdi-rotate-right",
    text: "rotate",
    target: "toolbarRotate",
  },
  {
    disabled: false,
    icon: "mdi-animation-outline",
    text: "stack scroll",
    target: "toolbarStackScroll",
  },
  {
    disabled: false,
    icon: "mdi-ruler-square-compass",
    text: "measure",
    dropdown: [
      {
        disabled: false,
        icon: "mdi-vector-line",
        text: "length",
        target: "measureLine",
      },
      {
        disabled: false,
        icon: 'mdi-plus-outline',
        text: 'Bidirectional',
        target: 'measureBidirectional'
      },
      {
        disabled: false,
        icon: 'mdi-message-bulleted',
        text: 'Annotation',
        target: 'toolbarArrowAnnotation'
      },
      {
        disabled: false,
        icon: 'mdi-vector-polyline',
        text: 'cobbAngle',
        target: 'toolbarCobbAngle'
      },
      {
        disabled: false,
        icon: "mdi-angle-acute",
        text: "angle",
        target: "measureAngle",
      },
      {
        disabled: false,
        icon: "mdi-record-circle-outline",
        text: "pixel probe",
        target: "toolbarPixelProbe",
      },
      {
        disabled: false,
        icon: "mdi-crop-square",
        text: "rectangle ROI",
        target: "toolbarRectangleRoi",
      },
      {
        disabled: false,
        icon: "mdi-circle-outline",
        text: "elliptical ROI",
        target: "toolbarEllipticalRoi",
      },
      {
        disabled: false,
        icon: "mdi-gesture",
        text: "free hand",
        target: "toolbarFreeHand",
      },
    ],
  },
  {
    disabled: true,
    icon: "mdi-vector-circle",
    text: "crosshair",
    target: "toolbarCrosshairs",
  },
  {
    disabled: true,
    icon: "mdi-account-network",
    text: "localizer",
    target: "toolbarLocalizer",
  },
  {
    disabled: true,
    icon: "mdi-sync",
    text: "synchronize",
    target: "toolbarSynchronization",
  },
];
