import API from "@/shared/API";
import {errorHandler} from "@/store/shared";
export const GET_RAD_PROFILE_PIC = "GET_PROFILE_PIC";
export const GET_RAD_SIGNATURE_IMAGE = "GET_RAD_SIGNATURE_IMAGE";
const Profile = {
    namespaced: true,
    state: {
        profilePictureURL: "/assets/images/avatar.png",
        signatureImageURL: "/assets/images/signature/signature-image.png"
    },
    actions: {
        async getProfilePic({dispatch, commit}, url) {
            try {
                const response = await API.get(url);
                if (response.data.imgUrl) {
                    commit(GET_RAD_PROFILE_PIC, response.data.imgUrl);
                }
            } catch (error) {
                console.log("Error while getting profile pic for current user.");
                errorHandler(error, dispatch, "Error Getting profile pic ", url);
            }
        },
        setProfilePicURL({commit}, imgUrl) {
            commit(GET_RAD_PROFILE_PIC, imgUrl);
        },
        async getSignatureImage({dispatch, commit}, url) {
            try {
                const response = await API.get(url);
                if (response.data.signatureImgUrl) {
                    commit(GET_RAD_SIGNATURE_IMAGE, response.data.signatureImgUrl);
                }
            } catch (error) {
                console.log("Error while getting signature image for current user.");
                errorHandler(error, dispatch, "Error Getting signature image ", url);
            }
        },
        setSignatureImageURL({commit}, signatureImgUrl) {
            commit(GET_RAD_SIGNATURE_IMAGE, signatureImgUrl);
        }
    },
    mutations: {
        [GET_RAD_PROFILE_PIC](state, val) {
            state.profilePictureURL = val;
        },
        [GET_RAD_SIGNATURE_IMAGE](state, val) {
            state.signatureImageURL = val;
        }
    }
}

export default Profile;