<template>
  <v-expansion-panels accordion multiple hover focusable>
    <StudyItem v-for="study in oldStudies" :key="study.id" :study="study" />
    <div v-if="oldStudies.length === 0">
      <br />
      <span class="text-subtitle-1">No History for the current patient</span>
    </div>
  </v-expansion-panels>
</template>
<script>
import { mapState } from "vuex";
import StudyItem from "@/components/StudyTree/StudyItem";

export default {
  name: "PatientHistory",
  components: { StudyItem },
  computed: {
    ...mapState(["patientHistoryList", "study"]),
    oldStudies() {
      return this.patientHistoryList.filter(({ studyId }) => studyId !== this.study.studyId);
    },
  },
};
</script>
