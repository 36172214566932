import Worker from 'worker-loader!./worker';
import PromiseWorker from 'promise-worker';

const postMessage = message => {
  return new Promise((resolve, reject) => {
    if (message) { 
      const worker = new Worker();
      const promiseWorker = new PromiseWorker(worker);
      promiseWorker.postMessage(message).then((data) => {
        resolve(data);
        worker.terminate();
      }).catch(function (error) {
        reject(error);
      });
    }
  })
}

export default {
  postMessage
};