<template >
  <v-card  >
    <v-card-text>
      <template>
        <v-data-table
            :headers="headers"
            :items="dataItems_Appeared"
            :search="searchInput"
            item-key="name"
            item-value="value"
            :item-class="rowClass"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :hide-default-header = "hideDefaultHeader"
            @update:options="handleOptionsUpdate"
            :items-per-page= "-1"
            :footer-props="{
                    'items-per-page-text':footerText,
                    'items-per-page-options': [5, 10, 15, -1],
                  }"
            class="elevation-1"
            :height="listHeight"
            style="cursor:pointer"

        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
            <tr>
              <td v-for="header in headers" :key="header.text" :class="headerClass(header)">
                <span style="font-weight: bold;"> {{header.text}}</span>
              </td>
            </tr>
            </thead>
          </template>
          <!-- TODO-->
          <!-- eslint-disable-next-line vue/valid-v-slot  -->
          <template  v-slot:body.prepend>
            <tr>
              <td class="d-block d-sm-table-cell">
                <v-text-field
                    v-model="searchInput"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :class=" {'header-name-class':!isMobileView }"
                ></v-text-field>
              </td>
              <td v-for="item in headersBooleans" :key="item.name" class="d-block d-sm-table-cell" style="text-align: center;">
                <div>
                  <span> {{item.name}} ONLY</span>
                  <v-simple-checkbox
                      v-model="item.value"
                      :ripple="false"
                      :color="item.color"
                      @input ="filterBoolean($event,item.name)"
                  ></v-simple-checkbox>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:item="{item}">
            <tr >
              <td v-for="ps in headersStrings" :key="ps.name" class="d-block d-sm-table-cell">
                <v-chip
                    :style="{width: !isMobileView ? '70%' : '100%'}"
                    :color="getColor(item[ps.filterProp])"
                    dark>
                  {{ item[ps.name] }}
                </v-chip>
              </td>
              <td v-for="ps in headersBooleans" :key="ps.name" class="d-block d-sm-table-cell" style="text-align: center;" >
                <v-simple-checkbox
                    v-model="item[ps.name]"
                    :color="getColor(item[ps.filterProp])"
                    :ripple="false"
                    @input ="checkBooleanEvent($event,item,ps.name)"
                    :disabled="isDisabled"
                ></v-simple-checkbox></td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "DataList",
  props: {
    modality: {
      type: String,
      default: () => undefined
    },
    dataItems: {
      type: Array,
      default: () => [],
    },
    dataProperties:{
      type: Array,
      default: () => [],
    },
    dataHeaders: {
      type: Array,
      default: () => [],
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    contentsName: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    listHeight: {
      type: String,
      default: '470px',
    },
  },
  data() {
    return {
      sortBy: [],
      sortDesc: [],
      headersBooleans:[],
      headersStrings:[],
      headers:[],
      headerItemDefault: null,
      footerText:'',
      startValue:'',
      isMobileView:false,
      searchInput:'',
      filterBooleans:[],
      dataItems_Appeared:[],
      filterProperty:'',
      uniqueProperty:'',
      isCheckedFromUI: false
    };
  },
  computed: {
    ...mapState( ["headersBooleanStored"] ),
  },
  methods: {
    ...mapActions(["setHeadersBooleanStored"]),
    applyFilters(rt,filters){
      let returnValue = true;
      for(let i=0 ;i< filters.length ;i++){
        if(filters[i].type === 'single') {
          returnValue = (returnValue && rt[filters[i].name] === filters[i].value);
        }
        else if ( filters[i].type === 'array') {
          returnValue = (returnValue && filters[i].value.includes(rt[filters[i].name]));
        }
      }
      return returnValue;
    },
    checkBooleanEvent(value,item,filterProperty){
      this.isCheckedFromUI = true;
      this.$emit("checkBooleanEmit", { value: value, item: item, filterProperty: filterProperty });
    },
    constructHeaders(){
      if(this.hideDefaultHeader) {
        for (let j = 0; j < this.dataHeaders.length; j++) {

          if (this.dataHeaders[j].type == 'boolean') {
            let search = {
              text: this.dataHeaders[j].text,
              name: this.dataHeaders[j].name,
              value: false,
              color: this.dataHeaders[j].color,
              filterProp: this.dataHeaders[j].filterProp
            }
            this.headersBooleans.push(search);
            this.headers.push(search);
            this.filterProperty = this.dataHeaders[j].filterProp;
          } else if (this.dataHeaders[j].type == 'string') {
            let booleanHeader = {
              text: this.dataHeaders[j].text,
              value: this.dataHeaders[j].value,
              name: this.dataHeaders[j].value,
              color: '',
              filterProp: this.dataHeaders[j].filterProp
            }
            this.headersStrings.push(booleanHeader);
            this.headers.push(booleanHeader);
            this.startValue = this.dataHeaders[j].value;
            this.uniqueProperty = this.dataHeaders[j].value;
          }
          if (this.dataHeaders[j].isDefault) {
            this.headerItemDefault = this.dataHeaders[j];
          }
        }
      }
      else{
        this.headers = this.dataHeaders;
      }
    },
    constructFooterText(){
      this.footerText = this.contentsName +' per page';
    },
    filterBoolean(value,booleanPropertyName){
      //check weather there is already exist filters or not
      for(let i =0; i< this.headersBooleans.length;i++ ){
        let index = this.filterBooleans.findIndex( p => p == this.headersBooleans[i].name);
        if(booleanPropertyName == this.headersBooleans[i].name && value == true && index == -1)
          this.filterBooleans.push(this.headersBooleans[i].name);
        else if(booleanPropertyName == this.headersBooleans[i].name && value == false && index > -1)
          this.filterBooleans.splice(index,1);
      }
      let storedValue = JSON.parse(JSON.stringify(this.filterBooleans))
      this.setHeadersBooleanStored(storedValue);
      this.showFilteredDataEvent();
    },
    getColor (headerName) {
      let j = this.headers.findIndex(p => p.name === headerName );
      if(j > -1)
        return this.headers[j].color;
      else
        return this.headerItemDefault.color;
    },
    getItemIndex (item,propertyIndex) {
      let index = this.items.map(function(x) {return x[propertyIndex]; }).indexOf(item[propertyIndex]);
      return index
    },
    handleOptionsUpdate(options){
      this.sortBy = options.sortBy;//['headerName']
      this.sortDesc = options.sortDesc;//[false]
      return
    },
    headerClass(header){
      if(header.value == this.startValue)
        return 'd-block d-sm-table-cell '
      else
        return 'd-block d-sm-table-cell text-center'
    },
    isMobile() {
      if (screen.width <= 760) {
        this.isMobileView =true;
      } else {
        this.isMobileView = false;
      }
    },
    rowClass(item){
      console.log('function to set row style');
      console.log(item);
    },
    showFilteredDataEvent(){
      this.$emit("showFilteredDataEmit");
    },
    showFilteredData(additionalFilters){
      //show the distinct data according to the filters -if not show it all distinct
      if(this.headersBooleanStored.length > 0) {
        this.filterBooleans = JSON.parse(JSON.stringify(this.headersBooleanStored));
      //Object.assign(storedValue,this.dataItems_Appeared);
        for(let i =0; i< this.headersBooleans.length;i++ ){
          let index = this.filterBooleans.findIndex( p => p == this.headersBooleans[i].name);
          if(index > -1){
            this.headersBooleans[i].value = true;
          }
        }
      }else{
        this.filterBooleans =[];
        for(let i =0; i< this.headersBooleans.length;i++ ){
            this.headersBooleans[i].value = false;
        }
      }
      if(this.filterBooleans.length >0) {
        this.dataItems_Appeared = this.uniqueArray(this.dataItems.filter(rt =>
            this.filterBooleans.includes(rt[this.filterProperty]) && this.applyFilters(rt, additionalFilters)
        ), this.uniqueProperty);
      }
      else{
        let f = this.dataItems.filter(rt =>
              this.applyFilters(rt, additionalFilters)
        );
        this.dataItems_Appeared = this.uniqueArray(f,this.uniqueProperty);
      }
    },
    resetStore(){
      this.filterBooleans =[];
      this.setHeadersBooleanStored(this.filterBooleans);
    },
    uniqueArray( ar,property ) {
      let j = {};
      ar.forEach( function(v) {
        if(property != null && property != undefined)
          j[v[property]+ '::' + typeof v[property]] = v;
        else
          j[v+ '::' + typeof v] = v;
      });
      return Object.keys(j).map(function(v){
        return j[v];
      });
    },
  },
  mounted() {
    this.constructHeaders();
    this.constructFooterText();
    this.showFilteredDataEvent();
  },
  created() {

  }
};
</script>

<style>

</style>