import Vue from "vue";
import Vuex from "vuex";
import state from "./InitialState";
import mutations from "./Mutations";
import actions from "./Actions";
import UserTypes from "@/util/UserTypes";
import Layout from "@/store/Layout";
import LocalStorage from "@/store/LocalStorage/LocalStorage";
import AiResponseTypes from "@/util/AiResponseTypes";
import DicomSeries from "@/store/DicomSeries/DicomSeries";
import RadStudies from "@/store/RadStudies/RadStudies";
import Templates from "@/store/Templates/Templates";
import Profile from "@/store/Profile/Profile";
import RadTemplatesHistory from "@/store/RadSettings/RadTemplatesHistory";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Layout,
    LocalStorage,
    DicomSeries,
    RadStudies,
    Templates,
    Profile,
    RadTemplatesHistory
  },
  strict: true,
  state,
  mutations,
  actions,
  getters: {
    studyId({ study }) {
      return study ? study.id : null;
    },
    studyStatus({ study }) {
      return study ? study.status : "";
    },
    quillShorthands({ shorthandList }) {
      return shorthandList.length
        ? shorthandList.map((item) => ({
            ...item,
            value: item.name,
            text: item.value,
          }))
        : [];
    },
    studyInfo: ({ study }) => study && study.studyInfo,
    requestType: ({ study }) => (study && study.studyInfo ? study.studyInfo.requestType : ""),
    aiResponse: ({ study }) => (study ? study.aiResponse : ""),
    isRadiologist({ currentUser }) {
      return currentUser && currentUser.type === UserTypes.radiology;
    },
    isMedicalAdmin({ currentUser }) {
      return currentUser && currentUser.type === UserTypes.medicalAdmin;
    },
    isAdmin({ currentUser }) {
      return currentUser && currentUser.type === UserTypes.admin;
    },
    showAiResponse({ study, currentUser }, { isAdmin, isMedicalAdmin }) {
      if (study && study.aiResponse !== AiResponseTypes.notPredicted) {
        return isAdmin || isMedicalAdmin || currentUser.allowAiFeedback;
      }
      return false;
    },
  },
});
