import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import * as mutationTypes from "@/store/mutation-types";

export const getQurePredictionStatus = async ({ commit }, studyId) => {
  commit(mutationTypes.SET_API_CALL, true);
  const url = apiEndpoints.getQurePredictionStatus + studyId;
  try {
    const response = await API.get(url);
    commit(mutationTypes.SET_API_CALL, false);
    return response.data;
  } catch {
    return null;
  }
};

export const getQurePredictionObject = async ({ commit }, studyId) => {
  commit(mutationTypes.SET_API_CALL, true);
  const url = apiEndpoints.getQurePredictionObject + studyId;
  try {
    const response = await API.get(url);
    commit(mutationTypes.SET_API_CALL, false);
    return response.data;
  } catch {
    return null;
  }
};

export default {
  getQurePredictionObject,
  getQurePredictionStatus,
};
