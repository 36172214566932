<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-2 mt-sm-n1"
        color="blue darken-1"
        dark
        fab
        small
        @click="toggleChangePassword"
      >
        <v-icon dark>mdi-lock-open</v-icon>
      </v-btn>
    </template>
    <span>Change password</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "ChangePasswordBtn",
  data() {
    return {
      enableChange: false,
    };
  },
  methods: {
    async toggleChangePassword() {
      this.$emit("enableChange", this.enableChange);
    },
  },
};
</script>