<template>
  <v-card style="margin: 110px auto; box-shadow: none;">
    <amplify-authenticator v-if="!showForgotUsernameSection">
      <amplify-sign-in slot="sign-in" header-text="Sign in to your account" submit-button-text="Login" hide-sign-up="true"
                       ref="signInComponent">
        <div slot="federated-buttons">
          <v-card
            class="flex pa-md-auto mx-lg-auto"
            flat
            tile
            style="background: #25262c"
          >
            <v-card-title>
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2 logoFDA"
                contain
                src="../../assets/logo-rology-fda.png"
                transition="scale-transition"
              />
            </v-card-title>
          </v-card>
        </div>
        <div slot="footer"></div>
      </amplify-sign-in>
    </amplify-authenticator>
    <ForgotUsername v-if="showForgotUsernameSection" />
    <div v-if="!showForgotUsernameSection" class="new-user">
      <div class="new-title"><strong>New to us?</strong></div>
      <div class="row">
        <div class="col-sm-6 col md-6">
          <a title="Register as Hospital/Medical Center" href="https://rology.health/healthcare-facility/" target="_blank">
            <Icon icon="fa:hospital-o"  color="#ffffff" width="50" />
          </a>
        </div>
        <div class="col-sm-6 col md-6">
          <a title="Register as Radiologist" href="https://rology.health/careers/radiologist/" target="_blank">
            <Icon icon="fa6-solid:user-doctor" color="white" width="50" />
          </a>
        </div>
      </div>
    </div>
    <div class="reserved">
      <a class="link" target="_blank" href="https://rology.health">Rology, Inc © 2020-2022</a>
      <div class="label">v {{version}}</div>
      <div class="label"># {{buildNumber}} - {{runningMode}}</div>
    </div>
  </v-card>
</template>

<script>
import { Icon } from '@iconify/vue2';
import axios from "axios";
import apiEndpoints from "@/util/apiEndpoints";
import ForgotUsername from "@/components/Login/ForgotUsername";


export default {
  components: {
    ForgotUsername,
    Icon
  },
  data() {
    return {
      version: "",
      buildNumber: "",
      runningMode: "",
      showForgotUsernameSection: false,
    };
  },
  mounted() {
    this.getAppVersion();
    window.addEventListener('hideSignIn', function () {
      document.querySelector("amplify-form-section").setAttribute("style", "display:none");
      document.getElementsByClassName("new-user")[0].setAttribute("style", "display:none");
    }, false);

    window.addEventListener('showSignIn', function () {
      document.querySelector("amplify-form-section").setAttribute("style", "display:block");
      document.getElementsByClassName("new-user")[0].setAttribute("style", "display:block");
    }, false);

    const signInComponent = this.$refs.signInComponent;
    // Check if the child component exists
    if (signInComponent) {
      setTimeout(() => {
        this.appendForgotUsername();
      }, 500); // Add a small delay (e.g., 100 milliseconds)
    }

    this.$root.$on("showForgotUsername", (value) => {
      this.showForgotUsernameSection = value;
      setTimeout(() => {
        this.appendForgotUsername();
      }, 300);
    });
  },
  methods: {
    async getAppVersion() {
      const url = `${apiEndpoints.getAppVersion}`;
      const response = await axios.get(url);
      const versionParts = response.data.version.split("@");
      this.version = versionParts[0];
      this.buildNumber = versionParts[1];
      this.runningMode = response.data.runningMode;
    },
    handleForgotUsernameBtnClick() {
      this.showForgotUsernameSection = !this.showForgotUsernameSection;
    },
    appendForgotUsername() {

      const forgotPassEle = document.getElementById("password-hint");
      const forgotUsernameElement = document.createElement('div');
      forgotUsernameElement.id = "forgot_username";
      forgotUsernameElement.textContent = 'Forgot your username? ';

      const forgotUsernameBtn = document.createElement('button');
      forgotUsernameBtn.textContent = 'Send username';
      forgotUsernameBtn.classList.add('anchor');
      forgotUsernameBtn.setAttribute('type', 'button');
      forgotUsernameBtn.addEventListener('click', this.handleForgotUsernameBtnClick);

      forgotUsernameElement.appendChild(forgotUsernameBtn);

      forgotPassEle.appendChild(forgotUsernameElement);

      console.log(document.querySelector('.form-field'))
      // Find the element in your component by classname
      const containerElement = document.querySelectorAll('.form-field')[1];
      // Check if the element with the classname exists
      if (containerElement) {
        // Append the new element to the container element
        containerElement.appendChild(forgotUsernameElement);
      }
    },
  }
};

</script>

<style>
:root {
  --amplify-primary-color: #3f51b5;
  --amplify-primary-tint: #3f6060;
  --amplify-primary-shade: #4b61dd;
  --amplify-background-color: #25262c;
  --amplify-secondary-color: gray;
}
.label {color: #808080;margin-bottom: 0px;}

.button {
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #0090ff;
}

#forgot_username {margin-top: -40px; font-size: 12.96px; color: rgb(130, 130, 130)}

button.anchor {color: #3f51b5;}

.v-sheet.v-card:not(.v-sheet--outlined) {box-shadow: none;}

.new-user {
  height: 150px; padding-top: 2px;
  background-color: #25262c;
  border-radius: 4px; width: 100%; text-align: center;
  color: #FFFFFF;
}

.new-title {
  margin-top: 10px; margin-bottom: 15px; font-size: 16px;
}

.reserved {
  text-align: center; margin-top: 6px;
}
.reserved .label {font-size: 12px;}
.reserved .link {
  font-size: 11px;
  color: #fff; text-decoration: none;
}

.logoFDA {
  width:336px;
}

@media only screen and (max-device-width: 480px) {
  .new-user {
    display: none;
  }
  .logoFDA {
    width:240px;
  }
}

.v-application--wrap {background-color: #1e1e1e}

.sign-in-form-footer {float: right;}
</style>