<template>
  <v-dialog v-model="dialog" width="800" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card>
      <v-card-title>Demerge Study</v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>DICOM Name</th>
              <th>Rology Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="study in patientHistoryList" :key="study.studyId">
              <td>{{ study.patientName }}</td>
              <td>{{ study.rologyPatientName }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="confirm" :disabled="disableButton">Demerge</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog :title="confirmTitle" @confirm="demerge" @cancel="cancel" v-if="showConfirm">
      Do you really want to demerge study of <strong class="red--text text--lighten-1">{{ study.patientName }}</strong>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import dialogMixins from "@/shared/dialog-mixins";
import { mapState, mapActions } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";

export default {
  name: "DemergeStudy",
  mixins: [dialogMixins],
  components: { ConfirmDialog },
  computed: {
    ...mapState(["patientHistoryList", "study"]),
    confirmBody() {
      return `Do you really want to demerge study of ${this.study.patientName}`;
    },
  },
  data() {
    return {
      dialog: true,
      disableButton: false,
      showConfirm: false,
      confirmTitle: "Demerge Study",
    };
  },
  methods: {
    ...mapActions(["demergeStudy"]),
    cancel() {
      this.showConfirm = false;
      this.$emit("resetAction");
    },
    async demerge() {
      this.disableButton = true;
      this.showConfirm = false;
      const url = `/medicalAdmin/unmergeStudy/studyId/${this.study.studyId}/rologyId/${this.study.rologyId}`;
      await this.demergeStudy(url);
      this.cancel();
    },
    confirm() {
      this.showConfirm = true;
    },
  },
};
</script>
