<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header class="rounded-0">
      {{ studyInfo ? studyInfo.requestType.replaceAll("_", " ").replaceAll(",", ", ") : "" }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="rounded-0" eager>
      <div class="thumbnail-vue thumbnails" :ref="study.studyId" :id="`study-series-id-${study.studyId}`" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "StudyTree",
  data() {
    return {
      studyLoaded: false,
    };
  },
  computed: {
    ...mapState(["study", "currentUser"]),
    ...mapGetters(["studyInfo"]),
    ...mapState("Layout", ["isViewerLoaded"]),
  },
  methods: {
    loadStudy() {
      if (!window["RADIOLOGY_VIEWER_SCRIPT"] && !this.studyLoaded) {
        console.log("waiting viewer script");
        this.timeoutCall = setTimeout(() => this.loadStudy(), 500);
      } else {
        clearTimeout(this.timeoutCall);
        const seriesWrapper = this.$refs[this.study.studyId];
        window["RADIOLOGY_VIEWER_SCRIPT"](this.study.studyId, seriesWrapper, null, false, null, false, this.currentUser.type, this.currentUser.allowAiFeedback);
        this.studyLoaded = true;
      }
    },
  },
  mounted() {
    if (this.isViewerLoaded) {
      this.loadStudy();
    }
  },
  watch: {
    isViewerLoaded(newValue) {
      if (newValue) {
        this.loadStudy();
      }
    },
  },
};
</script>
<style lang="scss">
body {
  // styles for the dragging series
  > .list-group-item {
    width: 200px;
    height: 125px;
    overflow: hidden;
    border: 2px solid #666;
    z-index: 5000;
    color: #ddd;
    border-radius: 3px;

    .csthumbnail {
      background: #000;
      width: 100%;
    }

    .series-footer {
      display: none;
    }
  }
}
.row {
  margin: 0;
}

.thumbnail-vue {
  min-height: 100px;

  a.series-item {
    width: 250px;
    color: white;
    display: block;
    margin: 15px auto 0 auto;
    position: relative;

    &.active-series .csthumbnail {
      border-color: #6cbffd !important;
    }

    .series-overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 20px);
      z-index: 5;
      background: transparent;
    }

    .csthumbnail {
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 10px;
      background: #000000;
    }

    .cornerstone-canvas {
      width: 100% !important;
      height: 100px !important;
    }

    .series-footer {
      font-size: 13px;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}
</style>
