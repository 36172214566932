<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-badge :content="commentsLength" :value="commentsLength" color="blue" offset-x="30" overlap>
        <v-btn class="tool-button level-1 py-1 px-2 comment-button" height="auto" text tile v-bind="attrs" v-on="on">
          <v-icon size="20">mdi-comment-multiple</v-icon>
          <div class="caption text-capitalize">Comments</div>
        </v-btn>
      </v-badge>
    </template>
    <v-card v-if="isStudy">
      <v-card-title style="text-transform: capitalize">Comments</v-card-title>
      <v-card-text>
        <CommentForm :save="true" @save="save"></CommentForm>
        <v-tabs v-model="tab" dark v-if="comments.length > 0">
          <v-tab>Newest</v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <CommentListItem v-for="(comment, index) in comments" :key="index" @edit="edit" @delete="deleteComment"
              :comment="comment">
            </CommentListItem>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import API from "@/shared/API";
import CommentForm from "@/components/StudyComments/CommentForm.vue";
import CommentListItem from "@/components/StudyComments/CommentListItem.vue";
import apiEndpoints from "@/util/apiEndpoints";

export default {
  name: "StudyComments",
  data() {
    return {
      dialog: false,
      valid: true,
      text: "",
      comments: [],
      commentsLength: 0,
      tab: null,
      timestamp: "",
      commentObj: { studyId: "", text: "", commentId: "" },
    };
  },
  mounted() {
    this.getCommentList();
  },
  components: { CommentForm, CommentListItem },
  created() { },
  props: {
    study: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isStudy() {
      return this.study && Object.keys(this.study).length > 0;
    },
  },
  methods: {
    async getCommentList() {
      try {
        const url = `${apiEndpoints.getComment}${this.study.studyId}`;
        const response = await API.get(url);
        this.comments = response.data;
        this.commentsLength = this.comments.filter(x => x.text != "Comment deleted").length;
      } catch (error) {
        console.log("No comments Found", error);
      }
    },
    getCurrentTime: function () {
      const today = new Date();
      const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      return (this.timestamp = dateTime);
    },
    async save(text) {
      try {
        this.commentObj.studyId = this.study.id;
        this.commentObj.text = text;
        const url = apiEndpoints.saveComment;
        const comment = (await API.post(url, this.commentObj)).data;
        if (comment.success != false) {
          comment.posted = comment.posted == null ? this.getCurrentTime() : comment.posted;
          this.comments.unshift(comment);
          await this.getCommentList();
        }
      } catch (error) {
        console.log("save comment fail", error);
      }
    },
    async edit(selectedComment, text) {
      try {
        this.commentObj.studyId = this.study.id;
        this.commentObj.text = text;
        this.commentObj.commentId = selectedComment.commentId;
        const url = apiEndpoints.editComment;
        const comment = (await API.put(url, this.commentObj)).data;
        comment.posted = comment.posted == null ? this.getCurrentTime() : comment.posted;
        this.comments[selectedComment.commentId] = comment;
        selectedComment.edited = comment.edited;
      } catch (error) {
        console.log("edit comment fail", error);
      }
    },
    async deleteComment(selectedComment) {
      try {
        const data = JSON.stringify({
          studId: this.study,
          commentId: selectedComment.commentId,
        });
        const url = apiEndpoints.deleteComment;
        await API.delete(url, {
          headers: { "Content-Type": "application/json" },
          data: data,
        });
        selectedComment.text = "Comment deleted";
        selectedComment.edited = null;
        await this.getCommentList();
      } catch (error) {
        console.log("edit comment fail", error);
      }
    },
  },
};
</script>
