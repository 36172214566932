<template>
  <div style="width: 100%">
    <div id="wrap">
      <div class="main">
        <!-- Tabs bar -->
        <!-- Tab content -->
        <div id="tabContent" class="tab-content">
          <div id="studyViewerTemplate" :data-id="this.id" class="tab-pane active" style="height: 100%">
            <div class="studyContainer" style="height: 100%; overflow: hidden">
              <div class="studyRow row" style="height: 100%">
                <div class="viewer">
                  <!-- Toolbar -->
                  <div class="text-center d-none">
                    <div class="btn-group">
                      <!-- WW/WL -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="WW/WC"
                        id="toolbarWW-WC"
                      >
                        <span class="fa fa-sun"></span>
                      </button>
                      <!-- WW/WL Region -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="WW/WC Region"
                        id="toolbarWW-WcRegion"
                      >
                        <span class="fa fa-vector-square" aria-hidden="true"></span>
                      </button>

                      <!-- preset WW/WL -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default dropdown-toggle dropdown-toggle-split"
                        data-container="body"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-placement="top"
                        title="Region"
                      >
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="pull-right dropdown-menu choose-region" role="menu">
                        <li>
                          <a href="#" id="regionBrain">Brain<span class="pull-right">1</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionLungs">Lungs<span class="pull-right">2</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionMediastinum">Mediastinum<span class="pull-right">3</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionSoftTissues">Soft Tissues<span class="pull-right">4</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionLiver">Liver<span class="pull-right">5</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionAngio">Angio<span class="pull-right">6</span></a>
                        </li>
                        <li>
                          <a href="#" id="regionBone">Bone<span class="pull-right">7</span></a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group">
                      <!-- Invert -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Invert"
                        id="toolbarInvert"
                      >
                        <span class="fa fa-adjust"></span>
                      </button>
                      <!-- Zoom -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Zoom"
                        id="toolbarZoom"
                      >
                        <span class="fa fa-search"></span>
                      </button>
                      <!-- Magnify -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Magnify"
                        id="toolbarMagnify"
                      >
                        <span class="fa fa-search-plus"></span>
                      </button>
                      <!-- Pan -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Pan"
                        id="toolbarPan"
                      >
                        <span class="fa fa-arrows-alt"></span>
                      </button>
                      <!-- Rotate -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Rotate"
                        id="toolbarRotate"
                      >
                        <span class="fa fa-redo"></span>
                      </button>
                      <!-- Stack scroll -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Stack Scroll"
                        id="toolbarStackScroll"
                      >
                        <span class="fa fa-bars"></span>
                      </button>
                      <!-- Length measurement -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Length Measurement"
                        id="measureLine"
                      >
                        <span class="fa fa-arrows-alt-v"></span>
                      </button>
                       <!-- Bidirectional measurement -->
                      <button type="button" class="btn btn-lg btn-default" data-container='body'
                              data-toggle="tooltip" data-placement="bottom"
                              title="Bidirectional Measurement" id="measureBidirectional"><span class="fa fa-arrows-alt-v"></span>
                      </button>
                      <!-- Annotation Tool -->
                      <button type="button" class="btn btn-lg btn-default" data-container='body'
                              data-toggle="tooltip" data-placement="bottom"
                              title="Arrow Annotation" id="toolbarArrowAnnotation"><span class="fa fa-arrows-alt-v"></span>
                      </button>
                      <!-- cobbAngle Tool -->
                      <button type="button" class="btn btn-lg btn-default" data-container='body'
                              data-toggle="tooltip" data-placement="bottom"
                              title="Arrow cobbAngle" id="toolbarCobbAngle"><span class="fa fa-arrows-alt-v"></span>
                      </button>
                      <!-- Angle measurement -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Angle Measurement"
                        id="measureAngle"
                      >
                        <span class="fa fa-angle-left"></span>
                      </button>
                      <!-- Pixel probe -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Pixel Probe"
                        id="toolbarPixelProbe"
                      >
                        <span class="far fa-dot-circle"></span>
                      </button>
                      <!-- Elliptical ROI -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        id="toolbarEllipticalRoi"
                        title="Elliptical ROI"
                      >
                        <span class="far fa-circle"></span>
                      </button>
                      <!-- Rectangle ROI -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Rectangle ROI"
                        id="toolbarRectangleRoi"
                      >
                        <span class="far fa-square"></span>
                      </button>
                      <!-- Free Hand -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Free Hand"
                        id="toolbarFreeHand"
                      >
                        <span class="fa fa-pencil-alt"></span>
                      </button>
                      <!-- Play clip -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Play Clip"
                        id="toolbarPlayClip"
                      >
                        <span class="fa fa-play"></span>
                      </button>
                      <!-- Stop clip -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Stop Clip"
                        id="toolbarStopClip"
                      >
                        <span class="fa fa-stop"></span>
                      </button>

                      <!-- Crosshairs -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default crossh syncTools"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Crosshairs"
                        id="toolbarCrosshairs"
                        disabled
                      >
                        <span class="fa fa-crosshairs"></span>
                      </button>

                      <!-- Localizer -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default localizer syncTools"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Localizer"
                        id="toolbarLocalizer"
                        disabled
                      >
                        <span class="fa fa-diagnoses"></span>
                      </button>

                      <!-- Sync -->
                      <button
                        type="button"
                        class="btn btn-lg btn-default sync syncTools"
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Synchronization"
                        id="toolbarSynchronization"
                        disabled
                      >
                        <span class="fa fa-link"></span>
                      </button>

                      <!-- ADD NEW TOOLS HERE -->

                      <button
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        data-placement="top"
                        title="Layout"
                      >
                        <span class="fa fa-th-large"></span>
                      </button>
                      <ul class="pull-right dropdown-menu choose-layout" role="menu">
                        <li><a href="#" id="oneByOne">1x1</a></li>
                        <li><a href="#" id="twoByOne">2x1</a></li>
                        <li><a href="#" id="oneByTwo">1x2</a></li>
                        <li><a href="#" id="twoByTwo">2x2</a></li>
                      </ul>
                      <!-- Medcial History -->
                      <button
                        id="medHistoryPop"
                        type="button"
                        class="btn btn-lg btn-default"
                        data-container="body"
                        data-toggle="popover"
                        data-placement="bottom"
                        data-html="true"
                        title="Medical History"
                      >
                        <span class="fa fa-list-alt"></span>
                      </button>
                      <span id="medHistoryContainer"></span>
                    </div>
                  </div>
                  <!-- Viewer -->
                  <div class="imageViewer">
                    <div
                      class="viewportWrapper cornerstone-enabled-image"
                      style="width: 100%; height: 100%; position: relative; color: white; background-color: black"
                      oncontextmenu="return false"
                      unselectable="on"
                      onselectstart="return false;"
                      onmousedown="return false;"
                    >
                      <!-- Viewport -->
                      <div class="viewport" tabindex="-1"></div>

                      <!-- Overlays -->
                      <div
                        class="overlay dicom-overlay-top-left"
                        style="top: 8px; left: 8px"
                        :style="{ color: theme.primary }"
                      >
                        <div>Patient Name</div>
                        <div>Patient Id</div>
                        <div>Patient Age</div> 
                        <div style="color: yellow;"></div>
                      </div>
                      <!-- overlay -->

                      <div
                        class="overlay dicom-overlay-top-right text-right"
                        style="top: 8px; right: 8px"
                        :style="{ color: theme.primary }"
                      >
                        <div>Study Description</div>
                        <div>Study Date</div>
                      </div>
                      <!-- overlay -->

                      <div
                        class="overlay dicom-overlay-bottom-left"
                        style="bottom: 8px; left: 8px"
                        :style="{ color: theme.primary }"
                      >
                        <div class="fps"></div>
                        <div class="acDate"></div>
                        <div class="renderTime"></div>
                        <div class="currentImageAndTotalImages"></div>
                      </div>
                      <!-- overlay -->

                      <div
                        class="overlay dicom-overlay-bottom-right text-right"
                        style="bottom: 8px; right: 8px"
                        :style="{ color: theme.primary }"
                      >
                        <div></div>
                        <div></div>
                      </div>
                      <!-- overlay -->
                    </div>
                    <!-- viewportWrapper cornerstone-enabled-image -->
                  </div>
                  <!-- imageViewer -->
                </div>
                <!-- viewer -->
              </div>
            </div>
          </div>
        </div>
        <!-- Study viewer tab content template -->
      </div>
      <div class="loader-indicator d-flex">
        <a class="navbar-brand" href="#" style="color: gray; font-size: 11px">
          Rology © {{ new Date().getFullYear() }}
        </a>
        <div class="led-box">
          <div id="led-light" class="led-red"></div>
        </div>
        <v-spacer />
        <span class="subtitle-2 status" v-if="isAdmin || isMedicalAdmin">
          <strong>Status:</strong>&nbsp;{{ study.status }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import UserTypes from "@/util/UserTypes";
import apiEndpoints from "@/util/apiEndpoints";
import { RologyDark } from "@/plugins/themes";

export default {
  name: "DicomViewer",
  computed: {
    ...mapState(["currentUser", "study"]),
    isMedicalAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.medicalAdmin;
    },
    isAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.admin;
    },
  },
  data() {
    return {
      id: "",
      theme: RologyDark,
    };
  },
  methods: {
    ...mapActions(["getShorthandList"]),
    ...mapActions("Layout", ["toggleViewerLoadingState"]),
    ...mapActions("Templates", ["getTemplatesList"]),
    setupReporting() {
      this.getShorthandList(apiEndpoints.shorthandList);
      this.getTemplatesList();
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    const { type } = this.currentUser;
    if (type === UserTypes.radiology) {
      this.setupReporting();
    }
    this.toggleViewerLoadingState(true);
  },
};
</script>
<style scoped lang="css" src="../../assets/legacy/font-awesome.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/bootstrap.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/jquery-ui.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/bootstrap-select.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/cornerstone.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/cornerstoneDemo.css"></style>
<style scoped lang="css" src="../../assets/legacy/fileinput.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/ui.fancytree.min.css"></style>
<style scoped lang="css" src="../../assets/legacy/viewer.css"></style>
<style scoped lang="css" src="../../assets/legacy/maViewer.css"></style>
<style scoped lang="css" src="../../assets/legacy/fine-uploader-new.css"></style>
<style scoped lang="css" src="../../assets/legacy/fine-uploader-gallery.css"></style>
<style scoped lang="css" src="../../assets/legacy/quill.snow.css"></style>
<style scoped lang="css" src="../../assets/legacy/quill.mention.min.css"></style>
<style scoped lang="scss">
.loader-indicator {
  align-items: center;
  padding: 5px;

  > * {
    height: auto !important;
    padding: 0 !important;
    margin: 0 15px 0 0 !important;
  }

  #led-light {
    width: 15px;
    height: 15px;
  }

  .status {
    color: gray;
    margin: 0 !important;
  }
}
</style>
