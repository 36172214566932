import API from "@/shared/API";
import {errorHandler} from "@/store/shared";

export const GET_RAD_TEMPLATES_HISTORY = "GET_RAD_TEMPLATES_HISTORY";
export const SET_HIDE_TEMPLATES_HISTORY = "SET_HIDE_TEMPLATES_HISTORY";
export const ADD_TEMPLATE_TO_HISTORY = "ADD_TEMPLATE_TO_HISTORY";
export const EMPTY_NEW_TEMPLATES_ADDED = "EMPTY_NEW_TEMPLATES_ADDED";

const RadTemplatesHistory = {
    namespaced: true,
    state: {
        templatesHistory: [],
        newTemplatesAdded: [],
        hideTemplatesHistory: true
    },
    actions: {
        async getTemplatesHistory({dispatch, commit}, url) {
            try {
                const response = await API.get(url);
                if (response.data.results.length > 0) {
                    commit(GET_RAD_TEMPLATES_HISTORY, response.data.results);
                    commit(SET_HIDE_TEMPLATES_HISTORY, false);
                }else {
                    commit(GET_RAD_TEMPLATES_HISTORY, response.data.results);
                    commit(SET_HIDE_TEMPLATES_HISTORY, true);
                }
            } catch (error) {
                console.log("Error while getting Rad templates history.");
                errorHandler(error, dispatch, "Error Getting Rad templates history ", url);
            }
        },
        applyTemplate({commit}, val) {
            commit(ADD_TEMPLATE_TO_HISTORY, val);
        },
        async saveTemplatesHistory({commit, dispatch, state}, url) {
            try {
                await API.post(url, state.newTemplatesAdded);
                commit(EMPTY_NEW_TEMPLATES_ADDED);
            } catch (error) {
                console.log("Error while saving Rad templates history.");
                errorHandler(error, dispatch, "Error saving Rad templates history ", url);
            }
        },
        emptyNewTemplatesAdded({commit}) {
            commit(EMPTY_NEW_TEMPLATES_ADDED);
        }
    },
    mutations: {
        [GET_RAD_TEMPLATES_HISTORY](state, list) {
            state.templatesHistory = list;
        },
        [SET_HIDE_TEMPLATES_HISTORY](state, val) {
            state.hideTemplatesHistory = val;
        },
        [ADD_TEMPLATE_TO_HISTORY](state, val) {
            state.newTemplatesAdded.push(val);
        },
        [EMPTY_NEW_TEMPLATES_ADDED](state) {
            state.newTemplatesAdded = [];
        }
    },
    getters: {
        getAllTemplatesHistory(state) {
            return state.templatesHistory;
        },
        getHideTemplatesHistory(state) {
            return state.hideTemplatesHistory;
        },
        getNewAddedTemplates(state) {
            return state.newTemplatesAdded;
        }
    }

}

export default RadTemplatesHistory;