<template>
  <v-navigation-drawer
    id="MaliciousCutsDrawer"
    class="resizable-drawer"
    :app="drawer.isPinned"
    hide-overlay
    :style="style"
    absolute
    :value="drawer.visible"
    :width="width"
    :right="drawer.right"
    ref="currentSidebar"
  >
    <v-card tile flat height="100%" @mouseleave="mouseLeave" class="scrollable-card" :loading="!isStudyLoaded">
      <span class="sidebar-handler" :class="{ right: drawer.right }" @mousedown="mouseDownHandler($event)" />
      <v-card-title>
        <span class="primary--text subtitle-1 font-weight-bold">Malicious Cuts</span>
        <v-spacer />
        <v-btn icon class="d-none d-md-flex">
          <v-icon @click="pin">{{ drawer.isPinned ? "mdi-pin" : "mdi-pin-off" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <MaliciousCuts v-if="isStudyLoaded" />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { MALICIOUS_CUTS_DRAWER } from "@/shared/Constants";
import SidebarMixins from "@/components/SideBars/SidebarMixins";
import MaliciousCuts from "@/components/MaliciousCuts/MaliciousCuts";
import { mapGetters, mapState } from "vuex";

export default {
  name: MALICIOUS_CUTS_DRAWER,
  components: { MaliciousCuts },
  mixins: [SidebarMixins],
  data() {
    return {
      componentName: MALICIOUS_CUTS_DRAWER,
    };
  },
  computed: {
    ...mapGetters(["studyId"]),
    ...mapState("DicomSeries", ["loadedStudies"]),
    isStudyLoaded() {
      return this.loadedStudies[this.studyId];
    },
  },
};
</script>
