/* eslint-disable */
const maxTimerToCountDown = 180;
const initialCountDownTimerInMin = 3;
const initialCountDownTimerInSec = 0;

class MprHandler {
    disableMPR; // to enable/disable mpr button
    isMprLoading; // to enable/disable icon progressBarTool of mpr
    mprSeriesListStatus;
    remainingMin; // remaining min to show countDown timer per series
    remainingSec; // remaining sec to show countDown timer per series

    constructor() {
        this.disableMPR = false;
        this.isMprLoading = false;
        this.mprSeriesListStatus = {};
        this.remainingMin = initialCountDownTimerInMin;
        this.remainingSec = initialCountDownTimerInSec;
    }

    getInProgressMsg(mprObj, seriesDetails) {
        let remainingMin = "";
        let remainingSec = "";
        let msg = "";
        if (seriesDetails) {
            const { seriesDesc, stackId } = seriesDetails;
            const objKey = `${seriesDesc}-${stackId}`;
            let mprSeriesObj = this.mprSeriesListStatus[objKey];
            remainingMin = mprSeriesObj.remainingMin;
            remainingSec = mprSeriesObj.remainingSec;
            mprObj.remainingMin = remainingMin;
            mprObj.remainingSec = remainingSec;
            msg = `MPR generation in-progress.`; 
        }
        return msg;
    }

    resetMprBtn(mprObj, seriesDetails) {
        mprObj.isMprLoading = false;
        mprObj.disableMPR = false;
    }

    updateMprStatus(mprObj, seriesDetails) {
        const { seriesDesc, stackId } = seriesDetails;
        const objKey = `${seriesDesc}-${stackId}`;
        if (!this.mprSeriesListStatus[objKey]) {
            this.resetMprBtn(mprObj, seriesDetails);
            this.mprSeriesListStatus[objKey] = {
                seriesDesc,
                stackId,
                isMprLoading: this.isMprLoading,
                disableMPR: this.disableMPR,
                trialNum: 0,
                countDownTimer: maxTimerToCountDown,
                remainingMin: 3,
                remainingSec: 0,
                CoronalReady: false,
                SagittalReady: false
            };
        } else { // btn clicked before and exists on series Obj, so we will get btn status from saved obj and show it
            mprObj.isMprLoading = this.mprSeriesListStatus[objKey].isMprLoading;
            mprObj.disableMPR = this.mprSeriesListStatus[objKey].disableMPR;
        }
    }

    countDownTimer(objKey, mprObj) {
        let mprSeriesObj = this.mprSeriesListStatus[objKey];
        if (mprSeriesObj.countDownTimer > 0) {
            mprSeriesObj.timeout = setTimeout(() => {
                let totalRemainingTime = mprSeriesObj.countDownTimer;
                mprObj.remainingMin = Math.floor(totalRemainingTime / 60);
                mprObj.remainingSec = totalRemainingTime - (mprObj.remainingMin * 60);
                totalRemainingTime--;
                mprSeriesObj.countDownTimer = totalRemainingTime;
                mprSeriesObj.remainingMin = mprObj.remainingMin;
                mprSeriesObj.remainingSec = mprObj.remainingSec;
                this.countDownTimer(objKey, mprObj);
            }, 1000);
        }
    }

    syncBtnWithCurrentStatus(objKey, seriesDetails, mprObj) {
        const { seriesDesc, stackId } = seriesDetails;
        const currentSeries = `${seriesDesc}-${stackId}`;
        if (objKey === currentSeries) { // Sync btn status if current selected is equals in progress objKey
            mprObj.isMprLoading = this.mprSeriesListStatus[objKey].isMprLoading;
            mprObj.disableMPR = this.mprSeriesListStatus[objKey].disableMPR;
        }
    }

    updateMprSeriesListStatus(objKey, isMprLoading, disableMPR, recursiveCallsCounter) {
        this.mprSeriesListStatus[objKey].isMprLoading = isMprLoading;
        this.mprSeriesListStatus[objKey].disableMPR = disableMPR;
        this.mprSeriesListStatus[objKey].trialNum = recursiveCallsCounter;
    }

    resetMprSeriesListObj(objKey, seriesDetails, mprObj) {
        let obj = this.mprSeriesListStatus[objKey];
        obj.isMprLoading = false;
        obj.disableMPR = false;
        obj.trialNum = 0;
        obj.countDownTimer = maxTimerToCountDown;
        obj.remainingMin = initialCountDownTimerInMin;
        obj.remainingSec = initialCountDownTimerInSec;
        obj.CoronalReady = false;
        obj.SagittalReady = false;
        this.syncBtnWithCurrentStatus(objKey, seriesDetails, mprObj);
        clearTimeout(obj.timeout);
    }

    setCoronalReady(objKey) {
        this.mprSeriesListStatus[objKey].CoronalReady = true;
    }
    getCoronalReady(objKey) {
        return this.mprSeriesListStatus[objKey].CoronalReady;
    }
    setSagittalReady(objKey) {
        this.mprSeriesListStatus[objKey].SagittalReady = true;
    }
    getSagittalReady(objKey) {
        return this.mprSeriesListStatus[objKey].SagittalReady;
    }
}

export default MprHandler;

