<template>
  <section :key="studyReportsList.length" class="d-flex align-center reports-list">
    <template v-for="(item, key) in studyReportsList">
      <div class="report-item" :class="{ active: key + 1 === reportPage }" :key="item.id"
        @click="clickHandler(key + 1)">
        <v-btn icon plain depressed>
          <span>{{ key + 1 }}</span>
        </v-btn>
        <span class="caption">{{ item.displayName.replaceAll(',', ' , ') }}</span>
      </div>
      <v-divider v-if="key !== studyReportsList.length - 1" :key="key"></v-divider>
    </template>
  </section>
</template>

<script>
export default {
  name: "CustomStepper",
  props: {
    studyReportsList: {
      type: Array,
      default: null,
    },
    reportPage: {
      type: Number,
      default: 1,
    },
    changeReport: {
      type: Function,
      default: () => 0,
    },
  },
  methods: {
    clickHandler(reportNumber) {
      this.$emit("changeReport", reportNumber);
    },
  },
};
</script>

<style scoped lang="scss">
.reports-list {
  border-radius: 4px;
  margin-bottom: 15px;
  background: rgb(48, 48, 48);
  transition: all 0.4s;

  .report-item {
    padding: 15px 10px;
    cursor: pointer;

    &:hover {
      background: #404040;
    }

    button {
      width: 28px;
      height: 28px;
      margin-right: 5px;
      background: #404040;
    }

    &.active {
      button {
        background: #4a148c;
      }
    }
  }
}
</style>
