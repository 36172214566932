import Vue from "vue";
import VueRouter from "vue-router";
import StudyViewer from "../views/StudyViewer.vue";
import PageNotFound from "@/views/PageNotFound";
import ClientRoutes from "@/router/ClientRoutes";
import Login from "../components/Login/Login"
import RadiologistStudies from "../views/RadiologistStudies"
import RadiologistProfile from "../views/RadiologistProfile"
import Home from "../views/Home"
import Signout  from "@/components/Logout/Signout";
import qs from "qs";
import { Auth } from "aws-amplify";
import RadiologistSettings from "@/views/RadiologistSettigs";
import TemplatesSettings_Pickup from "@/views/TemplatesSettings_Pickup";

Vue.use(VueRouter);

const routes = [
  { path: "/login", component: Login },
  { path: "/", component: Home,
    children: [
      {
        name: ClientRoutes.radiologistProfile,
        path: "/radiologist-profile",
        component: RadiologistProfile,
      },
      {
        name: ClientRoutes.radiologistStudies,
        path: "/radiologist-studies",
        component: RadiologistStudies
      },
      {
        path: "/study-path/:id",
        name: ClientRoutes.studyViewer,
        component: StudyViewer,
      },
      {
        path: "/study-report/:id/:isOldStudy",
        name: ClientRoutes.report,
        component: () => import(/* webpackChunkName: "report" */ "@/views/Report.vue"),
      },
      {
        path: "/radiologist-settings",
        name: ClientRoutes.radiologistSettings,
        component: RadiologistSettings,
      },
      {
        path: "/manage-templates",
        name: ClientRoutes.templatesSettings_Pickup,
        component: TemplatesSettings_Pickup,
      },
    ]
  },

  {
    path: "/study-mpr/:id/seriesNum/:seriesNum/seriesDesc/:seriesDesc/instanceUid/:instanceUid/mprPlane/:mprPlane",
    name: ClientRoutes.mpr,
    component: () => import(/* webpackChunkName: "MPR" */ "@/views/MPR.vue"),
  },
  {
    path: "/signout",
    name: ClientRoutes.signout,
    component: Signout
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/portal/",
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const parsed = qs.stringify(query, { encode: false });
    return parsed ? `?${parsed}` : "";
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = false;
  isAuthenticated = await checkAuthenticated();
  if (to.path !== "/login" && !isAuthenticated) {
    next({ path: "/login" });
  } else {
    if ((to.path == "/login" || to.path == "/") && isAuthenticated && localStorage.role == "R") {
      next({ path: "/radiologist-studies" });
    } else if ((to.path == "/login" || to.path == "/") && isAuthenticated && localStorage.role != "R") {
      window.location.href = "/";
    }
    else next();
  }
});

async function checkAuthenticated() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}
