<template>
  <v-card class="mx-auto" flat max-height="fit-content" max-width="1038" tile>
    <v-card-text class="pa-2">
      <v-text-field v-model="email" dense disabled label="Email" outlined></v-text-field>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            v-model="firstName"
            :rules="rules.name"
            dense
            label="First name"
            outlined
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="lastName"
            :rules="rules.name"
            dense
            label="Second name"
            outlined
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-textarea v-model="bio" :rules="rules.bio" auto-grow hide-details label="Bio" outlined rows="2"></v-textarea>
      <br />
      <v-row dense>
        <v-col cols="6">
          <v-textarea
              v-model="signature"
              :rules="rules.sign"
              auto-grow
              hide-details
              label="Signature"
              outlined
              rows="2"></v-textarea>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" class="signatureImageBorder" v-if="false">
          <label>Signature Image</label>
          <v-img v-bind:src="signatureImage" style="height: 100%; width: 100%;">
            <v-expand-transition>
              <v-overlay absolute class="parent" style="height: 100%; width: 100%;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab icon small class="vInputIcon">
                      <v-file-input
                          id="fileInput"
                          accept="image/*"
                          hide-input
                          multiple
                          prepend-icon="mdi-camera"
                          @change="uploadSignatureImage"
                      ></v-file-input>
                    </v-btn>
                  </template>
                  <span>Add Image Signature</span>
                </v-tooltip>
              </v-overlay>
            </v-expand-transition>
          </v-img>
        </v-col>
      </v-row>
    </v-card-text>
    <v-subheader class="pa-2">
      <v-btn color="blue darken-3">
        <v-icon>mdi-access-point</v-icon>
      </v-btn>
    </v-subheader>
    <v-divider />
    <v-card-text class="pa-2">
      <v-text-field v-model="facebook" dense label="Facebook" outlined></v-text-field>
      <v-text-field v-model="linkedin" dense label="LinkedIn" outlined></v-text-field>
      <v-text-field v-model="twitter" dense label="Twitter" outlined></v-text-field>
    </v-card-text>

    <v-card-actions class="pa-2 mt-sm-n6">
      <v-spacer></v-spacer>
      <v-progress-circular
          v-if="isProfileEdited" style="margin: 10px"
          indeterminate
          color="primary"
      />
      <v-btn :disabled="!formIsValid" class="pa-2" color="blue" @click="editProfile()">Save</v-btn>
      <v-btn class="pa-2" color="red" @click="closeForm()">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapState} from "vuex";
import apiEndpoints from "@/util/apiEndpoints";

export default {
  name: "RadProfileForm",
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      bio: "",
      signature: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        bio: [(val) => (val || "").length > 8 || "This field required at least 8 characters"],
        sign: [(val) => (val || "").length > 0 || "Please enter your signature"]
      },
      isProfileEdited: false,
      signatureImage: this.signatureImageURL
    };
  },
  computed: {
    formIsValid() {
      return this.firstName && this.lastName && this.bio && this.signature;
    },
    ...mapState("Profile", ["signatureImageURL"])
  },
  props: {
    radProfileInfo: null,
  },
  created() {
    this.email = this.radProfileInfo.email ? this.radProfileInfo.email : "";
    this.firstName = this.radProfileInfo.firstName ? this.radProfileInfo.firstName : "";
    this.lastName = this.radProfileInfo.lastName ? this.radProfileInfo.lastName : "";
    this.bio = this.radProfileInfo.radBio ? this.radProfileInfo.radBio : "";
    this.signature = this.radProfileInfo.signatureText ? this.radProfileInfo.signatureText : "";
    this.facebook = this.radProfileInfo.facebook ? this.radProfileInfo.facebook : "";
    this.linkedin = this.radProfileInfo.linkedin ? this.radProfileInfo.linkedin : "";
    this.twitter = this.radProfileInfo.twitter ? this.radProfileInfo.twitter : "";
  },
  methods: {
    ...mapActions("Profile", ["setSignatureImageURL", "getSignatureImage"]),
    editProfile() {
      /* eslint-disable vue/no-mutating-props */
      this.radProfileInfo.firstName = this.firstName;
      this.radProfileInfo.lastName = this.lastName;
      this.radProfileInfo.radBio = this.bio;
      this.radProfileInfo.signatureText = this.signature;
      this.radProfileInfo.facebook = this.facebook;
      this.radProfileInfo.linkedin = this.linkedin;
      this.radProfileInfo.twitter = this.twitter;
      this.radProfileInfo.signatureImage = this.signatureImage;
      /* eslint-enable vue/no-mutating-props */
      this.setSignatureImageURL(this.signatureImage);
      this.$emit("edit", this.radProfileInfo);
      this.closeForm();
    },
    closeForm() {
      this.$emit("close");
    },
    async uploadSignatureImage(files) {
      this.signatureImage = await this.readFileAsync(files);
      // eslint-disable-next-line vue/no-mutating-props
      this.radProfileInfo.signatureImageURL = files[0];
    },
    async loadRadSignatureImage() {
      try {
        const signatureUrl = `${apiEndpoints.getRadSignatureImage}`;
        await this.getSignatureImage(signatureUrl);
        this.signatureImage = this.signatureImageURL;
      } catch (error) {
        console.log("Error while getting signature image for current user.");
      }
    },
    readFileAsync(files) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function (e) {
          resolve(e.target.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(files[0]);
      });
    }
  },
  mounted() {
    this.loadRadSignatureImage();
  }
};
</script>
<style>
.signatureImageBorder {
  border: 2px solid #545454;
  border-radius: 8px;
  position: relative;
  background-color: white;
}

.signatureImageBorder label {
  position: absolute;
  top: -13px;
  left: 15px;
  background-color: rgb(30, 30, 30);
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 8px;
  z-index: 1;
}

.signatureImageBorder:hover {
  border-color: white;
}

.vInputIcon {

}

.vInputIcon.v-btn--icon.v-size--small .v-icon, .vInputIcon.v-btn--fab.v-size--small .v-icon {
  width: 100px !important;
  height: 100px !important;
}

.vInputIcon .v-input__icon {
  width: 100px;
}
.row--dense > .col, .row--dense > [class*=col-] {
  padding: 0;
}
</style>