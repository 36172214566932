<template >
<!--  <v-container class="pt-0 pb-0" fluid>-->
<div>
  <div style="margin: 40px; margin-left: 100px;">
    <ComboFilter :dataItems="radiologistsFilterItems" :isOutlined="true" :isDense="true" label="Choose a radiologist" v-if="loadingRadiologists" ref="radiologistsFilter"
                 @loadItemsChildrenEmit="loadRadDataHandler" textProperty="name" valueProperty="value" style="float: left; width: 500px;"/>

    <v-btn color="blue" @click="showTemplate()" v-if="pickupModalities.length > 0 && (radId != null && radId != undefined && radId != '') " style="margin-left: 50px;">APPLY TEMPLATE</v-btn>
  </div>
  <div style="clear: both"></div>
  <v-row style="margin-left: 66px;" v-if="pickupModalities.length > 0 && (radId != null && radId != undefined && radId != '') ">
    <v-col cols="auto">
      <v-checkbox label="Pediatric" v-model="pediatricPickup" />
    </v-col>
    <v-col cols="auto">
      <v-checkbox label="Emergency" v-model="emergencyPickup" />
    </v-col>
  </v-row>
  <v-tabs v-if="pickupModalities.length > 0 && (radId != null && radId != undefined && radId != '') "
          v-model="active_tab" style="margin-left: 50px; width: 60%; float: left; margin-right: 70px;">
    <v-tab  v-for="modality in pickupModalities" :key="modality" @click="loadSubSpecialtyList(modality,true)">
      {{modality}}
    </v-tab>
    <v-tab-item v-for ="modality in pickupModalities" :key="modality" >
      <v-row dense >
        <v-col cols="12" md="3">
          <ComboFilter style="margin-left: 20px;" :dataItems="subSpecialtiesNames_Appeared"  :isMultiple="true" :isChips="true" label="Sub Specialities" v-if="loadingSubSpecialties" ref="subSpecialtiesFilter"
                       @loadItemsChildrenEmit="loadSubSpecialtiesHandler"
          />
        </v-col>
      </v-row>
      <DataList :dataItems="requestTypes" :dataHeaders="dataListHeaders" :hideDefaultHeader="true" contentsName="Requests" v-if="loadingRequestTypes"  ref="tableList"
                @checkBooleanEmit="checkBooleanHandler" @showFilteredDataEmit="showFilteredDataHandler" :id="modality"
      />
    </v-tab-item>
  </v-tabs>

    <TemplatesHistoryList v-if="!hideTemplatesHistory" :dataItems="templatesHistoryList" style="width: 30%; margin-top: 90px;"/>

  <div style="clear: both"></div>

  <v-btn color="blue" @click="save(true)" v-if="pickupModalities.length > 0 && (radId != null && radId != undefined && radId != '') " style="margin-left: 66px;">SAVE CONFIGURATIONS</v-btn>

  <ConfirmDialog :title="confirmTitle" @confirm="save(false)" @cancel="cancel" v-if="showConfirm">
    There is unsaved data for the radiologist <strong class="red--text text--lighten-1">{{ radName }}</strong> ,<br>
    Please confirm if you want to save the data , or cancel the saving operation and continue
  </ConfirmDialog>
  <TemplatesSettings_Pickup :isForApply="show_Template" :show_Template="show_Template" :radId="radId" @applyTemplateEmit="applyTemplateHandler" @applyTemplateCancelEmit="applyTemplateCancelHandler" v-if="show_Template"></TemplatesSettings_Pickup>

</div>
   <!--  </v-container>-->
</template>

<script>
import apiEndpoints from "@/util/apiEndpoints";
import { mapActions, mapGetters, mapState } from "vuex";
import DataList from "@/components/RadSettings/DataList";
import ComboFilter from "@/components/RadSettings/ComboFilter";
import {cloneDeep} from "lodash";
import {ModalitySubSpecialties, PickupModality, RadRequestTypes} from "@/util/Interfaces";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";
import TemplatesSettings_Pickup from "@/views/TemplatesSettings_Pickup";
import ClientRoutes from "@/router/ClientRoutes";
import TemplatesHistoryList from "@/components/RadSettings/TemplatesHistoryList"
import API from "@/shared/API"

export default {
  name: "RadiologistSettings",
  components: { DataList,ComboFilter,ConfirmDialog,TemplatesSettings_Pickup, TemplatesHistoryList },
  data(){
    return {
      active_tab: 0,
      confirmTitle:"Saving data confirm",
      dataListHeaders:[],
      dataListItems: [],
      performances:[],
      loadingSubSpecialties: false,
      loadingRadiologists: false,
      loadingRequestTypes: false,
      modalities: [],
      pickupModality:"",
      pickupModalities:[],
      pickupModalityList:[],
      radiologistsFilterItems:[],
      radDataList:[],
      radiologistListUrl: "/lookups/radiologistList",
      radId:"",radName:"",
      radRequestTypesList: [],
      requestTypes: [],
      requestTypes_Appeared: [],
      subSpecialtiesFilterItems: [],
      subSpecialties: [],
      subSpecialtiesNames: [],
      subSpecialtiesNames_Appeared: [],
      showConfirm: false,
      selected_rad:"",
      show_Template:false,
      dataChanged:false,
      clientRoutes:ClientRoutes,
      templatesHistoryList: [],
      hideTemplatesHistory: true,
      radSettings:{},
      emergencyPickup: false,
      pediatricPickup: false,
    }
  },
  props: {
  },
  computed: {
    ...mapState( ["headersBooleanStored"] ),
  },
  methods: {
    ...mapActions(["getDataFromUrl","setDataToUrl","setMessage","setHeadersBooleanStored", "getCurrentUser"]),
    ...mapActions("RadTemplatesHistory", ["getTemplatesHistory", "saveTemplatesHistory", "emptyNewTemplatesAdded"]),
    ...mapGetters("RadTemplatesHistory", ["getAllTemplatesHistory", "getHideTemplatesHistory", "getNewAddedTemplates"]),
    cancel(){
      this.changeRad(this.selected_rad);
      this.dataChanged = false;
      this.showConfirm = false;
    },
    applyTemplateHandler(value){
      let radIndex  = this.radDataList.findIndex( rad => rad.radId === this.radId);
      for(let templateDataItem of value.templateData) {
        let radDataIndex = this.radDataList[radIndex].data.findIndex((el) => (
                el.requestType === templateDataItem.requestType ||
                el.requestType === 'ALL')
            && el.modality === templateDataItem.modality );
        if(radDataIndex > -1){
          this.radDataList[radIndex].data[radDataIndex] = templateDataItem;
        }
        else{
          this.radDataList[radIndex].data.push(templateDataItem);
        }
      }
      this.showRadData(this.pickupModalities[this.active_tab]);
      this.dataChanged = true;
      this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].showFilteredDataEvent();
      this.show_Template = false;
    },
    applyTemplateCancelHandler(){
      this.show_Template = false;
    },
    async changeRad(rad){
      this.$nextTick(async () => {
        this.radId = rad.value.value;
        this.radName = rad.value.name;
        this.selected_subSpecialties = "";
        if (this.$refs.subSpecialtiesFilter != undefined) {
          for (let i = 0; i < this.$refs.subSpecialtiesFilter.length; i++) {
            this.$refs.subSpecialtiesFilter[i].selected_dataItems = [];
          }
        }
        this.isRadChanged = true;
        this.modalitySubSpecialtiesList = [];
        this.active_tab = 0;
        this.resetStore();
        this.loadSubSpecialtyList(this.pickupModalities[this.active_tab], true);
        let radTemplatesHistoryUrl = `${apiEndpoints.getRadTemplatesHistory}`;
        await this.getRadProfileSettings(this.radId);
        this.emergencyPickup = this.radSettings.emergencyPickup;
        this.pediatricPickup = this.radSettings.pediatricPickup;
        radTemplatesHistoryUrl += `/${this.radId}`;
        await this.getTemplatesHistory(radTemplatesHistoryUrl);
        this.templatesHistoryList = this.getAllTemplatesHistory();
        this.hideTemplatesHistory = this.getHideTemplatesHistory();
      });
    },
    checkBooleanHandler(value){
      let additionalFilters =[];
      let additionalFilter = { name:'PICKUP_MODALITY', value: value.item['PICKUP_MODALITY'], type:'single' };
      additionalFilters.push(additionalFilter);
      additionalFilter = { name:'VALUE', value:value.item['VALUE'], type:'single' };
      additionalFilters.push(additionalFilter);
      if (value.value == true) {
        //change the status of the performance level for the request types of the selected radiologist
        let REQs = this.requestTypes.filter(x => x.VALUE === value.item['VALUE'] &&
            x.PICKUP_MODALITY === value.item['PICKUP_MODALITY'])
        for(let rt of REQs) {
          for(let header of this.dataListHeaders) {
            if(header.type == 'boolean'){
              if (value.filterProperty === header.name) {
                rt[header.name] = true;
              } else {
                rt[header.name] = false;
              }
            }
          }
          rt[this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].filterProperty] = value.filterProperty;
        }
      }
      else{
        //change the status of the performance level for the request types of the selected radiologist to the default performance level
        for(let rt of this.requestTypes.filter( x => x.VALUE === value.item['VALUE'] && x.PICKUP_MODALITY === value.item['PICKUP_MODALITY'])) {
          rt[this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].headerItemDefault.name] = true;
          rt[this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].filterProperty] = this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].headerItemDefault.name;
        }
      }
    },
    fillModalitySubSpecialtiesList(subSpecialtiesList,modality){
      let modalitySubSpecialties = cloneDeep(ModalitySubSpecialties);
      modalitySubSpecialties.modality = modality;
      for(let e of subSpecialtiesList) {
        modalitySubSpecialties.subSpecialties.push(e);
      }
      this.modalitySubSpecialtiesList.push(modalitySubSpecialties);

      if(this.isRadChanged) {
        this.isRadChanged =false;
      }
    },
    fillRequestTypes(pickupModality,modality,subName,requestTypes){
      for(let rt of requestTypes) {
        let requestType = {
          INDEX: this.requestTypes.length > 0 ? this.requestTypes.length - 1 : 1,
          NAME: rt.name, VALUE: rt.value,
          SUB_SPECIALTY: subName, MODALITY: modality,PICKUP_MODALITY:pickupModality , RAD_ID: this.radId
        };
        //Add Performance levels keys to the object
        for(let j =0; j<this.performances.length;j++){
          if(!this.performances[j].isDefault) {
            requestType[this.performances[j].name] = false;
          }
          else{
            requestType[this.performances[j].name] = true;//set with the default performance level
            requestType.PERFORMANCE = this.performances[j].name;
          }
        }
        this.requestTypes.push(requestType);
      }
    },
    getPerformances(){
      let getPerformanceLisForShowUrl = `${apiEndpoints.getPerformanceListForShow}`;

      Promise.resolve(this.getDataFromUrl(getPerformanceLisForShowUrl)).then((result) => {
        this.performances = result;
        let header =
            {
              text: 'Request Type',
              type: 'string',
              value: 'NAME',
              isDefault:false,
              color:'',
              name:'Request Type',
              filterProp:'PERFORMANCE'
            };
        this.dataListHeaders.push(header);
        for(let j =0; j< this.performances.length;j++){
          let header = {
            text: this.performances[j].name,
            value: false ,
            color: this.performances[j].color,
            name:this.performances[j].name,
            isDefault:this.performances[j].isDefault,
            type: 'boolean',
            filterProp:'PERFORMANCE'
          }
          this.dataListHeaders.push(header);
        }
      });

    },
    async getRadiologists(){
      let radList = await this.getDataFromUrl(this.radiologistListUrl);
      for(var i in radList){
        this.radiologistsFilterItems.push({"value": i, "name": radList[i]});
      }
      this.loadingRadiologists=true;
    },
    async getPickupModalities() {
      if (!this.pickupModalities.length) {
        let getPickupModalitiesListUrl = `${apiEndpoints.getPickupModalitiesList}`;
        let result = await this.getDataFromUrl(getPickupModalitiesListUrl);
        this.$nextTick(() => {
          this.loadPickupModalityList(result);
        });
      }
    },
    async getRadPickupDataConfigs(radId){
      let url = `${apiEndpoints.getRadPickupConfigs}`;
      const  radId_ = radId;
      if (radId_ && radId_ !== "" ) {
        url += `/${radId_}`;
      }
      let result =  await this.getDataFromUrl(url);
      return result;
    },
    loadRadDataHandler(event){
      this.selected_rad = event;
      let radIndex  = this.radRequestTypesList.findIndex( rad => rad.radId === this.radId);
      if(radIndex >-1) {
        //let dataChanged = false;
        if(this.dataChanged == false)
        {
          let radDataIndex = this.radDataList.findIndex(rad => rad.radId === this.radId);
          if (radDataIndex > -1) {
            for(let radDataItem of this.radDataList[radDataIndex].data) {
              for(let item of this.requestTypes.filter((el) => (
                  el.VALUE === radDataItem.requestType ||
                  radDataItem.requestType === 'ALL') && el.MODALITY === radDataItem.modality)) {
                if (item.PERFORMANCE !== radDataItem.performanceLevel) {
                  this.dataChanged = true;
                }
              }
            }
          }
          if (this.dataChanged) {
            //open the dialog here
            this.showConfirm = true;
            if (radIndex > -1) {
              //delete the radiologist (with the given index) cached data
              this.radRequestTypesList.splice(radIndex, 1);
              radIndex = -1;
            }
          } else {
            this.changeRad(event);
          }
        }
        else{
          this.showConfirm = true;
          if (radIndex > -1) {
            //delete the radiologist (with the given index) cached data
            this.radRequestTypesList.splice(radIndex, 1);
            radIndex = -1;
          }
        }
      }
      else {
        this.changeRad(event);
      }
    },
    loadSubSpecialtiesHandler(value){
      this.selected_subSpecialties = value.value.length > 0? value.value:  this.subSpecialtiesNames_Appeared;
      if(value.additionalFilters.length > 0) {
        let pickupModality = value.additionalFilters.filter( additional => additional.name === 'PICKUP_MODALITY' )[0].value;
        this.loadSubsRequestTypes(this.selected_subSpecialties,pickupModality,false);
      }
      else {
        this.loadSubsRequestTypes(this.selected_subSpecialties,this.pickupModalities[this.active_tab],false);
      }
    },
    loadPickupModalityList(pickupModalities){
      for(let i=0; i< pickupModalities.length; i++){
        this.pickupModalities.push(pickupModalities[i].name);
        let pickupModality_Modalities = cloneDeep(PickupModality);
        pickupModality_Modalities.pickupModality = pickupModalities[i].name;
        for(let j=0; j< pickupModalities[i].items.length; j++) {
          pickupModality_Modalities.modalities.push(pickupModalities[i].items[j].modality);
          let modality_SubSpecialties = cloneDeep(ModalitySubSpecialties);
          modality_SubSpecialties.modality = pickupModalities[i].items[j].modality;
          modality_SubSpecialties.subSpecialties = pickupModalities[i].items[j].subspecialities;
          pickupModality_Modalities.modality_SubSpecialties.push(modality_SubSpecialties);
        }
        this.pickupModalityList.push(pickupModality_Modalities);
      }
    },
    loadSubSpecialtyList(pickupModality,isModalityChanged) {

      this.modalities =[];this.subSpecialties=[];
      let result = this.pickupModalityList.findIndex(p => p.pickupModality == pickupModality);
      this.pickupModality = pickupModality;
      for(let m of this.pickupModalityList[result].modality_SubSpecialties) {
        this.modalities.push(m.modality);
        for(let s of m.subSpecialties) {
          let sub_specialty = {name: s.name, items: s.items, modality: m.modality}
          this.subSpecialties.push(sub_specialty);
        }
      }
      this.subSpecialtiesNames=[];
      this.subSpecialtiesNames_Appeared=[];
      for(let sub of this.subSpecialties){
        if(!this.subSpecialtiesNames_Appeared.includes(sub.name))
          this.subSpecialtiesNames_Appeared.push(sub.name);

        this.subSpecialtiesNames.push(sub.name);
      }
      this.loadingSubSpecialties = true;
      let index = this.modalitySubSpecialtiesList.findIndex((el) => el.modality === pickupModality );
      if(index >-1 ) {
        this.selected_subSpecialties = this.modalitySubSpecialtiesList[index].subSpecialties;
        this.loadSubsRequestTypes(this.modalitySubSpecialtiesList[index].subSpecialties, pickupModality,isModalityChanged);
      }
      else
      {
        this.selected_subSpecialties =this.subSpecialtiesNames;
        this.fillModalitySubSpecialtiesList(this.selected_subSpecialties,pickupModality);
        this.loadSubsRequestTypes(this.selected_subSpecialties, pickupModality,isModalityChanged);
      }
    },
    loadSubsRequestTypes(event,pickupModality,isModalityChanged){

      this.requestTypes_Appeared =[];
      if(event.length == 0 || event.length == this.subSpecialtiesNames.length){
        event = this.subSpecialtiesNames;
      }

      if(!isModalityChanged)
      {
        let j = this.modalitySubSpecialtiesList.findIndex((el) =>  el.modality === pickupModality );
        if (j > -1)
          this.modalitySubSpecialtiesList.splice(j, 1);
        this.fillModalitySubSpecialtiesList(event,pickupModality);
      }
      let radIndex  = this.radRequestTypesList.findIndex( rad => rad.radId === this.radId);
      if(radIndex >-1) {
        this.requestTypes = this.radRequestTypesList[radIndex].requestTypes;
      }
      else {
        this.requestTypes =[];
        let radRequestTypes = cloneDeep(RadRequestTypes);
        radRequestTypes.radId = this.radId;
        radRequestTypes.requestTypes =[];
        this.radRequestTypesList.push(radRequestTypes);
      }
      let filteredSubSpecialties = event;
      let additionalFilters =[];
      let additionalFilter = { name:'PICKUP_MODALITY', value:pickupModality, type:'single' };
      additionalFilters.push(additionalFilter);
      additionalFilter = { name:'SUB_SPECIALTY', value: filteredSubSpecialties, type:'array' };
      additionalFilters.push(additionalFilter);
      if(this.requestTypes.length == 0) {
        for(let sub of this.subSpecialties.filter(el =>  event.includes(el.name))) {
          this.fillRequestTypes(pickupModality,sub.modality,sub.name,sub.items);
        }
        this.loadingRequestTypes = true;
        Promise.resolve(
            this.getRadPickupDataConfigs(this.radId)).then(
            (data) => {
              let radData ={radId : this.radId, data: data}
              let radDataIndex = this.radDataList.findIndex(rad => rad.radId === this.radId);
              if(radDataIndex > -1)
                this.radDataList[radDataIndex] = radData;
              else
                this.radDataList.push(radData);
              this.showRadData(pickupModality);//(radIndex,pickupModality,event);
              //if(this.$refs.tableList[this.active_tab].dataItems_Appeared.length == 0) {
              //this.$refs.tableList[this.active_tab].showFilteredData(additionalFilters);
              //}
              this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].showFilteredDataEvent();
              radIndex > -1 ? this.radRequestTypesList[radIndex].requestTypes = this.requestTypes :
                  this.radRequestTypesList[this.radRequestTypesList.length - 1].requestTypes = this.requestTypes;
            }
        );
      }
      else {
        let result = this.pickupModalityList.findIndex(p => p.pickupModality == pickupModality);
        //this.loadingRequestTypes = false;
        let SUBs = this.subSpecialties.filter(el =>  event.includes(el.name));
        for(let sub of SUBs){
          let rtIndex = this.requestTypes.findIndex(y => y.SUB_SPECIALTY === sub.name && sub.modality === y.MODALITY && y.PICKUP_MODALITY === pickupModality && y.RAD_ID === this.radId);
          if (rtIndex > -1) {
            this.loadingRequestTypes = true;
            //this.showRadData(radIndex,pickupModality,event);
            this.showRadData(pickupModality);
            this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[result])[0].showFilteredDataEvent();
          } else {
            this.fillRequestTypes(pickupModality,sub.modality,sub.name,sub.items);
            this.loadingRequestTypes = true;
            //this.showRadData(radIndex,pickupModality,event);
            this.showRadData(pickupModality);
            radIndex > -1 ? this.radRequestTypesList[radIndex].requestTypes = this.requestTypes :
                this.radRequestTypesList[this.radRequestTypesList.length - 1].requestTypes = this.requestTypes;
          }
        }

        if(!isModalityChanged || this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[result])[0] != undefined ) {
          this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[result])[0].showFilteredDataEvent();
        }


      }
    },
    showFilteredDataHandler(){
      this.$nextTick(() => {
        let result = this.pickupModalityList.findIndex(p => p.pickupModality == this.pickupModality);
        let additionalFilters = [];
        let subSpecialties = this.selected_subSpecialties;

        let additionalFilter = {
          name: 'PICKUP_MODALITY',
          value: this.pickupModalities[result],
          type: 'single'
        };
        additionalFilters.push(additionalFilter);
        additionalFilter = {name: 'SUB_SPECIALTY', value: subSpecialties, type: 'array'};
        additionalFilters.push(additionalFilter);
        this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].showFilteredData(additionalFilters);
      });
    },
    showRadData(pickupModality){
      let radDataIndex = this.radDataList.findIndex( rad => rad.radId === this.radId);
      if(radDataIndex > -1) {
        for(let radDataItem of this.radDataList[radDataIndex].data) {
          for(let item of this.requestTypes.filter((el) => (
                  el.VALUE === radDataItem.requestType ||
                  radDataItem.requestType === 'ALL')
              && el.MODALITY === radDataItem.modality && el.PICKUP_MODALITY === pickupModality)) {
            let value ={value : true, item : item , filterProperty:radDataItem.performanceLevel};
            this.checkBooleanHandler(value);
          }
        }
      }
    },
    showTemplate(){
      this.show_Template = true;
    },
    async save(reloadData){
      Promise.resolve( this.setRadPickupDataConfigs()).then(async (response) => {
        if (response.data.success) {
          const data = {emergencyPickup: this.emergencyPickup, pediatricPickup: this.pediatricPickup}
          await this.setRadPickupSettings(this.radId, data)
          if (this.getNewAddedTemplates().length > 0) {
            let url = `${apiEndpoints.setRadTemplatesHistory}`;
            url += `/${this.radId}`;
            await this.saveTemplatesHistory(url);
            let radTemplatesHistoryUrl = `${apiEndpoints.getRadTemplatesHistory}`;
            radTemplatesHistoryUrl += `/${this.radId}`;
            await this.getTemplatesHistory(radTemplatesHistoryUrl);
            this.templatesHistoryList = this.getAllTemplatesHistory();
            this.hideTemplatesHistory = this.getHideTemplatesHistory();
          }

          this.setMessage({
            message: "Pickup configuration data has been saved successfully for the radiologist ' " + this.radName + " '",
            type: "success",
            timeout: 10000,
          });
          if (reloadData) {
            Promise.resolve(
              this.getRadPickupDataConfigs(this.radId)).then(
              (data) => {
                this.$nextTick(() => {
                  let index = this.radDataList.findIndex(rad => rad.radId === this.radId);
                  if (index > -1)
                    this.radDataList[index].data = data;
                });
                this.dataChanged = false;
              }
            );
          } else {
            this.changeRad(this.selected_rad);
            this.dataChanged = false;
            this.showConfirm = false;
          }
        } else {
          this.setMessage({
            message: "Pickup configuration data has been failed for the radiologist ' " + this.radName + " '",
            type: "error",
            timeout: 10000,
          });
        }
      });
    },
    async setRadPickupDataConfigs() {
      if (this.requestTypes.length > 0) {
        let url = `${apiEndpoints.setRadPickupConfigs}`;
        url += `/${this.radId}`;
        const data = [];
        for(let rt of this.requestTypes) {
          if (rt.PERFORMANCE !== this.$refs.tableList.filter(item => item.$attrs.id === this.pickupModalities[this.active_tab])[0].headerItemDefault.name) {
            let rtData = {
              "modality": rt.MODALITY,
              "requestType": rt.VALUE,
              "performanceLevel": rt.PERFORMANCE
            };
            data.push(rtData);
          }
        }
        let radDataIndex = this.radDataList.findIndex(rad => rad.radId === this.radId);
        if (radDataIndex > -1) {
          let unSeenData = this.radDataList[radDataIndex].data.filter(i => !this.requestTypes.some(r => r.MODALITY === i.modality));
          for(let u of unSeenData) {
            data.push(u);
          }
        }
        if (data.length > 0) {
          let response = await this.setDataToUrl({url, data});
          return response;
        }
      }
    },
    // async getRadTemplatesHistory() {
    //   let getRadTemplatesHistoryUrl = `${apiEndpoints.getRadTemplatesHistory}`;
    //   getRadTemplatesHistoryUrl += `/${this.radId}`;
    //   let result = await this.getDataFromUrl(getRadTemplatesHistoryUrl);
    //   this.templatesHistory = result.results;
    // },
    resetStore(){
      let emptyArr =[];
      this.setHeadersBooleanStored(emptyArr);
    },
    async getRadProfileSettings(id){
      try {
        const response = await API.get(apiEndpoints.getRadProfileSettings + id);
        this.radSettings = response.data;
        console.log("get radiologist profile settings successfully.");
      } catch (error) {
        console.error("Error when get radiologist profile settings successfully ", error);
      }
    },
    async setRadPickupSettings(id, data){
      try {
        await API.post(apiEndpoints.setRadPickupSettings + id , data);
        console.log("set radiologist pickup settings successfully.");
      } catch (error) {
        console.error("Error when get radiologist pickup settings successfully ", error);
      }
    },
  },
  async mounted() {
    this.getCurrentUser(apiEndpoints.currentUser);
    await this.getRadiologists();
    this.getPerformances();
    this.getPickupModalities();
  },
};
</script>

