import Quill from "quill";
export const quillFullScreenClassName = "in-fullscreen-mode";
//const $ = window.jQuery;
const PLAIN_TEXT = "text/plain";
const HTML_TEXT = "text/html";
let icons = Quill.import("ui/icons");
let arr = null;
let boldIcon = `<span title="Bold">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M13.5,15.5H10V12.5H13.5A1.5,1.5 0 0,1 15,14A1.5,1.5 0 0,1 13.5,15.5M10,6.5H13A1.5,1.5 0 0,1 14.5,8A1.5,1.5 0 0,1 13,9.5H10M15.6,10.79C16.57,10.11 17.25,9 17.25,8C17.25,5.74 15.5,4 13.25,4H7V18H14.04C16.14,18 17.75,16.3 17.75,14.21C17.75,12.69 16.89,11.39 15.6,10.79Z" />
</svg>
</span>`;
let italicIcon = `<span title="Italic">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M10,4V7H12.21L8.79,15H6V18H14V15H11.79L15.21,7H18V4H10Z" />
</svg>
</span>`;
let underlineIcon = `<span title="Underline">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M5,21H19V19H5V21M12,17A6,6 0 0,0 18,11V3H15.5V11A3.5,3.5 0 0,1 12,14.5A3.5,3.5 0 0,1 8.5,11V3H6V11A6,6 0 0,0 12,17Z" />
</svg>
</span>`;
let listIcon = `<span title="List">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M7,13V11H21V13H7M7,19V17H21V19H7M7,7V5H21V7H7M3,8V5H2V4H4V8H3M2,17V16H5V20H2V19H4V18.5H3V17.5H4V17H2M4.25,10A0.75,0.75 0 0,1 5,10.75C5,10.95 4.92,11.14 4.79,11.27L3.12,13H5V14H2V13.08L4,11H2V10H4.25Z" />
</svg>
</span>`;
let bulletIcon = `<span title="Bullets">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M3,4H7V8H3V4M9,5V7H21V5H9M3,10H7V14H3V10M9,11V13H21V11H9M3,16H7V20H3V16M9,17V19H21V17H9" />
</svg>
</span>`;
let superScript = `<span title="Superscript">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M16,7.41L11.41,12L16,16.59L14.59,18L10,13.41L5.41,18L4,16.59L8.59,12L4,7.41L5.41,6L10,10.59L14.59,6L16,7.41M21.85,9H16.97V8L17.86,7.18C18.62,6.54 19.18,6 19.56,5.55C19.93,5.11 20.12,4.7 20.13,4.32C20.14,4.04 20.05,3.8 19.86,3.62C19.68,3.43 19.39,3.34 19,3.33C18.69,3.34 18.42,3.4 18.16,3.5L17.5,3.89L17.05,2.72C17.32,2.5 17.64,2.33 18.03,2.19C18.42,2.05 18.85,2 19.32,2C20.1,2 20.7,2.2 21.1,2.61C21.5,3 21.72,3.54 21.72,4.18C21.71,4.74 21.53,5.26 21.18,5.73C20.84,6.21 20.42,6.66 19.91,7.09L19.27,7.61V7.63H21.85V9Z" />
</svg>
</span>`;
let leftIndentIcon = `<span title="Left Indent">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z" />
</svg>
</span>`;
let rightIndentIcon = `<span title="Right Indent">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z" />
</svg>
</span>`;
let undoIcon = `<span title="Undo">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
</svg>
</span>`;
let redoIcon = `<span title="Redo">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M18.4,10.6C16.55,9 14.15,8 11.5,8C6.85,8 2.92,11.03 1.54,15.22L3.9,16C4.95,12.81 7.95,10.5 11.5,10.5C13.45,10.5 15.23,11.22 16.62,12.38L13,16H22V7L18.4,10.6Z" />
</svg>
</span>`;
let copyIcon = `<span title="Copy">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
</svg>
</span>`;
let cutIcon = `<span title="Cut">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,3L13,9L15,11L22,4V3M12,12.5A0.5,0.5 0 0,1 11.5,12A0.5,0.5 0 0,1 12,11.5A0.5,0.5 0 0,1 12.5,12A0.5,0.5 0 0,1 12,12.5M6,20A2,2 0 0,1 4,18C4,16.89 4.9,16 6,16A2,2 0 0,1 8,18C8,19.11 7.1,20 6,20M6,8A2,2 0 0,1 4,6C4,4.89 4.9,4 6,4A2,2 0 0,1 8,6C8,7.11 7.1,8 6,8M9.64,7.64C9.87,7.14 10,6.59 10,6A4,4 0 0,0 6,2A4,4 0 0,0 2,6A4,4 0 0,0 6,10C6.59,10 7.14,9.87 7.64,9.64L10,12L7.64,14.36C7.14,14.13 6.59,14 6,14A4,4 0 0,0 2,18A4,4 0 0,0 6,22A4,4 0 0,0 10,18C10,17.41 9.87,16.86 9.64,16.36L12,14L19,21H22V20L9.64,7.64Z" />
</svg>
</span>`;
let pasteIcon = `<span title="Paste">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.86 1.5L12.91 6.43L15.03 8.55L16.09 7.5L15.03 6.43L17.86 3.6L18.92 4.66L20 3.6M21.4 3.6L15.56 9.44L13.43 10.15L3 20.57L4.43 22L10.08 16.33L14.33 20.57L18.57 16.33L14.33 12.09L14.5 11.9L16.63 11.19L21.4 6.43C22.18 5.65 22.18 4.38 21.4 3.6M6.55 4.31L2.31 8.55L7.26 13.5L11.5 9.26M18.22 18.1L16.09 20.22L17.5 21.63L19.63 19.5Z" />
</svg>
</span>`;
let removeFormatIcon = `<span title="Remove Format">
<svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M6,5V5.18L8.82,8H11.22L10.5,9.68L12.6,11.78L14.21,8H20V5H6M3.27,5L2,6.27L8.97,13.24L6.5,19H9.5L11.07,15.34L16.73,21L18,19.73L3.55,5.27L3.27,5Z" />
</svg>
</span>`;

let fullscreenIcon = `<span title="Fullscreen">
<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z" />
</svg>
</span>`;

let exitFullscreen = `<span title="Exit Fullscreen">
<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,14H19V16H16V19H14V14M5,14H10V19H8V16H5V14M8,5H10V10H5V8H8V5M19,8V10H14V5H16V8H19Z" />
</svg>
</span>`;

/**
 * @description override default icons for Quill editor
 * @returns Array: Quill's toolbar icons
 */
export function declareCustomIcons() {
  icons["bold"] = boldIcon;
  icons["italic"] = italicIcon;
  icons["underline"] = underlineIcon;
  icons["list"]["ordered"] = listIcon;
  icons["list"]["bullet"] = bulletIcon;
  icons["left"] = leftIndentIcon;
  icons["right"] = rightIndentIcon;
  icons["script"]["super"] = superScript;
  icons["undo"] = undoIcon;
  icons["redo"] = redoIcon;
  icons["copy"] = copyIcon;
  icons["paste"] = pasteIcon;
  icons["cut"] = cutIcon;
  icons["clean"] = removeFormatIcon;
  icons["fullscreen"] = fullscreenIcon;
  icons["exitFullscreen"] = exitFullscreen;
  return {
    container: [
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "super" }], // superscript/subscript
      [{ left: "-1" }, { right: "+1" }],
      ["undo", "redo"],
      ["copy", "paste", "cut"],
      ["clean"], // clear formatting button,
      ["fullscreen", "exitFullscreen"],
    ],
    handlers: {
      fullscreen: function () {
        this.container.parentElement.classList.add(quillFullScreenClassName);
        goFullscreen(this.container.parentElement);
      },
      exitFullscreen: async function () {
        await exitFullscreenMode();
        this.container.parentElement.classList.remove(quillFullScreenClassName);
      },
      undo: function () {
        this.quill.history.undo();
      },
      redo: function () {
        this.quill.history.redo();
      },
      copy: function () {
        document.execCommand("copy");
      },
      paste: async function () {
        let position = this.quill.getSelection();
        await pasteHandler(this.quill, position.index);
      },
      cut: function () {
        document.execCommand("cut");
      },
      left: function (value) {
        let jQuerySelector = window
          .jQuery(this.container.parentElement)
          .find("div.ql-editor > ol > li, div.ql-editor > ul > li");
        checkReportContentFormatting(jQuerySelector, this.quill, value);
      },
      right: function (value) {
        let jQuerySelector = window
          .jQuery(this.container.parentElement)
          .find("div.ql-editor > ol > li, div.ql-editor > ul > li");
        checkReportContentFormatting(jQuerySelector, this.quill, value);
      },
    },
  };
}

export function getQuillBindings() {
  return {
    custom: {
      key: "F",
      altKey: true,
      handler: function () {
        this.quill.focus();
        goFullscreen(this.quill.container.parentElement);
      },
    },
  };
}

export function goFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    /* Safari */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE11 */
    element.msRequestFullscreen();
  }
}

export async function exitFullscreenMode() {
  if (document.fullscreenElement) {
    await document.exitFullscreen();
  }
}

function decodeHtml(html) {
  let txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

function getLineNum(arr, index) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i]["lineLength"] + 1;
    if (sum >= index || i === arr.length - 1) {
      return i;
    }
  }
}

function getLines(range, quill) {
  // returns an array of ranges representing the lines that 'range' is part of.
  // getLines({index: 2, length: 0}) : [{index: number, length: number}]

  let firstLine = quill.getText(0, range.index).split("\n").length - 1;
  let lastLine = quill.getText(0, range.index + range.length).split("\n").length - 1;

  let text = quill.getText().split("\n");
  text.forEach(function (line, index, ary) {
    ary[index] += "\n";
  });

  let lines = [];
  lines.push({
    index: text.slice(0, firstLine).reduce((a, b) => {
      return a + b.length;
    }, 0),
    length: text[firstLine].length,
  });
  for (let i = firstLine + 1; i <= lastLine; ++i) {
    let lastLine = lines[lines.length - 1];
    lines.push({
      index: lastLine.index + lastLine.length,
      length: text[i].length,
    });
  }

  return text;
}

function updateArray(jQuerySelector, allListItems) {
  let arr = [];
  let newArr = []; // all List items objects (arr+allListItems)
  window.jQuery(jQuerySelector).each(function () {
    let numOfTabs = 0;
    if (typeof window.jQuery(this).attr("class") !== "undefined") {
      let element = window.jQuery(this).attr("class");
      numOfTabs = element.substr(element.lastIndexOf("-") + 1);
    }
    let lineContent = decodeHtml(window.jQuery(this).text());

    if (lineContent === "<br>") {
      lineContent = "";
    }
    arr.push({
      numOfTabs: parseInt(numOfTabs),
      lineLength: lineContent.length,
      lineContent: lineContent,
      bullets: true,
    });
  });
  for (let i = 0, j = 0; i < allListItems.length; i++) {
    if (j < arr.length && allListItems[i].trim() === arr[j]["lineContent"].trim()) {
      newArr.push(arr[j]);
      j++;
    } else {
      newArr.push({
        lineContent: allListItems[i],
        bullets: false,
        lineLength: allListItems[i].trim().length,
      });
    }
  }
  return newArr;
}

function isValidMove(arr, start, end) {
  if (start === 0 || !arr[start]["bullets"]) {
    return false;
  }
  let lastLineTabs = arr[start - 1]["numOfTabs"];
  let currentLineTabs = arr[start]["numOfTabs"];
  if (lastLineTabs === undefined || lastLineTabs === null || !arr[start - 1]["bullets"]) {
    //if last line is not bullets
    return false;
  }
  if (start === end) {
    if (arr[start]["numOfTabs"] - lastLineTabs < 1) {
      return true;
    }
    return false;
  }

  if (lastLineTabs < currentLineTabs && !(currentLineTabs - lastLineTabs === 0)) {
    return false;
  }
  for (let i = start + 1; i <= end; i++) {
    if (!arr[i]["bullets"] || arr[i]["numOfTabs"] < currentLineTabs) {
      return false;
    }
  }
  return true;
}

function shiftSelection(arr, start, end, val, selector, isDeleted) {
  // val is num of tabs
  let currentTabNumsBeforeUpdate = arr[start]["numOfTabs"];
  if (val === -1) {
    for (let i = end; i >= start; i--) {
      if (arr[i]["numOfTabs"] < currentTabNumsBeforeUpdate) {
        return;
      }
    }
  }
  for (let i = start; i < arr.length - 1; i++) {
    let element = window.jQuery(selector[i]).attr("class");
    let x;
    if (arr[i]["bullets"] && element === undefined && selector[i].nodeName === "LI") {
      // first list level doesn't have class indent
      x = 0;
    } else if (arr[i]["bullets"]) {
      x = parseInt(element.substr(element.lastIndexOf("-") + 1), 10);
    }
    let indent = x + val;
    if ((indent < 0 || !arr[i]["bullets"]) && !isDeleted) return;

    let previousIndent = 0;
    if (i > 0) {
      previousIndent = arr[i - 1]["numOfTabs"];
    }
    let currentIndent = arr[i]["numOfTabs"];
    let diff = currentIndent - previousIndent;
    if (val === -1 && diff > 1) {
      indent = previousIndent + 1;
    }
    if (start === i || arr[i]["numOfTabs"] > currentTabNumsBeforeUpdate) {
      window.jQuery(selector[i]).attr("class", "ql-indent-" + indent);
      arr[i]["numOfTabs"] = indent;
      continue;
    }

    if (i === end && start !== end && arr[i]["numOfTabs"] > currentTabNumsBeforeUpdate) {
      window.jQuery(selector[i]).attr("class", "ql-indent-" + indent);
      arr[i]["numOfTabs"] = indent;
      break;
    }
    if (i > end && start !== end && arr[i]["numOfTabs"] < currentTabNumsBeforeUpdate) {
      break;
    }
    if (start === end && arr[i]["numOfTabs"] <= currentTabNumsBeforeUpdate) {
      break;
    }
    if (i <= end && arr[i]["numOfTabs"] === currentTabNumsBeforeUpdate) {
      arr[i]["numOfTabs"] = indent;
      window.jQuery(selector[i]).attr("class", "ql-indent-" + indent);
    }
  }
}

function reFormatLevels(quillObject) {
  let allListItems = getLines(quillObject.getSelection(), quillObject); // get all list elements found in quill object
  let selector = window.jQuery(quillObject.container).find("ol > li, ul > li");
  arr = updateArray(selector, allListItems); // update array and selector after deletion done on text editor
  let newSelector = []; // to get selector on each li element in text

  for (let i = 0, j = 0; i < arr.length; i++) {
    if (arr[i]["bullets"]) {
      newSelector.push(selector[j]);
      j++;
    } else {
      newSelector.push({ bullets: false });
    }
  }
  if (arr[0]["bullets"]) {
    // forcing first level to be indent 0
    window.jQuery(newSelector[0]).attr("class", "ql-indent-0");
    arr[0]["numOfTabs"] = 0;
  }
  for (let i = 1; i < newSelector.length; i++) {
    let currentIndent = -1;
    let prevIndent = -1;

    if (arr[i]["bullets"]) {
      currentIndent = arr[i]["numOfTabs"];
    }
    if (arr[i - 1]["bullets"]) {
      prevIndent = arr[i - 1]["numOfTabs"];
    }
    let diff = currentIndent - prevIndent;
    let indent = prevIndent + 1;
    if (prevIndent === -1 && currentIndent !== -1) {
      window.jQuery(newSelector[i]).attr("class", "ql-indent-0"); // forcing first level to be indent 0
      arr[i]["numOfTabs"] = 0;
    } else if (currentIndent !== -1 && prevIndent !== -1 && diff > 1) {
      window.jQuery(newSelector[i]).attr("class", "ql-indent-" + indent);
      arr[i]["numOfTabs"] = prevIndent + 1;
    }
  }
}

function checkReportContentFormatting(selector, quillObject, event) {
  let allListItems = getLines(quillObject.getSelection(), quillObject); // get all list elements found in quill object
  arr = updateArray(selector, allListItems);
  let range = quillObject.getSelection();
  let newSelector = []; // to get selector on each li element in text
  for (let i = 0, j = 0; i < arr.length; i++) {
    if (arr[i]["bullets"]) {
      newSelector.push(selector[j]);
      j++;
    } else {
      newSelector.push({ bullets: false });
    }
  }
  let lineIndex = getLineNum(arr, range.index + 1); //start selection line index
  let endLineIndex = getLineNum(arr, range.index + range.length + 1); //end selection line index
  let validMove = isValidMove(arr, lineIndex, endLineIndex);
  if (event.keyCode === 8) {
    reFormatLevels(quillObject);
  } else if (validMove) {
    if (!event.shiftKey && event.keyCode === 9) {
      // Tab key
      shiftSelection(arr, lineIndex, endLineIndex, 1, newSelector);
    } else if (event.keyCode === undefined && event === "+1") {
      // indent key pressed
      shiftSelection(arr, lineIndex, endLineIndex, 1, newSelector);
    } else if ((event.shiftKey && event.keyCode === 9) || (event.keyCode === undefined && event === "-1")) {
      // indent key pressed
      shiftSelection(arr, lineIndex, endLineIndex, -1, newSelector);
    }
  } else if ((event.shiftKey && event.keyCode === 9) || (event.keyCode === undefined && event === "-1")) {
    // shift+tab | indent left
    shiftSelection(arr, lineIndex, endLineIndex, -1, newSelector);
  }
}

async function pasteHandler(quill, position) {
  quill.focus();
  const items = await navigator.clipboard.read();
  const textBlob = await items[0].getType(items[0].types.indexOf(HTML_TEXT) !== -1 ? HTML_TEXT : PLAIN_TEXT);
  let text = await new Response(textBlob).text();
  text = text.replace(/<pre/g, "<p");
  text = text.replace(/<\/pre>/g, "</p>");
  quill.clipboard.dangerouslyPasteHTML(position, text, Quill.sources.API);
  // TODO: control colors based on selected quill theme
  quill.formatText(position, text.length, { color: "#ddd" });
  quill.setSelection(quill.getSelection(), 0, Quill.sources.API);
}

export async function textEventsHandler(event, quillInstance) {
  if (event.type === "paste") {
    await navigator.clipboard.readText();
    return;
  }
  if (!(event.shiftKey && event.keyCode >= 37 && event.keyCode <= 40)) {
    //saveCurrentReportInfoInLocalStorage();
    let jQuerySelector = window.jQuery(quillInstance.container).find("ol > li, ul > li");
    checkReportContentFormatting(jQuerySelector, quillInstance, event);
  }
}

export function disableTabKey(quillInstance) {
  quillInstance.keyboard.bindings[9].unshift({
    key: 9,
    shiftKey: null,
    // eslint-disable-next-line no-unused-vars
    handler: function (range, context) {
      // console.log('Tab key is clicked: range', range);
      // console.log('Tab key is clicked: context', context);
      // console.log('Tab key is clicked: this', this);
      // // control tab key behavior if inside ordered or unordered list
      // if(context.format){
      //     let {list} = context.format;
      //     if( list && list === 'ordered' || list === 'bullet'){
      //         let jQuerySelector = window.jQuery(this.quill.container.parentEelement).find('div.ql-editor > ol > li, div.ql-editor > ul > li');
      //         checkReportContentFormatting(jQuerySelector, this.quill, event);
      //         return false
      //     }
      // }
      // return true; // pass tab key event to later handlers
    },
  });
}
