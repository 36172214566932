/**
 * get the best points in the nodule region to draw the nodule dimensions
 * @param regionPixels - array of pixels that represent the region
 * @return return noduleDimensionPoints object with vertical and horizontal details
 */
export function getNoduleDimensionPoints(regionPixels) {
  var noduleDimesionPoints = {
    verticalDimension: {
      upPoint: {
        x: undefined,
        y: undefined,
      },
      downPoint: {
        x: undefined,
        y: undefined,
      },
      length: undefined,
    },
    horizontalDimension: {
      leftPoint: {
        x: undefined,
        y: undefined,
      },
      rightPoint: {
        x: undefined,
        y: undefined,
      },
      length: undefined,
    },
  };

  var Xs = [];
  var Ys = [];

  regionPixels.forEach((pixel) => {
    if (Xs.find((p) => p === pixel.x) == undefined) Xs.push(pixel.x);

    if (Ys.find((p) => p === pixel.y) == undefined) Ys.push(pixel.y);
  });

  Xs.sort((a, b) => a - b);
  Ys.sort((a, b) => a - b);

  var leftPoints = regionPixels.filter((p) => p.x === Xs[0]);
  var rightPoints = regionPixels.filter((p) => p.x === Xs[Xs.length - 1]);
  var upPoints = regionPixels.filter((p) => p.y === Ys[0]);
  var downPoints = regionPixels.filter((p) => p.y === Ys[Ys.length - 1]);

  if (leftPoints.length == 0 || rightPoints.length == 0 || upPoints.length == 0 || downPoints.length == 0) {
    //debugger
  }

  var lineX = getMeanLine(leftPoints, rightPoints, "h");
  var lineY = getMeanLine(upPoints, downPoints, "v");

  if (lineX == undefined || lineY == undefined) {
    console.log("error getting nodule dimenstions - LineX or LineY is undefined");
    //debugger;
  }

  noduleDimesionPoints.horizontalDimension.leftPoint = lineX.point1;
  noduleDimesionPoints.horizontalDimension.rightPoint = lineX.point2;
  noduleDimesionPoints.horizontalDimension.length = lineX.length;
  noduleDimesionPoints.verticalDimension.upPoint = lineY.point1;
  noduleDimesionPoints.verticalDimension.downPoint = lineY.point2;
  noduleDimesionPoints.verticalDimension.length = lineY.length;

  return noduleDimesionPoints;
}

/* function getlongestLine(edgePoints1,edgePoints2) {
    lines = getAllLinesOrderedByLength(edgePoints1,edgePoints2);

    //get the longest
    result = lines[lines.length - 1];

    return result;
}

function getshortestLine(edgePoints1,edgePoints2) {
    lines = getAllLinesOrderedByLength(edgePoints1,edgePoints2);

    //get the sortest
    result = lines[0];

    return result;
}

function getmidLine(edgePoints1,edgePoints2) {
    lines = getAllLinesOrderedByLength(edgePoints1,edgePoints2);

    //get the middle
    index = lines.length % 2 == 0 ? lines.length/2 : Math.ceil(lines.length/2);
    result = lines[index];

    return result;
}
 */

function getMeanLine(edgePoints1, edgePoints2, direction = "h") {
  var line = {
    point1: undefined,
    point2: undefined,
    length: undefined,
  };

  if (direction === "h") {
    //left point
    var medPointY = 0;
    for (let i = 0; i < edgePoints1.length; i++) {
      medPointY += edgePoints1[i].y;
    }
    medPointY /= edgePoints1.length;
    line.point1 = {
      x: edgePoints1[0].x,
      y: medPointY,
    };

    //right point
    medPointY = 0;
    for (i = 0; i < edgePoints2.length; i++) {
      medPointY += edgePoints2[i].y;
    }
    medPointY /= edgePoints2.length;
    line.point2 = {
      x: edgePoints2[0].x,
      y: medPointY,
    };
  } else if (direction === "v") {
    //up point
    var medPointX = 0;
    for (i = 0; i < edgePoints1.length; i++) {
      medPointX += edgePoints1[i].x;
    }
    medPointX /= edgePoints1.length;
    line.point1 = {
      x: medPointX,
      y: edgePoints1[0].y,
    };

    //down point
    medPointX = 0;
    for (var i = 0; i < edgePoints2.length; i++) {
      medPointX += edgePoints2[i].x;
    }
    medPointX /= edgePoints2.length;
    line.point2 = {
      x: medPointX,
      y: edgePoints2[0].y,
    };
  }

  line.length = getLength(line.point1, line.point2);

  return line;
}

/* function getAllLinesOrderedByLength(edgePoints1,edgePoints2) {
    var line = {
        point1: undefined,
        point2: undefined,
        length: undefined
    }

    var lines = [];

    // calculate all lines
    edgePoints1.forEach(p1 => {
        edgePoints2.forEach(p2 => {
            var len = getLength(p1,p2);
            var tmpLine = {
                point1: p1,
                point2: p2,
                length: len
            }

            lines.push(tmpLine);
        })
    })
    //order by length
    lines.sort((a,b) => a.length - b.length );

    return lines;
} */

function getLength(point1, point2) {
  return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}
