<template>
  <div :class="{ 'display-in-rows': displayInRows }">
    <!--    <v-progress-linear :active="loadingFiles" color="light-blue darken-4" indeterminate/>-->
    <section class="align-center" :class="{ 'd-flex': !displayInRows }">
      <section class="report-uploader-wrapper d-flex align-center">
        <v-file-input
          v-if="!loadingFiles"
          accept="image/*"
          multiple
          hide-input
          @change="changeHandler"
          class="report-uploader"
        />
        <span class="report-uploader--text">Attach Images</span>
      </section>
    </section>
    <div>
      <image-thumbnail
        v-for="(item, index) in uploadedFiles"
        :key="index"
        :item="item"
        :index="index"
        :isHistoricalStudy="isHistoricalStudy"
        @remove="removeFile"
        @update="updateImage"
      >
      </image-thumbnail>
    </div>
  </div>
</template>
<script>
import API from "@/shared/API";
import { v4 as uuIdV4 } from "uuid";
import { mapActions } from "vuex";
import ImageThumbnail from "@/components/StudyReport/ImageThumbnail";
import apiEndpoints from "@/util/apiEndpoints";
export default {
  name: "FileUploader",
  components: { ImageThumbnail },
  props: {
    studyId: {
      type: String,
      default: "",
    },
    uploadFileUrl: {
      type: String,
      default: "",
    },
    removeFileUrl: {
      type: String,
      default: "",
    },
    uploadFields: {
      type: Object,
      default: () => ({}),
    },
    loadFilesUrl: {
      type: String,
      default: "",
    },
    displayInRows: {
      type: Boolean,
      default: false,
    },
    isHistoricalStudy: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      loadingFiles: false,
      uploadedFiles: [],
    };
  },
  methods: {
    ...mapActions(["setMessage"]),
    async uploadFile(file, name, fileSize) {
      const uuid = uuIdV4();
      const formData = new FormData();
      const keys = Object.keys(this.uploadFields);
      if (keys.length) {
        keys.forEach((key) => {
          formData.append(key, this.uploadFields[key]);
        });
      }
      // common
      formData.append("qquuid", uuid);
      formData.append("qqfilename", name);
      formData.append("qqtotalfilesize", fileSize);
      formData.append("qqfile", file);
      try {
        const response = await API.post(this.uploadFileUrl, formData);
        if (response.data.success) {
          const extension = "." + name.split(".").pop();
          const url = `${apiEndpoints.generateImageUrl}${response.data.reportId.studyId}/report/${response.data.reportId.reportId}/uuid/${uuid}/extenstion/${extension}`;
          const obj = { name: name, uuid: uuid, imgUrl: url, reportId: response.data.reportId.reportId };
          this.uploadedFiles.push(obj);
          this.setMessage({ message: `File was uploaded`, type: "success" });
          this.$emit("response", response.data.reportId);
        }
      } catch (error) {
        console.log(`Failed uploading ${name}`, error);
        this.setMessage({ message: `Failed uploading ${name}`, type: "error" });
      }
    },
    changeHandler(files) {
      files.forEach((file) => {
        this.uploadFile(file, file.name, file.size);
      });
    },
    async loadReportImages() {
      try {
        this.loadingFiles = true;
        const response = await API.get(this.loadFilesUrl);
        this.uploadedFiles = [...response.data];
        this.loadingFiles = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    async removeFile(index) {
      const { uuid, name } = this.uploadedFiles[index];
      const url = this.removeFileUrl.replace("uuidPlaceholder", uuid).replace("fileNamePlaceholder", name);
      try {
        const response = await API.delete(url);
        if (response.data.success) {
          this.uploadedFiles.splice(index, 1);
          this.setMessage({ message: `File was deleted`, type: "success" });
          this.$emit("response", response.data);
        } else this.setMessage({ message: `Failed deleting ${name}`, type: "error" });
      } catch (error) {
        console.log(error);
        this.setMessage({ message: `Failed deleting ${name}`, type: "error" });
      }
    },
    async updateImage(index, studyId, file) {
      const { uuid, reportId } = this.uploadedFiles[index];
      const url = `${apiEndpoints.updateImage}${uuid}/${studyId}/${reportId}`;
      const newUuid = uuIdV4();
      const formData = new FormData();
      const keys = Object.keys(this.uploadFields);
      if (keys.length) {
        keys.forEach((key) => {
          formData.append(key, file[0]);
        });
      }
      formData.append("qquuid", newUuid);
      formData.append("qqfilename", file[0].name);
      formData.append("qqtotalfilesize", file[0].size);
      formData.append("qqfile", file);
      try {
        const response = await API.post(url, formData);
        if (response.data.success) {
          const extension = "." + name.split(".").pop();
          const url = `${apiEndpoints.generateImageUrl}${response.data.reportId.studyId}/report/${response.data.reportId.reportId}/uuid/${uuid}/extenstion/${extension}`;
          const obj = { name: file[0].name, uuid: newUuid, imgUrl: url };
          this.uploadedFiles[index] = obj;
          this.setMessage({ message: `File was updated`, type: "success" });
          await this.loadReportImages();
        }
      } catch (error) {
        console.log(`Failed updating ${name}`, error.respond);
        this.setMessage({ message: `Failed updating ${name}`, type: "error" });
      }
    },
  },
  mounted() {
    this.loadReportImages();
  },
};
</script>
<style lang="scss">
.report-uploader-wrapper {
  width: 150px;

  .report-uploader {
    position: relative;
    bottom: 8px;
  }

  .report-uploader--text {
    position: relative;
    left: -30px;
  }
}

.display-in-rows {
  .chip-file {
    margin-bottom: 10px;
  }
}
</style>
