<template>
  <v-dialog v-model="dialog" persistent>
    <v-form>
      <v-card style="border: 2px solid white;">
        <v-card-title>
          <h2 style="width: 90%; text-align: center;"> AI Feedback </h2>
          <h4 class="scoreStyle"> {{ Math.ceil(score * 100 / qureFeedback.length) }} / {{ 100 }} </h4>
        </v-card-title>
        <v-card-text>
          <v-row v-if="this.qureFeedback.length > 0">
            <v-col>
              <v-row>
                <v-col cols="9" class="qureFeedbackItems"> <h3> Abnormal </h3> </v-col> <v-col cols="1"> {{ getTagPresence('abnormal') }} </v-col>
                <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'abnormal').radFeedback"
                                               @change="onChangeEventHandler($event.value, 'abnormal')" /> </v-col>
              </v-row>
              <v-row> <v-col> <h3> Lungs </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Opacity </v-col> <v-col cols="1"> {{ getTagPresence('opacity') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'opacity').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'opacity')" /> </v-col>
                  </v-row>
                  <ul>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Atelectasis </v-col> <v-col cols="1"> {{ getTagPresence('atelectasis') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'atelectasis').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'atelectasis')" /> </v-col>
                      </v-row>
                    </li>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Calcification </v-col> <v-col cols="1"> {{ getTagPresence('calcification') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'calcification').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'calcification')" /> </v-col>
                      </v-row>
                    </li>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Consolidation </v-col> <v-col cols="1"> {{ getTagPresence('consolidation') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'consolidation').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'consolidation')" /> </v-col>
                      </v-row>
                    </li>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Fibrosis </v-col> <v-col cols="1"> {{ getTagPresence('fibrosis') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'fibrosis').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'fibrosis')" /> </v-col>
                      </v-row>
                    </li>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Nodule </v-col> <v-col cols="1"> {{ getTagPresence('nodule') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'nodule').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'nodule')" /> </v-col>
                      </v-row>
                      <ul>
                        <li>
                          <v-row>
                            <v-col cols="9" class="qureFeedbackItems"> Malignancy Risk </v-col> <v-col cols="1"> {{ getTagPresence('lung_nodule_malignancy') }} </v-col>
                            <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'lung_nodule_malignancy').radFeedback"
                                                           @change="onChangeEventHandler($event.value, 'lung_nodule_malignancy')" /> </v-col>
                          </v-row>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <v-row>
                        <v-col cols="9" class="qureFeedbackItems"> Reticular Opacities </v-col> <v-col cols="1"> {{ getTagPresence('reticulonodularpattern') }} </v-col>
                        <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'reticulonodularpattern').radFeedback"
                                                       @change="onChangeEventHandler($event.value, 'reticulonodularpattern')" /> </v-col>
                      </v-row>
                    </li>
                  </ul>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Emphysema </v-col> <v-col cols="1"> {{ getTagPresence('emphysema') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'emphysema').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'emphysema')" /> </v-col>

                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Cavity </v-col> <v-col cols="1"> {{ getTagPresence('cavity') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'cavity').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'cavity')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Mass </v-col> <v-col cols="1"> {{ getTagPresence('mass') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'mass').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'mass')" /> </v-col>
                  </v-row>
                </li>
              </ul>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-row> <v-col> <h3> Pleura </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Blunted Costophrenic Angle </v-col> <v-col cols="1"> {{ getTagPresence('bluntedcp') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'bluntedcp').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'bluntedcp')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Pleural Effusion </v-col> <v-col cols="1"> {{ getTagPresence('peffusion') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'peffusion').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'peffusion')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Pneumothorax </v-col> <v-col cols="1"> {{ getTagPresence('pneumothorax') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'pneumothorax').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'pneumothorax')" /> </v-col>
                  </v-row>
                </li>
              </ul>
              <v-row> <v-col> <h3> Mediastinum </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Tracheal Shift </v-col> <v-col cols="1"> {{ getTagPresence('trachealshift') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'trachealshift').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'trachealshift')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Hilar Prominence </v-col> <v-col cols="1"> {{ getTagPresence('hilarlymphadenopathy') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'hilarlymphadenopathy').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'hilarlymphadenopathy')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Mediastinal Widening </v-col> <v-col cols="1"> {{ getTagPresence('mediastinalwidening') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'mediastinalwidening').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'mediastinalwidening')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Mediastinal Mass </v-col> <v-col cols="1"> {{ getTagPresence('mediastinal_mass') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'mediastinal_mass').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'mediastinal_mass')" /> </v-col>
                  </v-row>
                </li>
              </ul>
              <v-row> <v-col> <h3> Heart </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Cardiomegaly </v-col> <v-col cols="1"> {{ getTagPresence('cardiomegaly') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'cardiomegaly').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'cardiomegaly')" /> </v-col>
                  </v-row>
                </li>
              </ul>
              <v-row> <v-col> <h3> Diaphragm </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Raised/Tented Diaphragm </v-col> <v-col cols="1"> {{ getTagPresence('diaphragm') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'diaphragm').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'diaphragm')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Pneumoperitoneum </v-col> <v-col cols="1"> {{ getTagPresence('pneumoperitoneum') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'pneumoperitoneum').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'pneumoperitoneum')" /> </v-col>
                  </v-row>
                </li>
              </ul>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-row> <v-col> <h3> Bones </h3> </v-col> </v-row>
              <ul>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Scoliosis </v-col> <v-col cols="1"> {{ getTagPresence('scoliosis') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'scoliosis').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'scoliosis')" /> </v-col>
                  </v-row>
                </li>
                <li>
                  <v-row>
                    <v-col cols="9" class="qureFeedbackItems"> Rib Fracture </v-col> <v-col cols="1"> {{ getTagPresence('ribfracture') }} </v-col>
                    <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'ribfracture').radFeedback"
                                                   @change="onChangeEventHandler($event.value, 'ribfracture')" /> </v-col>
                  </v-row>
                </li>
              </ul>
              <v-row>
                <v-col cols="9" class="qureFeedbackItems"> <h3> Gastric Tube </h3> </v-col> <v-col cols="1"> {{ getTagPresence('gastrictube') }} </v-col>
                <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'gastrictube').radFeedback"
                                               @change="onChangeEventHandler($event.value, 'gastrictube')" /> </v-col>
              </v-row>
              <v-row>
                <v-col cols="9" class="qureFeedbackItems"> <h3> Breathing Tube </h3> </v-col> <v-col cols="1"> {{ getTagPresence('breathingtube') }} </v-col>
                <v-col cols="2"> <ToggleButton v-model="qureFeedback.find(a => a.tag === 'breathingtube').radFeedback"
                                               @change="onChangeEventHandler($event.value, 'breathingtube')" /> </v-col>
              </v-row>
              <v-card-title> <h3> Notes </h3> </v-card-title>
              <v-textarea rows="10" auto-grow outlined v-model="comment" />
              <v-card-actions>
                <v-spacer />
                <v-btn color="success" @click="submit()"> Submit </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import dialogMixins from "@/shared/dialog-mixins";
import apiEndpoints from "@/util/apiEndpoints";
import {localStorageConstant} from "@/util/localStorageUtils";
import ClientRoutes from "@/router/ClientRoutes";

export default {
  name: "AiFeedbackForm",
  mixins: [dialogMixins],
  computed: {
    ...mapState(["study"]),
    ...mapGetters(["studyId"]),
  },
  data() {
    return {
      dialog: true,
      ClientRoutes,
      comment: "",
      qureFeedback: [],
      score: 0,
      show: false
    };
  },
  methods: {
    ...mapActions(["saveRadResponseForAIFeedback", "endStudy", "getQurePredictionObject"]),
    async submit() {
      const data = { studyId: this.studyId, comment: this.comment, score: this.score, qureai: this.qureFeedback}
      const url = apiEndpoints.saveRadResponseForAIFeedback;
      await this.saveRadResponseForAIFeedback({ url, data });
      this.closeReportWindow();
    },
    closeReportWindow() {
      if (this.$route.name === this.ClientRoutes.studyViewer) {
        this.endStudy();
      } else {
        window.localStorage.setItem(`${localStorageConstant.closeReportWindow}_${this.studyId}`, "true");
      }
    },
    getTagPresence(item) {
      if(this.qureFeedback.length > 0) {
        let presence = this.qureFeedback.find(a => a.tag === item).presence;
        return presence === "-1" ? "NO" : presence === "1" ? "YES" : "";
      }
    },
    onChangeEventHandler(value, item) {
      this.show = !this.show
      const obj = this.qureFeedback.find(a => a.tag === item)
      if(obj.presence === "1") {
        this.score = value ? this.score + 1 : this.score - 1;
      } else if(obj.presence === "-1") {
        this.score = !value ? this.score + 1 : this.score - 1;
      }
    }
  },
  async created() {
    const resultObject = await this.getQurePredictionObject(this.studyId);
    console.log("resultObject: ", resultObject)

    let firstTagsArray = resultObject[0].response.tags;

    for (let i = 1; i < resultObject.length; i++) {
      const nextTagsArray = resultObject[i].response.tags;
      for (let j = 0; j < firstTagsArray.length; j++) {
        firstTagsArray[j].presence = "" + Math.max(parseInt(firstTagsArray[j].presence), parseInt(nextTagsArray[j].presence));
      }
    }

    this.qureFeedback = firstTagsArray;
    console.log(this.qureFeedback);
    this.qureFeedback.forEach(object => {
      object.radFeedback = object.presence === "-1" ? false : object.presence === "1";
    });
    this.score = this.qureFeedback.length;
  },
};
</script>

<style scoped>

.scoreStyle {
  padding: 5px;
  border: 1px solid;
  border-radius: 15px;
  background-color: #545454;
  margin-left: auto;
}

.qureFeedbackItems {
  display: flex;
  align-items: center;
  white-space: pre;
}

.qureFeedbackItems:after {
  content:" ";
  height: 2px;
  width: inherit;
  background: #495057;
  display: block;
  position: inherit;
  top: 50%;
  right: 0;
}

li {
  list-style-type: none;
}

.col-2, .col-1 {
  text-align: right;
  white-space: nowrap;
}

</style>