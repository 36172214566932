<template>
  <div class="d-flex sort-by" v-if="options.length" style="align-items: center">
    <v-subheader class="d-inline-block">
      <span class="mr-1 font-weight-bold">Sort</span>
      <v-icon size="20">mdi-sort-variant</v-icon>
    </v-subheader>
    <v-select
      dense
      outlined
      class="d-inline-block"
      :items="items"
      item-text="text"
      item-value="value"
      v-model="selectedValue"
      @change="change"
      style="width: 210px"
    />
  </div>
</template>
<script>
export default {
  name: "SortBy",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      selectedValue: null,
    };
  },
  computed: {
    activeRoute() {
      return this.$route.name;
    },
  },
  methods: {
    setDefaultValue() {
      const sortBy = this.$route.query.sortBy;
      if (sortBy) {
        this.items = this.options.map((item) => {
          if (item.value === sortBy) {
            this.selectedValue = item;
          }
          return item;
        });
      } else {
        this.selectedValue = this.options.find((item) => item.default === true);
      }
      this.items = this.options;
    },
    change() {
      console.log("selected value is:", this.selectedValue);
      const name = this.activeRoute;
      const query = { ...this.$route.query };
      if (this.selectedValue !== "") {
        query.sortBy = this.selectedValue.toString();
      } else {
        delete query.sortBy;
      }
      this.$router.push({ name: name, query });
    },
  },
  watch: {
    options: {
      handler() {
        this.setDefaultValue();
      },
      immediate: true,
    },
  },
};
</script>
