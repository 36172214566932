export default {
  inReview: "IN_REVIEW",
  readyForReview: "READY_FOR_REVIEW",
  assigned: "ASSIGNED",
  submitted: "SUBMITTED",
  invalid: "INVALID",
  parked: "PARKED",
  rejected: "REJECTED",
  missingInfo: "MISSING_INFO",
  finished: "FINISHED",
  recheck: "RECHECK",
  readyForAssign: "READY_FOR_ASSIGN",
  diagnosing: "DIAGNOSING",
  accepted: "ACCEPTED",
};
