<template>
  <v-dialog v-model="dialog" max-width="500px" :hide-overlay="hideOverlay" persistent>
    <v-card>
      <v-card-title>
        <h2>Finished Reports List:</h2>
      </v-card-title>
      <v-card-text>
        <div>
          <v-switch v-for="(name, id) in switchState" :key="id" v-model="switchState[id].checked" :label="switchState[id].name" inset></v-switch>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey darken-4" @click="confirm">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
export default {
  name:"GameBallRadsForm",
  props: {
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      switchState: {},
      dialog: false,
    };
  },
  mounted() {
    this.loadRadsNames();
  },
  methods: {
    ...mapActions(["setMessage", "saveRadReportsList", "getRadReportsList", "showRadReportsListAction"]),
    async loadRadsNames() {
      try {
        const url = apiEndpoints.getRadiologistsNames + this.study.id;
        await this.getRadReportsList({ url });
        // const res = await API.get(apiEndpoints.getRadiologistsNames + this.study.id);
        // this.data = {...res.data};
        // this.switchState = Object.entries(this.getRadReportsList).map(([id, value]) => ({
        this.switchState = Object.entries(this.radReportsList).map(([id, value]) => ({
          id,
          name: value,
          checked: false
        }));
        if(Object.keys(this.switchState).length > 0){
          this.dialog = true;
        }else {
          this.showRadReportsListAction(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async confirm(){
      const data = {
        radsData: this.switchState
      };
      const url = apiEndpoints.saveRadReportsList + this.study.id;
      await this.saveRadReportsList({ url, data });
    },
  },
  computed: {
    ...mapState(["study", "radReportsList"]),
  },
};
</script>