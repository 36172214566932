import TurnDown from "turndown";

const TurnDownInstance = new TurnDown();

TurnDownInstance.addRule("underline", {
  filter: ["u"],
  replacement: function (content) {
    return "++" + content + "++";
  },
});
TurnDownInstance.addRule("superscript", {
  filter: ["sup"],
  replacement: function (content) {
    return "<sup>" + content + "</sup>";
  },
});

const getListLevel = (el) => {
  const className = el.className || "0";
  return +className.replace(/[^\d]/g, "");
};

const convertIndentClassToLists = (richText) => {
  const tempEl = window.document.createElement("div");
  tempEl.setAttribute("style", "display: none;");
  tempEl.innerHTML = richText;
  const startLi = "::startli::";
  const endLi = "::endli::";

  ["ul", "ol"].forEach((type) => {
    const startTag = `::start${type}::`;
    const endTag = `::end${type}::`;

    // Grab each list, and work on it in turn
    Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
      const listChildren = Array.from(outerListEl.children).filter((el) => el.tagName === "LI");

      let lastLiLevel = 0;
      let currentLiLevel = 0;
      let difference = 0;

      // Now work through each li in this list
      for (let i = 0; i < listChildren.length; i++) {
        const currentLi = listChildren[i];
        lastLiLevel = currentLiLevel;
        currentLiLevel = getListLevel(currentLi);
        difference = currentLiLevel - lastLiLevel;

        // we only need to add tags if the level is changing
        if (difference > 0) {
          currentLi.before((startLi + startTag).repeat(difference));
        } else if (difference < 0) {
          currentLi.before((endTag + endLi).repeat(-difference));
        }

        if (i === listChildren.length - 1) {
          // last li, account for the fact that it might not be at level 0
          currentLi.after((endTag + endLi).repeat(currentLiLevel));
        }
      }
    });
  });

  //  Get the content in the element and replace the temporary tags with new ones
  let newContent = tempEl.innerHTML;
  newContent = newContent.replace(/::startul::/g, "<ul>");
  newContent = newContent.replace(/::endul::/g, "</ul>");
  newContent = newContent.replace(/::startol::/g, "<ol>");
  newContent = newContent.replace(/::endol::/g, "</ol>");
  newContent = newContent.replace(/::startli::/g, "<li>");
  newContent = newContent.replace(/::endli::/g, "</li>");

  tempEl.remove();
  newContent = newContent.replace(/<\/li><li><ol>/g, "<ol>");
  newContent = newContent.replace(/<\/li><li><ul>/g, "<ul>");
  return newContent;
};

const HtmlToMarkdown = (contents) => TurnDownInstance.turndown(convertIndentClassToLists(contents));

export default HtmlToMarkdown;
