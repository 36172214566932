export const GET_STUDY_BY_ID = "getStudyById";
export const UPDATE_STUDY = "updateStudy";
export const SET_API_CALL = "setApiCall";
export const API_ERROR = "apiError";
export const GET_RADIOLOGISTS = "getRadiologists";
export const GET_STUDY_LOGS = "getStudyLogs";
export const TOGGLE_REPORT_DIALOG = "toggleReportDialog";
export const GET_STUDY_REPORT_LIST = "getStudyReportList";
export const REMOVE_REPORT_BY_ID = "removeReportById";
export const GET_STUDY_REPORT = "getStudyReport";
export const GET_STUDY_REPORT_FOR_CHECK = "getStudyReportForCheck";
export const DELETE_STUDY_REPORT = "deleteStudyReport";
export const NEW_REPORT_ID = "newReportId";
export const GET_SHORTHAND_LIST = "getShorthandList";
export const SET_MESSAGE = "setMessage";
export const DELETE_MESSAGE = "deleteMessage";
export const REJECT_STUDY = "rejectStudy";
export const TOGGLE_SHORTHANDS_DIALOG = "toggleShorthandsDialog";
export const SET_SHORTHAND_QUILL_CONTENTS = "setShorthandQuillContents";
export const GET_REQUEST_TYPE_LIST = "getRequestTypesList";
export const GET_CURRENT_USER = "getCurrentUser";
export const FINISH_STUDY_REPORT = "finishStudyReport";
export const TOGGLE_STUDY_VISIBILITY = "TOGGLE_STUDY_VISIBILITY";
export const GET_PATIENT_HISTORY_LIST = "GET_PATIENT_HISTORY_LIST";
export const CLEAR_PATIENT_HISTORY_LIST = "CLEAR_PATIENT_HISTORY_LIST";
export const GET_CLINICAL_INFO = "GET_CLINICAL_INFO";
export const DEMERGE_STUDY = "DEMERGE_STUDY";
export const SHOW_AI_RESPONSE_FORM = "SHOW_AI_RESPONSE_FORM";
export const SHOW_PROBABILITY_OF_REVIEW_RESPONSE_FORM = "SHOW_PROBABILITY_OF_REVIEW_RESPONSE_FORM";
export const GET_PROBABILITY_OF_REVIEW_RESPONSE_LIST = "getProbabilityOfReviewResponseList";
export const SHOW_RAD_REPORTS_LIST_DIALOG = "SHOW_RAD_REPORTS_LIST_DIALOG";
export const RAD_REPORTS_LIST = "radReportsList";
export const SET_LOCAL_STORAGE_FLAG = "SET_LOCAL_STORAGE_FLAG";
export const GET_SYSTEM_TAGS = "getSystemTags";
export const GET_RAD_TAGS = "getRadTags";
export const SET_REPORT_FROM_TEMPLATE = "SET_REPORT_FROM_TEMPLATE";
export const GET_MODALITIES_LIST = "getModalitiesList";
export const SET_HEADERS_BOOLEAN_STORED = "setHeadersBooleanStored";
export const TOGGLE_ABOUT_US_FORM = "toggleAboutUsForm";
export const SHOW_RAD_RESPONSE_FOR_AI_FORM = "SHOW_RAD_RESPONSE_FOR_AI_FORM"