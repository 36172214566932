<template>
  <v-dialog v-model="dialog" width="400" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card>
      <v-card-title>AI Response</v-card-title>
      <v-card-text v-if="!isNormal">
        <strong class="red--text text--lighten-1">Abnormal case:</strong> There are more than 10 cuts detected.
      </v-card-text>
      <v-card-text v-if="isNormal">
        <strong class="green--text text--lighten-1">Normal case</strong>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import dialogMixins from "@/shared/dialog-mixins";
import AiResponseTypes from "@/util/AiResponseTypes";

export default {
  name: "AiResponse",
  mixins: [dialogMixins],
  computed: {
    ...mapState(["study"]),
    isNormal() {
      return this.study.aiResponse === AiResponseTypes.normal;
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    cancel() {
      this.$emit("resetAction");
    },
  },
};
</script>

<style scoped></style>
