<template >
  <div>
  <v-dialog v-model="dialog" max-width="900" v-if="isForApply" @click:outside="applyCancel">
    <RadSettings_Templates :isForApply="true" :radId="radId" @applyTemplateEmit="apply" @applyTemplateCancelEmit="applyCancel" ></RadSettings_Templates>
  </v-dialog>
  <v-container class="pt-0 pb-0" fluid v-if="!isForApply">
    <RadSettings_Templates :isForApply="false" :listHeight="listHeight"></RadSettings_Templates>
  </v-container>
  </div>
</template>

<script>

import RadSettings_Templates from "@/views/RadSettings_Templates";
export default {
  name: "TemplatesSettings_Pickup",
  components: { RadSettings_Templates },
  data(){
    return {
      dialog: true,
      listHeight: "435px",
    }
},
  props: {
    isForApply: {
      type: Boolean,
      default: false,
    },
    radId: {
      type: String,
      default: '',
    }
  },
  computed: {

  },
  methods: {
    apply(value){
      this.$emit("applyTemplateEmit", { templateData: value.templateData });
    },
    applyCancel(){
      this.$emit("applyTemplateCancelEmit");
    },
  },
  mounted() {
  },
};
</script>