<template v-slot:activator="{ on, attrs }">
  <v-tooltip bottom :disabled="!mprObj.isMprLoading">
    <template v-slot:activator="{ on }">
      <div v-on="on" id="mpr" class="d-inline-block">
        <v-btn v-on="on" :id="`mpr`" v-show="showMprIcon" class="py-1 px-2 mpr-button layout-button" height="auto" text
               tile v-on:click="clickHandler" :disabled="mprObj.disableMPR || loadingDisabled">
          <v-icon id="mpr" size="20">mdi-cube-outline</v-icon>
          <div class="caption text-capitalize" id="mpr">MPR</div>
          <v-progress-linear v-show="mprObj.isMprLoading" id="progressBarTool" indeterminate absolute bottom />
        </v-btn>
      </div>
    </template>
    <div v-html="mprObj.getInProgressMsg(this.mprObj, this.seriesDetails)"></div>
  </v-tooltip>
</template>

<script>
import { mapActions, mapState } from "vuex";
import worker from "@/MPR";

let viewerInterface;
let viewerActions;
const waitingTimeToRetryMpr = 2000;
const maxRecursiveCalls = 2; // # of trials if there are server error from lambda
let evenAbortController;
import MprHandler from "@/util/MprHandler";

export default {
  name: "MPR",
  computed: {
    ...mapState(["study"]),
  },
  beforeCreate() {
    evenAbortController = new AbortController();
    viewerInterface = window["VIEWER_INTERFACE"];
    viewerActions = window["VIEWER_ACTIONS"];
  },
  data() {
    return {
      mprObj: new MprHandler(),
      showMprIcon: false,
      seriesDetails: null,
      isMprDataLoadedBefore: false,
      mprData: null,
      loadingDisabled: true
    };
  },

  methods: {
    ...mapActions(["setMessage"]),
    mprVisibilityHandler() {
      const modality = this.study.studyInfo.modality;
      this.showMprIcon = modality == "CT" || modality == "PR" || modality == "MR" || modality == "PT";
    },
    async clickHandler() {
      if (window.focusElement == null) {
        this.setMessage({ message: `Please select case for MPR.`, type: "error" });
        return;
      }
      this.mprObj.isMprLoading = true;
      this.mprObj.disableMPR = true;
 
      let toolData = window.cornerstoneTools.getToolState(window.focusElement, "stack");
      if (toolData === undefined || toolData.data === undefined || toolData.data.length === 0) {
        this.mprObj.resetMprBtn(this.mprObj);
        return;
      }
      let stack = toolData.data[0];
      if (stack.loaded == stack.imageIds.length) {
        this.callMprApi(stack.imageIds, stack.seriesDescription, stack.stackId);
      } else {
        setTimeout(async () => {
          this.clickHandler();
        }, waitingTimeToRetryMpr);
      }
    },
    callMprApi(imageIds, seriesDescription, stackId) {
      try {
        const objKey = `${seriesDescription}-${stackId}`;
        const seriesDesc = seriesDescription;
        this.mprObj.countDownTimer(objKey, this.mprObj);
        this.mprObj.updateMprSeriesListStatus(objKey, true, true, 1);
        this.mprObj.syncBtnWithCurrentStatus(objKey, this.seriesDetails, this.mprObj);
        this.CheckIsMprDataLoadedBefore(seriesDescription, stackId);
        if (!this.isMprDataLoadedBefore) {
          if (this.mprObj.mprSeriesListStatus[objKey].trialNum > maxRecursiveCalls) {
            this.setMessage({
              message: `Failed to generate MPR for series ${seriesDesc}, please contact Rology support`,
              type: "error",
              timeout: 10000,
            });
            this.mprObj.resetMprSeriesListObj(objKey, this.seriesDetails, this.mprObj);
            return;
          }
          console.log("start generate MPR " + objKey);
          try {
            const { studyInstanceUID, mprPlane, studyId } = this.seriesDetails;
            let mprUrl = `/viewer/mpr/generateMPR/studyId/${studyId}/seriesDesc/${seriesDesc === "" ? stackId : seriesDesc}/seriesNum/${stackId}/studyInstanceUID/${studyInstanceUID}/mprPlane/${mprPlane}`;
            let encodedUrl = encodeURI(mprUrl);

            let dicomFiles = [];
            let loadedImages = {};
            let counter = 0;
            imageIds.forEach((uri) => {
              let cachedImage = window.cornerstone.imageCache.cachedImages.find(x => x.imageId == uri);
              let pixelData = cachedImage.image.getPixelData();
              let imgId = uri.substring(uri.indexOf(studyId), uri.indexOf('.zip?')).split('/')[1];

              loadedImages[imgId] = pixelData;
              let uriPrefixArr = uri.split("?")[0].split("/");
              let recordId = uriPrefixArr[uriPrefixArr.length - 2] + "/" + uriPrefixArr[uriPrefixArr.length - 1];

              let objectStore = window.getObjectStoreInstance();
              let request = objectStore.get(recordId);
              request.onsuccess = async () => {
                let data = await request.result.byteArray;
                let file = new File([data], recordId);
                dicomFiles.push(file);
                data = null;
                file = null;
                if (counter == imageIds.length - 1) {
                  worker.postMessage({
                    dicomFiles: dicomFiles,
                    encodedUrl: encodedUrl,
                    studyId: studyId,
                    studyInstanceUID: studyInstanceUID,
                    stackId: stackId,
                    study: this.study,
                    objKey: objKey,
                    mprPlane: mprPlane,
                    seriesDesc: seriesDesc === "" ? stackId : seriesDesc,
                    loadedImages: loadedImages
                  })
                    .then((data) => {
                      this.openMPRWindow(data);
                      dicomFiles = null;
                      loadedImages = null;
                    })
                    .catch((error) => {
                      console.log(error)
                      this.mprObj.resetMprSeriesListObj(objKey, this.seriesDetails, this.mprObj);
                      this.setMessage({ message: `Failed generating MPR.`, type: "error" });
                    });
                }
                counter++;
              };
            });
          } catch {
            this.mprObj.resetMprSeriesListObj(objKey, this.seriesDetails, this.mprObj);
            this.setMessage({ message: `Failed generating MPR.`, type: "error" });
          }
        }
      } catch (error) {
        console.log("Error generating mpr", error.response);
        this.setMessage({ message: `Failed generating MPR.`, type: "error" });
      }
    },
    openMPRWindow(data) {
      let mprData = this.getMPRData(data);
      localStorage.setItem(`MPR_${data.encodedUrl}`, JSON.stringify(mprData));
      this.SaveDataToLocalStorage(`MPR_${data.encodedUrl}`);
      this.mprObj.resetMprBtn(this.mprObj);
      this.openWindow(data);
    },
    SaveDataToLocalStorage(data) {
      let arr = [];
      arr = JSON.parse(localStorage.getItem('loaded-mpr-info')) || [];
      arr.push({ id: data, time: new Date().getTime() });
      localStorage.setItem('loaded-mpr-info', JSON.stringify(arr));
    },
    CheckIsMprDataLoadedBefore(seriesDesc, stackId) {
      let { mprPlane, studyInstanceUID, studyId } = this.seriesDetails;
      let mprUrl = `/viewer/mpr/generateMPR/studyId/${studyId}/seriesDesc/${seriesDesc === "" ? stackId : seriesDesc}/seriesNum/${stackId}/studyInstanceUID/${studyInstanceUID}/mprPlane/${mprPlane}`;
      let encodedUrl = encodeURI(mprUrl);
      const generatedMprData = JSON.parse(localStorage.getItem(`MPR_${encodedUrl}`));
      if (generatedMprData) {
        this.isMprDataLoadedBefore = true;
        this.mprObj.resetMprBtn(this.mprObj);
        let data = {
          studyId: studyId,
          stackId: stackId,
          studyInstanceUID: studyInstanceUID,
          mprPlane: mprPlane,
          objKey: `${seriesDesc}-${stackId}`,
          seriesDesc: seriesDesc
        }
        this.openWindow(data);
      } else {
        this.isMprDataLoadedBefore = false;
      }
    },
    openWindow(data) {
      let { id } = this.$route.params;
      let url = window.location.href.replace("study-path", "study-mpr").replace(id, data.studyId); // Use the sent id from mpr click because of old studies have diff ids
      url += `/seriesNum/${data.stackId}/seriesDesc/${data.seriesDesc === "" ? data.stackId : data.seriesDesc}/instanceUid/${data.studyInstanceUID}/mprPlane/${data.mprPlane}`;

      this.mprObj.updateMprSeriesListStatus(data.objKey, false, false, 0);
      window.open(url, "_blank", "width=1400");
      data = null;
    },
    getMPRData(data) {
      let image = {};
      image = {
        host: "s3.eu-central-1.amazonaws.com",
        protocol: "https:",
        bucket: "com.rology.s3.studies.mpr",
        auPrefix: null,
        dicom: {
          modality: `${data.study.studyInfo.modality}`,
          numImages: `${data.study.numImages}`,
          originalPatientName: null,
          patientAge: `${data.patientAge}`,
          patientBirthDate: `${data.patientBirthDate}`,
          patientId: `${data.patientId}`,
          patientName: `${data.study.patientName}`,
          patientSex: `${data.study.patientSex}`,
          seriesList: [
            {
              seriesDate: `${data.seriesDate}`,
              seriesNumber: "301" + data.stackId,
              seriesDescription: "sagittal",
              numberOfFrames: null,
              instanceList: [],
            },
            {
              seriesDate: `${data.seriesDate}`,
              seriesNumber: "201" + data.stackId,
              seriesDescription: "coronal",
              numberOfFrames: null,
              instanceList: [],
            },
          ],
          studyDate: `${data.seriesDate}`,
          studyDescription: `${data.studyDescription}`,
          studyId: `${data.studyId}`,
          studyInstanceUID: `${data.studyInstanceUID}`,
        },
        statusCode: 200,
        message: null,
        errorReason: null,
      };

      data.imageIds1.forEach((element, index) => {
        image.dicom.seriesList[0].instanceList.push({
          imageId: `${element}/${data.studyId}/${data.studyInstanceUID}/${data.stackId}`,
          instanceNumber: index + 1,
        });
      });
      data.imageIds2.forEach((element, index) => {
        image.dicom.seriesList[1].instanceList.push({
          imageId: `${element}/${data.studyId}/${data.studyInstanceUID}/${data.stackId}`,
          instanceNumber: index + 1,
        });
      });

      return image;
    },
  },
  mounted() {
    if (this.study) {
      this.mprVisibilityHandler();
    }

    viewerInterface.addEventListener(
        viewerActions.allLoaded,
        (e) => {
          this.loadingDisabled = !e.detail
        },
        { signal: evenAbortController.signal }
    );

    viewerInterface.addEventListener(
      viewerActions.selectSeries,
      (e) => {
        this.seriesDetails = e.detail.series;
        //enable mpr always, not depend on mprplane.
        // this.mprObj.disableMPR = !this.seriesDetails.mprPlane;
        // this.mprObj.disableMPR = false;

        this.isMprDataLoadedBefore = e.detail.isMprDataLoadedBefore;
        this.mprData = e.detail.mprData;
        // get series description with patient name if exist in same string
        const { seriesDesc } = e.detail.series;
        const { patientName } = this.study;
        let patientNameIndex = seriesDesc ? seriesDesc.indexOf(patientName) : -1;
        let seriesDescription = seriesDesc;
        if (patientNameIndex > -1) {
          seriesDescription = seriesDesc.substring(patientName.length).trim();
        }
        this.seriesDetails.seriesDesc = seriesDescription;
        this.mprObj.updateMprStatus(this.mprObj, this.seriesDetails);
      },
      { signal: evenAbortController.signal }
    );

    viewerInterface.addEventListener(
      viewerActions.openMpr,
      (e) => {
        if (e.detail.success) {
          this.openMPRWindow();
        } else {
          if (e.detail.reason) {
            this.setMessage({ message: `Failed generating MPR, unsupported modality type.`, type: "error" });
          } else {
            this.setMessage({ message: `Failed generating MPR, please try again in few minutes.`, type: "error" });
          }
        }
        this.mprObj.resetMprBtn(this.mprObj);
      },
      { signal: evenAbortController.signal }
    );
  },
  watch: {
    study(newValue) {
      if (newValue) {
        this.mprVisibilityHandler();
      }
    },
  },
  destroyed() {
    evenAbortController.abort();
  },
};
</script>
