<template>
  <v-card tile flat>
    <v-card-text class="px-0">
      <v-textarea hide-details outlined label="Add a Comment" v-model="text" auto-grow rows="1"></v-textarea>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-spacer></v-spacer>
      <v-btn v-if="save" small color="blue-grey darken-4" @click="saveComment(text)">Save</v-btn>
      <v-btn v-if="!save" small color="blue-grey darken-4" @click="closeForm()">Cancel</v-btn>
      <v-btn v-if="!save" small color="blue" @click="editComment(text)">Submit</v-btn>
      <v-btn v-if="!save" small color="red" @click="deleteComment()">Delete</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "CommentForm",
  data() {
    return {
      text: "",
    };
  },
  props: {
    save: {
      type: Boolean,
      default: false,
    },
    comment: null,
  },
  created() {
    this.text = this.comment ? this.comment.text : "";
  },
  methods: {
    saveComment(text) {
      this.$emit("save", text);
      this.text = "";
    },
    editComment() {
      // eslint-disable-next-line vue/no-mutating-props
      this.comment.text = this.text;
      this.$emit("edit", this.comment, this.text);
      this.closeForm();
    },
    deleteComment() {
      this.$emit("delete", this.comment);
      this.closeForm();
    },
    closeForm() {
      this.$emit("close");
    },
  },
};
</script>
