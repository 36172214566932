<template>
  <v-alert dismissible elevation="11" :type="message.type" @input="clickHandler">
    {{ message.message }}
  </v-alert>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Message",
  props: {
    message: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeoutId: null,
      timeout: 3000,
    };
  },
  methods: {
    ...mapActions(["deleteMessage"]),
    clickHandler() {
      clearTimeout(this.timeoutId);
      this.removeMessage();
    },
    removeMessage() {
      this.deleteMessage(this.message.id);
    },
  },
  mounted() {
    const messageTimeout = this.message.timeout || this.timeout;
    this.timeoutId = setTimeout(() => {
      this.removeMessage();
    }, messageTimeout);
  },
};
</script>

<style lang="scss">
.one-message {
  margin-bottom: 15px;
}
</style>
