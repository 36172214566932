export default {
  studyViewer: "StudyViewer",
  report: "Report",
  mpr: "MPR",
  radiologistStudies: "radiologistStudies",
  radiologistProfile: "radiologistProfile",
  signout: "signout",
  radiologistSettings: "radiologistSettings",
  radSettingsTemplates: "radSettingsTemplates",
  templatesSettings_Pickup:"templatesSettings_Pickup",
};
