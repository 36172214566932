const commonColorsForLightAndDarkTheme = {
  info: "#2196F3",
  secondary: "#424242",
  error: "#FF5252",
  success: "#4CAF50",
  warning: "#FB8C00",
};

export const dark = {
  primary: "#2196F3",
  accent: "#FF4081",
  ...commonColorsForLightAndDarkTheme,
};

export const light = {
  primary: "#1976D2",
  accent: "#82B1FF",
  ...commonColorsForLightAndDarkTheme,
};

export const RologyDark = {
  ...dark,
  primary: "#6cbffd",
};

export default {
  dark,
  light,
  RologyDark,
};
