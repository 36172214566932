<template>
  <div class="series-wrapper d-flex justify-center">
    <div class="series-thumbnail">
      <slot name="dicomImage" />
      <div class="series-details d-flex justify-space-between align-center">
        <span class="title"><slot name="title" /></span>
        <span class="icon"><slot name="number-and-icon" /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeriesWrapper",
};
</script>

<style scoped lang="scss">
.series-wrapper {
  display: flex;

  .series-thumbnail {
    width: 250px;
  }
}
</style>
