<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-badge :content="tagsLength" :value="tagsLength" color="blue" offset-x="30" overlap>
        <v-btn class="tool-button level-1 py-1 px-2 tag-button" height="auto" text tile v-bind="attrs" v-on="on">
          <v-icon size="20">mdi-tag-multiple</v-icon>
          <div class="caption text-capitalize">Tags</div>
        </v-btn>
      </v-badge>
    </template>
    <v-card v-if="isStudy">
      <v-card-title class="headline" style="text-transform: capitalize"> Tags on study </v-card-title>
      <v-card-text>
        <br />
        <v-combobox
          label="Choose a tag"
          outlined
          item-text="name"
          item-value="name"
          :items="tags"
          v-model="selectedItems"
          multiple
          chips
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="confirm" :disabled="!selectedItems">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import API from "@/shared/API";

export default {
  name: "StudyTags",
  data() {
    return {
      dialog: false,
      selectedItems: null,
      getTagsUrl: "/admin/systemTagging/getTags",
      saveTagsUrl: "/medicalAdmin/studyTagging/editTags",
      tagsLength: 0,
    };
  },
  methods: {
    ...mapActions(["getSystemTags", "getStudyById", "setMessage"]),
    cancel() {
      this.dialog = false;
    },
    async confirm() {
      let studyTags = "";
      this.selectedItems.forEach(function (val) {
        studyTags += (val.name ? val.name : val) + ",";
      });
      if (studyTags.length > 1) {
        studyTags = studyTags.slice(0, studyTags.length - 1);
      }
      let data = {
        studyId: this.study.id,
        tags: studyTags,
      };
      const response = await API.post(this.saveTagsUrl, data);
      if (response.status === 200) {
        this.setMessage({ message: `Tags on study updated`, type: "success" });
      }
      await this.getStudyById(this.study.id);
      this.tagsLength = this.selectedItems.length;
      this.dialog = false;
    },
    reloadSelectedTags() {
      this.selectedItems = this.study.tags ? this.study.tags.split(",") : [];
      let items = [];
      this.selectedItems.forEach(function (val) {
        items.push({ name: val, value: val, checked: true });
      });
      this.tagsLength = this.selectedItems.length;
    }
  },
  computed: {
    ...mapState(["study", "systemTags"]),
    tags() {
      return this.systemTags;
    },
    isStudy() {
      return this.study && Object.keys(this.study).length > 0;
    },
  },
  mounted() {
    this.reloadSelectedTags();
    this.getSystemTags(this.getTagsUrl);
  },
  watch: {
    study(newStudy){
      if(newStudy){
        this.study = newStudy
        this.reloadSelectedTags();
      }
    }
  }
};
</script>

<style scoped></style>
