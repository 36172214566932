<template>
  <v-dialog v-model="dialog" width="900" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card :loading="loading">
      <v-card-title>
        <v-container class="pa-0">
          <v-row class="templateHeader">
            <v-col><span style="white-space: nowrap;">Manage Templates</span></v-col>
<!--            <v-spacer />-->
            <v-col>
              <TemplatePicker
                :systemTemplates="systemTemplates"
                :customTemplates="customTemplates"
                @setTemplate="setTemplate"
                :templateName="selectedTemplate.templateName"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <div>
          <span class="caption">Template Name</span>
          <v-text-field outlined dense v-model="formTemplate.templateName" hint="Template name must be unique" />
        </div>
        <div>
          <span class="caption">Technique</span>
          <v-text-field outlined dense v-model="formTemplate.technique" />
        </div>
        <div>
          <span class="caption">Findings</span>
          <Quill type="findings" @onEditorChange="onFindingChange" :contents="findings" :key="renderingKey" />
        </div>
        <div>
          <span class="caption">Impression</span>
          <Quill type="impression" @onEditorChange="onImpressionChange" :contents="impression" :key="renderingKey" />
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn class="templateBtn" @click="clearForm">Clear</v-btn>
        <v-btn class="templateBtn" color="error" :disabled="isTemplateProtected" @click="toggleRemoveTemplateDialog">
          Delete template
        </v-btn>
        <v-btn class="templateBtn" color="success" :disabled="isTemplateExist || !isFormValid" @click="createTemplate">Save changes</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog
      title="Delete Template"
      v-if="showConfirm"
      @confirm="removeTemplate"
      @cancel="toggleRemoveTemplateDialog"
    >
      Are you sure you want to delete this template?
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "@/shared/dialog-mixins";
import { TemplateInterface } from "@/util/Interfaces";
import Quill from "@/components/Quill";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";
import TemplatePicker from "@/components/Template/TemplatePicker";

export default {
  name: "TemplateFormDialog",
  components: { TemplatePicker, ConfirmDialog, Quill },
  mixins: [dialogMixins],
  data() {
    return {
      loading: false,
      dialog: true,
      showConfirm: false,
      selectedTemplate: { templateName: "", protected: false },
      formTemplate: { ...TemplateInterface },
      findings: "",
      impression: "",
      renderingKey: 0,
    };
  },
  computed: {
    ...mapState("Templates", ["loadedTemplate", "systemTemplates", "customTemplates"]),
    isFormValid() {
      const { impression, technique, templateName, findings } = this.formTemplate;
      if(technique === null){
        return false;
      } else if (technique.trim() === "") {
        return false;
      } else if (findings.trim() === "") {
        return false;
      } else if (impression.trim() === "") {
        return false;
      } else if (templateName.trim() === "") {
        return false;
      }
      return true;
    },
    isTemplateExist() {
      if (this.formTemplate.templateName === "") {
        return false;
      }
      const isExist = this.systemTemplates.find((item) => {
        return item.templateName === this.formTemplate.templateName;
      });
      return isExist !== undefined;
    },
    isTemplateProtected() {
      return this.selectedTemplate.protected || this.selectedTemplate.templateName === "";
    },
  },
  methods: {
    ...mapActions("Templates", [
      "getTemplate",
      "toggleTemplateForm",
      "getTemplatesList",
      "deleteTemplate",
      "saveTemplate",
    ]),
    cancel() {
      this.toggleTemplateForm();
    },
    clearForm() {
      this.formTemplate = { ...TemplateInterface };
      this.selectedTemplate = { templateName: "", protected: false };
      this.impression = "";
      this.findings = "";
      this.renderingKey++;
    },
    onFindingChange(value) {
      this.formTemplate.findings = value;
    },
    onImpressionChange(value) {
      this.formTemplate.impression = value;
    },
    async loadTemplate() {
      if (this.selectedTemplate) {
        this.loading = true;
        let data = { templateName: this.selectedTemplate.templateName };
        await this.getTemplate(data);
        this.loading = false;
      } else {
        this.clearForm();
      }
    },
    async toggleRemoveTemplateDialog() {
      this.showConfirm = !this.showConfirm;
    },
    async removeTemplate() {
      this.loading = true;
      await this.toggleRemoveTemplateDialog();
      const isRemoved = await this.deleteTemplate(this.selectedTemplate.templateName);
      if (isRemoved) {
        this.loading = false;
        this.clearForm();
      }
    },
    async createTemplate() {
      this.loading = true;
      const isCreated = await this.saveTemplate(this.formTemplate);
      if (isCreated) {
        this.loading = false;
        this.clearForm();
      }
    },
    setTemplate(item) {
      this.selectedTemplate = item;
      this.loadTemplate();
    },
  },
  watch: {
    loadedTemplate(newValue) {
      Object.assign(this.formTemplate, newValue);
      this.findings = newValue.findings;
      this.impression = newValue.impression;
      this.formTemplate.templateName = this.selectedTemplate.templateName;
    },
  },
  mounted() {
    if (!this.systemTemplates.length) {
      this.getTemplatesList();
    }
  },
};
</script>
<style>
.v-card__actions {
  display: flex;
}

@media only screen and (max-device-width: 490px) {

  .v-card__actions {
    display: grid !important;
  }

  .templateBtn {
    width: 100%;
    margin: 0% 0% 2% 0% !important;
  }
}

@media only screen and (max-device-width: 480px) {
  .templateHeader {
    display: grid;
  }
}

</style>