<template>
  <v-app-bar>
    <div class="logoAndProfile">
    <div class="logoAndSlogan">
    <v-toolbar-title>
      <v-img :src="require(`@/assets/Rology-Logo-White.png`)" width="300px" height="70px" alt="logo" style="margin-right: 8px" />
    </v-toolbar-title>
    <div class="rologyMsg">
      <span> Simply, we save lives.</span>
      <v-icon class="rologyMsg-icon"
              color="red"
              left
      >
        mdi-heart
      </v-icon>
    </div>
    </div>
    <div class="logoAndSloganSMALL" >
      <v-toolbar-title>
        <v-img :src="require('@/assets/logo-white.png')" alt="logo" style="margin-right: 8px" ></v-img>
      </v-toolbar-title>

    </div>
    <v-spacer />
    <div class="threeButtons">

      <v-btn v-if="isRadiologist"
        class="text-capitalize mr-3"
        @click="goToMyStudies"
        id="studiesBtn"
        :input-value="activeRoute === ClientRoutes.radiologistStudies"
      >
        <v-icon left>mdi-heart-pulse</v-icon>
        <span>Studies</span>
      </v-btn>
      <AboutUsButton v-if="isRadiologist" html-class="mr-3"/>
      <v-btn v-if="isAdmin" color="teal"
             class="text-capitalize mr-3"
             @click="goToManageTemplates"
             :input-value="activeRoute === ClientRoutes.templatesSettings_Pickup">
        <v-icon left>mdi-file</v-icon>
        <span>Manage Templates</span>
      </v-btn>
      <v-btn v-if="isAdmin" color="indigo"
             class="text-capitalize mr-3"
             @click="goToRadSettings"
             :input-value="activeRoute === ClientRoutes.templatesSettings_Pickup">
        <v-icon left>mdi-file</v-icon>
        <span>Radiologist Settings</span>
      </v-btn>
    </div>

    <div class="profileAndLogout">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="goToEditProfile()"
               :input-value="activeRoute === ClientRoutes.radiologistProfile">
          <v-avatar>
            <img
                :src="profilePictureURL"
                alt="profile picture"
            >
          </v-avatar>
        </v-btn>
      </template>
      <span>Profile</span>
    </v-tooltip>
    <LogOut />
    </div>
      <div class="profileAndLogoutThreeDotsMenu">
        <div class="dropdown" v-click-outside="onClickOutside">
          <!-- three dots -->
          <ul class="dropbtn icons btn-right showLeft" @click="showDropdown()">
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <!-- menu -->
          <div id="myDropdown" ref="myDropdown" class="dropdown-content">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="goToEditProfile()"
                       :input-value="activeRoute === ClientRoutes.radiologistProfile">
                  <v-avatar>
                    <img
                        :src="profilePictureURL"
                        alt="profile picture">
                  </v-avatar>
                </v-btn>
              </template>
              <span>Profile</span>
            </v-tooltip>
            <LogOut />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="threeButtonsLINE">
      <v-btn v-if="isRadiologist"
             class="text-capitalize mr-3"
             @click="goToMyStudies"
             id="studiesBtn"
             :input-value="activeRoute === ClientRoutes.radiologistStudies">
        <v-icon left>mdi-heart-pulse</v-icon>
        <span>Studies</span>
      </v-btn>
      <AboutUsButton v-if="isRadiologist" html-class="mr-3"/>
      <IdleComponent v-if="isIdle && isRadiologist" :signOut="signOut" :idleTime="idleTime"/>
    </div>
  </v-app-bar>
</template>

<script>
import ClientRoutes from "@/router/ClientRoutes";
import { mapActions, mapState} from "vuex";
import LogOut from "@/components/Logout/Logout";
import AboutUsButton from "@/components/AboutUs/AboutUsButton";
import apiEndpoints from "@/util/apiEndpoints";
import UserTypes from "@/util/UserTypes";
import axios from "axios";
import IdleComponent from "@/components/IdleComponent/IdleComponent.vue";
import API from "@/shared/API";
import Signout from "@/components/Logout/Signout.vue";

const events = "mousemove,keydown,mousedown,touchstart,keypress,scroll,load,touchmove,click,wheel".split(",");

export default {
  name: "Header",
  components: {IdleComponent, LogOut, AboutUsButton },
  data() {
    return {
      ClientRoutes,
      apiKey: "",
      gameBallSetting: false,
      isIdle: false,
      idleTime: 0,
    };
  },
  computed: {
    ...mapState("Profile", ["profilePictureURL"]),
    ...mapState(["currentUser"]),
    ...mapState("RadStudies", ["studiesList"]),
    activeRoute() {
      return this.$route.name;
    },
    isRadiologist() {
      return this.currentUser && this.currentUser.type === UserTypes.radiology;
    },
    isAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.admin;
    }
  },
  methods: {
    ...mapActions("Profile", ["getProfilePic"]),
    ...mapActions("Layout", ["closeReportWindow"]),
    ...mapActions(["setMessage", "getCurrentUser"]),
    goToMyStudies() {
      this.closeReportWindow();
      // add catch block with empty function to avoid console errors when trying to access same page route
      this.$router.push({ name: ClientRoutes.radiologistStudies }).catch(() => {});
    },
    goToEditProfile() {
      this.$router.push({ name: ClientRoutes.radiologistProfile }).catch(() => {});
    },
    async loadRadProfilePic() {
      try {
        const url = `${apiEndpoints.getRadProfilePic}`;
        await this.getProfilePic(url);
      } catch (error) {
        console.log("Error while getting profile pic for current user.");
      }
    },
    showDropdown() {
      this.$refs["myDropdown"].classList.toggle("show");
    },
    onClickOutside() {
      this.$refs["myDropdown"].classList.remove("show");
    },
    goToManageTemplates() {
      this.$router.push({ name: ClientRoutes.templatesSettings_Pickup }).catch(() => {});
    },
    goToRadSettings() {
      this.$router.push({ name: ClientRoutes.radiologistSettings }).catch(() => {});
    },
    async getUserGameBallSetting(subId){
      try {
        const gameBallSettingUrl = apiEndpoints.getUserGameBallSetting + subId;
        const result = await axios.get(gameBallSettingUrl);
        this.gameBallSetting = result.data;
      } catch (error) {
        console.log('Error', error.message);
      }
    },
    async getGameBallApiKey() {
      try {
        const country = "eg";
        const gameBallUrl = apiEndpoints.getGameBallApiKey + country;
        const result = await axios.get(gameBallUrl);
        this.apiKey = result.data;
      } catch (error) {
        console.log('Error', error.message);
      }
    },
    gameBallScriptCall() {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://assets.gameball.co/widget/js/gameball-init.min.js';
      scriptElement.defer = true;
      document.body.appendChild(scriptElement);
    },
    GbLoadInit() {
      let key = this.apiKey;
      let playerId = localStorage.subjectId;
      window.GbLoadInit = () => {
        // TODO fix this undef please.
        // eslint-disable-next-line no-undef
        GbSdk.init({
          APIKey: key,
          lang: 'en',
          playerUniqueId: playerId,
        });
      };
    },
    async signOut() {
      await this.releaseAllPickupStudies();
      try {
        const logoutUrl = `${apiEndpoints.logout}`;
        await API.get(logoutUrl).then(() => {
          Signout.methods.signOut();
          Signout.methods.clear();
        }).catch((error) => {
          console.log(error);
        });
      } catch (error) {
        console.log("Error while logout.");
      }
    },
    releaseAllPickupStudies() {
      let studiesIds = [];
      this.studiesList.filter(item => item.pickup === true).forEach(item => {
        studiesIds.push(item.studyId);
      });
      return API.post("/medicalAdmin/releaseAll", {"studiesIds": studiesIds});
    },
    updateLastActivity() {
      localStorage.lastActivity = Date.now();
    },
    checkIdleStatus() {
      const lastActivity = localStorage.lastActivity;
      this.isIdle = Date.now() - lastActivity > this.idleTime;
    },
    getRadIdleTimeInMin() {
      return API.get(apiEndpoints.getRadIdleTimeInMin);
    },
  },
  async mounted() {
    await this.getCurrentUser(apiEndpoints.currentUser);
    if (localStorage.role == "R") {
      await this.getUserGameBallSetting(this.currentUser.subjectId);
      if(this.gameBallSetting){
        await this.getGameBallApiKey();
        await this.GbLoadInit();
        await this.gameBallScriptCall();
      }
    }
    this.loadRadProfilePic();
    const idleTimeInMin = await this.getRadIdleTimeInMin();
    this.idleTime = idleTimeInMin.data.radIdleTimeInMinutes * 60 * 1000;
    for (const element of events) {
      window.addEventListener(element, this.updateLastActivity);
    }
    this.updateLastActivity();
    this.idleInterval = setInterval(this.checkIdleStatus, 1000);
  },
  beforeDestroy() {
    for (const element of events) {
      window.removeEventListener(element, this.updateLastActivity);
    }
    clearInterval(this.idleInterval);
  },
};
</script>
<style>
.rologyMsg {
  margin-left: 20px;
  align-self: center;
}

.v-toolbar__content {
  height: fit-content !important;
  padding: 10px 16px !important;
}

.v-sheet {
  height: auto !important;
}

.logoAndSlogan {
  display: flex;
}

.logoAndSloganSMALL {
  display: none;
}

.threeButtonsLINE {
  display: none;
}

.profileAndLogout {
  display: flex;
}

.logoAndProfile {
  display: contents;
}

.profileAndLogoutThreeDotsMenu {
  display: none;
}

@media only screen and (min-device-width: 800px) and (max-device-width: 910px) {
  .profileAndLogout {
    display: flex;
  }
  .threeButtons {
    display: flex;
  }
}

@media only screen and (max-device-width: 800px) {
  .rologyMsg {
    margin: 0;
  }

  .logoAndSlogan {
    display: inline-block;
    height: 100%;
  }
}

@media only screen and (min-device-width: 685px){
  .threeButtons {
    display: flex;
  }
}

@media only screen and (max-device-width: 684px) {
  .threeButtons {
    display: none;
  }

  .threeButtonsLINE {
    display: flex;
  }

  .profileAndLogout {
    display: flex;
  }

  .v-toolbar__content {
    display: block !important;
  }

  .logoAndProfile {
    display: flex;
  }

  .mr-3 {
    width: 32%;
  }
}

@media only screen and (max-device-width: 410px) {
  .threeButtonsLINE {
    display: grid;
  }

  .mr-3 {
    width: auto;
    margin-bottom: 10px;
  }
}

@media only screen and (max-device-width: 350px) {
  .profileAndLogout {
    display: none;
  }

  .profileAndLogoutThreeDotsMenu {
    display: flex;
    justify-content: space-between;
  }
  .showLeft{
    background-color: transparent !important;
    /*border:1px solid #0d77b6 !important;*/
    text-shadow: none !important;
    color:#fff !important;
    padding:10px;
  }

  .icons li {
    background: none repeat scroll 0 0 #fff;
    height: 7px;
    width: 7px;
    line-height: 0;
    list-style: none outside none;
    margin-right: 15px;
    margin-top: 3px;
    vertical-align: top;
    border-radius:50%;
    pointer-events: none;
  }

  .btn-left {
    left: 0.4em;
  }

  .btn-right {
    right: 0.4em;
  }

  .btn-left, .btn-right {
    position: absolute;
    top: 0.24em;
  }

  .dropbtn {
    background-color: #4CAF50;
    position: fixed;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropbtn:hover, .dropbtn:focus {
    background-color: #3e8e41;
  }

  .dropdown {
    position: absolute;
    display: inline-block;
    right: 0.4em;
  }

  .dropdown-content {
    display: none;
    position: relative;
    margin-top: 60px;
    background-color: #f9f9f9;
    /*min-width: 160px;*/
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown a:hover {background-color: #f1f1f1}

  .show {
    display: grid;
    background-color: black;
    border: 1px solid grey;
    box-shadow: 0px 0px 3px 1px darkgrey;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1px;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
}

.rologyMsg-icon {
  animation: heartbeat 3s infinite;
}
</style>
