<template>
  <v-menu offset-y rounded="0" class="text-center mr-1" style="max-width: 100px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="tool-button level-1 py-1 px-2 actions-button"
        height="auto"
        text
        tile
        v-bind="attrs"
        v-on="on"
        :disabled="!canUserViewStudy"
      >
        <v-icon size="20">mdi-flash</v-icon>
        <div class="caption text-capitalize">Actions</div>
      </v-btn>
    </template>
    <v-list min-width="120" class="grey darken-3 rounded-0">
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableInvalidate">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('invalidate')">
          <div class="caption text-capitalize">Invalidate</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableMarkComplete">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('missing-info')">
          <div class="caption text-capitalize">Has Missing Info</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disablePark">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('park')">
          <div class="caption text-capitalize">Park</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="!actions.disableMarkForComparisonBtn && (isAdmin || isMedicalAdmin)">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('mark-for-comparison')">
          <span class="caption text-capitalize">Mark For Comparison</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableAssign">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('assign')">
          <div class="caption text-capitalize">Assign</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableRelease">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('release')">
          <div class="caption text-capitalize">Release</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableReview">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('review')">
          <div class="caption text-capitalize">Start Review</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableRecheck">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('recheck')">
          <div class="caption text-capitalize">Recheck</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="(isMedicalAdmin || isAdmin) && !actions.disableClinicalInfo">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('clinical-info')">
          <div class="caption text-capitalize">Edit Clinical Info</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="isMedicalAdmin || isAdmin">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('change-info')">
          <div class="caption text-capitalize">Change Info</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="isAdmin && !emergency">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('to-emergency')">
          <div class="caption text-capitalize">To Emergency</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="isAdmin && emergency">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('to-normal')">
          <div class="caption text-capitalize">To Normal</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" dense v-if="isAdmin">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('activity-log')">
          <div class="caption text-capitalize">Activity Log</div>
        </v-btn>
      </v-list-item>
      <!-- TODO: actions visibility should be added based on use type ( MedicalAdmin or Radiology ) -->
      <!-- TODO: MedicalAdmin still able to reject rejected study -->
      <v-list-item class="pa-0 rounded-0" dense v-if="isRadiologist && !actions.disableReject">
        <v-btn block text tile small class="justify-start rounded-0" v-on:click="setAction('reject')">
          <div class="caption text-capitalize">Reject</div>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="inReview">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('end-review')">
          <span class="caption text-capitalize">End Review</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="isAdmin && isReadyForReview">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('finish-no-review')">
          <span class="caption text-capitalize">Finish without review</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="isAiResponse && (isAdmin || isMedicalAdmin)">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('ai-response')">
          <span class="caption text-capitalize">AI Response</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="hasRologyId && (isAdmin || isMedicalAdmin)">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('demerge-study')">
          <span class="caption text-capitalize">Demerge</span>
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0 rounded-0" v-if="!actions.disableReadyForAssign && (isAdmin || isMedicalAdmin)">
        <v-btn block text tile small class="justify-start rounded-0" @click="setAction('ready-for-assign')">
          <span class="caption text-capitalize">Ready for assign</span>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UserTypes from "@/util/UserTypes";
import StudyStatus from "@/util/StudyStatus";
import AiResponseTypes from "@/util/AiResponseTypes";

export default {
  name: "ActionsButtons",
  data() {
    return {
      UserTypes,
    };
  },
  methods: {
    setAction(action) {
      this.$emit("setAction", action);
    },
  },
  computed: {
    ...mapGetters(["studyInfo"]),
    ...mapState(["study", "currentUser", "canUserViewStudy"]),
    actions() {
      let actions = {
        disableAssign: true,
        disablePark: true,
        disableReject: true,
        disableMarkComplete: true,
        disableInvalidate: true,
        disableRelease: true,
        disableReview: true,
        disableRecheck: true,
        disableClinicalInfo: true,
        disableReadyForAssign: true,
        disableMarkForComparisonBtn: true,
      };
      switch (this.study.status) {
        case StudyStatus.submitted:
          actions.disableAssign = false;
          actions.disablePark = false;
          actions.disableMarkComplete = false;
          actions.disableInvalidate = false;
          actions.disableClinicalInfo = false;
          actions.disableReadyForAssign = false;
          actions.disableMarkForComparisonBtn = false;
          break;
        case StudyStatus.readyForAssign:
          actions.disableAssign = false;
          actions.disablePark = false;
          actions.disableMarkComplete = false;
          actions.disableInvalidate = false;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.recheck:
          actions.disableAssign = false;
          actions.disablePark = false;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.assigned:
        case StudyStatus.diagnosing:
          actions.disableRelease = false;
          actions.disableReject = this.isMedicalAdmin;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.parked:
          actions.disableRelease = false;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.readyForReview:
          actions.disableReview = false;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.inReview:
          actions.disableRecheck = false;
          actions.disableClinicalInfo = false;
          break;
        case StudyStatus.finished:
        case "accepted":
          break;
      }
      return actions;
    },
    emergency() {
      return this.studyInfo.emergency.toLowerCase() === "yes";
    },
    isMedicalAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.medicalAdmin;
    },
    isAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.admin;
    },
    isRadiologist() {
      return this.currentUser && this.currentUser.type === UserTypes.radiology;
    },
    inReview() {
      return this.study.status === StudyStatus.inReview;
    },
    isFinished() {
      return this.study.status === StudyStatus.finished;
    },
    isSubmitted() {
      return this.study.status === StudyStatus.submitted;
    },
    isReadyForReview() {
      return this.study.status === StudyStatus.readyForReview;
    },
    isAiResponse() {
      return this.study.aiResponse !== AiResponseTypes.notPredicted;
    },
    hasRologyId() {
      const { rologyId } = this.study;
      return rologyId && rologyId !== "N/A";
    }
  },
};
</script>
