import { cloneDeep } from "lodash";
import StudyReportInterface from "@/interface/StudyReportInterface";
import { ClinicalInfo } from "@/util/Interfaces";
export default {
  study: null,
  snackbarMessages: [],
  apiCall: false,
  dialog: {},
  radiologists: [],
  studyLogs: [],
  isReportDialogOpen: false,
  isShorthandsDialogOpen: false,
  studyReport: cloneDeep(StudyReportInterface),
  shorthandList: [],
  shorthandQuillContents: "",
  templatesList: [],
  reportTemplate: {},
  requestTypeList: [],
  currentUser: null,
  studyReportsList: [],
  newReportId: null,
  isStudyReportFinished: false,
  canUserViewStudy: false,
  patientHistoryList: [],
  clinicalInfo: cloneDeep(ClinicalInfo),
  showAiResponseForm: false,
  isStudyVisible: false,
  systemTags: [],
  radTags: [],
  modalityList: [],
  studyReportForCheck: {},
  headersBooleanStored:[],
  showAboutUsForm: false,
  showProbabilityOfReviewResponseForm: false,
  probabilityOfReviewResponseList:[],
  showRadReportsListDialog: false,
  radReportsList: [],
  showRadResponseForAIFeedbackForm: false,
};
