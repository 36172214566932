<template>
  <div>
    <Header />
    <div v-if="displayViewerHeader">
      <ToolsBar />
      <SideBars v-if="study && currentUser !== null && canUserViewStudy" />
    </div>
  </div>
</template>

<script>
import SideBars from "@/components/SideBars/SideBars";
import { mapState } from "vuex";
import ClientRoutes from "@/router/ClientRoutes";
import ToolsBar from "@/components/ViewerBars/ToolsBar";
import Header from "@/components/Header/Header";

export default {
  name: "AppHeader",
  components: { Header, ToolsBar, SideBars },
  computed: {
    ...mapState(["study", "currentUser", "canUserViewStudy"]),
    displayViewerHeader() {
      return this.$route.name ? this.$route.name === ClientRoutes.studyViewer : false;
    },
  },
};
</script>

