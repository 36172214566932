<template>
  <section class="study-not-found d-flex align-center justify-center flex-column" v-if="showMessage">
    <h1>Not Found</h1>
    <p>
      The study you're trying to access is not found.
      <!--      <a href="javascript:void(0)" @click="toggleStudiesList">Open new study</a>-->
    </p>
  </section>
  <!--now we display loading animation for 3 secs and then show the message
      the idea that if this component is still rendered after 3 secs then this mean that user don't have access to the study -->
  <v-progress-linear indeterminate v-else />
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "StudyNotFound",
  data() {
    return {
      showMessage: false
    }
  },
  methods: {
    ...mapActions(["toggleStudiesList"]),
  },
  mounted() {
    window.setTimeout(() => {this.showMessage = true}, 3000)
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"),
    local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

.study-not-found {
  width: 100%;
  h1 {
    font-size: 150px;
    font-family: "system", Arial, sans-serif;
  }
}
</style>
