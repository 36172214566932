import { STUDY_DRAWER, HISTORY_DRAWER, REPORT_DRAWER, MALICIOUS_CUTS_DRAWER } from "@/shared/Constants.js";
import { localStorageConstant } from "@/util/localStorageUtils";

const width = 300;
const reportWidth = Math.round(window.outerWidth * 0.45);
const { APP_LAYOUT } = localStorageConstant;
const savedState = JSON.parse(window.localStorage.getItem(APP_LAYOUT));

const defaultLayout = {
  drawers: [
    {
      width,
      visible: true,
      isPinned: true,
      right: false,
      name: STUDY_DRAWER,
      title: "Study",
      icon: "mdi-image-multiple-outline",
      iconSize: 15,
    },
    {
      width,
      visible: false,
      isPinned: false,
      right: false,
      name: MALICIOUS_CUTS_DRAWER,
      title: "Malicious Cuts",
      icon: "mdi-virus",
      iconSize: 15,
      color: "error",
    },
    {
      width,
      visible: true,
      isPinned: true,
      right: true,
      name: HISTORY_DRAWER,
      title: "Old Studies",
      icon: "mdi-history",
      iconSize: 18,
    },
    {
      width: reportWidth,
      visible: false,
      isPinned: false,
      right: true,
      name: REPORT_DRAWER,
      title: "Report",
      icon: "mdi-file-document-edit-outline",
      iconSize: 18,
      external: false,
    },
  ],
  dockBarWidth: 40,
  isViewerLoaded: false,
  viewerBarHeight: 112, // the calculated height of top bars above viewer viewport
};
// restore default settings when adding new drawer component
if (savedState && savedState.drawers.length !== defaultLayout.drawers.length) {
  savedState.drawers = [...defaultLayout.drawers];
}

if (savedState && savedState.viewerBarHeight !== defaultLayout.viewerBarHeight) {
  savedState.viewerBarHeight = defaultLayout.viewerBarHeight;
}
const state = savedState ? { ...savedState, isViewerLoaded: false } : defaultLayout;
export default state;
