<template>
  <v-navigation-drawer
    id="ReportDrawer"
    class="resizable-drawer"
    :app="drawer.isPinned"
    hide-overlay
    :style="style"
    absolute
    :value="drawer.visible && !drawer.external"
    :width="width"
    :right="drawer.right"
    ref="currentSidebar"
  >
    <v-card tile flat height="100%" @mouseleave="mouseLeave" class="scrollable-card">
      <span class="sidebar-handler" :class="{ right: drawer.right }" @mousedown="mouseDownHandler($event)" />
      <v-card-title>
        <span class="primary--text subtitle-1 font-weight-bold">Report</span>
        <v-spacer />
        <v-btn icon @click="popOut">
          <v-icon v-if="!drawer.external">mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn icon class="d-none d-md-flex">
          <v-icon @click="pin">{{ drawer.isPinned ? "mdi-pin" : "mdi-pin-off" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <ManageStudyReport
          :show-dialog-actions="false"
          hide-report-label
          :parentWidth="width"
          v-if="drawer.visible && !drawer.external"
          :pin="pin"
          :isPinned="drawer.isPinned"
        />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { REPORT_DRAWER } from "@/shared/Constants";
import SidebarMixins from "@/components/SideBars/SidebarMixins";
import ManageStudyReport from "@/components/StudyReport/ManageStudyReport";
export default {
  name: REPORT_DRAWER,
  components: { ManageStudyReport },
  mixins: [SidebarMixins],
  data() {
    return {
      componentName: REPORT_DRAWER,
    };
  },
};
</script>
