<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-form>
      <v-card>
        <v-card-title>Submit your review response</v-card-title>
        <v-divider />
        <v-card-text>
          <v-autocomplete label="Review reasons" item-text="name" item-value="value" :items="probabilityOfReviewResponseList" outlined
                          item-color="primary" validate-on-blur required :return-object="false" v-model="formData.reviewReasons"
                          cache-items :error="!isFormValid" hide-selected flat multiple small-chips solo>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-if="item == Object(item)" v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2">
                          {{ item.name }}
                        </span>
                <v-icon small @click="parent.selectItem(item)">
                  $delete
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
          <v-textarea
              v-model="formData.extraReasons"
              dense
              auto-grow
              clearable
              outlined
              rows="4"
              label="Please type your extra reasons"
              id="extraReasons"
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue-grey lighten-4" outlined text @click="save" :disabled="!isFormValid">Needed to be reviewed</v-btn>
          <v-btn color="success" @click="cancel">did not need to be reviewed</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { ProbabilityOfReviewResponse } from "@/util/Interfaces";

import apiEndpoints from "@/util/apiEndpoints";



export default {
  name: "ProbabilityOfReviewResponseForm",
  computed: {
    ...mapState(["study","probabilityOfReviewResponseList"]),
    isFormValid() {
      return this.formData.reviewReasons.length+this.formData.extraReasons.length;
    },
  },
  data() {
    return {
      dialog: true,
      formData: cloneDeep(ProbabilityOfReviewResponse)

    };
  },
  methods: {
    ...mapActions(["saveHumanResponseForProbabilityOfReview","getProbabilityOfReviewResponseList"]),
    async save() {
      const url = apiEndpoints.saveHumanResponseForProbabilityOfReview;
      this.formData.studyId = this.study.studyId;
      this.formData.neededReview = "Yes";
      await this.saveHumanResponseForProbabilityOfReview({ url, data: this.formData });
      window.close();
    },
    async cancel() {
      const url = apiEndpoints.saveHumanResponseForProbabilityOfReview;
      this.formData.studyId = this.study.studyId;
      this.formData.neededReview = "No";
      this.formData.reviewReasons = "";
      await this.saveHumanResponseForProbabilityOfReview({ url, data: this.formData });
      window.close();
    },
    loadProbabilityOfReviewList() {
      let getProbabilityOfReviewResponseListUrl = `${apiEndpoints.getProbabilityOfReviewResponseList}`;
      this.getProbabilityOfReviewResponseList(getProbabilityOfReviewResponseListUrl);
    },
  },
  mounted() {
    this.loadProbabilityOfReviewList();
  }
};
</script>
