<template>
  <v-card ref="studyReport" id="studyReportCard">
    <v-card-title class="pt-0 pb-0">
      <v-container fluid class="pt-0 pb-0">
        <v-row align="center">
          <v-row>
            <v-col>
              <label style="text-transform: capitalize">
                <span v-if="!hideReportLabel">Report: </span><span>{{ study.patientName.toLowerCase() }}</span>
                <v-divider
                  v-if="!hideReportLabel && isOldStudy === 'true'"
                  class="mx-4"
                  vertical
                  style="vertical-align: top"
                ></v-divider>
                <span v-if="!hideReportLabel && isOldStudy === 'true'" style="color: red"
                  >Date: {{ study.studyDate }} (Old Study)</span
                >
              </label>
            </v-col>
            <v-col v-if="predictedQure">
              <v-btn @click="CheckAISupport" color="primary">Check AI Support</v-btn>
            </v-col>
            <v-col cols="4" class="pb-0" v-if="isRadiologist && !isHistoricalStudy">
              <v-combobox
                label="apply Template"
                dense
                item-text="templateName"
                item-value="templateName"
                :items="templatesList"
                outlined
                no-data-text="loading...."
                :return-object="false"
                v-model="selectedTemplate"
                @change="loadTemplate"
              />
            </v-col>
          </v-row>
          <v-col v-if="showDialogActions">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn icon @click="fullscreen" v-if="!inFullscreen">
                <v-icon size="22">mdi-fullscreen</v-icon>
              </v-btn>
              <v-btn icon @click="exitFullscreen" v-if="inFullscreen">
                <v-icon size="22">mdi-fullscreen-exit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon size="20" @click="popInReport">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <CustomStepper
          v-if="studyReportsList.length > 1"
          :studyReportsList="studyReportsList"
          :reportPage="reportPage"
          @changeReport="changeReport"
        />
      </v-container>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <br />
      <v-form
        ref="form"
        id="studyReportForm"
        :disabled="isHistoricalStudy || !studyReportsList.length || disableForm"
        autocomplete="on"
      >
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Request Type</v-subheader>
            </v-col>
            <v-col cols="4" :lg="inLineFieldColumns" sm="12" md="10">
              <div id="requestTypeBox" class="relative-container">
                <v-autocomplete
                  item-text="name"
                  item-value="value"
                  :items="FilterdRequestList"
                  name="requestType"
                  v-model="formReport.requestType"
                  outlined
                  item-color="primary"
                  validate-on-blur
                  required
                  :return-object="false"
                  :rules="[textRequestTypesRequiredHandler]"
                  @change="loadExamination"
                  attach="#requestTypeBox"
                  chips
                  deletable-chips
                  multiple
                >
                </v-autocomplete>
              </div>
            </v-col>
          </v-row>
          <v-row dense tag="label" for="referralDoctor">
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Referred by</v-subheader>
            </v-col>
            <v-col cols="4" :lg="inLineFieldColumns" sm="12" md="10">
              <v-text-field
                name="referralDoctor"
                outlined
                dense
                v-model="formReport.header.referralDoctor"
                placeholder="Dear Colleague"
                autocomplete="referralDoctor"
                @keyup="saveToLocalStorage"
              />
            </v-col>
          </v-row>
          <v-row dense tag="label" for="examination">
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Examination</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <v-text-field
                outlined
                dense
                v-model="formReport.header.examination"
                name="examination"
                validate-on-blur
                required
                :rules="[textRequiredHandler]"
                @keyup="saveToLocalStorage"
                autocomplete="on"
                id="examination"
                placeholder="Examination"
              />
            </v-col>
          </v-row>
          <v-row dense tag="label" for="technique">
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Technique</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <v-text-field
                outlined
                dense
                v-model="formReport.header.technique"
                validate-on-blur
                required
                :rules="[textRequiredHandler]"
                @keyup="saveToLocalStorage"
                name="technique"
                id="technique"
                autocomplete="on"
                placeholder="Technique"
              />
            </v-col>
          </v-row>
          <v-row dense tag="label" for="comparison">
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Comparison</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <v-textarea
                outlined
                dense
                v-model="formReport.header.comparison"
                validate-on-blur
                rows="1"
                auto-grow
                @keyup="saveToLocalStorage"
                name="comparison"
                id="comparison"
                autocomplete="on"
                placeholder="Comparison"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Findings</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <Quill
                type="findings"
                :contents="localFindingsData"
                :key="renderCounter"
                :disableEditor="isHistoricalStudy || disableEditor"
                @onEditorChange="onFindingChange"
                :rules="[textRequiredHandler]"
                :isEditorInvalid="isFindingsEditorInvalid"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Impression</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <Quill
                type="impression"
                :contents="localImpressionData"
                :key="renderCounter"
                :disableEditor="isHistoricalStudy || disableEditor"
                @onEditorChange="onImpressionChange"
                :rules="[textRequiredHandler]"
                :isEditorInvalid="isImpressionEditorInvalid"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="isRadiologist">
            <v-col cols="12" :lg="labelColumns" sm="12" md="2">
              <v-subheader class="px-1">Tags</v-subheader>
            </v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="md">
              <StudyRadTags v-if="study" :study="study" />
            </v-col>
          </v-row>
          <v-row dense v-if="reportId">
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10" :offset-md="offsetValue">
              <FileUploader
                :uploadFileUrl="uploadFileUrl"
                :uploadFields="uploadFields"
                :removeFileUrl="removeFileUrl"
                :isHistoricalStudy="isHistoricalStudy"
                :loadFilesUrl="loadFilesUrl"
                :key="reportId"
                @response="responseHandler"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :lg="labelColumns" sm="12" md="2"></v-col>
            <v-col cols="12" :lg="fieldColumns" sm="12" md="10">
              <v-checkbox
                v-model="formReport.critical"
                color="red"
                label="This report is critical"
                persistent-hint
                @change="saveToLocalStorage"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions v-if="!isHistoricalStudy">
      <v-spacer />
      <v-btn @click="preview" :disabled="!liveValidation || disableForm">Preview</v-btn>
      <v-btn color="error" @click="deleteCurrentReport" :disabled="studyReportsList.length <= 1 || disableForm">
        Delete
      </v-btn>
      <v-btn @click="resetReport" :disabled="!studyReportsList.length || disableForm">Reset</v-btn>
      <v-btn color="primary" @click="addReport" :disabled="!liveValidation || disableForm">Add Report</v-btn>
      <v-btn
        color="success"
        @click="attachmentConfirmation"
        v-if="isRadiologist"
        :disabled="!liveValidation || disableForm"
      >
        Finish
      </v-btn>
      <v-btn
        color="success"
        @click="attachmentConfirmation"
        v-if="isAdmin || isMedicalAdmin"
        :disabled="!liveValidation || disableForm"
      >
        End Review
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="confirmDialog.open" max-width="400" attach="#studyReportCard" hide-overlay>
      <v-card>
        <v-card-title class="px-5">{{ confirmDialog.title }}</v-card-title>
        <v-card-text class="px-5">{{ confirmDialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="confirmDialogClose">Cancel</v-btn>
          <v-btn color="blue-grey darken-4" @click="confirmDialogSubmit" :disabled="deleted">
            Yes
            <v-progress-circular color="primary" indeterminate v-if="deleted"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      title="Finish Report"
      @confirm="finishReport"
      v-if="showFinishConfirmDialog"
      hide-overlay
      @cancel="cancelFinishReportConfirmation"
      attach="#studyReportCard"
    >
      Are you sure you want to finish report ?
    </ConfirmDialog>
    <ConfirmDialog
      title="Attachment Review Confirmation"
      @confirm="handleAttachmentConfirmation"
      attach="#studyReportCard"
      v-if="showAttachmentConfirmDialog"
      @cancel="cancelAttachmentConfirmation"
      hide-overlay
    >
      Confirm that you have reviewed the attachments ?
    </ConfirmDialog>
    <v-dialog v-model="dialog" persistent max-width="400" :hide-overlay="false" v-if="modalityHasChanged">
      <v-card>
        <v-card-title class="px-5"> Data Changed </v-card-title>
        <v-card-text class="px-5">
          Modality and request types have changed, please reload the page to apply new changes.
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-grey darken-4" @click="reloadPage" style="margin: auto">Reload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showQureSelectReportDialog" max-width="1100">
      <v-card>
        <v-card-title class="px-5" style="padding-bottom: 0;">
          <h2 data-v-9b7fad34="" style="width: 95%;"></h2>
          <v-btn icon dark @click="() => (showQureSelectReportDialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="px-5" style="padding-top: 0;">
          <div>Dear dr. {{ currentUser.name }}, please check out initial findings.</div>
        </v-card-title>
        <v-card-text class="px-5">
          <v-tabs>
            <v-tab v-for="(tab, index) in qureFindings" :key="index">Series {{ index + 1 }} </v-tab>

            <v-tab-item v-for="(tab, index) in qureFindings" :key="index">
              <v-textarea filled readonly auto-grow v-model="tab.response.report" rows="5"></v-textarea>
              <v-btn color="primary" @click="fillFindingsWithQureSelectionInDialog(tab.response.report)"
                >Fill in the Findings</v-btn
              >
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog attach="#studyReportCard" v-model="tagsDialog" max-width="600" hide-overlay>
      <TagsComponent :hide-tags-dialog="hideTagsDialog" :save-report="saveReport" :end-review-action="endReviewAction" />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Quill from "@/components/Quill";
import { goFullscreen, exitFullscreenMode } from "@/util/common-Quill";
import apiEndpoints from "@/util/apiEndpoints";
import dialogMixins from "@/shared/dialog-mixins";
import HtmlToMarkdown from "@/util/HtmlToMarkdown";
import StudyFormReportInterface from "@/interface/StudyFormReportInterface";
import FileUploader from "@/components/StudyReport/FileUploader";
import UserTypes from "@/util/UserTypes";
import CustomStepper from "@/components/StudyReport/CustomStepper";
import AiResponseTypes from "@/util/AiResponseTypes";
import { localStorageConstant } from "@/util/localStorageUtils";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";
import ClientRoutes from "@/router/ClientRoutes";
import StudyStatus from "@/util/StudyStatus";
import _ from "lodash";
import StudyRadTags from "@/components/StudyActions/StudyRadTags.vue";
import TagsComponent from "@/components/StudyReport/TagsComponent.vue";

const REPORT_COMPARISON_DEFAULT_VALUE = "No available studies for comparison.";

const evenAbortController = new AbortController();

export default {
  name: "ManageStudyReport",
  components: { TagsComponent, Quill, FileUploader, CustomStepper, ConfirmDialog, StudyRadTags },
  mixins: [dialogMixins],
  data() {
    return {
      UserTypes,
      dialog: true,
      reportPage: null,
      confirmDialog: {
        open: false,
        title: "",
        text: "",
        actionFunction: "",
      },
      selectedTemplate: null,
      requestType: null,
      formReport: _.cloneDeep(StudyFormReportInterface),
      isCriticalOptions: ["Yes", "No"],
      isFindingsEditorInvalid: false,
      isImpressionEditorInvalid: false,
      renderCounter: 0,
      localFindingsData: "",
      localImpressionData: "",
      deletedReportId: false,
      newReportAdded: false,
      isFormValid: null,
      disableForm: false,
      reportId: null,
      disableEditor: false,
      AiResponseTypes,
      showFinishConfirmDialog: false,
      showAttachmentConfirmDialog: false,
      inFullscreen: false,
      deleted: false,
      ClientRoutes,
      requestTypesVaild: false,
      modalityHasChanged: false,
      isOldStudy: false,
      openDialog: false,
      ShowRadReportsListDialog: false,
      predictedQure: false,
      qureFindings: [],
      showQureSelectReportDialog: false,
      tagsDialog: false,
    };
  },
  props: {
    closeDialog: {
      type: Function,
      default: () => {},
    },
    showDialogActions: {
      type: Boolean,
      default: true,
    },
    hideReportLabel: {
      type: Boolean,
      default: false,
    },
    parentWidth: {
      type: Number,
      default: 0,
    },
    pin: Function,
    isPinned: Boolean,
  },
  computed: {
    ...mapState([
      "study",
      "studyReportsList",
      "studyReport",
      "studyReportForCheck",
      "apiCall",
      "reportTemplate",
      "requestTypeList",
      "newReportId",
      "currentUser",
      "isStudyReportFinished",
      "patientHistoryList",
      "showProbabilityOfReviewResponseForm",
      "showRadReportsListDialog",
    ]),
    ...mapState("Templates", ["templatesList"]),
    ...mapGetters(["studyInfo", "studyId"]),
    isAdmin() {
      return this.currentUser && this.currentUser.type === UserTypes.admin;
    },
    isMedicalAdmin() {
      return this.currentUser.type === UserTypes.medicalAdmin;
    },
    isRadiologist() {
      return this.currentUser.type === UserTypes.radiology;
    },
    liveValidation() {
      const { requestType } = this.formReport;
      const { examination, technique } = this.formReport.header;
      const impression = HtmlToMarkdown(this.formReport.content.impression);
      const findings = HtmlToMarkdown(this.formReport.content.findings);

      if (!requestType || requestType.length < 1) {
        return false;
      } else if (
        !examination ||
        (examination.trim() && examination.trim().length && examination.replace(/[^a-zA-Z]/g, "").trim().length) < 1
      ) {
        return false;
      } else if (
        !technique ||
        (technique.trim() && technique.trim().length && technique.replace(/[^a-zA-Z]/g, "").trim().length) < 1
      ) {
        return false;
      } else if (
        !findings ||
        (findings.trim() && findings.trim().length && findings.replace(/[^a-zA-Z]/g, "").trim().length) < 1
      ) {
        return false;
      } else if (
        !impression ||
        (impression.trim() && impression.trim().length && impression.replace(/[^a-zA-Z]/g, "").trim().length) < 1
      ) {
        return false;
      }
      return true;
    },
    uploadFileUrl() {
      return apiEndpoints.uploadReportImages + this.studyId;
    },
    removeFileUrl() {
      let qParameters = `studyId=${this.studyId}&reportId=${this.reportId}`;
      return `${apiEndpoints.removeReportImage}uuidPlaceholder?qqfilename=fileNamePlaceholder&${qParameters}`;
    },
    uploadFields() {
      return { reportId: this.reportId };
    },
    loadFilesUrl() {
      return apiEndpoints.getReportImage + this.reportId;
    },
    labelColumns() {
      return this.parentWidth && this.parentWidth < 580 ? 12 : 2;
    },
    fieldColumns() {
      return this.parentWidth && this.parentWidth < 580 ? 12 : 10;
    },
    inLineFieldColumns() {
      return this.parentWidth && this.parentWidth < 580 ? 12 : 4;
    },
    offsetValue() {
      return this.parentWidth && this.parentWidth < 580 ? 0 : 2;
    },
    isHistoricalStudy() {
      if (this.study.status === StudyStatus.finished || this.study.status === StudyStatus.accepted) {
        return true;
      }
      return false;
    },
    oldStudies() {
      return this.patientHistoryList.filter(({ studyId }) => studyId !== this.study.studyId);
    },
    FilterdRequestList() {
      let list = this.requestTypeList.filter(
        (item) => this.studyInfo.requestType.split(",").includes(item.value) && item.value != "New Report"
      );

      let requested = [];
      for (let index = 0; index < this.studyReportsList.length; index++) {
        if (this.reportPage != null && this.reportPage - 1 != index) {
          let splitedArr = this.studyReportsList[index].name.split(",");
          requested = requested.concat(splitedArr).sort();
        }
      }

      requested = this.arrayUnique(requested);
      requested.forEach((item) => {
        const index = list
          .map(function (e) {
            return e.value;
          })
          .indexOf(item);
        if (index > -1) {
          list.splice(index, 1);
        }
      });
      return list;
    },
  },
  methods: {
    ...mapActions([
      "getStudyReportsList",
      "getStudyReport",
      "getStudyReportForCheck",
      "toggleReport",
      "deleteStudyReport",
      "saveStudyReport",
      "removeReportById",
      "finishStudy",
      "updateStudy",
      "toggleStudyVisibility",
      "getRequestTypesList",
      "showAiResponseForm",
      "showProbabilityOfReviewResponseDialog",
      "setMessage",
      "endStudy",
      "getStudyById",
      "showRadReportsListAction",
      "getAIResults",
      "showRadResponseForAIFeedbackForm",
      "getQurePredictionStatus",
      "getQurePredictionObject",
    ]),
    ...mapActions("RadStudies", ["clearStudies"]),
    ...mapActions("Templates", ["applyTemplate"]),
    async showTagsDialog() {
      if (this.study.tags && (this.study.tags.includes("MA Recheck") || this.study.tags.includes("Client Recheck"))) {
        this.tagsDialog = true;
        if(!this.isPinned) {
          this.pin();
        }
      } else {
        await this.saveReport(false);
        await this.endReviewAction();
      }
    },
    hideTagsDialog() {
      this.tagsDialog = false;
    },
    cancel() {
      this.$emit("closeDialog");
    },
    showFinishReportConfirmation() {
      this.showFinishConfirmDialog = true;
    },
    showReportsListConfirmation() {
      this.ShowRadReportsListDialog = true;
    },
    cancelFinishReportConfirmation() {
      this.showFinishConfirmDialog = false;
    },
    attachmentConfirmation() {
      const { id } = this.$route.params;
      if (id === this.study.studyId) {
        const { STUDY_ATTACHMENT } = localStorageConstant;
        const attachment = JSON.parse(localStorage.getItem(`${STUDY_ATTACHMENT}_${id}`));
        if (attachment !== null) {
          if (attachment.isDownloaded) {
            this.showAttachmentConfirmation();
          } else {
            this.setMessage({
              message: "Please download the attachment first",
              type: "error",
              timeout: 10000,
            });
          }
        } else {
          this.handleAttachmentConfirmation();
        }
      }
    },
    handleAttachmentConfirmation() {
      this.cancelAttachmentConfirmation();
      if (this.currentUser.type === UserTypes.radiology) {
        this.showFinishReportConfirmation();
      }
      if (
        (this.currentUser && this.currentUser.type === UserTypes.admin) ||
        this.currentUser.type === UserTypes.medicalAdmin
      ) {
        this.endReview();
      }
    },
    showAttachmentConfirmation() {
      this.showAttachmentConfirmDialog = true;
    },
    cancelAttachmentConfirmation() {
      this.showAttachmentConfirmDialog = false;
    },
    fullscreen() {
      this.inFullscreen = true;
      goFullscreen(this.$refs.studyReport.$el);
    },
    exitFullscreen() {
      exitFullscreenMode();
      this.inFullscreen = false;
    },
    confirmDialogClose() {
      this.confirmDialog.open = false;
      this.selectedTemplate = null;
    },
    confirmDialogSubmit() {
      this[this.confirmDialog.actionFunction]();
      this.selectedTemplate = null;
      if (this.confirmDialog.actionFunction === "deleteCurrentReport") this.deleted = true;
    },
    changeReport(index) {
      if (this.reportPage === index) {
        return;
      }
      // change report page without validation
      this.reportPage = index;
    },
    async loadTemplate() {
      if (!this.confirmDialog.open) {
        this.requestType = this.formReport.requestType;
        this.confirmDialog.title = "Load Template";
        this.confirmDialog.text = `Are you sure you want to load template ${this.selectedTemplate}`;
        this.confirmDialog.actionFunction = "loadTemplate";
        this.confirmDialog.open = true;
      } else {
        let data = { templateName: this.selectedTemplate };
        await this.applyTemplate(data);
        this.confirmDialog.open = false;
        this.selectedTemplate = null;
      }
      this.formReport.requestType = this.requestType;
      await this.loadExamination();
    },
    async loadExamination() {
      try {
        let contrast = "";
        let contrastModalites = ["CT", "MR", "PR", "PT", "MG"];
        if (this.study.studyInfo.contrast != "" && contrastModalites.includes(this.study.studyInfo.modality)) {
          if (this.study.studyInfo.contrast == "Yes") {
            contrast = "with contrast";
          } else if (this.study.studyInfo.contrast == "No") {
            contrast = "without contrast";
          }
        }

        this.formReport.header.examination =
          this.study.studyInfo.modality +
          " " +
          this.formReport.requestType.join().replaceAll("_", " ").replaceAll(",", " , ") +
          " " +
          contrast;
        this.saveToLocalStorage();
      } catch (e) {
        console.log(e);
      }
    },
    onFindingChange(value) {
      this.formReport.content.findings = value.trim();
      this.saveToLocalStorage();
    },
    onImpressionChange(value) {
      this.formReport.content.impression = value.trim();
      this.saveToLocalStorage();
    },
    textRequiredHandler(item) {
      if (item && item.toLowerCase() === "new report") {
        return "Field is required";
      }
      // validate non empty string & non fake string with special chars
      return (
        (item.trim() && item.trim().length && item.replace(/[^a-zA-Z]/g, "").trim().length) > 0 || "Field is required"
      );
    },
    textRequestTypesRequiredHandler(item) {
      if (item == undefined) {
        return "Field is required";
      }
      return item.length > 0 || "Field is required";
    },
    async preview() {
      this.validateForm();
      if (!this.isFormValid) {
        return;
      }
      await this.saveReport(false);
      let reportId = this.studyReportsList[this.reportPage - 1].id;
      let url = `${window.location.origin}/report/previewReport/${this.study.id}/report/${reportId}`;
      window.open(url, "_blank");
    },
    async addReport() {
      this.validateForm();
      if (!this.isFormValid) {
        return;
      }
      await this.saveReport(true);
    },
    async saveReport(addReport = false) {
      //TODO: the structure of a report to be add MUST match the returned structure when getting report info
      const { localStorage } = window;
      const reportId = this.studyReportsList[this.reportPage - 1].id;
      let report = JSON.parse(localStorage.getItem(`STUDY_REPORT_${reportId}`));
      let form = report ? _.cloneDeep(report) : _.cloneDeep(this.formReport);
      form.requestType = form.requestType.join(",");
      let findingsMarkup = form.content.findings;
      let impressionMarkup = form.content.impression;

      form.addReport = addReport;
      form.content.findings = HtmlToMarkdown(findingsMarkup);
      form.content.impression = HtmlToMarkdown(impressionMarkup);

      let data = { url: apiEndpoints.saveReport, data: form };
      this.newReportAdded = addReport;
      const isSaved = await this.saveStudyReport(data);
      if (isSaved) {
        form.isModified = false;
        form.addReport = false;
        form.content.findings = findingsMarkup;
        form.content.impression = impressionMarkup;
        localStorage.setItem(`STUDY_REPORT_${reportId}`, JSON.stringify(form));

        await this.reloadReportsList();
        if (isSaved.reportIdChanged) {
          localStorage.removeItem(`STUDY_REPORT_${reportId}`);
        }
        this.saveToLocalStorage(form.isModified);
      }
    },
    checkAndRemoveDeletedRequestType() {
      let reported = this.studyInfo.requestType.split(",");
      this.studyReportsList.forEach((studyReport) => {
        const item = _.cloneDeep(studyReport);
        let splitedArr = item.name.split(",");
        splitedArr.forEach((request) => {
          if (!reported.includes(request)) {
            item.name = item.name.replace("," + request, "");
            item.name = item.name.replace(request + ",", "");
            const displayName = request.replace("_", " ");
            item.displayName = item.displayName.replace("," + displayName, "");
            item.displayName = item.displayName.replace(displayName + ",", "");
            const report = JSON.parse(localStorage.getItem(`STUDY_REPORT_${item.id}`));
            if (report) {
              var index = report.requestType.indexOf(request);
              if (index !== -1) {
                if (_.isString(report.requestType)) {
                  report.requestType = "";
                } else {
                  report.requestType.splice(index, 1);
                }
              }
              report.header.examination = report.header.examination.replace(", " + displayName, "");
              report.header.examination = report.header.examination.replace(displayName + " ,", "");
              report.isModified = true;
              localStorage.setItem(`STUDY_REPORT_${item.id}`, JSON.stringify(report));
            }
          }
        });
      });
    },
    async loadAndCheckMissingReports(reportId) {
      let data = { reportId };
      await this.getStudyReportForCheck({ url: apiEndpoints.getStudyReport, data });
      if (this.studyReportForCheck == null) return false;
      if (this.studyReportForCheck.findings == null || this.studyReportForCheck.findings == "") return false;
      if (this.studyReportForCheck.impression == null || this.studyReportForCheck.impression == "") return false;

      return true;
    },
    validateReportData(report) {
      if (report.content.findings == null || report.content.findings == "") return false;
      if (report.content.impression == null || report.content.impression == "") return false;
      if (report.header.technique == null || report.header.technique == "") return false;
      if (report.header.examination == null || report.header.examination == "") return false;

      return true;
    },
    async finishReport() {
      let modalityIsValid = await this.validateModality();
      if (modalityIsValid) {
        this.cancelFinishReportConfirmation();
        this.validateForm();
        if (!this.isFormValid) {
          return;
        }
        await this.saveReport();
        this.validateRequestTypes();
        if (!this.requestTypesVaild) {
          this.setMessage({ message: `Please select all request types`, type: "error" });
          return;
        }

        for (let index = 0; index < this.studyReportsList.length; index++) {
          const id = this.studyReportsList[index].id;
          let report = JSON.parse(localStorage.getItem(`STUDY_REPORT_${id}`));
          let critical = false;
          let valid = true;
          if (report == null) {
            valid = await this.loadAndCheckMissingReports(id);
            critical = this.studyReportForCheck.critical;
          }
          else {
            valid = this.validateReportData(report);
            critical = report.critical;
          }
          if (critical == true) {
            this.study.critical = true;
          }
          if (!valid) {
            this.setMessage({ message: `Please fill all reports data`, type: "error" });
            return;
          }
        }
        this.disableForm = true;
        await this.saveReport(false);
        let data = { url: apiEndpoints.finishStudy, data: this.study };
        const isFinished = await this.finishStudy(data);
        if (isFinished) {
          this.clearStudies();
          this.clearLocalStorage();
          this.showAiResponse();
          // this.$router.push({ name: ClientRoutes.radiologistStudies }).catch(() => {});
        } else {
          this.disableForm = false;
        }
      }
    },
    async deleteCurrentReport() {
      if (!this.confirmDialog.open) {
        this.confirmDialog.title = "Delete Report";
        this.confirmDialog.text = `Are you sure that you want to delete this report ${this.studyReport.requestType}`;
        this.confirmDialog.actionFunction = "deleteCurrentReport";
        this.confirmDialog.open = true;
      } else {
        let reportId = this.studyReportsList[this.reportPage - 1].id;
        this.deletedReportId = reportId;
        const data = { reportId, studyId: this.study.id };
        const isDeleted = await this.deleteStudyReport({ url: apiEndpoints.deleteStudyReport, data });
        if (isDeleted) {
          await this.reloadReportsList();
          this.confirmDialog.open = false;
          this.reportPage = this.reportPage > 1 ? this.reportPage - 1 : 1;
          if (this.reportPage === 1) {
            await this.loadStudyReport(this.reportPage);
          }
          localStorage.removeItem(`STUDY_REPORT_${this.deletedReportId}`);
        }
        this.deleted = false;
      }
    },
    async loadStudyReport(index) {
      const newIndex = index > this.studyReportsList.length ? 1 : index;
      this.resetValidation();
      let reportId = this.studyReportsList[newIndex - 1].id;
      const previousReport = JSON.parse(localStorage.getItem(`STUDY_REPORT_${reportId}`));
      if (previousReport && previousReport.isModified) {
        this.reportId = previousReport.reportId;
        this.formReport = _.cloneDeep(previousReport);
        this.localFindingsData = previousReport.content.findings;
        this.localImpressionData = previousReport.content.impression;
        this.renderCounter = this.renderCounter + 1;
      } else {
        let data = { reportId };
        await this.getStudyReport({ url: apiEndpoints.getStudyReport, data });
      }
    },
    async checkAI() {
      try {
        const response = await this.getQurePredictionStatus(this.studyId);
        if (
          this.response !== null &&
          response.status === "PREDICTED" &&
          (!this.isRadiologist || (this.isRadiologist && this.currentUser.allowAiFeedback))
        ) {
          this.predictedQure = true;
          this.qureFindings = await this.getQurePredictionObject(this.studyId);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async resetReport() {
      const reportId = this.studyReportsList[this.reportPage - 1].id;
      let data = { reportId };
      await this.getStudyReport({ url: apiEndpoints.getStudyReport, data });
      this.resetValidation();
    },
    async reloadReportsList() {
      let studyReportsUrl = this.isHistoricalStudy
        ? apiEndpoints.getHistoricalReports + this.study.id
        : this.currentUser.type === "R"
        ? apiEndpoints.getReports + this.study.id
        : apiEndpoints.getReportsForMa + this.study.id;
      await this.getStudyReportsList(studyReportsUrl);
    },
    arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) {
            a.splice(j--, 1);
          }
        }
      }
      return a;
    },
    validateRequestTypes() {
      let requested = [];
      this.studyReportsList.forEach((item) => {
        let splitedArr = item.name.split(",");
        requested = requested.concat(splitedArr).sort();
      });
      requested = this.arrayUnique(requested);
      let reported = this.studyInfo.requestType.split(",").sort();
      this.requestTypesVaild = _.isEqual(requested, reported);
    },
    async validateModality() {
      let oldModality = this.study.studyInfo.modality;
      let oldRequestTypes = this.study.studyInfo.requestType;
      await this.getStudyById(this.studyId);
      let newModality = this.study.studyInfo.modality;
      let newRequestTypes = this.study.studyInfo.requestType;
      if (oldModality !== newModality || oldRequestTypes !== newRequestTypes) {
        this.modalityHasChanged = true;
        return false;
      }
      return true;
    },
    reloadPage() {
      this.$router.go();
    },
    validateForm() {
      const isFormValid = this.$refs.form.validate();
      this.isFindingsEditorInvalid = this.formReport.content.findings.length <= 1;
      this.isImpressionEditorInvalid = this.formReport.content.impression.length <= 1;
      this.isFormValid = isFormValid && !this.isFindingsEditorInvalid && !this.isImpressionEditorInvalid;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.isFindingsEditorInvalid = this.isImpressionEditorInvalid = false;
    },
    popInReport() {
      const { POP_IN_REPORT } = localStorageConstant;
      window.localStorage.setItem(`${POP_IN_REPORT}_${this.studyId}`, "true");
    },
    async endReview() {
      if (!this.confirmDialog.open) {
        this.confirmDialog.title = "End Review";
        this.confirmDialog.text = `Are you sure you want to end review`;
        this.confirmDialog.actionFunction = "endReview";
        this.confirmDialog.open = true;
      } else {
        this.confirmDialog.open = false;
        this.showTagsDialog();
        // this.showRadReportsListAction(true); //Recheck Screen initially
      }
    },
    async endReviewAction() {
      let data = { studyId: this.study.id };
      const isUpdated = await this.updateStudy({ url: apiEndpoints.endReview, data, endStudy: true });
      if (isUpdated) {
        this.disableEditor = true;
        this.clearLocalStorage();
        this.showProbabilityOfReviewResponse();
        this.cancel();
      } else {
        this.disableForm = false;
      }
    },
    showAiResponse() {
      if (this.study.aiResponse !== this.AiResponseTypes.notPredicted) {
        if (this.isAdmin || this.isMedicalAdmin || this.currentUser.allowAiFeedback) {
          this.showAiResponseForm(true);
        } else {
          this.showRadResponseForAIFeedback();
        }
      } else {
        this.showRadResponseForAIFeedback();
      }
    },
    showRadResponseForAIFeedback() {
      if (this.predictedQure && this.isRadiologist && this.currentUser.allowAiFeedback) {
        this.showRadResponseForAIFeedbackForm(true);
      } else {
        this.closeReportWindow();
      }
    },
    showProbabilityOfReviewResponse() {
      this.showProbabilityOfReviewResponseDialog(true);

    },
    saveToLocalStorage(isModified = true) {
      const { localStorage } = window;
      const reportId = this.studyReportsList[this.reportPage - 1].id;
      this.formReport.addReport = false;
      this.formReport.studyId = this.study.id;
      this.formReport.reportId = reportId;
      this.formReport.modality = this.study.modality;
      this.formReport.patientName = this.patientName;
      let report = _.cloneDeep(this.formReport);
      report.isModified = isModified;
      localStorage.setItem(`STUDY_REPORT_${reportId}`, JSON.stringify(report));
    },
    populateCurrentReport(newReport) {
      this.reportId = newReport.reportId;
      this.formReport.studyId = this.study.id;
      this.formReport.reportId = newReport.reportId;
      let contrast = "";
      if (this.study.studyInfo.contrast != "") {
        if (this.study.studyInfo.contrast == "Yes") {
          contrast = "with contrast";
        } else if (this.study.studyInfo.contrast == "No") {
          contrast = "without contrast";
        }
      }
      this.formReport.header.examination =
        newReport.examination !== null && newReport.examination !== undefined && newReport.examination.trim().length > 0
          ? newReport.examination
          : this.studyInfo.modality +
            " " +
            newReport.requestType.join().replaceAll("_", " ").replaceAll(",", " , ") +
            " " +
            contrast;
      this.formReport.header.technique = newReport.technique;
      this.formReport.header.referralDoctor =
        newReport.referralDoctor == "" ? this.studyInfo.referralDoctor : newReport.referralDoctor;
      this.formReport.header.comparison =
        newReport.comparison && newReport.comparison.length ? newReport.comparison : REPORT_COMPARISON_DEFAULT_VALUE;
      this.formReport.content.findings = this.localFindingsData = newReport.findings;
      this.formReport.content.impression = this.localImpressionData = newReport.impression;
      this.formReport.requestType = newReport.requestType == "New Report" ? "" : newReport.requestType;
      this.formReport.critical = newReport.critical;
      this.renderCounter = this.renderCounter + 1;
      this.saveToLocalStorage(false);
    },
    initReportNumber() {
      const { localStorage } = window;
      const reportNumber = localStorage.getItem(`STUDY_REPORT_NUMBER_${this.study.studyId}`);
      if (!reportNumber) {
        this.reportPage = 1;
      } else {
        const reportPage = parseInt(reportNumber);
        this.reportPage = reportPage <= this.studyReportsList.length ? reportPage : 1;
      }
    },
    async savePreviousReport(index) {
      this.checkAndRemoveDeletedRequestType();
      const { localStorage } = window;
      if (!index) {
        return;
      }
      if (this.deletedReportId) {
        localStorage.removeItem(`STUDY_REPORT_${this.deletedReportId}`);
        this.deletedReportId = false;
        return;
      }

      const previousId = this.studyReportsList[index - 1].id;
      const previousReport = JSON.parse(localStorage.getItem(`STUDY_REPORT_${previousId}`));
      if (previousReport && previousReport.isModified) {
        let findingsMarkup = previousReport.content.findings;
        let impressionMarkup = previousReport.content.impression;
        previousReport.content.findings = HtmlToMarkdown(findingsMarkup);
        previousReport.content.impression = HtmlToMarkdown(impressionMarkup);
        previousReport.requestType = previousReport.requestType.sort().join(",");
        let data = { url: apiEndpoints.saveReport, data: previousReport };
        const isSaved = await this.saveStudyReport(data);
        if (isSaved) {
          previousReport.isModified = false;
          previousReport.content.findings = findingsMarkup;
          previousReport.content.impression = impressionMarkup;
          localStorage.setItem(`STUDY_REPORT_${previousId}`, JSON.stringify(previousReport));
          await this.reloadReportsList();
          if (isSaved.reportIdChanged) {
            localStorage.removeItem(`STUDY_REPORT_${previousId}`);
          }
          this.saveToLocalStorage(previousReport.isModified);
        }
      }
    },
    closeReportWindow() {
      if (this.$route.name === this.ClientRoutes.studyViewer) {
        this.endStudy();
      } else {
        window.localStorage.setItem(`${localStorageConstant.closeReportWindow}_${this.studyId}`, "true");
      }
    },
    responseHandler(response) {
      const { reportId } = response;
      this.formReport.reportId = reportId;
      this.reportId = reportId;
      this.reloadReportsList();
    },
    checkAttachment() {
      const { id } = this.$route.params;
      if (id === this.study.studyId) {
        const { STUDY_ATTACHMENT } = localStorageConstant;
        const attachment = JSON.parse(localStorage.getItem(`${STUDY_ATTACHMENT}_${id}`));
        if (attachment !== null) {
          if (!attachment.isDownloaded) {
            location.href = attachment.attachmentUrl;
            attachment.isDownloaded = true;
            localStorage.setItem(`${STUDY_ATTACHMENT}_${id}`, JSON.stringify(attachment));
            this.setMessage({
              message: "Study attachment is being downloaded, please review it",
              type: "info",
              timeout: 10000,
            });
          }
        }
      }
    },
    clearLocalStorage() {
      this.studyReportsList.forEach((item) => {
        localStorage.removeItem(`STUDY_REPORT_${item.id}`);
      });
      this.oldStudies.forEach((item) => {
        localStorage.removeItem(`STUDY_REPORT_NUMBER_${item.studyId}`);
        for (const key in localStorage) {
          if (key.includes("STUDY_REPORT_")) {
            const value = JSON.parse(localStorage.getItem(key));
            if (value.studyId == item.studyId) {
              localStorage.removeItem(key);
            }
          }
        }
      });
      localStorage.removeItem(`STUDY_REPORT_NUMBER_${this.study.studyId}`);
      localStorage.removeItem(`STUDY_ATTACHMENT_${this.study.studyId}`);
    },
    CheckAISupport() {
      this.showQureSelectReportDialog = true;
    },
    fillFindingsWithQureSelectionInDialog(text) {
      this.appendToReport(text);
      this.setMessage({ message: `Added to the current report successfully`, type: "success" });
      if (this.qureFindings.length == 1) this.showQureSelectReportDialog = false;
    },
    appendToReport(text) {
      text = text.replaceAll("\n\n", "\n");
      if (text.endsWith("\n")) {
        text = text.slice(0, -1);
      }
      this.localFindingsData = this.formReport.content.findings + `<ul>${text}<ul/>`;
    },
  },
  async created() {
    await this.reloadReportsList();
    this.initReportNumber();
    this.checkAttachment();
    const { isOldStudy } = this.$route.params;
    this.isOldStudy = isOldStudy;
    document.addEventListener(
      "fullscreenchange",
      () => {
        if (!document.fullscreenElement) {
          this.inFullscreen = false;
        }
      },
      { signal: evenAbortController.signal }
    );
  },
  watch: {
    studyReport(newReport) {
      if (newReport) {
        this.populateCurrentReport(newReport);
      }
    },
    async newReportId(newId) {
      if (newId) {
        await this.reloadReportsList();
        this.reportPage = this.studyReportsList.length;
      }
    },
    reportPage(newValue, oldValue) {
      this.savePreviousReport(oldValue);
      window.localStorage.setItem(`STUDY_REPORT_NUMBER_${this.study.studyId}`, newValue);
      this.loadStudyReport(newValue);
      // if (this.isAdmin || this.isMedicalAdmin || this.currentUser.allowAiFeedback)
      this.checkAI();
    },
    showProbabilityOfReviewResponseForm(newValue, oldValue) {
      if (!newValue) {
        this.showAiResponse();

      }
      console.log(oldValue);
    },
  },
  destroyed() {
    evenAbortController.abort();
  },
};
</script>
<style lang="scss" scoped>
.relative-container {
  position: relative;
}

input:-webkit-autofill {
  background: transparent;
}

.overlay {
  position: absolute;
  left: 8px;
  right: 8px;
  z-index: 2;
}
</style>
