<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-form>
      <v-card>
        <v-card-title>Submit your AI response</v-card-title>
        <v-divider />
        <v-card-text>
          <v-subheader class="px-0">Any Comments?</v-subheader>
          <v-textarea rows="1" auto-grow outlined v-model="comment" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="submit({ normal: true })">Normal</v-btn>
          <v-btn color="orange" @click="submit({ abnormal: true })">Abnormal</v-btn>
          <v-btn color="error" @click="submit({ covid: true })">Pneumonia</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import apiEndpoints from "@/util/apiEndpoints";
import { mapState, mapActions, mapGetters } from "vuex";
import { localStorageConstant } from "@/util/localStorageUtils";
import ClientRoutes from "@/router/ClientRoutes";
import AiResponseTypes from "@/util/AiResponseTypes";


export default {
  name: "AiResponseForm",
  computed: {
    ...mapState(["study", "currentUser"]),
    ...mapGetters(["aiResponse"]),
  },
  data() {
    return {
      dialog: true,
      comment: "",
      ClientRoutes,
      AiResponseTypes,
    };
  },
  methods: {
    ...mapGetters(["isRadiologist"]),
    ...mapActions(["saveHumanResponseForAI", "endStudy", "showRadResponseForAIFeedbackForm", "getQurePredictionStatus"]),
    async submit(status) {
      const url = apiEndpoints.saveHumanResponseForAI;
      const data = { studyId: this.study.studyId, ...status, predicted: this.aiResponse, comment: this.comment };
      await this.saveHumanResponseForAI({ url, data });
      await this.showRadResponseForAIFeedback();
    },
    async showRadResponseForAIFeedback() {
      const result = await this.getQurePredictionStatus(this.study.studyId);
      if (result !== null && result.status === "PREDICTED" &&  this.isRadiologist && this.currentUser.allowAiFeedback) {
        this.showRadResponseForAIFeedbackForm(true);
      } else {
        this.closeReportWindow();
      }
    },
    closeReportWindow() {
      if (this.$route.name === this.ClientRoutes.studyViewer) {
        this.endStudy();
      } else {
        window.localStorage.setItem(`${localStorageConstant.closeReportWindow}_${this.study.studyId}`, "true");
      }
    },
  },
};
</script>
