<template>
    <v-combobox :items="dataItems_Appeared"  @change="loadItemsChildrenEvent($event,additionalFilters)"
                :item-text="textProperty"
                :item-value="valueProperty" :outlined="isOutlined" :dense="isDense"
                :name="label" :label="label" :return-object="!isMultiple" @input="searchInput = null"
                :search-input.sync="searchInput" :multiple="isMultiple" hide-selected :chips="isChips"
                v-model="selected_dataItems">
      <template v-slot:selection="{ attrs, item, parent, selected }" >
        <v-chip v-if="item[textProperty] == Object(item[textProperty]) && isMultiple && textProperty != '' " v-bind="attrs" :input-value="selected" label small>
                                    <span class="pr-2">
                                      {{ item[textProperty] }}
                                    </span>
          <v-icon small @click="parent.selectItem(item[textProperty])">
            $delete
          </v-icon>
        </v-chip>
        <v-chip v-if="item == Object(item) && isMultiple && textProperty == '' " v-bind="attrs" :input-value="selected" label small>
                                    <span class="pr-2">
                                      {{ item }}
                                    </span>
          <v-icon small @click="parent.selectItem(item)">
            $delete
          </v-icon>
        </v-chip>
        <span v-if="!isMultiple && textProperty == ''">{{ item }}</span>
        <span v-if="!isMultiple && textProperty != '' ">{{ item[textProperty] }}</span>
      </template>
    </v-combobox>
</template>
<script>
export default {
  name: "ComboFilter",
  props: {
    dataItems: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => '',
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
    },
    isOutlined:{
      type: Boolean,
      default: () => false,
    },
    isDense:{
      type: Boolean,
      default: () => false,
    },
    isChips:{
      type: Boolean,
      default: () => false,
    },
    textProperty: {
      type: String,
      default: () => '',
    },
    valueProperty: {
      type: String,
      default: () => '',
    },
  },
data() {
  return {
    dataItems_Appeared: [],
    searchInput:'',
    selected_dataItems:[],
    additionalFilters:[]
  };
},
  methods: {
    loadItemsChildrenEvent(value,additionalFilters){
      this.$emit("loadItemsChildrenEmit", { value: value ,additionalFilters: additionalFilters});
    },
    setAdditionalFilters(additionalFilters){
      this.additionalFilters = additionalFilters;
    },
    uniqueArray( ar,property ) {
      let j = {};
      ar.forEach( function(v) {
        if(property != null && property != undefined && property != '')
          j[v[property]+ '::' + typeof v[property]] = v;
        else
          j[v+ '::' + typeof v] = v;
      });
      return Object.keys(j).map(function(v){
        return j[v];
      });
    },
  },
  mounted() {
    this.dataItems_Appeared = this.uniqueArray(this.dataItems,this.textProperty != ''  ? this.textProperty : '');
  },
};
</script>

<style>

</style>