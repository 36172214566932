<template>
  <v-progress-linear
      class="text-capitalize"
      :value="progressValue"
      height="36"
  >
  <template v-slot:default="">
    <v-icon>mdi-plus-box-outline</v-icon>
    <span>Pick up new study</span>
    <span style="margin-left: 2%"> {{ remainingTimeFormatted }}</span>
  </template>
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      interval: {},
      initialTime: this.calculateInitialTime(),
      elapsedTime: this.calculateElapsedTime(),
    };
  },
  computed: {
    remainingTime() {
      return Math.max(0, this.initialTime - this.elapsedTime);
    },
    progressValue() {
      return ((this.initialTime - this.elapsedTime )/ this.initialTime) * 100;
    },
    elapsedTimeFormatted() {
      const minutes = Math.floor(this.elapsedTime / 60);
      const seconds = this.elapsedTime % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    remainingTimeFormatted() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    calculateInitialTime() {
      const initialTimeMs = this.endDate - this.startDate;
      return Math.max(0, Math.ceil(initialTimeMs / 1000));
    },
    calculateElapsedTime() {
      const currentTime = new Date();
      const elapsedTimeMs = currentTime - this.startDate;
      return Math.max(0, Math.ceil(elapsedTimeMs / 1000));
    },
    startTimer() {
      this.interval = setInterval(() => {
        if (this.elapsedTime >= this.initialTime) {
          clearInterval(this.interval);
          return;
        }
        this.elapsedTime++;
      }, 1000);
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>