<template>
  <div>
    <TemplateFormDialog v-if="showTemplateFormDialog" />
    <AiResponseForm v-if="showAiResponseForm" />
    <ProbabilityOfReviewResponseForm v-if="showProbabilityOfReviewResponseForm" />
    <ManageShorthand v-if="isShorthandsDialogOpen" />
    <AboutUsForm v-if="showAboutUsForm" />
    <GameBallRadsForm v-if="showRadReportsListDialog" />
    <AiFeedbackForm v-if="showRadResponseForAIFeedbackForm"/>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AiResponseForm from "@/components/AI/AiResponseForm";
import ProbabilityOfReviewResponseForm from "@/components/AI/ProbabilityOfReviewResponseForm";
import ManageShorthand from "@/components/ManageShorthand/ManageShorthand";
import TemplateFormDialog from "@/components/Template/TemplateFormDialog";
import AboutUsForm from "@/components/AboutUs/AboutUsForm";
import GameBallRadsForm from "@/components/StudyActions/GameBallRadSForm";
import AiFeedbackForm from "@/components/AI/AiFeedbackForm";

export default {
  name: "Dialogs",
  components: {AboutUsForm, TemplateFormDialog, AiResponseForm, ProbabilityOfReviewResponseForm, ManageShorthand, GameBallRadsForm, AiFeedbackForm },
  computed: {
    ...mapState("Templates", ["showTemplateFormDialog"]),
    ...mapState(["showAiResponseForm", "isShorthandsDialogOpen", "showAboutUsForm", "showProbabilityOfReviewResponseForm", "showRadReportsListDialog", "showRadResponseForAIFeedbackForm"]),
  },
};
</script>
