<template>
  <v-dialog v-model="dialog" width="800" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card>
      <v-card-title>Change request type</v-card-title>
      <v-card-text>
        <v-autocomplete label="Request Type" item-text="name" item-value="value" :items="requestTypeList" outlined
          item-color="primary" validate-on-blur required :return-object="false" v-model="newRequestType" hide-selected
          multiple small-chips solo>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item == Object(item)" v-bind="attrs" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.name }}
              </span>
              <v-icon small @click="parent.selectItem(item)">
                $delete
              </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="save" :disabled="!newRequestType.length">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import dialogMixins from "@/shared/dialog-mixins";
import apiEndpoints from "@/util/apiEndpoints";

export default {
  name: "ChangeInfo",
  mixins: [dialogMixins],
  computed: {
    ...mapState(["requestTypeList"]),
    ...mapGetters(["studyId", "requestType"]),
  },
  data() {
    return {
      dialog: true,
      newRequestType: [],
    };
  },
  methods: {
    ...mapActions(["changeRequestType", "getStudyById"]),
    cancel() {
      this.$emit("resetAction");
    },
    async save() {
      const url = apiEndpoints.changeRequestType;
      const data = {
        studyId: this.studyId,
        requestType: this.newRequestType.sort().join(','),
      };
      await this.changeRequestType({ url, data });
      await this.getStudyById(this.studyId);
      this.cancel();
    },
  },
  mounted() {
    this.newRequestType = this.requestType.split(',');
  },
};
</script>
