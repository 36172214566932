<template>
  <v-list dense flat>
    <v-list-item-group v-model="selectedItem">
      <v-list-item v-for="(image, index) in maliciousImages" :key="image.imageId" @click="selectCut(image, index)">
        <v-list-item-content>
          <SeriesWrapper>
            <template v-slot:dicomImage>
              <DicomThumbnail :image-object="image" :is-highlighted="selectedItem === index" />
            </template>
            <template v-slot:title>
              <span class="subtitle-1">Score: </span>
              <span class="subtitle-2">{{ Math.floor(image.score * 100) }}%</span>
            </template>
            <template v-slot:number-and-icon>
              <v-icon color="error" size="20">mdi-virus-outline</v-icon>
            </template>
          </SeriesWrapper>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import { mapGetters } from "vuex";
import DicomThumbnail from "@/components/DicomThumbnail/DicomThumbnail";
import SeriesWrapper from "@/components/SeriesWrapper/SeriesWrapper";

const viewerInterface = window["VIEWER_INTERFACE"];
const viewerActions = window["VIEWER_ACTIONS"];

export default {
  name: "MaliciousCuts",
  components: { SeriesWrapper, DicomThumbnail },
  data() {
    return {
      data: {
        max: 0,
        count: 0,
        cutList: [],
      },
      images: [],
      maliciousImages: [],
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters(["studyId"]),
    ...mapGetters("DicomSeries", ["isMainStudyLoaded"]),
  },
  methods: {
    async loadAiCuts() {
      try {
        const res = await API.get(apiEndpoints.getMaliciousCuts + this.studyId);
        this.data = { ...res.data };
      } catch (error) {
        console.log(error);
      }
    },
    selectCut({ cutId }, selectedIndex) {
      this.selectedItem = selectedIndex;
      const viewPort = document.querySelector(".viewportWrapper.cornerstone-enabled-image > .viewport");
      const enabledElement = window.cornerstone.getEnabledElement(viewPort);
      viewerInterface.dispatchEvent(new CustomEvent(viewerActions.jumpToIndex, { detail: { cutId, enabledElement } }));
    },
    async loadImages() {
      const { findCutById } = window.cornerstone.utils;
      const { loadAndCacheImage } = window.cornerstone;
      const ids = this.data.cutList.map(({ cutId }) => findCutById(cutId));
      this.images = await Promise.all(ids.map(({ imageId }) => loadAndCacheImage(imageId)));
    },
    mapImagesWithAiCuts() {
      this.maliciousImages = this.images.map((image, index) => {
        return { ...image, ...this.data.cutList[index] };
      });
    },
    async init() {
      await this.loadAiCuts();
      await this.loadImages();
      this.mapImagesWithAiCuts();
    },
  },
  watch: {
    isMainStudyLoaded: {
      handler(newValue) {
        if (newValue) {
          this.init();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.score {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #6cbffd;
  border-radius: 100%;
  width: 75px;
  height: 75px;
  font-size: 22px;
}
</style>
