<template>
  <v-card :class="{ 'px-5 py-2': !removePadding }" tile flat v-if="isStudy">
    <div class="primary--text subtitle-1 font-weight-bold" v-if="!hideTitle">Medical History</div>
    <v-divider class="mb-4" v-if="!hideTitle" />
    <div class="name">
      <span class="mr-1 font-weight-bold primary--text">Name:</span>
      <span class="value" style="text-transform: capitalize">
        {{ study.patientName.toLowerCase() }}
      </span>
    </div>
    <div class="gender">
      <span class="mr-1 font-weight-bold primary--text">Gender:</span>
      <span class="value">{{ study.patientSex === "M" ? "Male" : "Female" }}</span>
    </div>
    <div class="with-contrast">
      <span class="mr-1 font-weight-bold" :class="{ 'error--text': hasContrast, 'primary--text': !hasContrast }">
        Contrast:
      </span>
      <span class="value" :class="{ 'error--text': hasContrast }">{{ studyInfo.contrast }}</span>
    </div>
    <div class="prev-operations">
      <span class="mr-1 font-weight-bold" :class="{ 'error--text': hasOperation, 'primary--text': !hasOperation }">
        Operations:
      </span>
      <span class="value" :class="{ 'error--text': hasOperation }" v-if="hasOperation">
        {{ studyInfo.operationDetails }}
      </span>
      <span class="value" v-if="!hasOperation">No</span>
    </div>
    <div class="prev-operations">
      <span class="mr-1 font-weight-bold" :class="{ 'error--text': hasMedication, 'primary--text': !hasMedication }">
        Medications:
      </span>
      <span class="value" :class="{ 'error--text': hasMedication }" v-if="hasMedication">
        {{ studyInfo.medicationDetails }}
      </span>
      <span class="value" v-if="!hasMedication">No</span>
    </div>
    <div class="complaint">
      <span class="mr-1 font-weight-bold primary--text">Complaint:</span>
      <span class="value">{{ studyInfo.complaint }}</span>
    </div>
    <div v-if="hasAttachments">
      <span class="mr-1 font-weight-bold primary--text">Attachments:</span>
      <v-btn small link :loading="!isAttachmentsLoaded" icon @click="attachmentHandler">
        <v-icon size="20">mdi-cloud-download</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import API from "@/shared/API";
import { localStorageConstant } from "@/util/localStorageUtils";
import { mapActions } from "vuex";

export default {
  name: "MedicalHistory",
  data() {
    return {
      attachmentUrl: null,
      isAttachmentsLoaded: false,
    };
  },
  props: {
    study: {
      type: Object,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    removePadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStudy() {
      return this.study && Object.keys(this.study).length > 0;
    },
    hasAttachments() {
      const { attachments } = this.studyInfo;
      return attachments !== "N/A";
    },
    studyInfo() {
      return this.study ? this.study.studyInfo : null;
    },
    hasMedication() {
      return this.studyInfo && this.studyInfo.medication.toLowerCase() === "yes";
    },
    hasOperation() {
      return this.studyInfo && this.studyInfo.operation.toLowerCase() === "yes";
    },
    hasContrast() {
      return this.studyInfo && this.studyInfo.contrast.toLowerCase() === "yes";
    },
  },
  methods: {
    ...mapActions(["setMessage"]),
    attachmentHandler() {
      window.open(this.attachmentUrl, "new");
      const { id } = this.$route.params;
      if (id === this.study.studyId) {
        const { STUDY_ATTACHMENT } = localStorageConstant;
        const attachment = JSON.parse(localStorage.getItem(`${STUDY_ATTACHMENT}_${id}`));
        attachment.isDownloaded = true;
        localStorage.setItem(`${STUDY_ATTACHMENT}_${id}`, JSON.stringify(attachment));
      }
    },
    checkAttachment() {
      const { id } = this.$route.params;
      if (id === this.study.studyId) {
        const { STUDY_ATTACHMENT } = localStorageConstant;
        const attachment = JSON.parse(localStorage.getItem(`${STUDY_ATTACHMENT}_${id}`));
        if (attachment !== null) {
          if (!attachment.isDownloaded) {
            window.open(attachment.attachmentUrl, "new");
            attachment.isDownloaded = true;
            localStorage.setItem(`${STUDY_ATTACHMENT}_${id}`, JSON.stringify(attachment));
            this.setMessage({
              message: "Study attachment is being downloaded, please review it",
              type: "info",
              timeout: 10000,
            });
          }
        }
      }
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    const url = `/case/downloadAttachments/${this.study.studyId}`;
    const { attachments } = this.studyInfo;
    if (attachments === "true") {
      try {
        const response = await API.get(url);
        this.attachmentUrl = response.data;
        this.isAttachmentsLoaded = true;
        const { STUDY_ATTACHMENT } = localStorageConstant;
        const attachment = JSON.parse(localStorage.getItem(`${STUDY_ATTACHMENT}_${id}`));
        if (id === this.study.studyId && !attachment) {
          const attachment = {
            attachmentUrl: this.attachmentUrl,
            isDownloaded: false,
          };
          localStorage.setItem(`${STUDY_ATTACHMENT}_${id}`, JSON.stringify(attachment));
          this.checkAttachment();
        }
      } catch (error) {
        console.log("Error getting attachments download link", error);
      }
    }
  },
};
</script>
