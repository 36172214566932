import { render, staticRenderFns } from "./MprButton.vue?vue&type=template&id=2944333c&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D"
import script from "./MprButton.vue?vue&type=script&lang=js"
export * from "./MprButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports