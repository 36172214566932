import { errorHandler, successHandler } from "@/store/shared";
import { getTemplatesList, loadTemplate, deleteTemplate, saveTemplate } from "@/store/Templates/TemplateService";
import { SET_REPORT_FROM_TEMPLATE } from "@/store/mutation-types";
import { TemplateInterface } from "@/util/Interfaces";

const GET_TEMPLATE = "GET_TEMPLATE";
const SET_TEMPLATES_LIST = "SET_TEMPLATES_LIST";
const TOGGLE_TEMPLATE_FORM = "TOGGLE_TEMPLATE_FORM";
const SET_SYSTEM_TEMPLATES = "SET_SYSTEM_TEMPLATES";
const SET_CUSTOM_TEMPLATES = "SET_CUSTOM_TEMPLATES";

const divideTemplates = (items) => {
  const systemTemplates = [];
  const customTemplates = [];
  items.forEach((item) => {
    if (item.protected) {
      systemTemplates.push(item);
    } else {
      customTemplates.push(item);
    }
  });
  return { systemTemplates, customTemplates };
};

const Templates = {
  namespaced: true,
  state: {
    showTemplateFormDialog: false,
    templatesList: [],
    systemTemplates: [],
    customTemplates: [],
    loadedTemplate: { ...TemplateInterface },
  },
  actions: {
    async getTemplatesList({ commit, dispatch }) {
      try {
        const response = await getTemplatesList();
        const { systemTemplates, customTemplates } = divideTemplates(response.data.results);
        commit(SET_TEMPLATES_LIST, response.data.results);
        commit(SET_SYSTEM_TEMPLATES, systemTemplates);
        commit(SET_CUSTOM_TEMPLATES, customTemplates);
      } catch (error) {
        errorHandler(error, dispatch, "Error getting templates list");
      }
    },
    async getTemplate({ commit, dispatch }, data) {
      try {
        // TODO: backend API require refactoring for the returned data
        const response = await loadTemplate(data);
        delete response.data.success;
        commit(GET_TEMPLATE, response.data);
      } catch (error) {
        errorHandler(error, dispatch, "Error getting predefined templates");
      }
    },
    async applyTemplate({ commit, dispatch, state }, data) {
      await dispatch("getTemplate", data);
      commit(SET_REPORT_FROM_TEMPLATE, state.loadedTemplate, { root: true });
    },
    async deleteTemplate({ dispatch }, templateName) {
      try {
        const { data } = await deleteTemplate(templateName);
        if (data.success) {
          await dispatch("getTemplatesList");
          successHandler(dispatch, "Template was deleted");
          return true;
        } else {
          errorHandler(null, dispatch, `Error deleting template ${templateName}`);
          return false;
        }
      } catch (error) {
        errorHandler(error, dispatch, `Error deleting template ${templateName}`);
        return false;
      }
    },
    async saveTemplate({ dispatch }, templateData) {
      try {
        const { data } = await saveTemplate(templateData);
        if (data.success) {
          successHandler(dispatch, "Template was saved");
          await dispatch("getTemplatesList");
          return true;
        }
      } catch (error) {
        errorHandler(error, dispatch, `Error saving template`);
      }
    },
    toggleTemplateForm({ commit }) {
      commit(TOGGLE_TEMPLATE_FORM);
    },
  },
  mutations: {
    [SET_TEMPLATES_LIST](state, value) {
      state.templatesList = value;
    },
    [SET_SYSTEM_TEMPLATES](state, value) {
      state.systemTemplates = value;
    },
    [SET_CUSTOM_TEMPLATES](state, value) {
      state.customTemplates = value;
    },
    [GET_TEMPLATE](state, value) {
      state.loadedTemplate = value;
    },
    [TOGGLE_TEMPLATE_FORM](state) {
      state.showTemplateFormDialog = !state.showTemplateFormDialog;
    },
  },
};

export default Templates;
