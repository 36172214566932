import { getGrayscaleImage } from "./grayscale";
import { blurMono16 } from "./gaussianBlurGrayscaleMono16";
import { growRegion } from "./growRegion";
import { getNoduleDimensionPoints } from "./noduleDimesionPoints";

/**
 * apply grow region filter to grayscal image
 * @param imagedata - source image data structure . https://developer.mozilla.org/en-US/docs/Web/API/ImageData
 * @param width - width of image.
 * @param height - height of image.
 * @param seed - array of 2 points [x,y], seed pixel point of the region.
 * @param threshold - threshold delta to be used in the grow region filter. - default : 35
 * @param radius - gaussian blur radius. - default : 1
 * @return return object with nodule region pixels and it's dimensions.
 */
export async function getLungNoduleInfo(imagedata, width, height, seed, threshold = 35, radius = 1) {
  var perfmonStart = performance.now();
  var tmpPerfmon = performance.now();

  //1 - get grayscal image
  var grayscaleImageData = getGrayscaleImage(imagedata.data, width, height);
  console.log("grayscal image done in : %s ms", (performance.now() - tmpPerfmon).toFixed(2));

  //2 - update image with blure filter
  tmpPerfmon = performance.now();
  if (radius > 0) {
    for (var i = 0; i < 1; i++) {
      blurMono16(grayscaleImageData, width, height, radius);
    }
  }
  console.log("bluring done in : %s ms", (performance.now() - tmpPerfmon).toFixed(2));

  //3 - get the grow region of the seed point
  tmpPerfmon = performance.now();
  var growenRegionIndexs = growRegion(grayscaleImageData, width, height, seed, threshold);
  console.log("grow region done in : %s ms", (performance.now() - tmpPerfmon).toFixed(2));

  //get axises
  tmpPerfmon = performance.now();
  var noduleDimensionPoints = getNoduleDimensionPoints(growenRegionIndexs);
  console.log("dimensions extracted in : %s ms", (performance.now() - tmpPerfmon).toFixed(2));

  var result = {
    growenRegionIndexs: growenRegionIndexs,
    noduleDimensionPoints: noduleDimensionPoints,
  };

  console.log("lung nodule info generated in total of : %s ms", (performance.now() - perfmonStart).toFixed(2));

  return result;
}
