export default {
  studyId: "",
  reportId: "",
  modality: "",
  addReport: null,
  critical: false,
  requestType: "",
  header: {
    referralDoctor: "",
    examination: "",
    technique: "",
    comparison: "",
  },
  content: {
    findings: "",
    impression: "",
  },
};
