<script>
import {Auth} from 'aws-amplify';

export default {
  name: "Signout",
  methods: {
    clearLocalStorage() {
      try {
        localStorage.clear();
      } catch (e) {
        console.error("Failed to clear localStorage", e)
      }
    },
    clearCookies() {
      try {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
      } catch (e) {
        console.error("Failed to clear cookies", e)
      }
    },
    clearSession() {
      try {
        sessionStorage.clear();
      } catch (e) {
        console.error("Failed to clear sessionStorage", e)
      }
    },
    clearIndexedDb() {
      try {
        const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

        if (indexedDB) {
          window.indexedDB.databases().then((r) => {
            for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
          })
        } else {
          console.log('IndexedDB not supported');
        }
      } catch (e) {
        console.error("Failed to clear indexedDB", e)
      }
    },
    clear() {
      console.log("start clearing ... ")
      this.clearIndexedDb();
      this.clearLocalStorage();
      this.clearCookies();
      this.clearSession();
    },
    async signOut() {
      try {
        await Auth.signOut();
        this.clear();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  },
  mounted() {
    this.signOut();
  }

};
</script>

<style scoped>

</style>