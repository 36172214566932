import API from "@/shared/API";
import * as mutationTypes from "./mutation-types";
import StudyActions from "@/store/Study/StudyActions";
import ReportActions from "@/store/Report/ReportActions";
import AIActions from "./AI/AIActions";
import ShorthandsList from "@/store/Shorthands/ShorthandsListActions";
import UserActions from "@/store/User/UserActions";
import { errorHandler } from "@/store/shared";

export default {
  ...StudyActions,
  ...ReportActions,
  ...AIActions,
  ...ShorthandsList,
  ...UserActions,
  setApiCall({ commit }, value) {
    commit(mutationTypes.SET_API_CALL, value);
  },
  apiError({ commit }, payload) {
    commit(mutationTypes.API_ERROR, payload);
  },
  setMessage({ commit }, message) {
    commit(mutationTypes.SET_MESSAGE, message);
  },
  deleteMessage({ commit }, index) {
    commit(mutationTypes.DELETE_MESSAGE, index);
  },
  async getRequestTypesList({ commit, dispatch }, url) {
    commit(mutationTypes.SET_API_CALL, true);
    try {
      const response = await API.get(url);
      response.data.sort((a, b) => (a.value > b.value ? 1 : -1));
      commit(mutationTypes.SET_API_CALL, false);
      commit(mutationTypes.GET_REQUEST_TYPE_LIST, response.data);
    } catch (error) {
      errorHandler(error, dispatch, "Error getting request type list", url);
    }
  },
  async downloadStudy({ dispatch }, url) {
    try {
      const response = await API.post(url, { trialNum: 0 });
      window.open(response.data["url"], "new");
    } catch (error) {
      errorHandler(error, dispatch, "Error downloading study, please try again later or contact system admin.", url);
    }
  },
  async endReview(context, data) {
    await this.updateStudy(context, data);
  },
  toggleStudyVisibility({ commit }, value) {
    commit(mutationTypes.TOGGLE_STUDY_VISIBILITY, value);
  },
  async getPatientHistoryList({ commit, dispatch }, url) {
    try {
      const response = await API.get(url);
      commit(mutationTypes.GET_PATIENT_HISTORY_LIST, response.data);
    } catch (error) {
      errorHandler(error, dispatch, "Error getting patient history.", url);
    }
  },
  async clearPatientHistoryList({ commit }) {
    commit(mutationTypes.CLEAR_PATIENT_HISTORY_LIST);
  },
  async demergeStudy({ dispatch, commit }, url) {
    try {
      await API.get(url);
      commit(mutationTypes.DEMERGE_STUDY, []);
      commit(mutationTypes.UPDATE_STUDY, { rologyId: false });
    } catch (error) {
      errorHandler(error, dispatch, "Error de-merging study", url);
    }
  },
  showAiResponseForm({ commit }, value) {
    commit(mutationTypes.SHOW_AI_RESPONSE_FORM, value);
  },
  async saveHumanResponseForAI({ commit, dispatch }, { url, data }) {
    try {
      await API.post(url, data);
      commit(mutationTypes.SHOW_AI_RESPONSE_FORM, false);
    } catch (error) {
      errorHandler(error, dispatch, "Error saving AI response ", url);
    }
  },
  showProbabilityOfReviewResponseDialog({ commit }, value) {
    commit(mutationTypes.SHOW_PROBABILITY_OF_REVIEW_RESPONSE_FORM, value);
  },
  async saveHumanResponseForProbabilityOfReview({ commit, dispatch }, { url, data }) {
    try {
      await API.post(url, data);
      commit(mutationTypes.SHOW_PROBABILITY_OF_REVIEW_RESPONSE_FORM, false);
    } catch (error) {
      errorHandler(error, dispatch, "Error saving Probability Of Review response ", url);
    }
  },
  async getModalitiesList({ commit, dispatch }, url) {
    commit(mutationTypes.SET_API_CALL, true);
    try {
      const response = await API.get(url);
      commit(mutationTypes.SET_API_CALL, false);
      commit(mutationTypes.GET_MODALITIES_LIST, response.data);
    } catch (error) {
      errorHandler(error, dispatch, "Error getting modality list", url);
    }
  },
  async getDataFromUrl({ commit, dispatch }, url) {
    commit(mutationTypes.SET_API_CALL, true);
    try {
      const response = await API.get(url);
      let returnValue = response.data;
      commit(mutationTypes.SET_API_CALL, false);
      return returnValue;
    } catch (error) {
      errorHandler(error, dispatch, "Error getting Data", url);
    }
  },
  async setDataToUrl(dispatch, { url, data }) {
    try {
      let response = await API.post(url, data);
      return response;
    } catch (error) {
      errorHandler(error, dispatch, "Error setting data ", url);
    }
  },
  async deleteDataFromUrl(dispatch, { url, data }) {
    try {
      let response = await API.delete(url, data);
      return response;
    } catch (error) {
      errorHandler(error, dispatch, "Error setting data ", url);
    }
  },
  setHeadersBooleanStored({ commit }, headersBooleanStored) {
    commit(mutationTypes.SET_HEADERS_BOOLEAN_STORED, headersBooleanStored);
  },
  toggleAboutUsForm({ commit }) {
    commit(mutationTypes.TOGGLE_ABOUT_US_FORM);
  },
  async getProbabilityOfReviewResponseList({ commit, dispatch }, url) {
    commit(mutationTypes.SET_API_CALL, true);
    try {
      const response = await API.get(url);
      commit(mutationTypes.SET_API_CALL, false);
      commit(mutationTypes.GET_PROBABILITY_OF_REVIEW_RESPONSE_LIST, response.data);
    } catch (error) {
      errorHandler(error, dispatch, "Error getting probability of review response list", url);
    }
  },
  showRadReportsListAction({ commit }, value) {
    commit(mutationTypes.SHOW_RAD_REPORTS_LIST_DIALOG, value);
  },
  async saveRadReportsList({ commit, dispatch }, { url, data }) {
    try {
      await API.post(url, data);
      commit(mutationTypes.SHOW_RAD_REPORTS_LIST_DIALOG, false)
    } catch (error) {
      errorHandler(error, dispatch, "Error saving Rad Reports List ", url);
    }
  },
  async getRadReportsList({ commit, dispatch }, { url }) {
    commit(mutationTypes.SET_API_CALL, true);
    try {
      const response = await API.get(url);
      commit(mutationTypes.SET_API_CALL, false);
      console.log(response.data);
      commit(mutationTypes.RAD_REPORTS_LIST, response.data);
    } catch (error) {
      errorHandler(error, dispatch, "Error getting rad reports list", url);
    }
  },
  showRadResponseForAIFeedbackForm({ commit }, value) {
    commit(mutationTypes.SHOW_RAD_RESPONSE_FOR_AI_FORM, value);
  },
  async saveRadResponseForAIFeedback({ commit, dispatch }, { url, data }) {
    try {
      await API.post(url, data);
      commit(mutationTypes.SHOW_RAD_RESPONSE_FOR_AI_FORM, false);
    } catch (error) {
      errorHandler(error, dispatch, "Error saving rad response for AI feedback ", url);
    }
  },
};
