import UserTypes from "@/util/UserTypes";
export const FileMenuItems = {
  file: [
    { title: "new study", shortcut: "Ctrl+N", role: "any" },
    { title: "open study", role: "any" },
    { title: "divider", role: "any" },
    { title: "download study", role: "any" },
    { title: "download report", role: "any" },
    { title: "divider", role: "any" },
    { title: "start review", role: "any" },
    { title: "end review", role: "any" },
    { title: "end annotation", role: "any" },
  ],
  tools: [
    { title: "write report", role: "any" },
    { title: "manage shorthands", role: UserTypes.radiology },
  ],
  view: {
    toolbar: { title: "toolbar", show: true, role: "any" },
    studiesList: { title: "study list", pinned: true, show: true, role: "any" },
    annotations: { title: "annotation", pinned: true, show: true, role: "any" },
  },
  settings: [],
};
