<template>
  <main>
    <v-container fluid class="pa-0 fill-height">
      <DicomViewer v-if="currentUser !== null && canUserViewStudy" />
      <StudyNotFound v-if="currentUser !== null && !isStudyVisible" />
    </v-container>
  </main>
</template>
<script>
import DicomViewer from "@/components/DicomViewer/DicomViewer";
import { mapState, mapActions } from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
import UserTypes from "@/util/UserTypes";
import StudyStatus from "@/util/StudyStatus";
import StudyNotFound from "@/components/StudyNotFound";
import API from "@/shared/API";

const viewerInterface = window["VIEWER_INTERFACE"];
const viewerActions = window["VIEWER_ACTIONS"];

export default {
  name: "Home",

  components: {
    DicomViewer,
    StudyNotFound,
  },
  methods: {
    toggleViewer() {
      if (this.study !== null) {
        const { type } = this.currentUser;
        if (type === UserTypes.admin || type === UserTypes.medicalAdmin) {
          this.toggleStudyVisibility(true);
        } else {
          /* TODO: backend validation is required because viewer can open study for rad users */
          const { assignee, status } = this.study;
          const { id } = this.currentUser;
          if (id === assignee && (status === StudyStatus.diagnosing || status === StudyStatus.assigned)) {
            this.toggleStudyVisibility(true);
          } else {
            this.toggleStudyVisibility(false);
          }
        }
      }
    },
    loadRequestTypesList() {
      if (this.study) {
        let getRequestTypesListUrl = `${apiEndpoints.getRequestTypesList}/${this.study.studyInfo.modality}`;
        this.getRequestTypesList(getRequestTypesListUrl);
      }
    },
    async changeStudyStatusToAssigned() {
      let data = { studyId: this.study.id };
      let changeStudyStatusToAssignedUrl = `${apiEndpoints.changeStudyStatusFromDiagnosingToAssigned}`;
      await API.post(changeStudyStatusToAssignedUrl, data);
    },
    async startDiagnosing(id) {
      let data = {
        studyId: id,
      };
      const url = apiEndpoints.startStudyDiagnosing;
      await this.startStudyDiagnosing({ url, data });
    },
    async beforeunload() {
      if (this.currentUser.type == "R"){
        await this.changeStudyStatusToAssigned();
      }
      this.closeReportWindow();
      this.clearLoadedStudies();
      this.clearCurrentLoadedStudy();
      this.clearPatientHistoryList();
    },
    ...mapActions([
      "getStudyById",
      "getCurrentUser",
      "toggleStudyVisibility",
      "getPatientHistoryList",
      "clearPatientHistoryList",
      "getRequestTypesList",
      "clearCurrentLoadedStudy",
      "startStudyDiagnosing",
    ]),
    ...mapActions("Layout", ["toggleViewerLoadingState", "closeReportWindow"]),
    ...mapActions("DicomSeries", ["clearLoadedStudies"]),
  },
  computed: {
    ...mapState(["study", "currentUser", "canUserViewStudy", "isStudyVisible", "showAiResponseForm"]),
    ...mapState("Layout", ["dockBarWidth"]),
  },
  async created() {
    let { id } = this.$route.params;
    await this.getStudyById(id);
    await this.getCurrentUser(apiEndpoints.currentUser);
    const { type } = this.currentUser;
    if (type === UserTypes.radiology) {
      await this.startDiagnosing(id);
    }
    window.addEventListener("beforeunload", this.beforeunload);
  },
  watch: {
    currentUser(newValue) {
      if (newValue) {
        this.toggleViewer();
        this.loadRequestTypesList();
      }
    },
    study(newValue) {
      if (newValue) {
        document.title = newValue.patientName;
        if (newValue.rologyId && newValue.rologyId !== "N/A") {
          this.getPatientHistoryList(apiEndpoints.patientHistory + newValue.rologyId);
        }
      }
    },
  },
  destroyed() {
    this.clearLoadedStudies();
    this.clearCurrentLoadedStudy();
    this.clearPatientHistoryList();
    this.toggleViewerLoadingState(false);
    this.toggleStudyVisibility(false);
    viewerInterface.dispatchEvent(new CustomEvent(viewerActions.destroyViewer));
    document.title = "Rology Viewer";
  },
  async beforeRouteLeave(to, from, next) {
    this.beforeunload();
    return next();
  },
};
</script>
