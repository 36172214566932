<template>
  <v-menu offset-y rounded="0" class="text-center mr-1" :nudge-width="200" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs, value }">
      <v-btn class="py-1 px-2 layout-button" height="auto" text tile v-bind="attrs" v-on="on" :input-value="value">
        <v-icon size="20">mdi-animation-play</v-icon>
        <div class="caption text-capitalize">Play</div>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list-item class="grey darken-3">
        <v-card class="grey darken-3" width="100%" tile flat>
          <v-card-text>
            <v-row class="mb-4" justify="space-between">
              <v-col class="text-left">
                <span class="display-1 font-weight-light" v-text="fps"></span>
                <span class="subheading font-weight-light mr-1">FPS</span>
                <v-fade-transition>
                  <v-avatar v-if="isPlaying" color="info" class="mb-1 v-avatar--metronome" size="12" />
                </v-fade-transition>
              </v-col>
              <v-col class="text-right">
                <v-btn color="info" depressed fab @click="toggle" small>
                  <v-icon size="25">
                    {{ isPlaying ? "mdi-pause" : "mdi-play" }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-slider v-model="fps" color="info" track-color="grey" always-dirty :min="min" :max="max">
              <template v-slot:prepend>
                <v-icon color="info" @click="decrement">mdi-minus</v-icon>
              </template>
              <template v-slot:append>
                <v-icon color="info" @click="increment">mdi-plus</v-icon>
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
const viewerInterface = window["VIEWER_INTERFACE"];
const viewerActions = window["VIEWER_ACTIONS"];

export default {
  name: "PlayPauseClip",
  data() {
    return {
      min: 1,
      max: 90,
      playbackSpeed: 10,
      fps: 40,
      interval: null,
      isPlaying: false,
    };
  },
  methods: {
    playClip() {
      let event = new CustomEvent(viewerActions.playClip, { detail: this.fps });
      viewerInterface.dispatchEvent(event);
    },
    stopClip() {
      let event = new CustomEvent(viewerActions.stopClip);
      viewerInterface.dispatchEvent(event);
    },
    decrement() {
      this.fps--;
    },
    increment() {
      this.fps++;
    },
    toggle() {
      this.isPlaying = !this.isPlaying;
      this.toggleClip();
    },
    toggleClip() {
      if (this.isPlaying) {
        this.playClip();
      } else {
        this.stopClip();
      }
    },
  },
  watch: {
    fps() {
      this.toggleClip();
    },
  },
};
</script>
