<template >
  <v-card width="25%" class="templatesHistoryCard">
    <v-list dense >
      <h3 style="padding: 20px 0px; text-align: center;">Templates History</h3>
      <v-list-item-group color="primary" style="font-size: large; overflow: scroll; height: 50vh;">
        <v-list-item v-for="(item, i) in dataItems" :key="i" >
          <v-list-item style="max-width: 35%;">
            <span v-text="item.name"></span>
          </v-list-item>
          <v-list-item>
            <span v-text="item.date"></span>
          </v-list-item>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>

export default {
  name: "TemplatesHistoryList",
  props: {
    dataItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { };
  },
  computed: {},
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        this.isMobileView =true;
      } else {
        this.isMobileView = false;
      }
    },
  },
  mounted() {},
  created() {}
};
</script>

<style>
.templatesHistoryCard {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 5px rgb(255 255 255 / 12%) !important;
  margin-right: 1% !important;
  overflow: auto !important;
}
</style>