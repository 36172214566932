<template>
  <div class="d-inline-block">
    <ActionsButtons @setAction="setAction" v-if="this.study"/>
    <ChangeStatus v-if="selectedAction === 'invalidate'" :action="actions.invalidate" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'missing-info'" :action="actions.missingInfo" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'park'" :action="actions.park" @resetAction="resetAction"/>
    <ChangeStatus
        v-if="selectedAction === 'mark-for-comparison'"
        :action="actions.markForComparison"
        @resetAction="resetAction"
    />
    <ChangeStatus v-if="selectedAction === 'to-emergency'" :action="actions.toEmergency" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'to-normal'" :action="actions.toNormal" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'release'" :action="actions.release" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'review'" :action="actions.review" @resetAction="resetAction"/>
    <ChangeStatus v-if="selectedAction === 'end-review'" :action="actions.endReview" @resetAction="resetAction"/>
    <ChangeStatus
        v-if="selectedAction === 'finish-no-review'"
        :action="actions.finishNoReview"
        @resetAction="resetAction"
    />
    <ChangeStatus
        v-if="selectedAction === 'ready-for-assign'"
        :action="actions.readyForAssign"
        @resetAction="resetAction"
    />
    <AssignStudy
        v-if="selectedAction === 'assign' || selectedAction === 'recheck'"
        @resetAction="resetAction"
        :selectedAction="selectedAction"
    />
    <ActivityLog v-if="selectedAction === 'activity-log'" @resetAction="resetAction"/>
    <RejectForm v-if="selectedAction === 'reject'" @resetAction="resetAction"/>
    <ClinicalInfo v-if="selectedAction === 'clinical-info'" @resetAction="resetAction"/>
    <ChangeInfo v-if="selectedAction === 'change-info'" @resetAction="resetAction"/>
    <AiResponse v-if="selectedAction === 'ai-response'" @resetAction="resetAction"/>
    <DemergeStudy v-if="selectedAction === 'demerge-study'" @resetAction="resetAction"/>
    <GameBallRadsForm v-model="openDialog" v-if="ShowRadReportsListDialog"/>
    <v-dialog v-model="showChangeViewerDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="px-5">Change the viewer</v-card-title>
        <v-card-text class="px-5">
          Please review <strong>the Study</strong> from <strong>Action List</strong>.
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="() => (this.showChangeViewerDialog = false)" style="margin: auto;">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showIsHTMLDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="px-5">Change the viewer</v-card-title>
        <v-card-text class="px-5">
          To view the report, you should <strong>navigate</strong> to the <strong>Dicom Viewer</strong>.
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="() => (this.showIsHTMLDialog = false)" style="margin: auto;">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChangeStatus from "@/components/StudyActions/ChangeStatus";
import ActionsButtons from "@/components/StudyActions/ActionsButtons";
import AssignStudy from "@/components/StudyActions/AssignStudy";
import ActivityLog from "@/components/StudyActions/ActivityLog";
import RejectForm from "@/components/StudyActions/RejectForm";
import {mapState} from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
import ClinicalInfo from "@/components/ClinicalInfo/ClinicalInfo";
import ChangeInfo from "@/components/StudyActions/ChangeInfo";
import AiResponse from "@/components/AI/AiResponse";
import DemergeStudy from "@/components/DemergeStudy/DemergeStudy";
import GameBallRadsForm from "@/components/StudyActions/GameBallRadSForm.vue";

const actions = {
  invalidate: {
    title: "invalidate study",
    status: "invalid",
    url: "/medicalAdmin/invalidateStudy",
  },
  missingInfo: {
    title: "missing info",
    status: "missing info",
    url: "/medicalAdmin/markStudyAsMissingInfo",
  },
  park: {
    title: "park study",
    status: "park",
    to: "PARKED",
    url: "/admin/study/changeStatus",
  },
  toEmergency: {
    title: "change to emergency",
    status: "emergency",
    url: "/case/study/studyToEmergencyOrFastTrackStatus",
  },
  toNormal: {
    title: "change to normal",
    status: "normal",
    url: "/case/study/studyToEmergencyOrFastTrackStatus",
  },
  release: {
    title: "change to release",
    status: "release",
    to: "SUBMITTED",
    url: "/admin/study/release",
    fromParkedUrl: "/admin/study/changeStatus",
  },
  review: {
    title: "start review",
    status: "review",
    to: "IN_REVIEW",
    url: "/admin/study/changeStatusToInReview",
  },
  endReview: {
    title: "end review",
    status: "finished",
    to: "finished",
    url: apiEndpoints.endReview,
  },
  finishNoReview: {
    title: "end review",
    status: "finish without review",
    url: "/case/study/studyFinishWithoutReview/",
  },
  recheck: {
    title: "Recheck",
    url: "/medicalAdmin/reassignStudy",
  },
  readyForAssign: {
    title: "Ready for assign",
    status: "Ready for assign",
    url: "/admin/study/changeStatus",
    to: "READY_FOR_ASSIGN",
  },
  markForComparison: {
    title: "Mark for comparison",
    status: "comparison",
    to: "COMPARISON",
    url: "/admin/study/changeStatus",
  },
};

export default {
  name: "Actions",
  components: {
    ChangeStatus,
    ActionsButtons,
    AssignStudy,
    ActivityLog,
    RejectForm,
    ClinicalInfo,
    ChangeInfo,
    AiResponse,
    DemergeStudy,
    GameBallRadsForm
  },
  mounted() {
    this.$root.$on("showGameBallRadsForm", () => {
      this.showReportsListConfirmation()
    })

    this.$root.$on("showChangeViewerDialog", () => {
      this.showChangeViewerDialog = true;
    })

    this.$root.$on("showIsHTMLDialog", () => {
      this.showIsHTMLDialog = true;
    })
  },
  data() {
    return {
      actions,
      selectedAction: "",
      ShowRadReportsListDialog: false,
      openDialog: true,
      showChangeViewerDialog: false,
      showIsHTMLDialog: false,
    };
  },
  methods: {
    setAction(action) {
      this.selectedAction = action;
    },
    resetAction() {
      this.selectedAction = "";
    },
    showReportsListConfirmation() {
      this.ShowRadReportsListDialog = true;
    },
  },
  computed: {
    ...mapState(["study"]),
  },
};
</script>
