<template>
  <v-container>
    <header class="pickUpAndSort">
      <v-btn class="text-capitalize mr-3 reloadBtn" v-on:click="fetchStudies">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn class="text-capitalize pickUpBtn" color="success" :disabled="!enablePickUpBTN || this.waitPickUpResult || hasStudyWithMultiplePickup" v-on:click="pickupBTN" v-if="showPickUpBTN">
        <v-icon>mdi-plus-box-outline</v-icon>
        <span>Pick up new study</span>
      </v-btn>
      <LinearProgressTime class="linearProgress" :startDate="startDate" :endDate="blockPickupdate" v-if="showLinearProgress"/>
      <v-spacer class="spacerTag"/>
      <SortBy :options="radSortOptions" />
    </header>
    <RadFilterForm />
    <br/>
    <StudyList :items="studiesList" :loading="loading" />
    <div class="d-flex">
      <v-spacer />
    </div>
  </v-container>
</template>
<script>
import StudyList from "@/components/StudyList/StudyList";
import { mapActions, mapState } from "vuex";
import SortBy from "@/components/SortBy/SortBy";
import { radSortOptions } from "@/util/SortOptions";
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";

const FINISHED_WITHOUT_DATA = "finished_without_data";
const FINISHED_WITH_DATA = "finished_with_data";
const PROCESSING = "Processing";
const FINISHED_FAILED = "finished_failed";
const HAS_STUDY_WITH_MULTIPLE_PICKUP = "has_study_with_multiple_pickup";
// time in milliseconds ( 1 minutes )
const waitingTime = 60000;
// 1 * 60 * 1000 = 60,000
let pageReload ;
let studiesCount ;
let waitPickUpResult = false;

import RadFilterForm from "@/components/radFilterForm/RadFilterForm"
import LinearProgressTime from "@/components/LinearProgressTime/LinearProgressTime.vue"
import axios from "axios";
export default {
  name: "RadiologistStudies",
  components: { SortBy, StudyList, RadFilterForm, LinearProgressTime },
  data() {
    return {
      pickUpBTN: false,
      loading: false,
      radSortOptions,
      pageReload,
      studiesCount,
      waitPickUpResult,
      intervalId: null,
      disablePickupBTN: true,
      showLinearProgress: false,
      blockPickupdate: 0,
      startDate: 0,
      blockPickupDueTime: 0,
      hasStudyWithMultiplePickup: false,
    };
  },
  computed: {
    ...mapState("RadStudies", ["studiesList", "totalItems"]),
    ...mapState(["currentUser"]),
    enablePickUpBTN() {
      if (this.totalItems > 1 || this.disablePickupBTN){
        return false;
      }
      return true;
    },
    showPickUpBTN() {
      return this.pickUpBTN;
    },

  },
  methods: {
    ...mapActions("RadStudies", ["loadStudies"]),
    ...mapActions(["setMessage"]),
    ...mapActions([
      "getCurrentUser",
      "clearCurrentLoadedStudy",
      "clearPatientHistoryList",
    ]),
    ...mapActions("DicomSeries", ["clearLoadedStudies"]),
    async fetchStudies() {
      this.loading = true;
      const queryString = this.$router.options.stringifyQuery(this.$route.query);
      await this.loadStudies(queryString);
      if (this.totalItems === 0) {
        this.hasStudyWithMultiplePickup = false;
      }
      this.loading = false;
    },
    async pickupBTN() {
      this.waitPickUpResult = true;
      this.loading = true;
      clearInterval(this.pageReload);
      this.pageReload = null;
      try {
        let url = `/radiologist/pickupStudies`;
        let encodedUrl = encodeURI(url);
        await API.post(encodedUrl);
          await this.pickupStatus();
      } catch (error) {
        console.log("Error getting pickupStudies");
        this.setMessage({
          message: error.response.data.message
              ? error.response.data.message
              : `Failed to get studies`,
          type: "error",
          timeout: 10000,
        });
      }
      this.loading = false;
      await this.refresh(true);
    },
    async pickupStatus() {
      try {
        let url = `/radiologist/pickupStatus`;
        let encodedUrl = encodeURI(url);
        const response = await API.get(encodedUrl);
        if (response.status == 200) {
          if (response.data.status == FINISHED_WITHOUT_DATA) {
            this.setMessage({
              message: "No available studies now.",
              type: "error",
              timeout: 10000,
            });
          } else if (response.data.status == FINISHED_WITH_DATA) {
            this.setMessage({
              message: "Now you have new studies.",
              type: "info",
              timeout: 10000,
            });
          } else if (response.data.status == PROCESSING) {
            await this.pickupStatus();
          } else if (response.data.status == FINISHED_FAILED) {
            this.setMessage({
              message: "Failed to get studies, please try again later.",
              type: "error",
              timeout: 10000,
            });
          } else if (response.data.status === HAS_STUDY_WITH_MULTIPLE_PICKUP) {
            this.disablePickupBTN = true;
            this.setMessage({
              message: "You need to finish the picked up study first.",
              type: "error",
              timeout: 10000,
            });
          }
        } else if (response.status != 200) {
          console.log("Error getting pickupStudies");
          this.setMessage({
            message: `Failed to get studies`,
            type: "error",
            timeout: 10000,
          });
        }
      } catch (error) {
        console.log("Error getting pickupStudies");
        this.setMessage({
          message: error.response.data.message
              ? error.response.data.message
              : `Failed to get studies`,
          type: "error",
          timeout: 10000,
        });
      }
      this.fetchStudies();
    },
     async getRadPicking() {
      try {
        let url = `/radiologist/getRadPicking/${this.currentUser.id}`;
        let encodedUrl = encodeURI(url);
        const response = await API.get(encodedUrl);
        if (response.status == 200) {
          this.pickUpBTN = response.data.success;
        }
      } catch (error) {
        console.log("Error getting radPicking");
      }
    },
    async refresh(pickupBTNClicked) {
      let self = this;
      if(this.pageReload == null || this.pageReload == undefined){
        this.pageReload = setInterval(async function () {
          await self.fetchStudies();
          if (pickupBTNClicked){
            self.waitPickUpResult = false;
            pickupBTNClicked =false;
            self.studiesCount = self.totalItems;
          }
          if (self.studiesCount < self.totalItems) {
            self.setMessage({
              message: "Now you have new studies.",
              type: "info",
              timeout: 10000,
            });
          }
          self.studiesCount = self.totalItems;
        }, waitingTime);
      }
    },
    convertTimestampToDate(timestamp) {
      const milliseconds = timestamp * 1000;
      const dateObj = new Date(milliseconds);
      return dateObj;
    },
    isDateNowOrBefore(targetDate) {
      const currentDate = new Date();
      return targetDate > currentDate;
    },
    async getBlockPickupDueDate() {
      const getBlockPickupDueDate = `${apiEndpoints.getBlockPickupDueDate}`;
      const res = await axios.get(getBlockPickupDueDate + this.currentUser.id);
      this.blockPickupdate =  this.convertTimestampToDate(res.data);
    },
    async getBlockPickupDueTime() {
      const getBlockPickupDueTime = `${apiEndpoints.getBlockPickupDueTime}`;
      const res = await axios.get(getBlockPickupDueTime);
      this.blockPickupDueTime = res.data.blockPickupDueTime;
    },
    async checkIfRadHasStudyWithMultiplePickup() {
      try {
        let url = `/radiologist/pickupStatus`;
        let encodedUrl = encodeURI(url);
        const response = await API.get(encodedUrl);
        if (response.status === 200 && response.data.status === HAS_STUDY_WITH_MULTIPLE_PICKUP && this.totalItems === 1) {
          return true;
        } else if (response.status !== 200) {
          console.log("Error getting pickupStudies");
        }
      } catch (error) {
        console.log("Error getting pickupStudies");
      }
      return false;
    },
  },
  async mounted() {
    // clear all before fetch studies
    await this.getCurrentUser(apiEndpoints.currentUser);
    this.clearLoadedStudies();
    await this.clearCurrentLoadedStudy();
    await this.clearPatientHistoryList();
    console.log("clear all before fetch studies");
    //if (!this.studiesList.length) {
    await this.fetchStudies();
    this.studiesCount = this.totalItems;
    await this.getBlockPickupDueDate();
    const storedDateStr = localStorage.getItem('rejectDate');
    if(storedDateStr) {
      this.startDate = new Date(storedDateStr);
    }else {
      await this.getBlockPickupDueTime();
      this.startDate = new Date(this.blockPickupdate.getTime() - (this.blockPickupDueTime * 60 * 1000));
    }
    if (this.totalItems <= 1){
      this.intervalId = setInterval( () => {
        if (this.isDateNowOrBefore(this.blockPickupdate)) {
          this.disablePickupBTN = true;
          this.showLinearProgress = true;
          this.pickUpBTN = false;
        } else {
          this.disablePickupBTN = false;
          this.showLinearProgress = false;
          this.pickUpBTN = true;
          localStorage.removeItem('rejectDate');
          clearInterval(this.intervalId);
        }
      }, 2000);
    }else {
      clearInterval(this.intervalId);
    }

    this.hasStudyWithMultiplePickup = await this.checkIfRadHasStudyWithMultiplePickup();

    this.refresh();
    //}
    this.getRadPicking();
  },
  watch: {
    $route() {
      this.fetchStudies();
    },
  },
};
</script>

<style>
.v-select__selections {
  min-height: 32px !important;
}
.v-chip.v-size--default {
  height: 24px !important;
}
.pickUpAndSort {
  display: flex;
}
.v-progress-linear.text-capitalize.linearProgress.theme--dark {
  padding: 0 16px;
  border-radius: 4px;
}
.reloadBtn {
  background-color: #1e1e1e00 !important;
}
@media only screen and (min-width: 1904px){
  .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 14% !important;
  }
}
@media only screen and (min-width: 1264px){
  .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 20%;
  }
}
/* For Desktop View */
@media only screen and (max-width: 1264px) and (min-width: 1024px) {
  .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 25%;
  }
}
/* For Tablet View */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 30%;
  }
}
/* For Mobile Landscape View */
@media screen and (max-width: 768px){
  .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 40%;
  }
}
@media only screen and (max-device-width: 525px) {
  .pickUpAndSort {
    display: contents;
  }
  .pickUpBtn, .v-progress-linear.text-capitalize.linearProgress.theme--dark {
    width: 100%;
    margin-bottom: 5%;
  }
  .spacerTag {
    flex-grow: 0 !important;
  }
}
</style>