<template>
  <v-dialog v-model="dialog" max-width="600" persistent @keydown.esc="cancel">
    <v-card>
      <v-card-title class="mb-2">Reject Case</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-if="isRadiologist"
          label="Choose a reason"
          outlined
          dense
          item-text="value"
          item-value="value"
          :items="reasons"
          v-model="selectedItem"
          id="reasonslist"
        />
        <v-textarea
          v-model="otherReason"
          dense
          auto-grow
          clearable
          outlined
          rows="4"
          label="Please type your reason"
          id="comment"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn
          color="blue-grey darken-4"
          @click="confirm"
          :disabled="
            (!selectedItem && isRadiologist) ||
            (isRadiologist &&
              selectedItem &&
              selectedItem !== reasons[1].value && selectedItem !== reasons[0].value &&
              (otherReason == null || otherReason.trim() === ''))
          "
          id="submitbtn"
        >
          Yes
        </v-btn>
      </v-card-actions>
      <v-progress-linear :active="apiCall" indeterminate absolute bottom color="light-blue darken-4" />
    </v-card>
    <ConfirmDialog
        title="Not my sub-specialty"
        v-if="isNotMySubSpecialitySingleRT"
        @confirm="confirmNotMySubSpecialitySingleRT"
        @cancel="cancelNotMySubSpecialitySingleRT"
    >
      Are you sure you want to reject the case with reason
      <strong style="color: red;">"Not my sub-specialty"</strong> ?
      You will not be able to pickup this request type <strong style="color: red;">
      {{chooseRejectedRequestTypesList !== null ? chooseRejectedRequestTypesList : rejectedRequestTypesList}}
    </strong> again.
    </ConfirmDialog>
    <ConfirmDialog
        title="Not my sub-specialty"
        v-if="isNotMySubSpecialityMultiRT"
        @confirm="confirmNotMySubSpecialityMultiRT"
        @cancel="cancelNotMySubSpecialityMultiRT"
        :disableCondition="chooseRejectedRequestTypesList == null || chooseRejectedRequestTypesList.length == 0"
    >
      Please select which request types are <strong> not your sub specialty </strong>
      <v-autocomplete item-text="name" item-value="value" :items="rejectedRequestTypesList" name="requestType"
                      v-model="chooseRejectedRequestTypesList" outlined item-color="primary" validate-on-blur required
                      :return-object="false" :rules="[textRequestTypesRequiredHandler]"
                      chips deletable-chips multiple>
      </v-autocomplete>
      <strong style="color: red;">Note: </strong>
      You will not be able to pickup this request type again.
    </ConfirmDialog>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import UserTypes from "@/util/UserTypes";
import ClientRoutes from "@/router/ClientRoutes";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";
import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";

const reasons = [
  {
    id: 1,
    value: "Not my sub-specialty",
  },
  {
    id: 2,
    value: "I’m not currently available",
  },
  {
    id: 3,
    value: "I'd like to have a second opinion",
  },
  {
    id: 4,
    value: "Inappropriate technique",
  },
  {
    id: 5,
    value: "Incomplete Study",
  },
  {
    id: 6,
    value: "Other",
  },
];
export default {
  name: "RejectForm",
  components: {ConfirmDialog},
  data() {
    return {
      dialog: true,
      reasons,
      selectedItem: null,
      otherReason: null,
      isNotMySubSpecialitySingleRT: false,
      isNotMySubSpecialityMultiRT: false,
      chooseRejectedRequestTypesList: null,
    };
  },
  methods: {
    ...mapActions("RadStudies", ["loadStudies", "clearStudies"]),
    ...mapActions(["rejectStudy", "toggleStudyVisibility", "setMessage"]),
    cancel() {
      this.resetAction();
    },
    async confirm() {
      // check if the rad chose "Not my sub-specialty"
      if(this.isRadiologist && this.selectedItem === reasons[0].value) {
        // with single request type
        if(this.rejectedRequestTypesList.length === 1) {
          this.isNotMySubSpecialitySingleRT = true;
        }
        // with multi request type
        else if (this.rejectedRequestTypesList.length > 1) {
          this.isNotMySubSpecialityMultiRT = true;
        }
      }
      else {
        await this.confirmRejection();
      }
    },
    async confirmRejection() {
      const { isRadiologist, selectedItem, otherReason } = this;
      let rejectionReason = "";
      let radiologistUrl = "/case/reject";
      let medicalAdminUrl = "/admin/study/reject";
      let url = isRadiologist ? radiologistUrl : medicalAdminUrl;
      if (isRadiologist && selectedItem === "Other") {
        rejectionReason = otherReason ? otherReason : "";
      } else if (isRadiologist && otherReason) {
        rejectionReason = selectedItem + ", " + otherReason;
      } else {
        rejectionReason = selectedItem ? selectedItem : otherReason;
      }
      if (isRadiologist && rejectionReason === "") {
        this.setMessage({ message: `Failed Rejection, Please select reason`, type: "error" });
        return;
      }
      let data = {
        studyId: this.study.id,
        rejectionReason,
        from: this.study.status,
        to: "REJECTED",
      };
      await this.rejectStudy({ url, data });
      if (selectedItem == reasons[1].value) {
        const rejectDate = new Date();
        const rejectDateStr = rejectDate.toISOString();
        localStorage.setItem('rejectDate', rejectDateStr);
      }
      this.resetAction();
      if (isRadiologist) {
        //this.toggleStudyVisibility(false);
        // redirect to my studies page
        this.clearStudies();
        this.$router.push({ name: ClientRoutes.radiologistStudies }).catch(() => {});
      }
    },
    resetAction() {
      this.$emit("resetAction");
    },
    confirmNotMySubSpecialitySingleRT(){
      this.toggleNotMySubSpecialitySingleRT();
      const {currentUser, study, rejectedRequestTypesList, rejectNotMySubSpeciality, chooseRejectedRequestTypesList} = this;
      this.confirmRejection().then(function () {
        let combinedRejectedRequestTypesList = "";
        if(chooseRejectedRequestTypesList === null) {
          for (const requestType of rejectedRequestTypesList) {
            combinedRejectedRequestTypesList += requestType + ",";
          }
        }
        else {
          for (const requestType of chooseRejectedRequestTypesList) {
            combinedRejectedRequestTypesList += requestType + ",";
          }
        }
        let notMySubSpecialityData = {
          radName: currentUser.name,
          studyName: study.patientName,
          studyId: study.studyId,
          modality: study.studyInfo.modality,
          rejectedRequestTypes : combinedRejectedRequestTypesList
        }
         rejectNotMySubSpeciality(notMySubSpecialityData);
      });
      if(this.isNotMySubSpecialityMultiRT) {
        this.toggleNotMySubSpecialityMultiRT();
      }
    },
    cancelNotMySubSpecialitySingleRT() {
      this.toggleNotMySubSpecialitySingleRT();
    },
    toggleNotMySubSpecialitySingleRT() {
      this.isNotMySubSpecialitySingleRT = !this.isNotMySubSpecialitySingleRT;
    },
    confirmNotMySubSpecialityMultiRT(){
      this.toggleNotMySubSpecialitySingleRT();
    },
    cancelNotMySubSpecialityMultiRT() {
      this.chooseRejectedRequestTypesList = null;
      this.toggleNotMySubSpecialityMultiRT();
    },
    toggleNotMySubSpecialityMultiRT() {
      this.isNotMySubSpecialityMultiRT = !this.isNotMySubSpecialityMultiRT;
    },
    textRequestTypesRequiredHandler(item) {
      if (item == undefined) {
        return "Field is required";
      }
      return (item.length) > 0 || "Field is required";
    },
    async rejectNotMySubSpeciality(notMySubSpecialityData) {
      await API.post(apiEndpoints.rejectStudyNotMySubSpeciality, notMySubSpecialityData);
    }
  },
  computed: {
    ...mapState(["study", "apiCall", "currentUser"]),
    ...mapGetters(["requestType"]),
    isRadiologist() {
      return this.currentUser ? this.currentUser.type === UserTypes.radiology : false;
    },
    rejectedRequestTypesList() {
      return this.requestType.split(',');
    }
  },
  created() { }
};
</script>
