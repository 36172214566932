<template>
  <div class="studyRadTags">
    <template>
      <v-container>
        <v-select
          v-model="selectedRadItems"
          :items="tags"
          item-text="name"
          item-value="name"
          label="Select tag"
          multiple
          outlined
          v-on:change="onChange()"
          style="text-transform: capitalize"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import API from "@/shared/API";

export default {
  name: "StudyRadTags",
  data() {
    return {
      dialog: false,
      selectedRadItems: null,
      getTagsUrl: "/admin/systemTagging/getRadTags",
      saveTagsUrl: "/radiologist/studyTagging/editRadTags",
    };
  },
  methods: {
    ...mapActions(["getRadTags", "getStudyById", "setMessage"]),
    async onChange() {
      let studyRadTags = "";
      this.selectedRadItems.forEach(function (val) {
        studyRadTags += (val.name ? val.name : val) + ",";
      });
      if (studyRadTags.length > 1) {
        studyRadTags = studyRadTags.slice(0, studyRadTags.length - 1);
      }
      let data = {
        studyId: this.study.id,
        tags: studyRadTags,
      };
      const response = await API.post(this.saveTagsUrl, data);
      if (response.status === 200) {
        this.setMessage({ message: `Tags on study updated`, type: "success" });
      }
      await this.getStudyById(this.study.id);
    },
  },
  computed: {
    ...mapState(["study", "radTags", "apiCall"]),
    tags() {
      return this.radTags;
    },
    isStudy() {
      return this.study && Object.keys(this.study).length > 0;
    },
    getCheckedTagsURL() {
      return "/radiologist/studyTagging/getTagsByStudyId/" + this.study.id;
    },
  },
  async mounted() {
    const response = await API.get(this.getCheckedTagsURL);
    if (response.status === 200) {
      this.selectedRadItems = response.data ? response.data.split(",") : [];
    }
    let items = [];
    this.selectedRadItems.forEach(function (val) {
      items.push({ name: val, value: val, checked: true });
    });
    this.getRadTags(this.getTagsUrl);
  },
};
</script>
<style scoped>
.v-list-item__title {
  text-transform: capitalize;
}
</style>
