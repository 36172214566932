export default {
  methods: {
    clickOutside(event) {
      let { className } = event.target;
      if (className.search && className.search("overlay") !== -1) {
        this.cancel();
      }
    },
  },
};
