import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";

export const getTemplatesList = () => {
  return API.get(apiEndpoints.getTemplatesList);
};

export const loadTemplate = (data) => {
  return API.post(apiEndpoints.loadTemplate, data);
};

export const deleteTemplate = (templateName) => {
  return API.delete(`${apiEndpoints.deleteTemplate}/${templateName}`);
};

export const saveTemplate = (data) => {
  return API.post(apiEndpoints.saveTemplate, data);
};
