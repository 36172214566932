/**
 * return grayscale image of RGBA image
 * @param src - source RGBA image data array , array will be updated with grayscale image.
 * @param width - width of image.
 * @param height - height of image.
 * @return return array of mono16 that represents points of grayscale image data. updated image is saved in the src buffer
 */
export function getGrayscaleImage(src, width, height) {
  var size = width * height;
  var srcMono16 = new Uint16Array(size);

  for (let i = 0; i < size; i++) {
    srcMono16[i] = (src[4 * i] + src[4 * i + 1] + src[4 * i + 2]) / 3;
    src[4 * i] = src[4 * i + 1] = src[4 * i + 2] = srcMono16[i];
  }

  return srcMono16;
}
