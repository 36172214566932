<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mx-2 mt-sm-n1" color="blue darken-1" dark fab small @click="toggleEdit">
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <span>Edit profile</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "RadEditButton",
  data() {
    return {
      enableEdit: false,
    };
  },
  methods: {
    async toggleEdit() {
      this.$emit("enableEdit", this.enableEdit);
    },
  },
};
</script>