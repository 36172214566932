import * as mutationTypes from "@/store/mutation-types";
import API from "@/shared/API";
import { errorHandler, successHandler } from "@/store/shared";

export const toggleShorthandDialog = ({ commit, state }) => {
  const value = !state.isShorthandsDialogOpen;
  commit(mutationTypes.TOGGLE_SHORTHANDS_DIALOG, value);
};
export const getShorthandList = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.get(url);
    const list = response.data.results.map((item, id) => ({ ...item, id }));
    commit(mutationTypes.GET_SHORTHAND_LIST, list);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting shorthand list", url);
  }
};
export const saveShorthand = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.post(url, data);
    commit(mutationTypes.SET_API_CALL, false);
    successHandler(dispatch, "Shorthand is saved");
  } catch (error) {
    errorHandler(error, dispatch, "Error while saving shorthand", url);
  }
};
export const deleteShorthand = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.delete(url);
    commit(mutationTypes.SET_API_CALL, false);
    successHandler(dispatch, "Shorthand is deleted");
  } catch (error) {
    errorHandler(error, dispatch, "Error while deleting shorthand", url);
  }
};
export const setShorthandQuillContents = ({ commit }, value) => {
  commit(mutationTypes.SET_SHORTHAND_QUILL_CONTENTS, value);
};

export default {
  toggleShorthandDialog,
  getShorthandList,
  saveShorthand,
  deleteShorthand,
  setShorthandQuillContents,
};
