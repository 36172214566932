import API from "@/shared/API";
import * as mutationTypes from "@/store/mutation-types";
import { errorHandler, successHandler } from "@/store/shared";

export const getStudyReportsList = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.get(url);
    response.data
      .map((report) => {
        report.displayName = report.name.replaceAll('_', ' ');
        return { report };
      })
    commit(mutationTypes.GET_STUDY_REPORT_LIST, response.data);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting study report:", url);
  }
};

export const getStudyReport = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.post(url, data);
    delete response.data.success;
    response.data.requestType = response.data.requestType.split(',');
    response.data.reportId = data.reportId;
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.GET_STUDY_REPORT, response.data);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting report info:", url);
  }
};

export const getStudyReportForCheck = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.post(url, data);
    delete response.data.success;
    response.data.requestType = response.data.requestType.split(',');
    response.data.reportId = data.reportId; 
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.GET_STUDY_REPORT_FOR_CHECK, response.data);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting report info:", url);
  }
};

export const deleteStudyReport = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  // TODO: API should returned new study reports list
  try {
    await API.post(url, data);
    successHandler(dispatch, "Report was deleted");
    commit(mutationTypes.SET_API_CALL, false);
    return true;
  } catch (error) {
    errorHandler(error, dispatch, "Error deleting report:", url);
    return false;
  }
};

export const saveStudyReport = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  // TODO: API should returned new study reports list
  try {
    const response = await API.post(url, data);
    const { newReportId } = response.data;
    successHandler(dispatch, "Report was saved");
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.NEW_REPORT_ID, newReportId);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, "Error saving report:", url);
    return false;
  }
};

export const removeReportById = ({ commit }, id) => {
  commit(mutationTypes.REMOVE_REPORT_BY_ID, id);
};

export const toggleReport = ({ commit, state }) => {
  const value = !state.isReportDialogOpen;
  commit(mutationTypes.TOGGLE_REPORT_DIALOG, value);
};

export const finishStudy = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.post(url, data);
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.FINISH_STUDY_REPORT, response.data.success);
    return true;
  } catch (error) {
    errorHandler(error, dispatch, "Error finishing study report", url);
  }
  return false;
};

export default {
  getStudyReport,
  getStudyReportsList,
  getStudyReportForCheck,
  deleteStudyReport,
  saveStudyReport,
  removeReportById,
  toggleReport,
  finishStudy,
};
