<template>
  <v-navigation-drawer
    id="HistoryDrawer"
    class="resizable-drawer"
    :app="drawer.isPinned"
    hide-overlay
    :style="style"
    absolute
    :value="drawer.visible"
    :width="width"
    :right="drawer.right"
    ref="currentSidebar"
  >
    <v-card tile flat height="100%" @mouseleave="mouseLeave" class="scrollable-card">
      <span class="sidebar-handler" :class="{ right: drawer.right }" @mousedown="mouseDownHandler($event)" />
      <v-card-title>
        <span :class="getOldStudiesColor()" class="subtitle-1 font-weight-bold old-studies-title">Old Studies</span>
        <v-spacer />
        <v-btn icon class="d-none d-md-flex">
          <v-icon @click="pin">{{ drawer.isPinned ? "mdi-pin" : "mdi-pin-off" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <PatientHistory />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { HISTORY_DRAWER } from "@/shared/Constants";
import SidebarMixins from "@/components/SideBars/SidebarMixins";
import PatientHistory from "@/components/StudyTree/PatientHistory";
import {mapState} from "vuex";

export default {
  name: HISTORY_DRAWER,
  components: { PatientHistory },
  mixins: [SidebarMixins],
  computed :{
    ...mapState(["patientHistoryList", "study"]),
    oldStudies() {
      return this.patientHistoryList.filter(({ studyId }) => studyId !== this.study.studyId);
    },
  },
  data() {
    return {
      componentName: HISTORY_DRAWER,
    };
  },
  methods: {
    getOldStudiesColor() {
      return this.oldStudies.length === 0 ? 'primary--text' : 'red--text';
    }
  }
};
</script>
