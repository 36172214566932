/* eslint-disable */
function next4Edges(edge, width, height) {
  const x = edge[0];
  const y = edge[1];
  const edges = [
    [x + 1, y],
    [x - 1, y],
    [x, y + 1],
    [x, y - 1],
  ];

  let result = [];
  edges.forEach((edge) => {
    if(checkBounds(edge,width,height)) {
      result.push(edge);
    }
  })
  return result;
}

function next8Edges(edge, width, height) {
  const x = edge[0];
  const y = edge[1];
  const edges = [
    [x-1, y-1],
    [x, y-1],
    [x+1, y-1],

    [x-1, y],
    [x, y],
    [x+1, y],

    [x-1, y+1],
    [x, y+1],
    [x+1, y+1],
  ];

  let result = [];
  edges.forEach((edge) => {
    if(checkBounds(edge,width,height)) {
      result.push(edge);
    }
  })
  return result;
}

export function next24Edges(edge, width, height) {
  const x = edge[0];
  const y = edge[1];
  const edges = [
    [x-2, y-2],
    [x-1, y-2],
    [x, y-2],
    [x+1, y-2],
    [x+2, y-2],

    [x-2, y-1],
    [x-1, y-1],
    [x, y-1],
    [x+1, y-1],
    [x+2, y-1],

    [x-2, y],
    [x-1, y],
    [x+1, y],
    [x+2, y],

    [x-2, y+1],
    [x-1, y+1],
    [x, y+1],
    [x+1, y+1],
    [x+2, y+1],

    [x-2, y+2],
    [x-1, y+2],
    [x, y+2],
    [x+1, y+2],
    [x+2, y+2],
  ];

  let result = [];
  edges.forEach((edge) => {
    if(checkBounds(edge,width,height)) {
      result.push(edge);
    }
  })
  return result;
}
function checkBounds(edge,width,height){
  const x = edge[0];
  const y = edge[1];
  return (edge[0] <= width && edge[0] >= 0) && (edge[1] <= height && edge[1] >= 0);
}
function getPointEdgesGrayscaleAvg(inputData, width, height, point, numOfEdges = 8) {
  let pointEdges = undefined;

  if(numOfEdges == 24)
    pointEdges = next24Edges(point, width, height);
  else
    pointEdges = next8Edges(point, width, height);

  let grayscalAvg = getGrayScale(point, width, inputData);
  pointEdges.forEach((edge) => {
    grayscalAvg += getGrayScale(edge, width, inputData);
  });
  grayscalAvg /= pointEdges.length + 1;

  return grayscalAvg;
}
function getGrayScale(point, width, inputData) {
  var pointIdx = point[1] * width + point[0];
  var garyscal = inputData[pointIdx];

  return garyscal;
}


/**
 * apply grow region filter to grayscal image
 * @param src - source grayscale image data array - mono16.
 * @param width - width of image.
 * @param height - height of image.
 * @param seed - array of 2 points [x,y], seed pixel point of the region.
 * @param threshold - threshold delta to be used in the grow region filter.
 * @return return array of {x,y} pixel points that represents the region.
 */
export function growRegion(src, width, height, seed, threshold) {
  var resultRegionPoints = [];

  if (!seed) {
    return null;
  }

  seed = seed.map(Math.round);

  var inputData = src;

  var seedGrayscaleAvg = getPointEdgesGrayscaleAvg(inputData, width, height, seed, 24);

  var edge = [seed];
  var visited = inputData.slice(0).fill(1);
  while (edge.length) {
    var newedge = [];
    for (var i = 0, ii = edge.length; i < ii; i++) {
      var next = next4Edges(edge[i], width, height);
      for (var j = 0, jj = next.length; j < jj; j++) {
        var s = next[j][0];
        var t = next[j][1];
        if (s >= 0 && s < width && t >= 0 && t < height) {
          var ci = t * width + s;

          //var cc = inputData[ci];
          var grayscalAvg = getPointEdgesGrayscaleAvg(inputData, width, height,[s, t]);
          // if point visited before, carry on
          if (visited[ci] == 0) {
            continue;
          }
          //if (Math.abs(seedGrayscaleAvg - cc) < threshold) {
          if (Math.abs(seedGrayscaleAvg - grayscalAvg) < threshold) {
            resultRegionPoints.push({ x: s, y: t });
            newedge.push([s, t]);
          }
          // mark as visited
          visited[ci] = 0;
        }
      }
    }
    edge = newedge;
  }
  return resultRegionPoints;
}
