import Vue from "vue";
import Vuetify from "vuetify/lib";
import { RologyDark } from "./themes";
import VueExpandableImage from "vue-expandable-image";

window.themes = {
  dark: RologyDark,
};
Vue.use(Vuetify);
Vue.use(VueExpandableImage);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      ...window.themes,
    },
  },
});
