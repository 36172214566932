<template>
  <v-expansion-panel @click="loadStudy" v-if="study">
    <v-expansion-panel-header>
      {{ study.studyInfo.modality }} - {{ study.studyInfo.requestType }} - {{ study.studyDate }}
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <br />
      <v-card tile flat class="mb-1 d-flex justify-space-between">
        <v-btn small class="medical-history action-button" @click="expandHistory = !expandHistory">
          History
          <v-icon size="18">mdi-information-variant</v-icon>
        </v-btn>
        <v-btn small class="action-button" v-if="reportUrl" @click="loadReport" target="_blank">
          Report
          <v-icon size="18" class="ml-1">mdi-cloud-download</v-icon>
        </v-btn>
        <v-btn small class="action-button" v-if="studyUrl" link :href="studyUrl" target="_blank">
          Study
          <v-icon size="18" class="ml-1">mdi-cloud-download</v-icon>
        </v-btn>
      </v-card>
      <v-expand-transition>
        <MedicalHistory v-show="expandHistory" :study="study" hide-title remove-padding />
      </v-expand-transition>
      <div class="thumbnail-vue thumbnails" :ref="study.studyId" :id="`study-series-id-${study.studyId}`" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import MedicalHistory from "@/components/MedicalHistory/MedicalHistory";
import { mapState } from "vuex";
import UserTypes from "@/util/UserTypes";
import { isURL } from "validator";
import API from "@/shared/API";
import StudyStatus from "@/util/StudyStatus";

export default {
  name: "StudyItem",
  components: { MedicalHistory },
  computed: {
    ...mapState(["currentUser"]),
    isRadiology() {
      return this.currentUser ? this.currentUser.type === UserTypes.radiology : false;
    },
  },
  data() {
    return {
      studyLoaded: false,
      expandHistory: false,
      reportUrl: null,
      studyUrl: null,
      reportWindow: undefined,
    };
  },
  props: {
    study: {
      type: Object,
      default: null,
    },
  },
  methods: {
    loadStudy() {
      if (!this.studyLoaded) {
        const seriesWrapper = this.$refs[this.study.studyId];
        window["RADIOLOGY_VIEWER_SCRIPT"](this.study.studyId, seriesWrapper, null, false, null, true);
        this.studyLoaded = true;
      }
    },
    loadReport() {
      if (this.reportWindow && !this.reportWindow.closed) {
        this.reportWindow.focus();
      } else {
        this.reportWindow = window.open("/portal/study-report/" + this.study.studyId + "/true", "_blank", "width=1400");
      }
    },
  },
  async created() {
    if (
      this.study.report !== "N/A" &&
      (this.study.status === StudyStatus.finished || this.study.status === StudyStatus.accepted)
    ) {
      const reportUrl = `/report/download/${this.study.studyId}`;
      try {
        const downloadReportResponse = await API.get(reportUrl);
        if (isURL(downloadReportResponse.data)) {
          this.reportUrl = downloadReportResponse.data;
        }
      } catch (error) {
        console.log(`Error getting report download link: ${reportUrl}`, error);
      }
    }

    const studyUrl = `/case/download/${this.study.studyId}`;
    try {
      const downloadStudyResponse = await API.post(studyUrl, { trialNum: 0 });
      if (downloadStudyResponse.data.success && isURL(downloadStudyResponse.data.url)) {
        this.studyUrl = downloadStudyResponse.data.url;
      }
    } catch (error) {
      console.log(`Error getting study download link: ${studyUrl}`, error);
    }
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  padding: 0 8px !important;
  text-transform: capitalize;
}
</style>
