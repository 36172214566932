<template>
  <v-card tile flat>
    <v-list v-if="!enableEdit" two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon size="40">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold primary--text">{{ comment.author }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">{{ comment.text }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="comment.edited != null" class="text-sm-caption mt-2">
            Edited {{ getCommentDate(comment.edited) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text class="text-sm-caption">{{ getCommentDate(comment.posted) }}</v-list-item-action-text>
          <v-btn
            class="white--text"
            x-small
            dark
            color="#0d71bb"
            @click="toggleEdit"
            v-if="comment.text != 'Comment deleted' && comment.author != 'System' && isSameMedicalAdmin"
          >
            Edit
            <v-icon right x-small>mdi-pencil</v-icon>
          </v-btn>
          <div v-else></div>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-row v-else>
      <v-col cols="12">
        <v-list-item-content>
          <CommentForm
            :save="false"
            @edit="edit"
            @delete="deleteComment"
            :comment="comment"
            @close="toggleEdit"
          ></CommentForm>
        </v-list-item-content>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-card>
</template>
<script>
import CommentForm from "@/components/StudyComments/CommentForm.vue";
import { mapState } from "vuex";

export default {
  name: "CommentListItem",
  data() {
    return {
      enableEdit: false
    };
  },
  props: {
    comment: null,
  },
  components: { CommentForm },
  methods: {
    toggleEdit() {
      this.enableEdit = !this.enableEdit;
    },
    edit() {
      this.$emit("edit", this.comment, this.comment.text);
    },
    deleteComment() {
      this.$emit("delete", this.comment);
    },
    getCommentDate(timestampStr) {
      var date = new Date(Number(timestampStr)*1000);
      return date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    isSameMedicalAdmin() {
      return this.currentUser.name === this.comment.author;
    },
  }
};
</script>
