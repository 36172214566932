<template>
  <v-app>
    <AppHeader />
    <v-main :style="{ margin: `0 ${marginStyle}px`, overflow: 'auto' }">
      <router-view />
    </v-main>
    <Dialogs />
    <Messages :styles="{ top: 0, left: 'calc( 50% - 200px )', width: '400px' }" />
  </v-app>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { storageAvailable } from "@/util/localStorageUtils";
import ClientRoutes from "@/router/ClientRoutes";
import Messages from "@/components/Messages/Messages";
import Dialogs from "@/components/Dialogs/Dialogs";
import AppHeader from "@/components/AppHeader/AppHeader";

const viewerInterface = window["VIEWER_INTERFACE"];
const viewerActions = window["VIEWER_ACTIONS"];
const evenAbortController = new AbortController();

export default {
  name: "Home",
  components: { AppHeader, Messages, Dialogs },
  computed: {
    ...mapState("LocalStorage", ["isLocalStorageAvailable"]),
    ...mapState("Layout", ["dockBarWidth"]),
    ...mapState(["study", "currentUser", "canUserViewStudy"]),
    isViewer() {
      return this.$route.name === ClientRoutes.studyViewer;
    },
    marginStyle() {
      return this.isViewer ? this.dockBarWidth : 0;
    },
  },
  methods: {
    ...mapActions("LocalStorage", ["setLocalStorageFlag", "triggerStorageActions"]),
    ...mapActions("DicomSeries", ["saveLoadedStudy"]),
    subscribeLocalStorageEvents() {
      if (storageAvailable("localStorage")) {
        this.setLocalStorageFlag(true);
        window.addEventListener("storage", this.triggerStorageActions);
      }
    },
    subscribeViewerEvents() {
      const { studyLoaded } = viewerActions;
      viewerInterface.addEventListener(studyLoaded, ({ detail }) => {
        this.saveLoadedStudy(detail.id);
      }, {signal: evenAbortController.signal});
    },
  },
  mounted() {
    this.subscribeLocalStorageEvents();
    this.subscribeViewerEvents();
  },
  destroyed() {
    evenAbortController.abort();
  }
};
</script>
