<template>
  <div>
  <v-menu offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="py-1 px-2 layout-button" height="auto" text tile :input-value="active"
             v-bind="attrs"
             v-on="on"
             :id="`auto-measure-menu`">
        <v-icon size="20">mdi-diameter-outline</v-icon>
        <div class="caption text-capitalize">Auto Measure</div>
        <v-progress-linear
            indeterminate
            color="cyan"
            v-if="detecting"/>
      </v-btn>
    </template>
      <v-list min-width="150" class="grey darken-3 rounded-0">
        <v-list-item class="pa-0 rounded-0"  dense>
          <v-btn
            ref="nearChestBtn"
            block
            text
            tile
            class="justify-start rounded-0"
            :id="`auto-measure-near-chest`"
            :input-value="buttonSelected($refs.nearChestBtn)"
            @click="handleAutoMeasurementClick($event,22,0)"
          >
            <div class="d-flex justify-center align-content-center">
              <Icon icon="mdi:lungs" width="14" />
              <span class="caption text-capitalize sub-item-text">Near Chest Wall</span>
            </div>
          </v-btn>
        </v-list-item>
        <v-list-item class="pa-0 rounded-0"  dense>
          <v-btn
            ref="centralChestBtn"
            block
            text
            tile
            class="justify-start rounded-0"
            :id="`auto-measure-central-chest`"
            :input-value="buttonSelected($refs.centralChestBtn)"
            @click="handleAutoMeasurementClick($event,46,1)"
          >
            <div class="d-flex justify-center align-content-center">
              <Icon icon="mdi:heart-pulse" width="14" />
              <span class="caption text-capitalize sub-item-text">Central Chest</span>
            </div>
          </v-btn>
        </v-list-item>
        <v-list-item class="pa-0 rounded-0"  dense>
          <v-btn
              ref="softTissueBtn"
              block
              text
              tile
              class="justify-start rounded-0"
              :id="`auto-measure-softTissue-nodule`"
              :input-value="buttonSelected($refs.softTissueBtn)"
              @click="handleAutoMeasurementClick($event,9,0)"
          >
            <div class="d-flex justify-center align-content-center">
              <Icon icon="mdi:stomach" width="14" />
              <span class="caption text-capitalize sub-item-text">Soft Tissues Nodule</span>
            </div>
          </v-btn>
        </v-list-item>
        <v-list-item class="pa-0 rounded-0"  dense>
          <v-btn
            ref="kidneyStoneBtn"
            block
            text
            tile
            class="justify-start rounded-0"
            :id="`auto-measure-kidney-stone`"
            :input-value="buttonSelected($refs.kidneyStoneBtn)"
            @click="handleAutoMeasurementClick($event,75,1)"
          >
            <div class="d-flex justify-center align-content-center">
              <Icon icon="healthicons:kidneys" width="14" />
              <span class="caption text-capitalize sub-item-text">Kidney Stone</span>
            </div>
          </v-btn>
        </v-list-item>
      </v-list>
  </v-menu>
    <div>
      <div v-if="debugTools" class="debugTool">
        <div class="float-right">
          <v-subheader class="pl-0">Threshold</v-subheader>
          <v-slider v-model="selectedThreshold" :thumb-size="20" thumb-label="always" />
          <v-subheader class="pl-0">Blur Factor</v-subheader>
          <v-slider v-model="selectedBlurFactor" max="10" :thumb-size="20" thumb-label="always" />
          <v-text-field v-model="selectedWW" label="WW" disabled />
          <v-text-field v-model="selectedWL" label="WL" disabled />
        </div>
        <div class="float-left">
          <v-checkbox v-model="ShowHighlightedArea" :label="`Show Highlighted Area: ${ShowHighlightedArea.toString()}`" @change="showHideCanvases"/>
          <v-checkbox v-model="ShowBlurredImage" :label="`Show Blurred Image: ${ShowBlurredImage.toString()}`" @change="showHideCanvases" />
          <v-checkbox v-model="ShowGrayscaleImage" :label="`Show Grayscale Image: ${ShowGrayscaleImage.toString()}`" @change="showHideCanvases" />
        </div>
      </div>
      <div v-if="debugTools" class="debugCanvasTop">
        <v-subheader class="pl-0">Debug Canvas</v-subheader>
        <div>
          <canvas id="canvasOrignalImg" class="canvasImg"></canvas>
          <canvas id="canvasGrayscalImg" class="canvasDebugImg"></canvas>
          <canvas id="canvasBluredImg" class="canvasDebugImg"></canvas>
          <canvas id="canvasHighlightImg" class="canvasDebugImg"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLungNoduleInfo } from "./LNAutoDetection/lungNodule";
import { getGrayscaleImage } from "@/components/AutoMeasure/LNAutoDetection/grayscale";
import { blurMono16 } from "@/components/AutoMeasure/LNAutoDetection/gaussianBlurGrayscaleMono16";
import { Icon } from '@iconify/vue2';

let viewerInterface;
let viewerActions;

export default {
  name: "AutoMeasure",
  components: {
    Icon
  },
  beforeCreate() {
    viewerInterface = window["VIEWER_INTERFACE"];
    viewerActions = window["VIEWER_ACTIONS"];
  },
  data() {
    return {
      active: false,
      activatedElementId: undefined,
      detecting: false,
      debugTools: false,
      selectedThreshold: 45,
      selectedBlurFactor: 1,
      selectedWW: 0,
      selectedWL: 0,
      ShowHighlightedArea: true,
      ShowBlurredImage: true,
      ShowGrayscaleImage: true,
    };
  },
  methods: {
    buttonSelected(btn){
      if(this.activatedElementId != undefined && btn != undefined){
       return btn.$el.id === this.activatedElementId;
      }else{
        return false;
      }
    },
    activate(activatedElementTargetId) {
      if(activatedElementTargetId === this.activatedElementId) {
        if (!this.active) {
          this.active = true;
          this.enableTool();
        } else {
          this.active = false;
          this.disableTool();
          this.activatedElementId = undefined;
        }
      } else {
        this.active = true;
        this.enableTool();
        this.activatedElementId = activatedElementTargetId;
      }
    },
    clickHandler(event) {
      if (event.detail.event.button === 2 && event.detail.event.shiftKey) {
        this.debugTools = !this.debugTools;
      } else if (event.detail.event.button === 0 && event.detail.event.shiftKey) {
        const { image, currentPoints } = event.detail;
        this.detectLungNodule(event.target, image, [currentPoints.image.x, currentPoints.image.y]);
      }
    },
    enableTool() {
      // disable other tool
      const event = new CustomEvent(viewerActions.disableCornerStoneTools);
      viewerInterface.dispatchEvent(event);
      // register click listener on canvas
      document.querySelectorAll(".viewport.ui-droppable").forEach((viewport) => {
        viewport.addEventListener(window.cornerstoneTools.EVENTS.MOUSE_CLICK, this.clickHandler);
      });

      //apply WW / WL
      this.setViewportVOI();
    },
    disableTool() {
      document.querySelectorAll(".viewport.ui-droppable").forEach((canvas) => {
        canvas.removeEventListener(window.cornerstoneTools.EVENTS.MOUSE_CLICK, this.clickHandler);
      });
    },
    handleAutoMeasurementClick(event, threshold, blur, WW = undefined, WL = undefined) {
      //set auto measurement parameters
      this.selectedThreshold = threshold;
      this.selectedBlurFactor = blur;
      this.selectedWW = WW;
      this.selectedWL = WL;

      //activate the tool
      this.activate(event.currentTarget.id);
    },
    detectLungNodule(element, { width, height, ...image }, seed) {
      //this.detecting = !this.detecting;

      //convert cornerstone image data to canvas image data
      const imageData = this.getImageDataFromCornerStone(element, image);
      getLungNoduleInfo(
        imageData,
        width,
        height,
        seed,
        parseInt(this.selectedThreshold),
        parseInt(this.selectedBlurFactor)
      ).then((info) => {
        this.drawLungNoduleMeasurment(element, info);

        if(this.debugTools) {
          const newSize = this.calculateNewDebugImageSize(imageData);
          this.getResizedImageData(imageData, newSize.width, newSize.height).then((resizedImageData) => {
            this.readyDebugCanvas(newSize.width, newSize.height);
            this.drawDebugCanvases(resizedImageData, newSize.width, newSize.height);
            this.drawNoduleDebugInfo(info, width, height);
          });
        }
      });

      //this.detecting = !this.detecting;
    },
    setViewportVOI() {
      if(this.selectedWL != undefined && this.selectedWW != undefined) {
        if (window.focusElement) {
          const element = window.focusElement;
          const vp = window.cornerstone.getViewport(element);
          vp.voi.windowCenter = this.selectedWL;
          vp.voi.windowWidth = this.selectedWW;

          //set view port changes
          window.cornerstone.setViewport(element, vp);
        } else {
          document.querySelectorAll(".viewport.ui-droppable").forEach((element) => {
            const vp = window.cornerstone.getViewport(element);
            vp.voi.windowCenter = this.selectedWL;
            vp.voi.windowWidth = this.selectedWW;

            //set view port changes
            window.cornerstone.setViewport(element, vp);
          });
        }
      }
    },
    drawLungNoduleMeasurment(element, lungNoduleInfo) {
      //draw vertical line
      this.drawLungNoduleLine(
        element,
        lungNoduleInfo.noduleDimensionPoints.verticalDimension.upPoint,
        lungNoduleInfo.noduleDimensionPoints.verticalDimension.downPoint,
        {
          color: "orange",
          x: lungNoduleInfo.noduleDimensionPoints.verticalDimension.upPoint.x,
          y: lungNoduleInfo.noduleDimensionPoints.verticalDimension.upPoint.y - 5,
        }
      );
      //draw horizontal line
      this.drawLungNoduleLine(
        element,
        lungNoduleInfo.noduleDimensionPoints.horizontalDimension.rightPoint,
        lungNoduleInfo.noduleDimensionPoints.horizontalDimension.leftPoint,
        {
          color: "blue",
          x: lungNoduleInfo.noduleDimensionPoints.horizontalDimension.rightPoint.x + 5,
          y: lungNoduleInfo.noduleDimensionPoints.horizontalDimension.rightPoint.y,
        }
      );
    },
    drawLungNoduleLine(element, point1, point2, textProps) {
      //1 - create new measurment data (Length tool)
      const measurementData = {
        visible: true,
        active: true,
        color: textProps.color,
        handles: {
          start: {
            x: point1.x,
            y: point1.y,
            highlight: false,
            active: false,
          },
          end: {
            x: point2.x,
            y: point2.y,
            highlight: false,
            active: false,
          },
          textBox: {
            x: textProps.x,
            y: textProps.y,
            active: false,
            hasMoved: true,
            movesIndependently: false,
            drawnIndependently: true,
            allowedOutsideImage: true,
            hasBoundingBox: true,
          },
        },
      };

      //todo set tool interactive
      //window.cornerstoneTools.setToolActiveForElement(element,'Length')

      //2 - Associate this data with the imageId so we can render it and manipulate it
      window.cornerstoneTools.addToolState(element, "Length", measurementData);
      const image = window.cornerstone.getImage(element);
      const tool = window.cornerstoneTools.getToolForElement(element, "Length");
      tool.updateCachedStats(image, element, measurementData);

      //3 - update corner stone
      window.cornerstone.updateImage(element);
    },
    getImageDataFromCornerStone(element, image, displayDebug = false) {
      let tempCanvas = document.createElement("canvas");
      tempCanvas.width = image.columns;
      tempCanvas.height = image.rows;
      tempCanvas.id = "tempCanvas";
      tempCanvas.style.position = "absolute";
      tempCanvas.style.top = "50px";
      tempCanvas.style.left = "50px";
      tempCanvas.style.zIndex = 1000;
      if (displayDebug) document.body.appendChild(tempCanvas);

      const ctx = tempCanvas.getContext("2d");
      const canvasImageData = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);

      const renderingConvas = window.cornerstone.getEnabledElement(element).renderingTools.renderCanvas;
      const renderingCanvasCTX = renderingConvas.getContext("2d");
      const elementImageData = renderingCanvasCTX.getImageData(0, 0, tempCanvas.width, tempCanvas.height).data; //image.getPixelData();
      for (let index = 0; index < elementImageData.length; index += 4) {
        //const idx = index ;//* 4;
        canvasImageData.data[index] = elementImageData[index + 3]; //elementImageData[index];
        canvasImageData.data[index + 1] = elementImageData[index + 3]; //elementImageData[index+1];
        canvasImageData.data[index + 2] = elementImageData[index + 3]; //elementImageData[index+2];
        canvasImageData.data[index + 3] = 255; //elementImageData[index+3];
      }

      ctx.putImageData(canvasImageData, 0, 0);
      return ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
    },
    showHideCanvases() {
      const canvasGrayscalImg = document.getElementById("canvasGrayscalImg");
      const canvasBluredImg = document.getElementById("canvasBluredImg");
      const canvasHighlightImg = document.getElementById("canvasHighlightImg");

      if (!this.ShowHighlightedArea) {
        canvasHighlightImg.setAttribute("hidden", "true");
      } else {
        canvasHighlightImg.removeAttribute("hidden");
      }

      if (!this.ShowBlurredImage) {
        canvasBluredImg.setAttribute("hidden", "true");
      } else {
        canvasBluredImg.removeAttribute("hidden");
      }

      if (!this.canvasGrayscalImg) {
        canvasGrayscalImg.setAttribute("hidden", "true");
      } else {
        canvasGrayscalImg.removeAttribute("hidden");
      }
    },
    /**
     * Make Debug Canvases Ready for drawing Debug Info
     * @param width - width of image.
     * @param height - height of image.
     * @return return nothing
     */
    readyDebugCanvas(width, height) {
      const canvasOrignalImg = document.getElementById("canvasOrignalImg");
      const canvasGrayscalImg = document.getElementById("canvasGrayscalImg");
      const canvasBluredImg = document.getElementById("canvasBluredImg");
      const canvasHighlightImg = document.getElementById("canvasHighlightImg");

      //set dimestions
      canvasOrignalImg.width = width;
      canvasOrignalImg.height = height;
      canvasGrayscalImg.width = width;
      canvasGrayscalImg.height = height;
      canvasBluredImg.width = width;
      canvasBluredImg.height = height;
      canvasHighlightImg.width = width;
      canvasHighlightImg.height = height;
    },
    /**
     * Perform Draw on Debug Canvases
     * @param imageData - source image data structure . https://developer.mozilla.org/en-US/docs/Web/API/ImageData
     * @param width - width of image.
     * @param height - height of image.
     * @return return nothing
     */
    drawDebugCanvases(imageData){
      const canvasOrignalImg = document.getElementById("canvasOrignalImg");
      const canvasGrayscalImg = document.getElementById("canvasGrayscalImg");
      const canvasBluredImg = document.getElementById("canvasBluredImg");
      //const canvasHighlightImg = document.getElementById("canvasHighlightImg");

      //draw original image
      var canvasOriginalImgctx = canvasOrignalImg.getContext("2d");
      canvasOriginalImgctx.putImageData(imageData, 0, 0);

      //draw grayscale image
      const imageDataTemp = canvasOriginalImgctx.getImageData(0, 0, canvasOrignalImg.width, canvasOrignalImg.height);
      const grayscaleImageData = getGrayscaleImage(imageDataTemp.data, imageDataTemp.width, imageDataTemp.height);
      const canvasGrayscalImgctx = canvasGrayscalImg.getContext("2d");
      canvasGrayscalImgctx.putImageData(imageDataTemp, 0, 0);

      //draw blurred image
      if (parseInt(this.selectedBlurFactor) > 0) {
        blurMono16(grayscaleImageData, imageDataTemp.width, imageDataTemp.height, parseInt(this.selectedBlurFactor));
      }
      for (let i = 0; i < grayscaleImageData.length; i++) {
        imageDataTemp.data[4 * i] =
          imageDataTemp.data[4 * i + 1] =
          imageDataTemp.data[4 * i + 2] =
            grayscaleImageData[i];
      }
      const canvasBluredImgctx = canvasBluredImg.getContext("2d");
      canvasBluredImgctx.putImageData(imageDataTemp, 0, 0);

      this.showHideCanvases();
    },
    drawNoduleDebugInfo(noduleInfo, oldWidth, oldHeight) {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = oldWidth;
      tempCanvas.height = oldHeight;

      const ctx = tempCanvas.getContext("2d");
      const resultImage = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);

      noduleInfo.growenRegionIndexs.forEach((pixel) => {
        const index = (pixel.y * resultImage.width + pixel.x) * 4;
        resultImage.data[index] = 255; //R
        resultImage.data[index + 1] = 0; //G
        resultImage.data[index + 2] = 0; //B
        resultImage.data[index + 3] = 255; //A
      });

      createImageBitmap(resultImage).then((imgBitmap) => {
        ctx.drawImage(imgBitmap, 0, 0);
        const highlightedImage = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);

        const canvasHighlightImg = document.getElementById("canvasHighlightImg");
        const highlightCtx = canvasHighlightImg.getContext("2d");
        this.getResizedImageData(highlightedImage, canvasHighlightImg.width, canvasHighlightImg.height).then(
          (resizedImageData) => {
            highlightCtx.putImageData(resizedImageData, 0, 0);
          }
        );
      });
    },
    calculateNewDebugImageSize(imageData) {
      let width = imageData.width;
      let height = imageData.height;

      if (width != 512 || height != 512) {
        width = Math.abs((width * 512) / height);
        height = 512;
      }

      return {
        width,
        height,
      };
    },
    async getResizedImageData(imageData, newWidth, newHeight) {
      let tempCanvas = document.createElement("canvas");
      tempCanvas.width = imageData.width;
      tempCanvas.height = imageData.height;

      const ctx = tempCanvas.getContext("2d");
      ctx.putImageData(imageData, 0, 0);

      // clear & scale the canvas
      // draw the image to the canvas
      let p = new Promise((resolve) => {
        const imageObject = new Image();
        imageObject.onload = function () {
          tempCanvas.width = newWidth;
          tempCanvas.height = newHeight;
          const ctx2 = tempCanvas.getContext("2d");

          ctx2.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
          ctx2.scale(newWidth / imageData.width, newHeight / imageData.height);
          ctx2.drawImage(imageObject, 0, 0);
          resolve();
        };
        imageObject.src = tempCanvas.toDataURL();
      });

      await p;
      return ctx.getImageData(0, 0, newWidth, newHeight);
    },
  },
};
</script>
<style lang="scss">
.debugTool {
  position: absolute;
  bottom: 30px;
  right: 0px;
  z-index: 1000;
  background-color: black;
  width: 600px;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
  width: 300px;
  padding-right: 10px;
}
.debugCanvasBottom {
  position: absolute;
  bottom: 36px;
  right: 300px;
  z-index: 1000;
  background-color: black;
  width: 1000px;
}
.debugCanvasTop {
  position: absolute;
  bottom: 370px;
  right: 0px;
  z-index: 1000;
  background-color: black;
  width: 800px;
}
.canvasImg {
  cursor: crosshair;
  padding-left: 8px;
}
.canvasDebugImg {
  position: absolute;
  pointer-events: none;
  left: 8px;
}
</style>
