<template>
  <v-btn class="text-capitalize" :class="htmlClass" style="background-color: white; color: black;" @click="toggleAboutUsForm">
    <v-icon left>mdi-information-outline</v-icon>
    <span>About Us</span>
  </v-btn>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "AboutUsButton",
  methods: {
    ...mapActions(["toggleAboutUsForm"]),
  },
  props: {
    htmlClass: {
      type: String,
      default: "",
    },
  },
};
</script>
