<template>
  <v-dialog v-model="dialog" persistent max-width="400" :attach="attach" :hide-overlay="hideOverlay">
    <v-card>
      <v-card-title class="px-5">{{ title }}</v-card-title>
      <v-card-text class="px-5">
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="confirm" :disabled="disableCondition">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    attach: {
      type: [Boolean, String],
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    disableCondition: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
