<template>
  <div class="messages-overlay" :style="styles">
    <Message v-for="(item, i) in snackbarMessages" :key="item.id" :message="item" :index="i" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Message from "@/components/Messages/Message";

export default {
  name: "Messages",
  components: { Message },
  computed: {
    ...mapState(["snackbarMessages"]),
  },
  props: {
    styles: {
      type: Object,
      default: () => ({
        bottom: 0,
        right: 0,
        width: "300px",
      }),
    },
  },
};
</script>

<style lang="scss">
.messages-overlay {
  position: fixed;
  z-index: 205; // higher than v-dialog which is 202
}
</style>
