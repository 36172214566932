import * as mutationTypes from "@/store/mutation-types";
import { errorHandler } from "@/store/shared";
import API from "@/shared/API";

const getRadiologists = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.get(url);
    const data = response.data.results || [];
    const list = data.sort(function (a, b) {
      if (b.status === a.status) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }
      return b.status - a.status;
    });
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.GET_RADIOLOGISTS, list);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting radiologists list", url);
  }
};
const getCurrentUser = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.get(url);
    commit(mutationTypes.SET_API_CALL, false);
    commit(mutationTypes.GET_CURRENT_USER, response.data);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting current user", url);
  }
};

export default {
  getRadiologists,
  getCurrentUser,
};
