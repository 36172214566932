<template>
  <div
    class="dock-bar left-dock"
    :style="{ left: `-${width}px`, ...style }"
    @drop="dropHandler"
    @dragover="dragoverHandler($event)"
    v-if="!right"
  >
    <v-btn
      class="dock-button rounded-0"
      :style="{ width: `${width}px` }"
      block
      v-for="item in leftItems"
      :key="item.name"
      small
      depressed
      :input-value="item.visible && !item.external"
      draggable="true"
      @dragstart="dragHandler(item, 'left-dock', $event)"
      @click="toggleSidebar(item)"
      :disabled="disableReport && item.name === REPORT_DRAWER"
    >
    <span :class=getOldStudiesColor(item.name)>
      <v-icon :size="item.iconSize" style="margin-bottom: 8px">{{ item.icon }}</v-icon>
       {{ item.title }}
    </span>
    </v-btn>
  </div>
  <div
    class="dock-bar right-dock"
    :style="{ right: `-${width}px`, ...style }"
    @drop="dropHandler"
    @dragover="dragoverHandler($event)"
    v-else
  >
    <v-btn
      class="dock-button rounded-0"
      :style="{ width: `${width}px` }"
      block
      v-for="item in rightItems"
      :key="item.name"
      small
      depressed
      :input-value="item.visible && !item.external"
      draggable="true"
      @dragstart="dragHandler(item, 'right-dock', $event)"
      @click="toggleSidebar(item)"
      :disabled="disableReport && item.name === REPORT_DRAWER"
    >
    <span :class=getOldStudiesColor(item.name)>
      <v-icon :size="item.iconSize" style="margin-top: 8px">{{ item.icon }}</v-icon>
      {{ item.title }}
    </span>
    </v-btn>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import UserTypes from "@/util/UserTypes";
import StudyStatus from "@/util/StudyStatus";
import {REPORT_DRAWER, MALICIOUS_CUTS_DRAWER, HISTORY_DRAWER} from "@/shared/Constants";
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";

export default {
  name: "DockBar",
  data() {
    return {
      REPORT_DRAWER,
      isHTML: false,
    };
  },
  props:{
    right: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["studyId", "studyStatus"]),
    ...mapState(["patientHistoryList", "study"]),
    ...mapState({
      drawers: (state, { showAiResponse }) => {
        return state.Layout.drawers.filter((item) => {
          return item.name === MALICIOUS_CUTS_DRAWER ? showAiResponse : true;
        });
      },
      oldStudies() {
        return this.patientHistoryList.filter(({ studyId }) => studyId !== this.study.studyId);
      },
    }),
    leftItems() {
      return this.drawers.filter((item) => !item.right);
    },
    rightItems() {
      return this.drawers.filter((item) => item.right);
    },
    style() {
      return { width: `${this.width}px`, zIndex: 10 };
    },
    disableReport() {
      const { studyStatus, currentUser } = this;
      const { medicalAdmin, admin, radiology } = UserTypes;
      if (!currentUser) {
        return true;
      } else if(this.isHTML && (currentUser.type === admin || currentUser.type === medicalAdmin)){
        return true;
      } else if (
        (currentUser.type === admin || currentUser.type === medicalAdmin) &&
        studyStatus === StudyStatus.inReview
      ) {
        return false;
      } else if (
        currentUser.type === radiology &&
        (studyStatus === StudyStatus.diagnosing || studyStatus === StudyStatus.assigned)
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("Layout", ["toggleSidebarVisibility", "moveSidebar", "popOutSidebar"]),
    toggleSidebar(item) {
      if (item.external === undefined || !item.external) {
        this.toggleSidebarVisibility(item);
      } else {
        this.popOutSidebar(item);
      }
    },
    dragoverHandler(event) {
      event.preventDefault();
    },
    dragHandler(item, from, event) {
      const data = JSON.stringify({ item, from });
      event.dataTransfer.setData("droppableItemName", data);
    },
    dropHandler(event) {
      const targetClass = "dock-bar";
      const { classList } = event.target;
      const { item, from } = JSON.parse(event.dataTransfer.getData("droppableItemName"));
      if (!classList.contains(from) && classList.contains(targetClass)) {
        this.moveSidebar(item);
      }
    },
    getOldStudiesColor(item) {
      return (item === HISTORY_DRAWER && this.oldStudies.length !== 0) ? 'red--text' : '';
    },
    async getReportHasHTML() {
      const { medicalAdmin, admin } = UserTypes;
      let report = await API.get(apiEndpoints.getReportsForMa + this.studyId);
      this.isHTML = report.data[0].reportData.isHTML;
      if(this.isHTML && (this.currentUser.type === admin || this.currentUser.type === medicalAdmin) && this.studyStatus === StudyStatus.inReview) {
        this.$root.$emit("showIsHTMLDialog");
      }
    },
  },
  async mounted() {
    if(this.right){
      await this.getReportHasHTML()
    }
  },
};
</script>

<style lang="scss">
.dock-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;

  &.left-dock {
    .dock-button {
      span {
        transform: rotate(180deg);
      }
    }
  }

  .dock-button {
    height: auto !important;
    margin: 0;
    padding: 12px 0 !important;

    span {
      text-transform: capitalize;
      writing-mode: vertical-lr;
    }
  }
}
</style>
