export default {
  patientName: "",
  referredBy: "",
  examination: "",
  technique: "",
  comparison: "",
  findings: "",
  impression: "",
  requestType: "",
};
