<template>
  <v-hover v-slot:default="{ hover }">
    <v-card tile flat width="250" height="150" style="display: inline-block" class="mx-2">
      <expandable-image height="250" close-on-background-click :src="item.imgUrl"></expandable-image>
      <v-fade-transition>
        <v-overlay v-if="hover" class="parent" style="height: 193%" absolute>
          <v-tooltip top v-if="!isHistoricalStudy">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" color="white" icon small v-bind="attrs" v-on="on">
                <v-icon @click="removeFile(index)" aria-label="delete">mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip top v-if="!isHistoricalStudy">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" color="white" icon small v-bind="attrs" v-on="on">
                <v-file-input
                  prepend-icon="mdi-file-replace"
                  accept="image/*"
                  multiple
                  hide-input
                  v-model="file"
                  @change="updateImage(index)"
                ></v-file-input>
              </v-btn>
            </template>
            <span>Replace</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" color="white" icon small v-bind="attrs" v-on="on" @click="downloadImage(index)">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
        </v-overlay>
      </v-fade-transition>
    </v-card>
  </v-hover>
</template>
<script>
import { mapGetters } from "vuex";
import API from "@/shared/API";

export default {
  name: "ImageThumbnail.vue",
  props: {
    item: null,
    index: {
      type: Number,
      default: 0,
    },
    isHistoricalStudy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    ...mapGetters(["studyInfo", "studyId"]),
  },
  methods: {
    removeFile(index) {
      this.$emit("remove", index);
    },
    updateImage(index) {
      const studyId = this.studyId;
      this.$emit("update", index, studyId, this.file);
    },
    async downloadImage() {
      const url = this.item.imgUrl;
      const name = this.item.name;
      API({
        url: url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", name);
        document.body.appendChild(fURL);

        fURL.click();
      });
    },
  },
};
</script>
<style>
.parent {
  pointer-events: none;
}

.parent .v-btn {
  pointer-events: auto;
}

.mdi-file-replace::before {
  position: relative;
  bottom: -12px;
  top: -8px;
  right: -4px;
}

.v-overlay__content {
  position: relative;
  bottom: 5px;
}

body > .expandable-image.expanded > img {
  margin: auto;
  height: 600px;
}
</style>
