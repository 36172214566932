<template>
  <v-list-item-content>
    <v-row v-if="radProfileInfo !== null" class="text-center">
      <v-col>
        <v-list-item-title class="text-h6">
          {{ radProfileInfo.firstName }} {{ radProfileInfo.lastName }}
          <v-list-item-subtitle>{{ radProfileInfo.email }}</v-list-item-subtitle>
        </v-list-item-title>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-btn class="mx-2" color="blue darken-3" fab :href="radProfileInfo.facebook" link small target="_blank">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn class="mx-2" color="cyan darken-2" fab :href="radProfileInfo.twitter" link small target="_blank">
          <v-icon color="white">mdi-twitter</v-icon>
        </v-btn>
        <v-btn class="mx-2" color="blue darken-2" fab :href="radProfileInfo.linkedin" link small target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center" dense>
      <v-col>
        <v-list-item-subtitle class="pt-10 text-wrap font-weight-thin"
          >{{ radProfileInfo.radBio }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="pa-5">{{ radProfileInfo.signatureText }}</v-list-item-subtitle>
      </v-col>
    </v-row>
  </v-list-item-content>
</template>
<script>
export default {
  name: "RadProfileInfo",
  props: {
    radProfileInfo: {},
  },
};
</script>