<template>
  <v-dialog v-model="dialog" width="980" persistent>
    <v-card>
      <v-card-title class="headline"> Assign Study to </v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col>
            <v-combobox
              label="Choose a radiologist"
              outlined
              dense
              item-text="name"
              item-value="value"
              :items="radiologists"
              v-model="selectedItem"
            >
              <template slot="item" slot-scope="data">
                <v-badge dot inline left v-if="data.item.status === '-1'" color="blue-grey"></v-badge>
                <v-badge dot inline left v-if="data.item.status === '1'" color="green"></v-badge>
                <v-badge dot inline left v-if="data.item.status === '0'" color="yellow"></v-badge>
                <span>{{ data.item.name }} ({{ data.item.assigned }}/{{ data.item.diagnosing }})</span>
              </template>
            </v-combobox>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-card color="primary" dark>
                  <v-card-title class="justify-center"><span class="font-weight-bold">assigned</span></v-card-title>
                  <v-card-text v-if="selectedItem != null" class="text-h6 font-weight-bold text-center">
                    {{ selectedItem.assigned }}
                  </v-card-text>
                  <v-card-text v-else class="text-h6 font-weight-bold text-center">0</v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="red" dark>
                  <v-card-title class="justify-center"><span class="font-weight-bold">diagnosing</span></v-card-title>
                  <v-card-text v-if="selectedItem != null" class="text-h6 font-weight-bold text-center">
                    {{ selectedItem.diagnosing }}
                  </v-card-text>
                  <v-card-text v-else class="text-h6 font-weight-bold text-center">0</v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="green" dark>
                  <v-card-title class="justify-center"><span class="font-weight-bold">finished</span></v-card-title>
                  <v-card-text v-if="selectedItem != null" class="text-h6 font-weight-bold text-center">
                    {{ selectedItem.finished }}
                  </v-card-text>
                  <v-card-text v-else class="text-h6 font-weight-bold text-center">0</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col md="6">
          <v-textarea
              dense
              auto-grow
              clearable
              outlined
              rows="3"
              label="Please type your reason"
              v-model="reassignReason"
          >
          </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="confirm" :disabled="!selectedItem">Yes</v-btn>
      </v-card-actions>
      <v-progress-linear :active="apiCall" indeterminate absolute bottom color="light-blue darken-4" />
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";

export default {
  name: "AssignStudy",
  data() {
    return {
      dialog: true,
      selectedItem: null,
      url: "/admin/radiologist/list",
      assignUrl: "/admin/study/assign",
      recheckUrl: "/medicalAdmin/reassignStudy",
      reassignReason : ""
    };
  },
  props: {
    selectedAction: {
      type: String,
      default: null,
    },
  },
  created() {
    let caseData = {
      "organizationIds": this.studyInfo().organizationId
    }
    if (!this.radiologists.length) {
      this.getRadiologists(this.url + "?data=" + encodeURIComponent(JSON.stringify(caseData)) );
    }
  },
  computed: {
    ...mapState(["radiologists", "apiCall", "study", "currentUser", "patientHistoryList"]),
    oldStudies() {
      return this.patientHistoryList.filter(({ studyId }) => studyId !== this.study.studyId);
    },
  },
  methods: {
    ...mapGetters(["studyInfo"]),
    ...mapActions(["getRadiologists", "assignStudy", "getStudyById"]),
    ...mapActions("RadStudies", ["clearStudies"]),
    cancel() {
      this.resetAction();
    },
    async confirm() {
      let data = {
        studyId: this.study.id,
        radiologistId: this.selectedItem.value,
        from: this.study.status,
        to: "ASSIGNED",
        reason: this.reassignReason ? this.reassignReason : ""
      };
      const url = this.selectedAction === "recheck" ? this.recheckUrl : this.assignUrl;
      await this.assignStudy({ url, data });
      await this.getStudyById(this.study.id);
      this.resetAction();
      this.clearReports();
      this.clearReportsLocalStorage(data.studyId);
    },
    resetAction() {
      this.$emit("resetAction");
    },
    clearReports() {
      this.clearStudies();
    },
    clearReportsLocalStorage(studyId) { 
      Object.keys(localStorage).forEach(element => { 
        if(element.includes("STUDY_REPORT_")) 
          if(localStorage.getItem(element).includes(studyId))
             localStorage.removeItem(element);
      });

      this.oldStudies.forEach((item) => {
        localStorage.removeItem(`STUDY_REPORT_NUMBER_${item.studyId}`);
        for (const key in localStorage) {
          if (key.includes("STUDY_REPORT_")) {
            const value = JSON.parse(localStorage.getItem(key));
            if (value.studyId === item.studyId) {
              localStorage.removeItem(key);
            }
          }
        }
      });

      localStorage.removeItem(`STUDY_REPORT_NUMBER_${studyId}`);
      localStorage.removeItem(`STUDY_ATTACHMENT_${studyId}`);
    },
  },
};
</script>
