export default {
  getStudy: "/viewer/getStudy/",
  currentUser: "/user/getLoggedInUser",
  shorthandList: "/radiologist/shorthands/getShortHandList",
  saveShorthand: "/radiologist/shorthands/saveChanges",
  deleteShorthand: "/radiologist/shorthands/deleteShortHand/",
  getReportsForMa: "/study/reportsForMA/",
  getReports: "/study/reports/",
  getHistoricalReports: "/study/history/reports/",
  getStudyReport: "/report/getReportInfo",
  deleteStudyReport: "/reports/remove",
  saveReport: "/reports/saveReport",
  getTemplatesList: "/report/getTemplateList",
  loadTemplate: "/report/getTemplate",
  deleteTemplate: "/report/deleteTemplate",
  saveTemplate: "/report/saveChanges",
  getRequestTypesList: "/lookups/requestTypes",
  getRequestTypesListSubs: "/lookups/requestTypesWithSubs",
  finishStudy: "/radiologist/finishStudy",
  uploadReportImages: "/report/upload/uploadImage/",
  getReportImage: "/report/getImagesFromS3/",
  removeReportImage: "/report/upload/removeImage/",
  downloadStudy: "/case/download/",
  endReview: "/study/endReview",
  patientHistory: "/viewer/getMergedStudiesByRID/",
  saveClinicalInfo: "/medicalAdmin/editPatientClinicalInfo",
  getClinicalInfo: "/studies/info/",
  getAttachments: "/attachment/upload/getAttachments/",
  uploadAttachments: "/attachment/upload/uploadAttachment/",
  deleteAttachments: "/attachment/upload/deleteAttachments",
  changeRequestType: "/admin/study/changeRequestType",
  saveHumanResponseForAI: "/medicalAdmin/saveHumanResponseForAi",
  saveComment: "/medicalAdmin/comments/addComment",
  getComment: "/medicalAdmin/comments/getComments/",
  editComment: "/medicalAdmin/comments/updateComment",
  deleteComment: "/medicalAdmin/comments/deleteComment",
  generateMPR: "/viewer/mpr/generateMPR/",
  generateImageUrl: "/report/generateImage/",
  updateImage: "/report/upload/updateImage/",
  getMaliciousCuts: "/ai/getMaliciousCuts/",
  getQurePredictionStatus: "/ai/getQurePredictionStatus/",
  getQurePredictionObject: "/ai/getQurePredictionObject/",
  getRadStudies: "/api/rad/studies",
  getModalitiesList: "/lookups/modalityList",
  getPickupModalitiesList: "/lookups/pickupModalityList",
  getRadProfileInfo: "/profile/rad/getRadProfileInfo",
  getRadProfilePic: "/profile/rad/getRadProfilePic",
  editProfile: "/profile/rad/editProfile",
  changePassword: "/profile/changePassword",
  startStudyDiagnosing: "/radiologist/study/startDiagnosing",
  authenticateCognitoUser: "/login/authenticateCognitoUser",
  getAppVersion: "/login/getAppVersion",
  logUserInfoDetails: "/user/logUserInfoDetails",
  getCognitoProperties: "/login/getCognitoProperties",
  getPerformanceListForShow: "/lookups/getPerformanceListForShow",
  setRadPickupConfigs: "/admin/config/setRadPickupConfigs",
  getRadPickupConfigs: "/admin/config/getRadPickupConfigs",
  getRadSignatureImage: "/profile/rad/getRadSignatureImage",
  getTemplatePickupConfigs: "/admin/config/getTemplatePickupConfig",
  setTemplatePickupConfigs: "/admin/config/setTemplatePickupConfig",
  deleteTemplatePickupConfig: "/admin/config/deleteTemplatePickupConfig",
  getPickupTemplates: "/admin/config/getTemplatesPickup",
  setRadTemplatesHistory: "/admin/setRadTemplatesHistory",
  getRadTemplatesHistory: "/admin/getRadTemplatesHistory",
  changeStudyStatusFromDiagnosingToAssigned: "/radiologist/changeStudyStatusFromDiagnosingToAssigned",
  rejectStudyNotMySubSpeciality: "/radiologist/rejectStudyNotMySubSpeciality",
  logout: "/login/logout",
  getProbabilityOfReviewResponseList: "/lookups/ProbabilityOfReviewResponse",
  saveHumanResponseForProbabilityOfReview: "/medicalAdmin/saveHumanResponseForProbabilityOfReview",
  getGameBallApiKey: "/admin/config/getGameBallApiKey/",
  getRadiologistsNames: "/admin/study/getRadsAssignedToStudyId/",
  saveRadReportsList: "/admin/study/addRadsToRadReportsList/",
  getUserGameBallSetting: "/user/getUserGameBallSetting/",
  saveRadResponseForAIFeedback: "/radiologist/saveRadResponseForAIFeedback",
  getBlockPickupDueDate: "/user/getBlockPickupDueDate/",
  getBlockPickupDueTime: "/radiologist/getBlockPickupDueTime",
  sendUserName: "/sendUserName/",
  isRadDiagnosingStudy: "/radiologist/isRadDiagnosingStudy/",
  getTagsUrl: "/admin/systemTagging/getTags",
  saveTagsUrl: "/medicalAdmin/studyTagging/editTags",
  getRadIdleTimeInMin: "/rad/config/getRadIdleTimeInMin",
  getRadProfileSettings: "/admin/getRadProfileSettings/",
  setRadPickupSettings: "/admin/setRadPickupSettings/",
};
