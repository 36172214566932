<template>
  <v-menu offset-y rounded="0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="py-1 px-2 layout-button" height="auto" text tile v-bind="attrs" v-on="on" retain-focus-on-click>
        <v-icon size="20">mdi-view-grid-plus</v-icon>
        <div class="caption text-capitalize">Layout</div>
      </v-btn>
    </template>
    <v-list class="rounded-0 pa-0">
      <v-list-item class="pa-0 rounded-0">
        <div class="layout-picker" v-click-outside="clearSelection">
          <div class="layout-rows" v-for="(r, rowNumber) in rows" :key="rowNumber">
            <div
              class="layout-col"
              v-for="(col, colNumber) in r"
              :key="colNumber"
              :ref="col"
              @mouseenter="mouseEnterHandler(rowNumber, colNumber)"
              @click="pickLayout(rowNumber, colNumber)"
            />
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LayoutPicker",
  data() {
    return {
      rows: [],
      rowsCounter: 5,
      columnsCounter: 5,
      activeClass: "active",
    };
  },
  methods: {
    mouseEnterHandler(rowNumber, colNumber) {
      const { rowsCounter, columnsCounter, activeClass } = this;
      for (let i = 0; i < rowsCounter; i++) {
        if (i <= rowNumber) {
          for (let x = 0; x < columnsCounter; x++) {
            const item = `col-${i}-${x}`;
            if (x <= colNumber) {
              this.$refs[item][0].classList.add(activeClass);
            } else {
              this.$refs[item][0].classList.remove(activeClass);
            }
          }
        } else {
          for (let x = 0; x < columnsCounter; x++) {
            const item = `col-${i}-${x}`;
            this.$refs[item][0].classList.remove(activeClass);
          }
        }
      }
    },
    pickLayout(x, y) {
      const layout = `${x + 1}x${y + 1}`;
      this.$emit("setLayout", layout);
      this.clearSelection();
    },
    clearSelection() {
      setTimeout(() => {
        for (const item in this.$refs) {
          this.$refs[item][0].classList.remove(this.activeClass);
        }
      }, 250);
    },
  },
  created() {
    const { rowsCounter, columnsCounter } = this;
    for (let i = 0; i < rowsCounter; i++) {
      const columns = [];
      for (let x = 0; x < columnsCounter; x++) {
        columns.push(`col-${i}-${x}`);
      }
      this.rows.push(columns);
    }
  },
};
</script>

<style scoped lang="scss">
.layout-rows {
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  padding: 1px;

  .layout-col {
    height: 20px;
    flex: 0 0 20%;
    background: rgba(61, 61, 61, 0.5);
    outline: rgb(61, 61, 61) solid 1px;

    &.active {
      background: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
