import { API_ERROR, SET_MESSAGE } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";

export const errorHandler = (error, dispatch, message, url = "") => {
  console.error(message, url, error);
  dispatch(API_ERROR, { message, type: "error" }, { root: true });
  dispatch(mutationTypes.SET_API_CALL, false);
};

export const successHandler = (dispatch, message) => {
  dispatch(SET_MESSAGE, { message, type: "success" }, { root: true });
};
