export const StudyReport = {
  addReport: false,
  content: { findings: "", impression: "" },
  findings: "",
  impression: "",
  critical: false,
  header: { technique: "", examination: "", referralDoctor: "", comparison: "" },
  comparison: "",
  examination: "",
  referralDoctor: "",
  technique: "",
  modality: "",
  reportId: "",
  requestType: "",
  studyId: "",
};

export const ClinicalInfo = {
  modality: "",
  requestType: "",
  emergency: "No",
  contrast: "No",
  complaint: "",
  operation: "No",
  operationDetails: "",
  medication: "No",
  medicationDetails: "",
  referralDoctor: "",
  attachments: null,
  organizationId: "",
  patientPhoneNumber: "",
  countryCP:"",
  onlineReport:false,
};

export const TemplateInterface = {
  technique: "",
  findings: "",
  impression: "",
  templateName: "",
};

export const RadFilterFormInterface = {
  patientId: "",
  patientName: "",
  modality: "",
  requestType: "",
};

export const RadSettingsFormInterface = {
  radId: "",
  radName: "",
  modality: "",
  requestType: "",
  subSpeciality:"",
  performanceLevel: "",
};

export const PickupModality ={
  pickupModality: "",
  modalities:[],
  modality_SubSpecialties:[],
};
/*export const Modality_SubSpecialties ={
  modality: "",
  subSpecialty_RequestTypes:[],
};
export const SubSpecialty_RequestTypes ={
  subSpecialty: "",
  requestTypes:[],
};*/

export const ModalitySubSpecialties ={
  modality: "",
  subSpecialties:[],
};
export const RadRequestTypes ={
  radId: "",
  requestTypes:[],
};

export const ProbabilityOfReviewResponse = {
  reviewReasons: "",
  extraReasons: ""
};
