import state from "./InitialState";
import {
  TOGGLE_SIDEBAR_VISIBILITY,
  MOVE_SIDEBAR,
  TOGGLE_VIEWER_LOADING_STATE,
  VIEWER_BAR_HEIGHT,
  PIN_SIDEBAR,
  HIDE_UNPINNED_SIDEBARS,
  SET_SIDEBAR_WIDTH,
  POP_IN_OUT_SIDEBAR,
} from "./constants";
import { localStorageConstant } from "@/util/localStorageUtils";
import { REPORT_DRAWER } from "@/shared/Constants";

const { APP_LAYOUT } = localStorageConstant;
let reportWindow = null;

window.onbeforeunload = () => {
  if (reportWindow) {
    reportWindow.close();
  }
};

export default {
  namespaced: true,
  state,
  actions: {
    toggleSidebarVisibility({ commit, getters, dispatch }, currentItem) {
      const { name, visible, isPinned } = currentItem;
      const activeItems = currentItem.right ? getters.visibleRightItems : getters.visibleLeftItems;
      // hide the previous unpinned item to have only one at a time unpinned alongside current pinned item
      if (!isPinned) {
        const itemToHide = activeItems.filter((item) => item.name !== name && !item.isPinned);
        if (itemToHide.length) {
          commit(TOGGLE_SIDEBAR_VISIBILITY, itemToHide[0]);
        }
      }
      if (visible && isPinned && activeItems.length > 1) {
        // hide the current unpinned sidebar to focus on the pinned one
        const itemToHide = activeItems.filter((item) => item.visible && !item.isPinned);
        if (itemToHide.length) {
          commit(TOGGLE_SIDEBAR_VISIBILITY, itemToHide[0]);
        }
      } else {
        commit(TOGGLE_SIDEBAR_VISIBILITY, currentItem);
      }
      dispatch("saveLayout");
    },
    moveSidebar({ commit, dispatch }, item) {
      const { visible, right } = item;
      commit(MOVE_SIDEBAR, item);
      if (visible) {
        // unpin the rest items in opposite side
        commit(PIN_SIDEBAR, { ...item, right: !right });
      }
      dispatch("saveLayout");
    },
    toggleViewerLoadingState({ commit }, value) {
      commit(TOGGLE_VIEWER_LOADING_STATE, value);
    },
    setViewerBarHeight({ commit, dispatch }, height) {
      commit(VIEWER_BAR_HEIGHT, height);
      dispatch("saveLayout");
    },
    togglePin({ commit, dispatch }, item) {
      const itemToToggle = { ...item };
      commit(PIN_SIDEBAR, itemToToggle);
      dispatch("saveLayout");
    },
    hideUnpinSidebars({ commit }) {
      commit(HIDE_UNPINNED_SIDEBARS);
    },
    setSidebarWidth({ commit, dispatch }, item) {
      commit(SET_SIDEBAR_WIDTH, item);
      dispatch("saveLayout");
    },
    saveLayout({ state }) {
      window.localStorage.setItem(APP_LAYOUT, JSON.stringify(state));
    },
    popOutSidebar({ commit, dispatch }, drawer) {
      if (!drawer.external || !reportWindow || reportWindow.closed) {
        const url = window.location.href.replace("study-path", "study-report") + "/false";
        reportWindow = window.open(url, "_blank", "width=1400");
      } else {
        reportWindow.focus();
      }
      if (!drawer.external) {
        commit(POP_IN_OUT_SIDEBAR, drawer);
        dispatch("saveLayout");
      }
    },
    popInSidebar({ state, dispatch, commit, rootGetters }) {
      const { localStorage } = window;
      const { POP_IN_REPORT } = localStorageConstant;
      const { studyId } = rootGetters;
      const popInReport = JSON.parse(localStorage.getItem(`${POP_IN_REPORT}_${studyId}`));
      if (popInReport) {
        localStorage.removeItem(`${POP_IN_REPORT}_${studyId}`);
        if (reportWindow) {
          reportWindow.close();
          const reportDrawer = state.drawers.find(({ name }) => name === REPORT_DRAWER);
          commit(POP_IN_OUT_SIDEBAR, { ...reportDrawer });
          commit(PIN_SIDEBAR, { ...reportDrawer, isPinned: false });
          dispatch("saveLayout");
        }
      }
    },
    toggleReportWindow({ dispatch, rootGetters }) {
      const { localStorage } = window;
      const { closeReportWindow } = localStorageConstant;
      const { studyId } = rootGetters;
      const closeReportWindowId = JSON.parse(localStorage.getItem(`${closeReportWindow}_${studyId}`));
      if (closeReportWindowId) {
        localStorage.removeItem(`${closeReportWindow}_${studyId}`);
        if (reportWindow) {
          reportWindow.close();
        }
        dispatch("endStudy", null, { root: true });
      }
    },
    closeReportWindow() {
      if (reportWindow) {
        reportWindow.close();
      }
    },
  },
  mutations: {
    [TOGGLE_VIEWER_LOADING_STATE](state, value) {
      state.isViewerLoaded = value;
    },
    [VIEWER_BAR_HEIGHT](state, value) {
      state.viewerBarHeight = value;
    },
    [TOGGLE_SIDEBAR_VISIBILITY](state, currentItem) {
      state.drawers = state.drawers.map((item) => {
        if (item.right === currentItem.right) {
          // toggling items on the same side
          if (item.name === currentItem.name) {
            item.visible = !item.visible;
          }
        }
        return item;
      });
    },
    [PIN_SIDEBAR](state, currentItem) {
      state.drawers = state.drawers.map((item) => {
        if (item.right === currentItem.right) {
          // toggling items on the same side
          if (currentItem.isPinned) {
            // unpin the pinned item
            item.isPinned = !item.isPinned;
          } else {
            // pin new item
            if (item.name === currentItem.name) {
              item.isPinned = true;
            } else {
              // unpin the rest
              item.isPinned = false;
              item.visible = false;
            }
          }
        }
        return item;
      });
    },
    [MOVE_SIDEBAR](state, currentItem) {
      state.drawers = state.drawers.map((item) => {
        if (item.name === currentItem.name) {
          item.right = !item.right;
        }
        return item;
      });
    },
    [HIDE_UNPINNED_SIDEBARS]() {
      state.drawers = state.drawers.map((item) => {
        if (!item.isPinned) {
          item.visible = false;
        }
        return item;
      });
    },
    [SET_SIDEBAR_WIDTH](state, value) {
      state.drawers = state.drawers.map((item) => {
        if (item.name === value.name) {
          item = value;
        }
        return item;
      });
    },
    [POP_IN_OUT_SIDEBAR](state, value) {
      state.drawers = state.drawers.map((item) => {
        if (item.name === value.name) {
          item.external = !item.external;
        }
        return item;
      });
    },
  },
  getters: {
    visibleRightItems({ drawers }) {
      return drawers.filter(({ right, visible }) => right && visible);
    },
    visibleLeftItems({ drawers }) {
      return drawers.filter(({ right, visible }) => !right && visible);
    },
  },
};
