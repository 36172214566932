<template>
  <v-dialog v-model="dialog" max-width="1200" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card>
      <h2 class="logs-heading">
        Logs on
        <strong style="text-transform: capitalize" class="blue--text text--darken-1">
          {{ study.modality }}
        </strong>
        of
        <strong style="text-transform: capitalize" class="red--text text--lighten-1">
          {{ study.patientName.toLocaleLowerCase() }}
        </strong>
      </h2>
      <v-card-text>
        <v-data-table
          class="activity-log-table"
          :items="logs"
          :headers="headers"
          :items-per-page="10"
          :loading="apiCall"
          loading-text="loading"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dialogMixins from "@/shared/dialog-mixins";

export default {
  name: "ActivityLog",
  mixins: [dialogMixins],
  data() {
    return {
      dialog: true,
      url: "/medicalAdmin/ActivityLog/getActivityLog/",
    };
  },
  methods: {
    ...mapActions(["getStudyLogs"]),
    cancel() {
      this.resetAction();
    },
    resetAction() {
      this.$emit("resetAction");
    },
  },
  computed: {
    ...mapState(["study", "studyLogs", "apiCall"]),
    headers() {
      let { modality, patientName } = this.study;
      return [{ text: `Logs on ${modality} of ${patientName.toLocaleLowerCase()}`, value: "log" }];
    },
    logs() {
      return this.studyLogs.map((item) => ({ log: item }));
    },
  },
  mounted() {
    let url = this.url + this.study.id;
    this.getStudyLogs(url);
  },
};
</script>

<style lang="scss">
.logs-heading {
  padding: 15px 15px 15px 30px;
}

.activity-log-table {
  thead {
    display: none !important;
  }
}
</style>
