<template>
  <div id="templatePicker" style="position: relative">
    <v-text-field
      v-model="filter"
      outlined
      dense
      clearable
      placeholder="Select Template"
      hide-details
      @click="onFocus"
      @keydown="onFocus"
      @click:clear="onFocus"
      @blur="onBlur"
      autocomplete="off"
      ref="inputFilter"
      v-click-outside="clickOutsideHandler"
    />
    <v-menu
      nudge-bottom="40"
      min-width="280"
      max-height="300"
      attach="#templatePicker"
      v-model="isActive"
      :close-on-content-click="false"
    >
      <v-list>
        <v-list-item v-if="filteredSystemTemplates.length" disabled>
          <v-list-item-title>Default Templates</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, counter) in filteredSystemTemplates"
          :key="'system_' + counter"
          dense
          link
          @click="clickHandler(item)"
        >
          <v-list-item-title>{{ item.templateName }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="filteredCustomTemplates.length" disabled>
          <v-list-item-title>Your Templates</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in filteredCustomTemplates"
          :key="'custom_' + index"
          dense
          link
          @click="clickHandler(item)"
        >
          <v-list-item-title>{{ item.templateName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "TemplatePicker",
  data() {
    return {
      filter: "",
      isActive: false,
    };
  },
  props: {
    templateName: {
      type: String,
      default: "",
    },
    systemTemplates: {
      type: Array,
      default: () => [],
    },
    customTemplates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredSystemTemplates() {
      const { filter, systemTemplates } = this;
      if (filter) {
        return systemTemplates.filter((item) => item.templateName.toLowerCase().includes(filter.toLowerCase()));
      }
      return systemTemplates;
    },
    filteredCustomTemplates() {
      const { filter, customTemplates } = this;
      if (filter) {
        return customTemplates.filter((item) => item.templateName.toLowerCase().includes(filter.toLowerCase()));
      }
      return customTemplates;
    },
  },
  methods: {
    onFocus() {
      this.isActive = true;
    },
    onBlur() {
      if (this.isActive) {
        this.$refs.inputFilter.$el.querySelector("input").focus();
      }
    },
    clickOutsideHandler($event) {
      if (!$event.target.closest("#templatePicker")) {
        setTimeout(() => {
          this.$refs.inputFilter.$el.querySelector("input").blur();
        }, 200);
      }
    },
    clickHandler(item) {
      this.isActive = false;
      this.$emit("setTemplate", item);
    },
  },
  watch: {
    templateName(newValue) {
      this.filter = newValue;
    },
  },
};
</script>
