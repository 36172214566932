<template>
  <v-dialog v-model="dialog" width="900" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card :loading="loading" style="border: 1px solid white; background-color: white; color: black;">
      <v-card-title style="background-color: black; color: white; padding-top: 0; padding-bottom: 0;">
        <v-container class="pa-0">
          <v-row class="templateHeader">
            <v-col style="display: flex;">
              <v-icon left>mdi-information-outline</v-icon>
              <span style="white-space: nowrap;"> About Us </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-title style="padding-top: 0; padding-bottom: 0;">
        <v-container class="pa-0">
          <v-row class="templateHeader">
            <v-col class="col-lg-1" style="padding: 0; display: flex; align-self: center;">
              <v-img :src="require(`@/assets/logo-login.png`)" max-width="70"></v-img>
            </v-col>
            <v-col>
              <h3 style="white-space: nowrap;"> Rology Teleradiology Platform </h3>
              <h6> Medical image management and processing system </h6>
              <span>UDI: <span> (01)5060608880348(8012)<span style="font-size: 16px;"> {{version}} </span> (11)20200506 </span></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-divider style="border-color: gainsboro; margin-bottom: 3%;" />
      <v-card-text style="padding-top: 0; padding-bottom: 0;">
        <p>
          <v-img :src="require(`@/assets/SN.png`)" max-width="60" title="Version"></v-img>
          <span class="aboutUs-span-data" title="Version">v {{version}} </span>
        </p>
        <p>
          <v-icon title="Build Number"> mdi-wrench </v-icon>
          <span class="aboutUs-span-data" title="Build Number"> {{buildNumber}} </span>
        </p>
        <p>
          <v-img :src="require(`@/assets/white.jpg`)" max-width="60" title="Year"></v-img>
          <span class="aboutUs-span-data" title="Year"> {{new Date().getFullYear()}} </span>
        </p>
        <p>
          <v-icon title="Copyright"> mdi-copyright </v-icon>
          <span class="aboutUs-span-data" title="Copyright"> Rology, Inc © {{new Date().getFullYear()}} </span>
        </p>
        <p>
            <v-img :src="require(`@/assets/black.jpg`)" max-width="60" title="Address"></v-img>
            <span class="aboutUs-span-data" style="width: 80%; display: inline-flex;" title="Address">
              1202 NE McClain Road, Bldg 7, Bentonville, Arkansas, 72712, United States of America.
            </span>
        </p>
        <p>
          <v-icon title="Phone Number"> mdi-phone </v-icon>
          <span class="aboutUs-span-data" title="Phone Number"> +201006815699 </span>
        </p>
        <v-row>
          <v-col>
            <p>
              <v-icon title="Email" style="padding: 3%;"> mdi-email </v-icon>
              <span class="aboutUs-span-data" title="Email"> customer.support@rology.net </span>
            </p>
            <p>
              <v-img :src="require(`@/assets/userManual.jpg`)" max-width="60" title="User Manual" style="margin: 1%;"></v-img>
              <span class="aboutUs-span-data" title="User Manual"> <a href="/"> User Manual </a> </span>
            </p>
          </v-col>
          <v-col style="margin: auto;">
            <v-img :src="require(`@/assets/RxOnly.png`)" max-width="60" title="RX Only" style="margin: auto;"></v-img>
          </v-col>
        </v-row>
      </v-card-text>
<!--      <v-card-title style="background-color: gray; color: white;">-->
<!--        UDI: <span> (01)5060608880348(8012)<span style="font-size: 16px;">1.0.0</span> (11)20200506 </span>-->
<!--      </v-card-title>-->
    </v-card>
  </v-dialog>
</template>

<script>

import dialogMixins from "@/shared/dialog-mixins";
import apiEndpoints from "@/util/apiEndpoints";
import axios from "axios";
import {mapActions} from "vuex";

export default {
  name: "AboutUsForm",
  mixins: [dialogMixins],
  data() {
    return {
      loading: false,
      dialog: true,
      version: "",
      runningMode: "",
      buildNumber: "",
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["toggleAboutUsForm"]),
    cancel() {
      this.toggleAboutUsForm();
    },
    async getAppVersion() {
      const url = `${apiEndpoints.getAppVersion}`;
      const response = await axios.get(url);
      this.runningMode = response.data.runningMode;
      this.version = response.data.version.split('@')[0];
      this.buildNumber = response.data.version.split('@')[1];
    }
  },
  watch: {
  },
  mounted() {
    this.getAppVersion();
  },
};
</script>
<style>

.aboutUs-span-title {
  font-size: 16px;
  font-weight: bold;
  color: gray;
  padding: 1%;
  width: 16%
}

.aboutUs-span-data {
  font-size: 18px;
  font-weight: bold;
  padding: 1%;
  color: black;
}

@media only screen and (max-device-width: 490px) {

  .templateBtn {
    width: 100%;
    margin: 0% 0% 2% 0% !important;
  }
}

@media only screen and (max-device-width: 480px) {
  .templateHeader {
    display: grid;
  }
}

p i.v-icon.v-icon {
  border-radius: 50%;
  padding: 1%;
  color: black;
  font-size: 40px;
}

.v-card__text p {
  display: flex;
  align-items: center;
}
</style>