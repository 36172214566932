import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";
import * as mutationTypes from "@/store/mutation-types";
import { errorHandler, successHandler } from "@/store/shared";
import ClientRoutes from "@/router/ClientRoutes";
import router from "../../router";

export const clearCurrentLoadedStudy = async ({ commit }) => {
  commit(mutationTypes.GET_STUDY_BY_ID, null);
};
export const getStudyById = async ({ commit, dispatch }, id) => {
  const url = `${apiEndpoints.getStudy}${id}`;
  try {
    const response = await API.get(url);
    const study = response.data;
    if (study) {
      study.id = id;
    }
    commit(mutationTypes.GET_STUDY_BY_ID, study);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting study meta data", url);
  }
};
export const updateStudy = async ({ state, dispatch, commit }, { url, data, endStudy }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.post(url, data);
    successHandler(dispatch, "Study was changed.");
    commit(mutationTypes.SET_API_CALL, false);
    if (!endStudy) {
      dispatch(mutationTypes.GET_STUDY_BY_ID, state.study.id);
    }
    return true;
  } catch (error) {
    if (error.response.status == 403) {
      errorHandler(error, dispatch, `Error Changing Study Status, ${error.response.data.message}.`, url);
    } else {
      errorHandler(error, dispatch, "Error Changing Study Status", url);
    }
    return false;
  }
};
export const assignStudy = async ({ commit, dispatch, state }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.post(url, data); // Study assigned to:id
    successHandler(dispatch, "Study was assigned");
    dispatch(mutationTypes.GET_STUDY_BY_ID, state.study.id);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error assigning study to radiology", url);
  }
};
export const getStudyLogs = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    const response = await API.get(url);
    commit(mutationTypes.GET_STUDY_LOGS, response.data);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting study logs:", url);
  }
};
export const rejectStudy = async ({ commit, dispatch, state }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.post(url, data);
    dispatch(mutationTypes.GET_STUDY_BY_ID, state.study.id);
    commit(mutationTypes.SET_API_CALL, false);
    successHandler(dispatch, "Study was rejected");
  } catch (error) {
    errorHandler(error, dispatch, `Error while rejecting study, ${error.response.data.message}`, url);
  }
};

export const startStudyDiagnosing = async ({ commit, dispatch }, { url, data }) => {
  commit(mutationTypes.SET_API_CALL, true);
  try {
    await API.post(url, data);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, `Error while start Diagnosing study, ${error.response.data.message}`, url);
  }
};

export const saveClinicalInfo = async ({ state, dispatch }, { url, data }) => {
  try {
    data.requestType = data.requestType.sort().join(',');
    await API.post(url, data);
    successHandler(dispatch, "Clinical info was saved");
    dispatch(mutationTypes.GET_STUDY_BY_ID, state.study.id);
  } catch (error) {
    errorHandler(error, dispatch, "Error saving clinical info.", url);
  }
};
export const changeRequestType = async ({ dispatch }, { url, data }) => {
  try {
    await API.post(url, data);
    successHandler(dispatch, "request type was changed");
  } catch (error) {
    errorHandler(error, dispatch, "Error changing request type", url);
  }
};
export const getClinicalInfo = async ({ commit, dispatch }, url) => {
  try {
    const response = await API.get(url);
    response.data.requestType = response.data.requestType.split(',');
    commit(mutationTypes.GET_CLINICAL_INFO, response.data);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting clinical info.", url);
  }
};
export const getSystemTags = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);

  try {
    const response = await API.get(url);
    commit(mutationTypes.GET_SYSTEM_TAGS, response.data.results);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting study tags:", url);
  }
};

export const getRadTags = async ({ commit, dispatch }, url) => {
  commit(mutationTypes.SET_API_CALL, true);

  try {
    const response = await API.get(url);
    commit(mutationTypes.GET_RAD_TAGS, response.data.results);
    commit(mutationTypes.SET_API_CALL, false);
  } catch (error) {
    errorHandler(error, dispatch, "Error getting study tags:", url);
  }
};

export const endStudy = ({ getters }) => {
  const { isRadiologist, isMedicalAdmin, isAdmin } = getters;
  if (isRadiologist) {
    router.push({ name: ClientRoutes.radiologistStudies }).catch(() => { });
  } else if (isMedicalAdmin || isAdmin) {
    window.close();
  }
};

export default {
  getStudyById,
  updateStudy,
  assignStudy,
  getStudyLogs,
  rejectStudy,
  startStudyDiagnosing,
  saveClinicalInfo,
  changeRequestType,
  getClinicalInfo,
  getSystemTags,
  getRadTags,
  endStudy,
  clearCurrentLoadedStudy
};
