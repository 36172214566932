<template>
  <v-card max-height="300" max-width="1900" style="background-color: rgb(73 146 226)">
    <v-container class="pt-0 pb-0" fluid>
      <v-card class="profileCard">
        <v-row style="direction: rtl;">
          <div class="col-4" style="z-index: 2; direction: ltr; text-align: right; padding: 20px 20px 0px 0px;">
            <v-progress-circular
                v-if="isProfileEdited" style="margin: 10px"
                indeterminate
                color="primary"
            />
            <RadEditButton @enableEdit="toggleEdit"></RadEditButton>
            <ChangePasswordBtn @enableChange="toggleChangePassword"></ChangePasswordBtn>
          </div>
        </v-row>
        <!-- TODO-->
        <!-- eslint-disable-next-line vue/valid-v-slot  -->
        <v-hover v-slot:default>
          <div class="profilePic">
            <v-avatar size="164" style="position: inherit">
              <v-img v-bind:src="profileImage">
                <v-expand-transition>
                  <v-overlay v-if="enableEdit" absolute class="parent" style="height: 100%">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" fab icon small class="vInputIcon">
                          <v-file-input
                            id="fileInput"
                            accept="image/*"
                            hide-input
                            multiple
                            prepend-icon="mdi-camera"
                            @change="uploadImage"
                          ></v-file-input>
                        </v-btn>
                      </template>
                      <span>Add Image</span>
                    </v-tooltip>
                  </v-overlay>
                </v-expand-transition>
              </v-img>
            </v-avatar>
          </div>
        </v-hover>
        <div v-if="enableChange">
          <ChangePasswordForm></ChangePasswordForm>
        </div>
        <div v-if="enableEdit">
          <RadProfileForm :radProfileInfo="radProfileInfo" @close="toggleEdit" @edit="editProfile()"></RadProfileForm>
        </div>
        <div v-if="!enableChange && !enableEdit">
          <RadProfileInfo :radProfileInfo="radProfileInfo"></RadProfileInfo>
        </div>
        <div v-if="signatureImageURL !== null && signatureImageURL !== '/assets/images/signature/signature-image.png' && !enableEdit && !enableChange">
          <v-row class="justify-center">
            <v-col cols="6" class="signatureImageBorder">
              <v-img v-bind:src="signatureImageURL" style="height: 100%">
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </v-card>
</template>
<script>
import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";
import {mapActions, mapState} from "vuex";
import RadProfileInfo from "@/components/RadProfile/RadProfileInfo";
import RadProfileForm from "@/components/RadProfile/RadProfileForm";
import RadEditButton from "@/components/RadProfile/RadEditButton";
import ChangePasswordBtn from "@/components/RadProfile/ChangePasswordBtn";
import ChangePasswordForm from "@/components/RadProfile/ChangePasswordForm";

export default {
  name: "RadiologistProfile",
  components: { ChangePasswordBtn, RadEditButton, RadProfileForm, RadProfileInfo, ChangePasswordForm },
  data() {
    return {
      radProfileInfo: {},
      isProfileEdited: false,
      enableEdit: false,
      enableChange: false,
      profileImage: this.profilePictureURL
    };
  },
  mounted() {
    this.getCurrentUser(apiEndpoints.currentUser);
    this.loadRadProfilePic();
    this.loadRadSignatureImage();
    this.getRadProfileInfo();
  },

  methods: {
    ...mapActions(["setMessage", "getCurrentUser"]),
    ...mapActions("Profile", ["setProfilePicURL", "getProfilePic", "getSignatureImage"]),

    toggleEdit() {
      this.enableEdit = !this.enableEdit;
      if (this.enableChange) {
        this.enableChange = false;
      }
    },
    toggleChangePassword() {
      this.enableChange = !this.enableChange;
      if (this.enableEdit) {
        this.enableEdit = false;
      }
    },
    async getRadProfileInfo() {
      try {
        const url = `${apiEndpoints.getRadProfileInfo}`;
        const response = await API.get(url);
        this.radProfileInfo = response.data;

        if (!this.radProfileInfo.facebook) {
          this.radProfileInfo.facebook = "https://www.facebook.com/";
        }
        if (!this.radProfileInfo.linkedin) {
          this.radProfileInfo.linkedin = "https://www.linkedin.com/";
        }
        if (!this.radProfileInfo.twitter) {
          this.radProfileInfo.twitter = "https://twitter.com/";
        }
      } catch (error) {
        this.setMessage({ message: "rad Profile Info Not Found ", type: "error", timeout: 10000 });
      }
    },
    async editProfile() {
      this.isProfileEdited = true;
      try {
        const url = apiEndpoints.editProfile;
        const formData = new FormData();
        formData.append("firstName", this.radProfileInfo.firstName);
        formData.append("lastName", this.radProfileInfo.lastName);
        formData.append("radBio", this.radProfileInfo.radBio);
        formData.append("signatureText", this.radProfileInfo.signatureText);
        formData.append("facebook", this.radProfileInfo.facebook);
        formData.append("linkedin", this.radProfileInfo.linkedin);
        formData.append("twitter", this.radProfileInfo.twitter);
        formData.append("url", this.radProfileInfo.url);
        formData.append("signatureImageUrl", this.radProfileInfo.signatureImageURL);
        this.radProfileInfo = (await API.post(url, formData)).data;
        this.setProfilePicURL(this.profileImage);
        this.setMessage({ message: "Rad profile edited successfully ", type: "success", timeout: 10000 });
      } catch (error) {
        this.setMessage({ message: "Edit rad profile fail", type: "error", timeout: 10000 });
      }
      this.isProfileEdited = false;
    },
    readFileAsync(files) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function (e) {
          resolve(e.target.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(files[0]);
      });
    },
    async uploadImage(files) {
      this.profileImage = await this.readFileAsync(files);
      this.radProfileInfo.url = files[0];
    },
    async loadRadProfilePic() {
      try {
        const url = `${apiEndpoints.getRadProfilePic}`;
        await this.getProfilePic(url);
        this.profileImage = this.profilePictureURL;
      } catch (error) {
        console.log("Error while getting profile pic for current user.");
      }
    },
    async loadRadSignatureImage() {
      try {
        const signatureUrl = `${apiEndpoints.getRadSignatureImage}`;
        await this.getSignatureImage(signatureUrl);
      } catch (error) {
        console.log("Error while getting signature image for current user.");
      }
    }
  },
  computed:{
    ...mapState("Profile", ["profilePictureURL", "signatureImageURL"]),
  }
  // ,
  // watch: {
  //   profilePictureURL() {
  //     this.profileImage = this.profilePictureURL;
  //   }
  // }
};
</script>
<style>
.profileCard {
  height: 410px;
  width: 1100px;
  margin: auto;
  text-align: center;
  bottom: -217px;
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
}

.profilePic {
  top: -98px;
  position: inherit;
  margin: auto;
  z-index: 1;
}

.signatureImageBorder {
  border: 2px solid #545454;
  border-radius: 8px;
  position: relative;
  background-color: white;
}
</style>
