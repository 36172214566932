<template>
  <v-navigation-drawer
    id="StudyDrawer"
    class="resizable-drawer"
    :class="{ 'right-drawer': drawer.right }"
    :app="drawer.isPinned"
    hide-overlay
    :style="style"
    absolute
    :value="drawer.visible"
    :width="width"
    :right="drawer.right"
    ref="currentSidebar"
  >
    <v-card tile flat height="100%" @mouseleave="mouseLeave" class="scrollable-card">
      <span class="sidebar-handler" :class="{ right: drawer.right }" @mousedown="mouseDownHandler($event)" />
      <v-card-title>
        <span class="primary--text subtitle-1 font-weight-bold">Series List</span>
        <v-spacer />
        <v-btn icon class="d-none d-md-flex">
          <v-icon @click="pin">{{ drawer.isPinned ? "mdi-pin" : "mdi-pin-off" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-expansion-panels focusable hover accordion multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>Medical Info</v-expansion-panel-header>
            <v-expansion-panel-content>

              <br />
              <v-card tile flat class="mb-1 d-flex justify-space-between">
                <v-btn small class="action-button text-capitalize" @click="downloadReportBtn" v-if="(isMedicalAdmin || isAdmin) && isShowDownloadReportBtn ">
                  Report
                  <v-icon size="18" class="ml-1">mdi-cloud-download</v-icon>
                </v-btn>
              </v-card>
              <MedicalHistory :study="study" hide-title remove-padding />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <StudyTree />
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { STUDY_DRAWER } from "@/shared/Constants";
import StudyTree from "@/components/StudyTree/StudyTree";
import SidebarMixins from "@/components/SideBars/SidebarMixins";
import { mapGetters, mapState } from "vuex";
import MedicalHistory from "@/components/MedicalHistory/MedicalHistory";
import API from "@/shared/API";
import {isURL} from 'validator'
import StudyStatus from "@/util/StudyStatus";


export default {
  name: STUDY_DRAWER,
  components: { MedicalHistory, StudyTree },
  mixins: [SidebarMixins],
  data() {
    return {
      panels: [0, 1],
      componentName: STUDY_DRAWER,
      reportUrl: null,
    };
  },
  computed: {
    ...mapGetters(["studyStatus", "studyId", "isMedicalAdmin", "isAdmin"]),
    ...mapState(["study"]),
    isShowDownloadReportBtn() {
      return ( this.study.status === StudyStatus.finished ||
                this.study.status == StudyStatus.inReview ||
                this.study.status == StudyStatus.readyForReview ||
                this.study.status == StudyStatus.recheck ||
               this.study.status === StudyStatus.accepted) ;
    }
  },
  methods: {
    async downloadReportBtn() {
      await this.downloadReport();
      window.open(this.reportUrl, "_blank");
    },
    async downloadReport() {
      if (this.isRadiology) return;
      if (this.study.report === "N/A") return;
      const url = `/report/download/${this.studyId}`;
      try {
        const response = await API.get(url);
        if (isURL(response.data)) {
          this.reportUrl = response.data
        }
      } catch (error) {
        console.log(`Error getting report download link: ${url}`, error)
      }
    },
  },
};
</script>
