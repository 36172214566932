<template>
  <v-system-bar height="auto" class="d-flex flex-wrap">
    <v-menu offset-y rounded="0" class="text-center mr-1" style="max-width: 100px" v-for="(tool, i) in tools" :key="i">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="tool-button level-1 py-1 px-2"
          height="auto"
          text
          tile
          v-bind="attrs"
          v-on="on"
          :disabled="(tool.disabled || !canUserViewStudy) && !isMprWindow"
          v-on:click="handleToolbarActions(tool)"
          :id="`dicom-tool-${tool.text.replace(' ', '-')}`"
        >
          <v-icon size="20">{{ tool.icon }}</v-icon>
          <div class="caption text-capitalize">{{ tool.text }}</div>
        </v-btn>
      </template>
      <v-list v-if="tool.dropdown" min-width="150" class="grey darken-3 rounded-0">
        <v-list-item class="pa-0 rounded-0" v-for="(subItem, i) in tool.dropdown" :key="i" dense>
          <v-btn
            block
            text
            tile
            class="justify-start rounded-0"
            :id="`dicom-tool-${subItem.text.replace(' ', '-')}`"
            v-on:click="handleToolbarActions(subItem, tool)"
          >
            <div class="d-flex justify-center align-content-center">
              <v-icon size="14" v-if="subItem.icon">{{ subItem.icon }}</v-icon>
              <span class="caption text-capitalize sub-item-text">{{ subItem.text }}</span>
            </div>
            <v-spacer v-if="subItem.shortcut" />
            <span>{{ subItem.shortcut }}</span>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <div>
      <AutoMeasure v-if="showAutoMeasure" />
    </div>
    <LayoutPicker v-if="!isMprWindow" @setLayout="setLayout" />
    <PlayPauseClip />
    <div v-if="!isMprWindow">
      <MprButton />
      <StudyComments v-if="study && isRadiologist != null && !isRadiologist" :study="study" />
      <StudyTags v-if="study  && isRadiologist != null && !isRadiologist" :study="study" />
      <StudyActions />
    </div>
    <v-spacer />
    <v-btn
      v-if="aiResponse !== AiResponseTypes.notPredicted && showAiResponse"
      :color="aiResponseType"
      style="text-transform: capitalize"
      small
      depressed
      tag="span"
      type="text"
    >
      {{ aiResponse == "covid"?"pneumonia":aiResponse }}
    </v-btn>
  </v-system-bar>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { handleActiveTool, toggleSyncTools } from "@/util/dicomUtils";
import { DicomTools } from "@/util";
import AutoMeasure from "../AutoMeasure/AutoMeasure.vue";
import LayoutPicker from "@/components/LayoutPicker/LayoutPicker";
import PlayPauseClip from "@/components/DicomTools/PlayPauseClip";
import MprButton from "@/components/DicomTools/MprButton";
import StudyActions from "@/components/StudyActions";
import StudyComments from "../StudyComments/StudyComments.vue";
import AiResponseTypes from "@/util/AiResponseTypes";
import UserTypes from "@/util/UserTypes";

const viewerInterface = window["VIEWER_INTERFACE"];
const viewerActions = window["VIEWER_ACTIONS"];
import { cloneDeep } from "lodash";
import StudyTags from "../StudyActions/StudyTags.vue";

export default {
  name: "ToolsBar",
  components: {StudyTags, AutoMeasure, LayoutPicker, PlayPauseClip, MprButton, StudyActions, StudyComments },
  data() {
    return {
      prevDicomTool: null,
      tools: cloneDeep(DicomTools),
      activeClass: "active-dicom-tool",
      AiResponseTypes,
      UserTypes,
    };
  },
  computed: {
    ...mapState(["study", "currentUser", "canUserViewStudy"]),
    ...mapGetters(["studyId", "isRadiologist", "aiResponse"]),
    ...mapState(["currentUser"]),
    aiResponseType() {
      if(this.aiResponse === AiResponseTypes.normal) return "success";
      else return this.aiResponse === AiResponseTypes.covid ? "error" : "orange";

    },
    showAiResponse() {
      if (!this.currentUser) {
        return false;
      }
      const { type, allowAiFeedback } = this.currentUser;
      return type === UserTypes.admin || type === UserTypes.medicalAdmin || allowAiFeedback;
    },
    showAutoMeasure() {
      return (
        this.currentUser &&
        (this.currentUser.type === UserTypes.medicalAdmin || this.currentUser.type === UserTypes.admin)
      );
    },
  },
  methods: {
    ...mapActions(["setMessage"]),
    handleToolbarActions(item, parent = false) {
      const { tools, activeClass, prevDicomTool } = this;
      handleActiveTool(tools, activeClass, item, prevDicomTool, parent);
      this.prevDicomTool = item;
    },
    setLayout(layout) {
      const { tools, activeClass } = this;
      let event = new CustomEvent(viewerActions.setLayout, { detail: layout });
      viewerInterface.dispatchEvent(event);
      toggleSyncTools(tools, layout, activeClass);
    },
  },
  props: {
    isMprWindow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.v-list-item-custom {
  min-height: 32px;

  &.v-list-item--disabled {
    color: #999 !important;
  }
}

.tool-button.level-1 {
  transition-delay: 0s !important;

  .v-btn__content {
    flex-direction: column;
  }

  &:not(.actions-button):not(#dicom-tool-layout) {
    &:before {
      display: none;
    }
  }
}

.active-dicom-tool {
  background: #4c4c4c !important;
}

.sub-item-text {
  margin-left: 2px;
}

.layout-button {
  .v-btn__content {
    flex-direction: column;
  }
}
</style>
