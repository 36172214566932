<template >
  <!--<v-dialog v-model="dialog" persistent max-width="900">-->
  <v-container class="pt-0 pb-0" fluid >
    <v-card class="profileCard" style="padding-top: 0px;padding-bottom: 0px;">
      <v-row>
        <v-col cols="12" md="3" style="margin-left: 20px;" v-if="!isForApply">
          <v-radio-group
              v-model="column"
              column
              @change="setSettingOperation($event)"
              style="padding-bottom: 0px;"
          >
            <v-radio
                :disabled="isHideAddNew"
                label="Update Existing Template"
                value="UPDATE"
            ></v-radio>
            <v-radio
                label="Add New Template"
                value="ADD_NEW"
            ></v-radio>
            <v-radio
                :disabled="isHideAddNew"
                label="Delete Existing Template"
                value="DELETE"
            ></v-radio>
          </v-radio-group>

        </v-col>
        <v-col cols="12" :md="templatesCol" v-if="isForApply || isForDelete || isForUpdate" style="margin-right: 20px;">
          <br v-if="!isForApply">
          <ComboFilter :dataItems="templatesFilterItems" label="Choose a template" v-if="loadingTemplates" ref="templatesFilter"
                       @loadItemsChildrenEmit="loadTemplateDataHandler" textProperty="name" valueProperty="value"
          />
        </v-col>
        <v-col cols="12" md="8" v-if="!isForApply && isForAddNew" style="margin-right: 20px;">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="newTemplateName" @input="getTemplateName()" outlined shaped label="Template Name:" :disabled="isHideAddNew"> </v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="!isHideAddNew">
              <v-btn color="blue" @click="addNewTemplate()"  style="margin-right: 5px;" :disabled="isDisableAddNew">ADD NEW TEMPLATE</v-btn>
              <br>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-tabs v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') "
            v-model="active_tab">
      <v-tab  v-for="modality in pickupModalities" :key="modality" @click="loadSubSpecialtyList(modality,true)">
        {{modality}}
      </v-tab>
      <v-tab-item v-for ="modality in pickupModalities" :key="modality" >
    <v-row dense >
      <v-col cols="12" md="3">
      <ComboFilter style="margin-left: 20px;" :dataItems="subSpecialtiesNames_Appeared"  :isMultiple="true" :isChips="true" label="Sub Specialities" v-if="loadingSubSpecialties" ref="subSpecialtiesFilter"
                @loadItemsChildrenEmit="loadSubSpecialtiesHandler"
      />
      </v-col>
    </v-row>
    <DataList :modality="modality" :dataItems="requestTypes" :dataHeaders="dataListHeaders" :hideDefaultHeader="true" contentsName="Requests" v-if="loadingRequestTypes"  ref="tableList"
              @checkBooleanEmit="checkBooleanHandler" @showFilteredDataEmit="showFilteredDataHandler" :isDisabled="isForApply" :listHeight="listHeight"
    />
    </v-tab-item>
    </v-tabs>
    <v-col cols="12" md="6" v-if="!isForApply">
      <v-btn color="blue" @click="save(true)" v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') && (isForUpdate || isForAddNew)  " style="margin-right: 5px;" >SAVE CONFIGURATIONS</v-btn>
      <v-btn color="blue" @click="deleteTemplate()" v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') && (isForDelete) " style="margin-right: 5px;">DELETE TEMPLATE</v-btn>
      <v-btn color="blue" @click="saveCancel()" v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') && (isForUpdate || isForAddNew || isForDelete) " >Cancel</v-btn>

    </v-col>
      <v-col cols="12" md="6" v-if="isForApply">
      <v-btn color="blue" @click="apply()" v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') && isForApply " style="margin-right: 5px;" >APPLY TEMPLATE</v-btn>
      <v-btn color="blue" @click="applyCancel()" v-if="pickupModalities.length > 0 && (templateId != null && templateId != undefined && templateId != '') && isForApply " >Cancel</v-btn>
    </v-col>
    <ConfirmDialog :title="confirmTitle" @confirm="save(false)" @cancel="cancel" v-if="showConfirm">
      There is unsaved data for the template <strong class="red--text text--lighten-1">{{ templateName }}</strong> ,<br>
      Please confirm if you want to save the data , or cancel the saving operation and continue
    </ConfirmDialog>
    <ConfirmDialog :title="confirmDeleteTitle" @confirm="deleteTemplateExecute(true)" @cancel="deleteTemplateCancel" v-if="showConfirmDelete">
      Are you sure that you want to delete the template <strong class="red--text text--lighten-1">{{ templateName }}</strong> and all its configurations ,<br>
    </ConfirmDialog>
  </v-container>
  <!--</v-dialog>-->
</template>

<script>
import apiEndpoints from "@/util/apiEndpoints";
import {mapActions, mapState} from "vuex";
import DataList from "@/components/RadSettings/DataList";
import ComboFilter from "@/components/RadSettings/ComboFilter";
import {cloneDeep} from "lodash";
import {ModalitySubSpecialties, PickupModality, RadRequestTypes} from "@/util/Interfaces";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";
export default {
  name: "RadSettings_Templates",
  components: { DataList,ComboFilter,ConfirmDialog },
  data(){
    return {
      active_tab:"",
      confirmTitle:"Saving data confirm",
      confirmDeleteTitle:"Delete data confirm",
      dataListHeaders:[],
      dataListItems: [],
      dialog: true,
      performances:[],
      loadingSubSpecialties: false,
      loadingTemplates: false,
      loadingRequestTypes: false,
      modalities: [],
      pickupModality:"",
      pickupModalities:[],
      pickupModalityList:[],
      templatesFilterItems:[],
      templatesDataList:[],
      //templatesListUrl: "/lookups/getPickupTemplates",
      templatesListUrl: "/admin/config/getTemplatesPickup",
      templateId:"",templateName:"",
      templateRequestTypesList: [],
      requestTypes: [],
      requestTypes_Appeared: [],
      subSpecialtiesFilterItems: [],
      subSpecialties: [],
      subSpecialtiesNames: [],
      subSpecialtiesNames_Appeared: [],
      showConfirm: false,
      showConfirmDelete:false,
      selected_template:"",
      isDisableAddNew:true,
      newTemplateName:"",
      isHideAddNew:false,
      column: "UPDATE",
      isForUpdate:true,
      isForDelete: false,
      isForAddNew: false,
      templatesCol: this.isForApply? 12 : 8,
    }
},
  props: {
    isForApply: {
      type: Boolean,
      default: false,
    },
    listHeight: {
      type: String,
      default: '470px',
    },
    radId: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapState( ["headersBooleanStored"] ),
  },
  methods: {
    ...mapActions(["getDataFromUrl","setDataToUrl","deleteDataFromUrl","setMessage","setHeadersBooleanStored", "getCurrentUser"]),
    ...mapActions("RadTemplatesHistory", ["applyTemplate", "saveTemplatesHistory"]),
    getTableFromTableList(modalityName){
      let tableList =this.$refs.tableList;
      let targetTable = tableList.find(e => e.modality === modalityName);

      return targetTable;
    },
    setSettingOperation(value){
      if(value === "UPDATE"){
        this.isForUpdate = true;
        this.isForAddNew = false;
        this.isForDelete = false;
        if(!this.loadingTemplates){
          this.drawScreen();
        }
      }
      else if (value ==="ADD_NEW"){
        this.isForAddNew = true;
        this.isForUpdate = false;
        this.isForDelete = false;
        this.templateId = null;
        this.templateName = null;
      }
      else if (value ==="DELETE"){
        this.isForDelete = true;
        this.isForUpdate = false;
        this.isForAddNew = false;
        if(!this.loadingTemplates){
          this.drawScreen();
        }
      }
    },
    async addNewTemplate(){
      //Add new template
      //this.templateId = this.newTemplateName;
      //this.templateName = this.newTemplateName;
      let value={value: {id:this.newTemplateName ,name: this.newTemplateName}};
      this.getPerformances();
      Promise.resolve( this.getPickupModalities()).then(()=>{
        this.loadTemplateDataHandler(value);
        this.isHideAddNew=true;
      });
    },
    apply() {
      let templateDataIndex = this.templatesDataList.findIndex(template => template.templateId === this.templateId);
      let templateData = [];
      if (templateDataIndex > -1) {
        templateData = this.templatesDataList[templateDataIndex].data
        try {
          let data = {name: this.templateName};
          this.applyTemplate(data);
        } catch (error) {
          this.setMessage({ message: "rad Profile Template History Not Saved.", type: "error", timeout: 10000 });
        }
      }
      this.$emit("applyTemplateEmit", {templateData: templateData});
    },
    applyCancel(){
      this.$emit("applyTemplateCancelEmit");
    },
    saveCancel(){
      window.location.reload();
    },
    cancel(){
      this.changeTemplate(this.selected_template);
      this.showConfirm = false;
    },
    changeTemplate(template){
      this.$nextTick(() => {
        this.templateId = template.value.id;
        this.templateName = template.value.name;
        this.selected_subSpecialties = "";
        if(this.$refs.subSpecialtiesFilter != undefined){
          for(let i =0 ; i< this.$refs.subSpecialtiesFilter.length ; i++) {
            this.$refs.subSpecialtiesFilter[i].selected_dataItems = [];
          }
        }
        this.isTemplateChanged = true;
        this.modalitySubSpecialtiesList = [];
        this.active_tab = 0;
        this.resetStore();
        this.loadSubSpecialtyList(this.pickupModalities[this.active_tab],true);
      });
    },
    checkBooleanHandler(value){
      let additionalFilters =[];
      let additionalFilter = { name:'PICKUP_MODALITY', value: value.item['PICKUP_MODALITY'], type:'single' };
      additionalFilters.push(additionalFilter);
      additionalFilter = { name:'VALUE', value:value.item['VALUE'], type:'single' };
      additionalFilters.push(additionalFilter);
      if (value.value == true) {
        //change the status of the performance level for the request types of the selected template
        let REQs = this.requestTypes.filter(x => x.VALUE === value.item['VALUE'] &&
            x.PICKUP_MODALITY === value.item['PICKUP_MODALITY'])
        for(const rt of REQs) {
          for(const header of this.dataListHeaders) {
            if(header.type == 'boolean'){
              if (value.filterProperty === header.name) {
                rt[header.name] = true;
              } else {
                rt[header.name] = false;
              }
            }
          }
          let dataTable = this.getTableFromTableList(this.pickupModalities[this.active_tab]);
          rt[dataTable.filterProperty] = value.filterProperty;
        }
      }
      else{
        //change the status of the performance level for the request types of the selected template to the default performance level
        let dataTable = this.getTableFromTableList(this.pickupModalities[this.active_tab]);
        let filteredRequestTypes = this.requestTypes.filter( x => x.VALUE === value.item['VALUE'] && x.PICKUP_MODALITY === value.item['PICKUP_MODALITY']);
        for(const rt of filteredRequestTypes) {
          rt[dataTable.headerItemDefault.name] = true;
          rt[dataTable.filterProperty] = dataTable.headerItemDefault.name;
        }
      }
    },
    deleteTemplate(){
      this.showConfirmDelete = true;
    },
    async deleteTemplateExecute(deleteFromDB){
      if(deleteFromDB){
        let url = `${apiEndpoints.deleteTemplatePickupConfig}`;
        url += `/${this.templateId}`;
        let data = {templateId: this.templateId}
        let response = await this.deleteDataFromUrl({url,data});
        if (response.data.success) {
          this.setMessage({
            message: "Pickup configuration data has been deleted successfully for the template ' " + this.templateName + " '",
            type: "info",
            timeout: 10000,
          });
          this.$nextTick(()=>{
            window.location.reload();
            /*this.loadingTemplates = false;
            this.loadingRequestTypes =false;
            this.loadingSubSpecialties =false;
            this.$refs.templatesFilter = [];
            this.$refs.tableList =[];
            this.$refs.subSpecialtiesFilter=[];

            let templateIndex = this.templatesFilterItems.findIndex(template => template.templateId === this.templateId );
            if (templateIndex > -1) {
              //delete the template (with the given index) cached data
              this.templatesFilterItems.splice(templateIndex, 1);
              templateIndex=-1;
            }
            templateIndex  = this.templateRequestTypesList.findIndex( template => template.templateId === this.templateId);
            if (templateIndex > -1) {
              //delete the template (with the given index) cached data
              this.templateRequestTypesList.splice(templateIndex, 1);
              templateIndex=-1;
            }
            this.requestTypes =[];
            this.templateId=null;
            this.templateName=null;
            this.drawScreen();*/
          });
        }
      }

      this.showConfirmDelete = false;
    },
    deleteTemplateCancel(){
      this.showConfirmDelete = false;
    },
    drawScreen(){
      this.getTemplates();
      this.getPerformances();
      this.getPickupModalities();
    },
    fillModalitySubSpecialtiesList(subSpecialtiesList,modality){
      let modalitySubSpecialties = cloneDeep(ModalitySubSpecialties);
      modalitySubSpecialties.modality = modality;
      modalitySubSpecialties.subSpecialties = modalitySubSpecialties.subSpecialties.concat(subSpecialtiesList);
      this.modalitySubSpecialtiesList.push(modalitySubSpecialties);

      if(this.isTemplateChanged) {
        this.isTemplateChanged =false;
      }
    },
    fillRequestTypes(pickupModality,modality,subName,requestTypes){
      for(const rt of requestTypes) {
        let requestType = {
          INDEX: this.requestTypes.length > 0 ? this.requestTypes.length - 1 : 1,
          NAME: rt.name, VALUE: rt.value,
          SUB_SPECIALTY: subName, MODALITY: modality,PICKUP_MODALITY:pickupModality , RAD_ID: this.templateId
        };
        //Add Performance levels keys to the object
        for(let j =0; j<this.performances.length;j++){
          if(!this.performances[j].isDefault) {
            requestType[this.performances[j].name] = false;
          }
          else{
            requestType[this.performances[j].name] = true;//set with the default performance level
            requestType.PERFORMANCE = this.performances[j].name;
          }
        }
        this.requestTypes.push(requestType);
      }
    },
    getPerformances(){
      let getPerformanceLisForShowUrl = `${apiEndpoints.getPerformanceListForShow}`;

      Promise.resolve(this.getDataFromUrl(getPerformanceLisForShowUrl)).then((result) => {
        if(this.dataListHeaders.length == 0) {
          this.performances = result;
          let header =
              {
                text: 'Request Type',
                type: 'string',
                value: 'NAME',
                isDefault: false,
                color: '',
                name: 'Request Type',
                filterProp: 'PERFORMANCE'
              };
          this.dataListHeaders.push(header);
          for (let j = 0; j < this.performances.length; j++) {
            let header = {
              text: this.performances[j].name,
              value: false,
              color: this.performances[j].color,
              name: this.performances[j].name,
              isDefault: this.performances[j].isDefault,
              type: 'boolean',
              filterProp: 'PERFORMANCE'
            }
            this.dataListHeaders.push(header);
          }
        }
      });

    },
    getTemplateName(){
      if(this.newTemplateName.length > 0){
        this.isDisableAddNew = false;
      }
      else{
        this.isDisableAddNew = true;
      }
    },
    getTemplates(){
      Promise.resolve(this.getDataFromUrl(this.templatesListUrl)).then((result) => {
        this.templatesFilterItems = this.templatesFilterItems.concat(result);
        this.loadingTemplates=true;
      });
    },
    async getPickupModalities() {
      if (!this.pickupModalities.length) {
        let getPickupModalitiesListUrl = `${apiEndpoints.getPickupModalitiesList}`;
        let result = await this.getDataFromUrl(getPickupModalitiesListUrl);
        this.$nextTick(() => {
          this.loadPickupModalityList(result);
        });
      }
    },
    async getTemplatePickupDataConfigs(templateId){
      let url = `${apiEndpoints.getTemplatePickupConfigs}`;
      const  templateId_ = templateId;
      if (templateId_ && templateId_ !== "" ) {
        url += `/${templateId_}`;
      }
      let result =  await this.getDataFromUrl(url);
      return result;
    },
    loadTemplateDataHandler(event){
      this.selected_template = event;
      let templateIndex  = this.templateRequestTypesList.findIndex( template => template.templateId === this.templateId);
      if(templateIndex >-1){
        let dataChanged = false;
        let templateDataIndex = this.templatesDataList.findIndex(template => template.templateId === this.templateId);
        if(templateDataIndex > -1) {
          let updatedTemplate = this.getUpdatedRequestTypes();
          if(updatedTemplate.length != this.templatesDataList[templateDataIndex].data.length){
            dataChanged = true;
          }else {
            for(const templateDataItem of this.templatesDataList[templateDataIndex].data){
              let filteredRequestTypes = updatedTemplate.filter((el) => (
                el.VALUE === templateDataItem.requestType ||
                templateDataItem.requestType === 'ALL') && el.MODALITY === templateDataItem.modality)
              for(const item of filteredRequestTypes){
                if(item.performanceLevel !== templateDataItem.performanceLevel) {
                  dataChanged = true;
                }
              }
            }
          }
        }
        if(dataChanged) {
          //open the dialog here
          this.showConfirm = true;
          if (templateIndex > -1) {
            //delete the template (with the given index) cached data
            this.templateRequestTypesList.splice(templateIndex, 1);
            templateIndex=-1;
          }
        }
        else{
          this.changeTemplate(event);
        }
      }
      else {
        this.changeTemplate(event);
      }
    },
    loadSubSpecialtiesHandler(value){
      this.selected_subSpecialties = value.value.length > 0? value.value:  this.subSpecialtiesNames_Appeared;
      if(value.additionalFilters.length > 0) {
        let pickupModality = value.additionalFilters.filter( additional => additional.name === 'PICKUP_MODALITY' )[0].value;
        this.loadSubsRequestTypes(this.selected_subSpecialties,pickupModality,false);
      }
      else {
        this.loadSubsRequestTypes(this.selected_subSpecialties,this.pickupModalities[this.active_tab],false);
      }
    },
    loadPickupModalityList(pickupModalities){
      for(let i=0; i< pickupModalities.length; i++){
        this.pickupModalities.push(pickupModalities[i].name);
        let pickupModality_Modalities = cloneDeep(PickupModality);
        pickupModality_Modalities.pickupModality = pickupModalities[i].name;
        for(let j=0; j< pickupModalities[i].items.length; j++) {
          pickupModality_Modalities.modalities.push(pickupModalities[i].items[j].modality);
          let modality_SubSpecialties = cloneDeep(ModalitySubSpecialties);
          modality_SubSpecialties.modality = pickupModalities[i].items[j].modality;
          modality_SubSpecialties.subSpecialties = pickupModalities[i].items[j].subspecialities;
          pickupModality_Modalities.modality_SubSpecialties.push(modality_SubSpecialties);
        }
        this.pickupModalityList.push(pickupModality_Modalities);
      }
    },
    loadSubSpecialtyList(pickupModality,isModalityChanged) {

      this.modalities =[];this.subSpecialties=[];
      let result = this.pickupModalityList.findIndex(p => p.pickupModality == pickupModality);
      this.pickupModality = pickupModality;
      for(const m of this.pickupModalityList[result].modality_SubSpecialties){
        this.modalities.push(m.modality);
        for(const s of m.subSpecialties) {
          let sub_specialty = {name: s.name , items: s.items, modality: m.modality }
          this.subSpecialties.push(sub_specialty);
        }
      }

      this.subSpecialtiesNames=[];
      this.subSpecialtiesNames_Appeared=[];
      for(const sub of this.subSpecialties){
        if(!this.subSpecialtiesNames_Appeared.includes(sub.name))
          this.subSpecialtiesNames_Appeared.push(sub.name);

        this.subSpecialtiesNames.push(sub.name);
      }

      this.loadingSubSpecialties = true;
      let index = this.modalitySubSpecialtiesList.findIndex((el) => el.modality === pickupModality );
      if(index >-1 ) {
        this.selected_subSpecialties = this.modalitySubSpecialtiesList[index].subSpecialties;
        this.loadSubsRequestTypes(this.modalitySubSpecialtiesList[index].subSpecialties, pickupModality,isModalityChanged);
      }
      else
      {
        this.selected_subSpecialties =this.subSpecialtiesNames;
        this.fillModalitySubSpecialtiesList(this.selected_subSpecialties,pickupModality);
        this.loadSubsRequestTypes(this.selected_subSpecialties, pickupModality,isModalityChanged);
      }
    },
    loadSubsRequestTypes(event,pickupModality,isModalityChanged){

      this.requestTypes_Appeared =[];
      if(event.length == 0 || event.length == this.subSpecialtiesNames.length){
        event = this.subSpecialtiesNames;
      }

      if(!isModalityChanged)
      {
        let j = this.modalitySubSpecialtiesList.findIndex((el) =>  el.modality === pickupModality );
        if (j > -1)
          this.modalitySubSpecialtiesList.splice(j, 1);
        this.fillModalitySubSpecialtiesList(event,pickupModality);
      }
      let templateIndex  = this.templateRequestTypesList.findIndex( template => template.templateId === this.templateId);
      if(templateIndex >-1) {
        this.requestTypes = this.templateRequestTypesList[templateIndex].requestTypes;
      }
      else {
        this.requestTypes =[];
        let templateRequestTypes = cloneDeep(RadRequestTypes);
        templateRequestTypes.templateId = this.templateId;
        templateRequestTypes.requestTypes =[];
        this.templateRequestTypesList.push(templateRequestTypes);
      }
      let filteredSubSpecialties = event;
      let additionalFilters =[];
      let additionalFilter = { name:'PICKUP_MODALITY', value:pickupModality, type:'single' };
      additionalFilters.push(additionalFilter);
      additionalFilter = { name:'SUB_SPECIALTY', value: filteredSubSpecialties, type:'array' };
      additionalFilters.push(additionalFilter);
      if(this.requestTypes.length == 0) {
        let filteredSubSpecialies = this.subSpecialties.filter(el =>  event.includes(el.name));
        for(const sub of filteredSubSpecialies){
          this.fillRequestTypes(pickupModality,sub.modality,sub.name,sub.items);
        }
        this.loadingRequestTypes = true;


        //Promise.resolve(
            //this.getTemplatePickupDataConfigs(this.templateId)).then(
            //(data) => {
        this.$nextTick(() => {
          let pickupTemplate = this.templatesFilterItems.filter(template => template.id == this.templateId);
          if(pickupTemplate.length > 0) {
            let templateData = {templateId: this.templateId, data: pickupTemplate[0].items}
            let templateDataIndex = this.templatesDataList.findIndex(template => template.templateId === this.templateId);
            if (templateDataIndex > -1)
              this.templatesDataList[templateDataIndex] = templateData;
            else
              this.templatesDataList.push(templateData);
            this.showTemplateData(pickupModality);
            let dataTable = this.getTableFromTableList(this.pickupModalities[this.active_tab]);
            dataTable.showFilteredDataEvent();
            templateIndex > -1 ? this.templateRequestTypesList[templateIndex].requestTypes = this.requestTypes :
                this.templateRequestTypesList[this.templateRequestTypesList.length - 1].requestTypes = this.requestTypes;
          }
        });
            //}
        //);
      }
      else {
        //let result = this.pickupModalityList.findIndex(p => p.pickupModality == pickupModality);
        //this.loadingRequestTypes = false;
        let SUBs = this.subSpecialties.filter(el =>  event.includes(el.name));
        for(const sub of SUBs){
          let rtIndex = this.requestTypes.findIndex(y => y.SUB_SPECIALTY === sub.name && sub.modality === y.MODALITY && y.PICKUP_MODALITY === pickupModality && y.RAD_ID === this.templateId);
          if (rtIndex > -1) {
            this.loadingRequestTypes = true;
            //this.showTemplateData(templateIndex,pickupModality,event);
            let dataTable = this.getTableFromTableList(pickupModality);
            dataTable.showFilteredDataEvent();
          } else {
            this.fillRequestTypes(pickupModality,sub.modality,sub.name,sub.items);
            this.loadingRequestTypes = true;
            //this.showTemplateData(templateIndex,pickupModality,event);
            this.showTemplateData(pickupModality);
            templateIndex > -1 ? this.templateRequestTypesList[templateIndex].requestTypes = this.requestTypes :
                this.templateRequestTypesList[this.templateRequestTypesList.length - 1].requestTypes = this.requestTypes;
          }
        }

        let dataTable = this.getTableFromTableList(pickupModality);
        if(!isModalityChanged || dataTable != undefined ) {
          dataTable.showFilteredDataEvent();
        }


      }
    },
    showFilteredDataHandler(){
      this.$nextTick(() => {
        let dataTable = this.getTableFromTableList(this.pickupModality);
        if(dataTable == undefined) {
          this.showFilteredDataHandler();
          console.log("dataTable is undefined - recall next tick step")
          return;
        }
        let result = this.pickupModalityList.findIndex(p => p.pickupModality == this.pickupModality);
        let additionalFilters = [];
        let subSpecialties = this.selected_subSpecialties;

        let additionalFilter = {
          name: 'PICKUP_MODALITY',
          value: this.pickupModalities[result],
          type: 'single'
        };
        additionalFilters.push(additionalFilter);
        additionalFilter = {name: 'SUB_SPECIALTY', value: subSpecialties, type: 'array'};
        additionalFilters.push(additionalFilter);

        dataTable.showFilteredData(additionalFilters);
      });
    },
    showTemplateData(pickupModality){

      let templateDataIndex = this.templatesDataList.findIndex( template => template.templateId === this.templateId);
      if(templateDataIndex > -1) {
        if(this.isHideAddNew){
          this.setMessage({
            message: "Pickup configuration data template with the name' " + this.templateName + " ' is already exist",
            type: "info",
            timeout: 10000,
          });
        }
        for(const templateDataItem of this.templatesDataList[templateDataIndex].data){
          let filteredRequestTypes = this.requestTypes.filter((el) => (
                  el.VALUE === templateDataItem.requestType ||
                  templateDataItem.requestType === 'ALL')
              && el.MODALITY === templateDataItem.modality && el.PICKUP_MODALITY === pickupModality)
            for(const item of filteredRequestTypes) {
              let value ={value : true, item : item , filterProperty:templateDataItem.performanceLevel};
              this.checkBooleanHandler(value);
            }
        }
      }
    },
    async save(reloadData){
      Promise.resolve( this.setTemplatePickupDataConfigs()).then((response) => {
        if (response.data.success) {
          this.setMessage({
            message: "Pickup configuration data has been saved successfully for the template ' " + this.templateName + " '",
            type: "success",
            timeout: 10000,
          });
          Promise.resolve(
              this.getTemplatePickupDataConfigs(this.templateId)).then(
              (data) => {
                this.$nextTick(() => {
                  let filterItemIndex = this.templatesFilterItems.findIndex(template => template.id == this.templateId);
                  if(filterItemIndex > -1)
                    this.templatesFilterItems[filterItemIndex].items = data;

                  let dataIndex = this.templatesDataList.findIndex(template => template.templateId === this.templateId);
                  if (dataIndex > -1)
                    this.templatesDataList[dataIndex].data = data;

                  if(!reloadData && !this.isHideAddNew){
                    this.changeTemplate(this.selected_template);
                    this.showConfirm = false;
                  }
                  else if(this.isHideAddNew){
                    window.location.reload();
                  }
                });
              }
          );
        } else {
          this.setMessage({
            message: "Pickup configuration data has been failed for the template ' " + this.templateName + " '",
            type: "error",
            timeout: 10000,
          });
        }
      });
    },
    getUpdatedRequestTypes() {
      if (this.requestTypes.length > 0) {
        let data = [];
        let dataTable = this.getTableFromTableList(this.pickupModalities[this.active_tab]);
        for(const rt of this.requestTypes){
          if (rt.PERFORMANCE !== dataTable.headerItemDefault.name) {
            let rtData = {
              "modality": rt.MODALITY,
              "requestType": rt.VALUE,
              "performanceLevel": rt.PERFORMANCE
            };
            data.push(rtData);
          }
        }
        let templateDataIndex = this.templatesDataList.findIndex(template => template.templateId === this.templateId);
        if (templateDataIndex > -1) {
          let unSeenData = this.templatesDataList[templateDataIndex].data.filter(i => !this.requestTypes.some(r => r.MODALITY === i.modality));
          data = data.concat(unSeenData);
        }
        return data;
      }
    },
    async setTemplatePickupDataConfigs() {
      if (this.requestTypes.length > 0) {
        let url = `${apiEndpoints.setTemplatePickupConfigs}`;
        url += `/${this.templateId}`;
        let data = this.getUpdatedRequestTypes();
        if (data.length > 0) {
          let response = await this.setDataToUrl({url, data});
          return response;
        }
      }
    },
    resetStore(){
      let emptyArr =[];
      this.setHeadersBooleanStored(emptyArr);
    },
  },
  mounted() {
    this.getCurrentUser(apiEndpoints.currentUser);
    if(this.isForApply || this.isForUpdate) {
      this.drawScreen();
    }
  },
};
</script>

<style>
.profileCard {
  height: 550px;
  width: 1100px;
  margin: auto;
  text-align: center;
  bottom: -33px;
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
}
</style>