<template>
  <div
    class="sidebars-container"
    :style="{ margin: `0 ${dockBarWidth}px`, position: 'relative' }"
    @mouseleave="hideSidebars"
  >
    <span class="overlay-bar left-overlay-bar" :style="{ width: `${dockBarWidth}px`, left: `-${dockBarWidth}px` }" />
    <span class="overlay-bar right-overlay-bar" :style="{ width: `${dockBarWidth}px`, right: `-${dockBarWidth}px` }" />
    <DockBar :width="dockBarWidth" />
    <DockBar :width="dockBarWidth" :isHTML="isHTML" right />
    <StudyDrawer />
    <HistoryDrawer />
    <ReportDrawer v-if="displayReport" />
    <MaliciousCutsDrawer v-if="showAiResponse" />
  </div>
</template>
<script>
import DockBar from "@/components/SideBars/DockBar";
import { mapState, mapActions, mapGetters } from "vuex";
import StudyDrawer from "@/components/SideBars/StudyDrawer";
import HistoryDrawer from "@/components/SideBars/HistoryDrawer";
import ReportDrawer from "@/components/SideBars/ReportDrawer";
import StudyStatus from "@/util/StudyStatus";
import MaliciousCutsDrawer from "@/components/SideBars/MaliciousCutsDrawer";

export default {
  name: "SideBars",
  components: { MaliciousCutsDrawer, ReportDrawer, HistoryDrawer, StudyDrawer, DockBar },
  computed: {
    ...mapGetters(["studyStatus", "isRadiologist", "isMedicalAdmin", "isAdmin", "showAiResponse","studyId"]),
    ...mapState("Layout", ["dockBarWidth"]),
    displayReport() {
      const { studyStatus, isRadiologist, isMedicalAdmin, isAdmin } = this;
      const { inReview, assigned, diagnosing } = StudyStatus;
      this.resizeWindow();
      if (isRadiologist && (studyStatus === assigned || studyStatus === diagnosing)) {
        return true;
      }
      return (isMedicalAdmin || isAdmin) && studyStatus === inReview;
    },
  },
  methods: {
    ...mapActions("Layout", ["hideUnpinSidebars"]),
    hideSidebars() {
      const reportForm = document.activeElement.closest("#studyReportForm");
      if (!reportForm) {
        this.hideUnpinSidebars();
      }
    },
    resizeWindow() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    },
  },
};
</script>
<style lang="scss">
.sidebars-container {
  z-index: 1;
  position: relative;

  .overlay-bar {
    display: block;
    position: absolute;
    top: 0;
    height: 100vh;
    background: #121212;
    z-index: 10;

    &.left-overlay-bar {
      left: 0;
    }

    &.right-overlay-bar {
      right: 0;
    }
  }

  .scrollable-card {
    display: flex;
    flex-direction: column;

    &:hover {
      .sidebar-handler {
        opacity: 0.8;
      }
    }

    .v-card__title {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .v-card__text {
      padding: 0;
      flex-grow: 1;
      overflow-y: auto;
    }

    .sidebar-handler {
      position: absolute;
      top: 0;
      bottom: 0;
      border: 3px solid #fff;
      cursor: w-resize;
      opacity: 0.3;
      transition: opacity 0.2s;
      right: -3px;
      z-index: 50;

      &.dragging {
        border-color: transparent;
        opacity: 0 !important;
      }

      &.right {
        right: auto;
        left: -3px;
      }
    }
  }
}
</style>
