import * as mutations from "./mutation-types";

export default {
  [mutations.GET_STUDY_BY_ID](state, study) {
    state.study = study;
  },
  [mutations.UPDATE_STUDY](state, newStudy) {
    state.study = { ...state.study, ...newStudy };
  },
  [mutations.SET_API_CALL](state, value) {
    state.apiCall = value;
  },
  [mutations.API_ERROR](state, payload) {
    state.snackbarMessages.push(payload);
  },
  [mutations.GET_RADIOLOGISTS](state, list) {
    state.radiologists = list;
  },
  [mutations.GET_STUDY_LOGS](state, logs) {
    state.studyLogs = logs;
  },
  [mutations.TOGGLE_REPORT_DIALOG](state, value) {
    state.isReportDialogOpen = value;
  },
  [mutations.TOGGLE_SHORTHANDS_DIALOG](state, value) {
    state.isShorthandsDialogOpen = value;
  },
  [mutations.GET_STUDY_REPORT](state, value) {
    state.studyReport = { ...state.studyReport, ...value };
  },
  [mutations.GET_STUDY_REPORT_FOR_CHECK](state, value) {
    state.studyReportForCheck = { ...state.studyReportForCheck, ...value };
  },
  [mutations.GET_SHORTHAND_LIST](state, list) {
    state.shorthandList = list;
  },
  [mutations.SET_MESSAGE](state, message) {
    message.id = new Date().getTime();
    state.snackbarMessages = [...state.snackbarMessages, message];
  },
  [mutations.DELETE_MESSAGE](state, id) {
    state.snackbarMessages = state.snackbarMessages.filter((item) => item.id !== id);
  },
  [mutations.SET_SHORTHAND_QUILL_CONTENTS](state, value) {
    state.shorthandQuillContents = value;
  },
  [mutations.SET_REPORT_FROM_TEMPLATE](state, value) {
    state.studyReport = { ...state.studyReport, ...value };
  },
  [mutations.GET_REQUEST_TYPE_LIST](state, value) {
    state.requestTypeList = value;
  },
  [mutations.GET_CURRENT_USER](state, value) {
    state.currentUser = value;
  },
  [mutations.GET_STUDY_REPORT_LIST](state, value) {
    state.studyReportsList = [...value];
  },
  [mutations.NEW_REPORT_ID](state, value) {
    state.newReportId = value;
  },
  [mutations.REMOVE_REPORT_BY_ID](state, id) {
    state.studyReportsList = state.studyReportsList.filter((item) => item.id !== id);
  },
  [mutations.FINISH_STUDY_REPORT](state, value) {
    state.isStudyReportFinished = value;
  },
  [mutations.TOGGLE_STUDY_VISIBILITY](state, value) {
    state.canUserViewStudy = value;
    state.isStudyVisible = value;
  },
  [mutations.GET_PATIENT_HISTORY_LIST](state, value) {
    state.patientHistoryList = [...value];
  },
  [mutations.CLEAR_PATIENT_HISTORY_LIST](state) {
    state.patientHistoryList = [];
  },
  [mutations.GET_CLINICAL_INFO](state, value) {
    state.clinicalInfo = { ...value };
  },
  [mutations.DEMERGE_STUDY](state, value) {
    state.patientHistoryList = value;
  },
  [mutations.SHOW_AI_RESPONSE_FORM](state, value) {
    state.showAiResponseForm = value;
    // state.aiResponseForm = value;
  },
  [mutations.SHOW_PROBABILITY_OF_REVIEW_RESPONSE_FORM](state, value) {
    state.showProbabilityOfReviewResponseForm = value;
  },
  [mutations.GET_SYSTEM_TAGS](state, tags) {
    state.systemTags = tags;
  },
  [mutations.GET_RAD_TAGS](state, tags) {
    state.radTags = tags;
  },
  [mutations.GET_MODALITIES_LIST](state, value) {
    state.modalityList = value;
  },
  [mutations.SET_HEADERS_BOOLEAN_STORED](state, list) {
    state.headersBooleanStored = list;
  },
  [mutations.TOGGLE_ABOUT_US_FORM](state) {
    state.showAboutUsForm = !state.showAboutUsForm;
  },
  [mutations.GET_PROBABILITY_OF_REVIEW_RESPONSE_LIST](state, value) {
    state.probabilityOfReviewResponseList = value;
  },
  [mutations.SHOW_RAD_REPORTS_LIST_DIALOG](state, value) {
    state.showRadReportsListDialog = value;
  },
  [mutations.RAD_REPORTS_LIST](state, value) {
    state.radReportsList = value;
  },
  [mutations.SHOW_RAD_RESPONSE_FOR_AI_FORM](state, value) {
    state.showRadResponseForAIFeedbackForm = value;
  },
};
