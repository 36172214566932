/* eslint-disable */
import "./assets/custom-styles.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { setupAuthorization, setupErrorHandler } from "./plugins/interceptors";
import { amplifySetup, amplifyConfigure } from "./plugins/amplify";
import vuetify from "./plugins/vuetify";
import store from "./store";
import API from "@/shared/API";
import axios from "axios";
import apiEndpoints from "@/util/apiEndpoints";
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)
Vue.config.productionTip = false;

setupAuthorization(API);
setupErrorHandler(API);

var response = "";

// get cognito properties from backend then create vue object
async function getCognitoProperties (){
  const url = `${apiEndpoints.getCognitoProperties}`;
  response = await axios.get(url).catch(function(error){
    console.log('Error', error.message);
  });
};

getCognitoProperties().then(
  function(value){
    amplifyConfigure(response);
    amplifySetup();
    new Vue({
      router,
      vuetify,
      store,
      render: (h) => h(App),
    }).$mount("#app");

  }
);