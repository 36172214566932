<template>
  <v-card class="mx-auto" flat max-height="fit-content" max-width="350" tile>
    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card-text>
        <v-text-field
          v-model="currantPassword"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="show3 ? 'text' : 'password'"
          label="Currant password"
          outlined
          required
          @click:append="show3 = !show3"
          @input="validate"
        ></v-text-field>
       <v-text-field
          v-model="newPassword"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="show1 ? 'text' : 'password'"
          class="pre-formatted"
          label="New password"
          outlined
          required
          @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
          v-model="newPasswordRetype"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="confirmPasswordRules"
          :type="show2 ? 'text' : 'password'"
          label="Re-type new password"
          outlined
          required
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pa-2 mt-sm-n6">
        <v-spacer></v-spacer>
        <v-progress-circular
            v-if="isPasswordChanged" style="margin: 10px"
            indeterminate
            color="primary"
        />
        <v-btn :disabled="!isFormValid" class="pa-2" color="blue" @click="showChangePasswordConfirmation()"
          >Save Change
        </v-btn>
      </v-card-actions>
    </v-form>
    <ConfirmDialog
      v-if="showChangePasswordConfirmDialog"
      title="Change password"
      @cancel="cancelChangePasswordConfirmation"
      @confirm="handleChangePasswordConfirmation"
    >
      Are you sure you want to change password ?
    </ConfirmDialog>
  </v-card>
</template>
<script>
import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog/ConfirmDialog";

export default {
  name: "ChangePasswordForm",
  components: { ConfirmDialog },
  data() {
    return {
      currantPassword: "",
      newPassword: "",
      newPasswordRetype: "",
      showChangePasswordConfirmDialog: false,
      isFormValid: false,
      isPasswordChanged: false,
      passwordObj: { oldPassword: "", newPassword: "" },
      passwordRules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 8) || "Minimum 8 characters",
        (value) => {
          const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
          return pattern.test(value) || this.errorMessage;
        },
        (value) =>
            value != this.currantPassword || "Old and new password should not be same.",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Required.",
        (value) =>
            value === this.newPassword || "The password confirmation does not match.",
      ],
      errorMessage: `
                    At least one capital letter (A to Z).\n
                    At least one small letter (a to z)\n
                    At least one digit (0 to 9).\n
                    At least one special character (?=.*[-@_$! %*?&).`,
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required:  v => !!v || "Required.",
      },
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["setMessage"]),
    async changePassword() {
      this.isPasswordChanged = true;
      try {
        this.passwordObj.oldPassword = this.currantPassword;
        this.passwordObj.newPassword = this.newPassword;
        this.passwordObj.newPasswordRetype = this.newPasswordRetype;
        const url = apiEndpoints.changePassword;
        var data = (await API.post(url, this.passwordObj)).data;
        if (data.success === true) {
          this.setMessage({ message: data.message, type: "success", timeout: 10000 });
        } else {
          this.setMessage({ message: "Error while changing password!", type: "error", timeout: 10000 });
        }
      } catch (error) {
        if (error.response.data.message) {
          this.setMessage({message: error.response.data.message, type: "error", timeout: 10000});
        } else {
          this.setMessage({message: "Password changed fail", type: "error", timeout: 10000});
        }
      }
      this.isPasswordChanged = false;
    },
    showChangePasswordConfirmation() {
      this.validate();
      if(this.isFormValid){
        this.showChangePasswordConfirmDialog = true;
      }
    },
    handleChangePasswordConfirmation() {
      this.cancelChangePasswordConfirmation();
      this.changePassword();
    },
    cancelChangePasswordConfirmation() {
      this.showChangePasswordConfirmDialog = false;
    },
    validate () {
      this.$refs.form.validate();
      this.isFormValid = !!this.$refs.form.validate();
    },
  },
};
</script>
<style>
.pre-formatted {
  white-space: pre-line;
  text-align: right;
}
</style>