import { getStudies } from "@/store/RadStudies/RadStudiesService";
import { errorHandler } from "@/store/shared";

const LOAD_RAD_STUDIES = "LOAD_RAD_STUDIES";
const CLEAR_RAD_STUDIES = "CLEAR_RAD_STUDIES";
const UPDATE_TOTAL_STUDIES = "UPDATE_TOTAL_STUDIES";

const RadStudies = {
  namespaced: true,
  state: {
    studiesList: [],
    totalItems: 1,
  },
  actions: {
    async loadStudies({ commit, dispatch }, querySrring) {
      try {
        const { data } = await getStudies(querySrring);
        for (let x=0;x<data.items.length;x++) {
          const typesStr = data.items[x]["requestType"];
          const types = typesStr.split(",");
          var uniqueTypesStr = "";
          var singleType = "";

          for (let i=0;i<types.length;i++){
            if (types[i].includes("LEFT")){
              singleType = getUniqueRequestType(typesStr, types[i], uniqueTypesStr, "LEFT");
              if (uniqueTypesStr.indexOf(singleType) == -1) {
                uniqueTypesStr += singleType;
              }
            } else if (types[i].includes("RIGHT")) {
              singleType = getUniqueRequestType(typesStr, types[i], uniqueTypesStr, "RIGHT");
              if (uniqueTypesStr.indexOf(singleType) == -1) {
                uniqueTypesStr += singleType;
              }
            } else {
              uniqueTypesStr += types[i] + ", ";
            }

          }
          data.items[x]["requestType"] = uniqueTypesStr.substring(0, uniqueTypesStr.lastIndexOf(",")).replaceAll("_", " ");
        }
        commit(LOAD_RAD_STUDIES, data.items);
        commit(UPDATE_TOTAL_STUDIES, data.totalItems);
      } catch (error) {
        errorHandler(error, dispatch, "Error getting study list");
      }
    },
    clearStudies({ commit }) {
      commit(CLEAR_RAD_STUDIES);
    },
  },
  mutations: {
    [LOAD_RAD_STUDIES](state, value) {
      state.studiesList = value;
    },
    [CLEAR_RAD_STUDIES](state) {
      state.studiesList = [];
    },
    [UPDATE_TOTAL_STUDIES](state, value) {
      state.totalItems = value;
    },
  },
};

function getUniqueRequestType(data, type, uniqueTypesStr, side) {
  var singleType = type.substring(0, type.indexOf(side)-1);
  if (data.indexOf(singleType) < data.lastIndexOf(singleType)) {
    return singleType + " BOTH SIDES, ";
  }else {
    return type + ", ";
  }
};

export default RadStudies;
