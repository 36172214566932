<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="signOut">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </template>
    <span>Log out</span>
  </v-tooltip>
</template>

<script>
import Signout from "@/components/Logout/Signout";
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import {mapState} from "vuex";

export default {
  name: "LogOut",
  computed: {
    ...mapState("RadStudies", ["studiesList"]),
  },
  methods: {
    async signOut() {
      await this.releaseAllPickupStudies();
      try {
        const logoutUrl = `${apiEndpoints.logout}`;
        await API.get(logoutUrl).then(() => {
          Signout.methods.signOut();
          Signout.methods.clear();
        }).catch((error) => {
          console.log(error);
        });
      } catch (error) {
        console.log("Error while logout.");
      }
    },
    logout() {
      window.location.href = "/login/logout";
    },
    releaseAllPickupStudies() {
      let studiesIds = [];
      this.studiesList.filter(item => item.pickup === true).forEach(item => {
        studiesIds.push(item.studyId);
      });
      return API.post("/medicalAdmin/releaseAll", {"studiesIds": studiesIds});
    },
  },
};
</script>
