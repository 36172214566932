<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header v-slot="{ open }">
          <template>
            <v-row no-gutters>

              <v-col cols="10">
                <v-icon small>mdi-table-search</v-icon>
                Search For Studies
              </v-col>

              <v-col cols="2" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <v-row v-if="!open" no-gutters style="width: 100%">
                    <v-col>{{ getFilters() }}</v-col>

                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="formData.patientId" label="Patient Id"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="formData.patientName" :rules="nameRules" label="Patient Name"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-combobox v-model="formData.modality" :items="modalityList" @change="loadRequestTypeList()"
                      name="modality" label="Modality" :return-object="false" @input="modalitySearchInput = null"
                      :search-input.sync="modalitySearchInput" multiple hide-selected chips>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-if="item == Object(item)" v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2">
                            {{ item }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-combobox item-text="name" item-value="value" :items="requestTypeList"
                                v-model="formData.requestType" name="requestType" label="Request Type" :return-object="false"
                                @input="requestTypeSearchInput = null" :search-input.sync="requestTypeSearchInput" multiple
                                hide-selected chips>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-if="item == Object(item)" v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2">
                            {{ item }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-switch
                      v-model="containSwitch"
                      :label="`Contain: ${containSwitch.toString()}`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <v-btn class="mr-4" @click="change" v-on:keyup.enter="change" color="info" :disabled="!isFormValid()">
                search
              </v-btn>
              <v-btn @click="clear()" color="grey">
                clear
              </v-btn>
            </v-form>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
import { cloneDeep } from "lodash";
import { RadFilterFormInterface } from "@/util/Interfaces";
import tableQueryParamFilters from "@/util/tableQueryParamFilters";

export default {
  name: "RadFilterForm",
  data: () => ({
    containSwitch: false,
    valid: false,
    formData: cloneDeep(RadFilterFormInterface),
    requestTypeSearchInput: null,
    modalitySearchInput: null,
    selectedValue: null,
    nameRules: [
      v => !v || /^[a-zA-Z0-9]+(([,. -][a-zA-Z0-9 ])? [a-zA-Z0-9]*)*$/.test(v) || 'Name must be valid name',
    ]
  }),
  computed: {
    ...mapState(["requestTypeList", "modalityList"]),
    activeRoute() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(['getRequestTypesList', "getModalitiesList"]),
    isFormValid() {
      return (this.formData.patientName.trim() === "" ||
        /^[a-zA-Z0-9]+(([,. -][a-zA-Z0-9 ])? [a-zA-Z0-9]*)*$/.test(this.formData.patientName));
    },
    loadRequestTypeList() {
      let getRequestTypesListUrl = `${apiEndpoints.getRequestTypesList}`;
      const { modality } = this.formData;
      if (modality && modality !== "" && modality.length > 0) {
        getRequestTypesListUrl += `/${modality}`;
        this.formData.requestType = "";
      }
      this.getRequestTypesList(getRequestTypesListUrl);
    },
    loadModalityList() {
      let getModalitiesListUrl = apiEndpoints.getModalitiesList;
      this.getModalitiesList(getModalitiesListUrl);
    },
    clear() {
      this.formData = cloneDeep(RadFilterFormInterface)
      const { patientIdFilter, modalityFilter, patientNameFilter, requestTypeFilter } = tableQueryParamFilters;
      const query = { ...this.$route.query };
      delete query[patientIdFilter];
      delete query[patientNameFilter]
      delete query[modalityFilter]
      delete query[requestTypeFilter]
      this.$router.push({ name: name, query }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      }
      );
    },
    getFilters() {
      const { patientId, modality, patientName, requestType } = this.formData;

      const patientIdFilter = (patientId && patientId.trim() !== "")
        ? `patientId:${patientId}, `
        : "";

      const patientNameFilter = (patientName && patientName.trim() !== "")
        ? `patientName:${patientName},`
        : "";

      const modalityFilter = (modality && modality !== "" && modality.length > 0)
        ? `modality:${modality}, `
        : "";

      const requestTypeFilter = (requestType && requestType !== "" && requestType.length > 0)
        ? `requestType:${requestType}, `
        : "";

      return `${patientIdFilter} ${patientNameFilter} ${modalityFilter} ${requestTypeFilter}`;
    },
    change() {
      const name = this.activeRoute;
      const query = { ...this.$route.query };
      const { patientId, modality, patientName, requestType } = this.formData;
      const { patientIdFilter, modalityFilter, patientNameFilter, requestTypeFilter } = tableQueryParamFilters;

      if ((patientId && patientId.trim() !== "")) {
        query[patientIdFilter] = `${patientId}-T-F`; // patientId val, regex, smart
      } else {
        delete query[patientIdFilter]
      }

      if (patientName && patientName.trim() !== "") {
        query[patientNameFilter] = `${patientName}-T-F`; // patientName val, regex, smart
      } else {
        delete query[patientNameFilter];
      }
      if (modality && modality !== "" && modality.length > 0) {
        query[modalityFilter] = `${modality.toString()}`;
      } else {
        delete query[modalityFilter];
      }
      if (requestType && requestType !== "" && requestType.length > 0) {
        if (this.containSwitch)
          query[requestTypeFilter] = `${requestType.toString()}-T-F`;
        else
          query[requestTypeFilter] = `${requestType.toString()}`;
      } else {
        delete query[requestTypeFilter];
      }
      this.$router.push({ name: name, query }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });

    },
  },
  mounted() {
    this.loadRequestTypeList();
    this.loadModalityList();
    this.clear();
  }
}
</script>

<style scoped>
</style>