<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title class="px-5"> Add a tag to study</v-card-title>
    <v-card-text class="px-5">
      <br/>
      <v-combobox
          v-model="selectedItem"
          label="Choose a tag"
          outlined
          :items="systemTags"
          chips
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue-grey lighten-4" outlined text @click="hideTagsDialog"
      >Cancel
      </v-btn>
      <v-btn
          color="blue-grey darken-4"
          :disabled="!selectedItem"
          @click="confirm"
      >Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import {mapActions, mapState} from "vuex";

export default {
  name: "TagsComponent",
  props: ["hideTagsDialog", "saveReport", "endReviewAction"],
  data() {
    return {
      selectedItem: null,
      systemTags: ["Error", "Not Error"],
      loading: false,
    };
  },
  computed: {
    ...mapState(["study"]),
  },
  methods: {
    ...mapActions(["setMessage"]),
    async confirm() {
      if (this.isTagIncluded(this.selectedItem)) {
        this.hideTagsDialog();
        await this.saveReport(false);
        await this.endReviewAction();
        return;
      }

      this.loading = true;
      let data = {
        studyId: this.study.id,
        tags: this.getUpdatedTags(),
      };
      const response = await API.post(apiEndpoints.saveTagsUrl, data);
      if (response.status === 200) {
        this.loading = false;
        this.hideTagsDialog();
        this.setMessage({message: `Tags on study updated`, type: "success"});
        await this.saveReport(false);
        await this.endReviewAction();
      }
    },
    isTagIncluded(item) {
      const tagsArray = this.study.tags.split(',').map(tag => tag.trim());
      return tagsArray.includes(item);
    },
    getUpdatedTags() {
      return this.study.tags
          .split(',')
          .map(item => item.trim())
          .filter(item => !this.systemTags.includes(item))
          .join(', ') + "," + this.selectedItem;
    }
  },
  mounted() {
    if (this.isTagIncluded(this.systemTags[0])) {
      this.selectedItem = this.systemTags[0];
    } else if (this.isTagIncluded(this.systemTags[1])) {
      this.selectedItem = this.systemTags[1];
    }
  },
};
</script>

<style scoped></style>
