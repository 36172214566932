const SAVE_LOADED_STUDY = "SAVE_LOADED_STUDY";
const CLEAR_LOADED_STUDIES = "CLEAR_LOADED_STUDIES";

export default {
  namespaced: true,
  state: {
    loadedStudies: {},
  },
  actions: {
    saveLoadedStudy({ commit }, studyId) {
      commit(SAVE_LOADED_STUDY, studyId);
    },
    clearLoadedStudies({ commit }) {
      commit(CLEAR_LOADED_STUDIES);
    },
  },
  mutations: {
    [SAVE_LOADED_STUDY](state, id) {
      state.loadedStudies = { ...state.loadedStudies, [id]: true };
    },
    [CLEAR_LOADED_STUDIES](state) {
      state.loadedStudies = [];
    },
  },
  getters: {
    isMainStudyLoaded({ loadedStudies }, getters, rootState, rootGetters) {
      return loadedStudies?.[rootGetters.studyId];
    },
  },
};
