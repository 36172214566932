<template>
  <amplify-section class="hydrated">
    <div>
      <div class="form-section-header">
        <h3 class="header">
          Send Username
        </h3>
      </div>
    </div>
    <label class="phoneNumberLabel">Phone Number *</label>
    <input class="hydrated"
           placeholder="Example: +101234567890"
           @input="validateInput"
           v-model="phoneNumber"
           @click="removeErrorMsg"
           type="text">
    <div>
      <p v-if="phoneNumber.length !== 0 && !isValidPhoneNumber" style="font-size: 14px; color: grey;">
        * Please Enter a phone number, including + and the country code
        , for example +201234567890.
      </p>
      <p v-if="!phoneNumberExist" style="font-size: 14px; color: grey;">
        * Phone number not found
      </p>
      <p v-if="isTimerRunning" style="font-size: 14px; color: grey;"> Message sent! <br> If you didn't receive please try to send again in <span style="color:white; font-weight: bold">{{ timer }}</span>  seconds</p>
      <div class="form-section-footer">
        <amplify-button data-test="forgot-password-forgot-password-button" class="hydrated"
                        :disabled="!isValidPhoneNumber || isTimerRunning" @click="sendUsername">
          Send Username
        </amplify-button>
        <button class="anchor" type="button" @click="backToSignIn">Back to Sign In</button>
      </div>
    </div>
  </amplify-section>
</template>

<script>
import apiEndpoints from "@/util/apiEndpoints";
import axios from "axios";

export default {
  name: "ForgotUsername",
  data() {
    return {
      phoneNumber: '',
      isValidPhoneNumber: false,
      phoneNumberExist: true,
      timer: 120,
      isTimerRunning: false,
      storedNumber: 0,
    };
  },
  methods: {
    validateInput() {
      // Remove non-numeric characters from the input
      this.phoneNumber = this.phoneNumber.replace(/[^\d+]/g, '');
      this.isValidPhoneNumber = /[+][0-9]/.test(this.phoneNumber) && this.phoneNumber.length >= 13;
    },
    async sendUsername() {
      await this.isPhoneNumberHasUser();
      if(this.phoneNumberExist){
        this.startTimer();
      }
    },
    backToSignIn() {
      this.$root.$emit("showForgotUsername", false);
    },
    async isPhoneNumberHasUser(){
      const url = `${apiEndpoints.sendUserName}${this.phoneNumber}`;
      const response = await axios.post(url);
      this.phoneNumberExist = response.data;
    },
    startTimer() {
      if (!this.isTimerRunning) {
        this.isTimerRunning = true;
        const interval = setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
            const currentDate = new Date();
            localStorage.setItem('lastRefreshed', currentDate.toISOString());
            localStorage.setItem('timer', this.timer.toString());
          } else {
            clearInterval(interval);
            this.timer = 120;
            this.isTimerRunning = false;
            localStorage.removeItem('lastRefreshed');
            localStorage.removeItem('timer');
          }
        }, 1000);
      }
    },
    calculateTimer() {
      const savedDate = new Date(localStorage.getItem('lastRefreshed'));
      const savedTimer = localStorage.getItem('timer');
      if(savedDate && savedTimer){
        const timeDifferenceInSeconds = Math.floor((Date.now() - savedDate) / 1000);
        if(timeDifferenceInSeconds >= savedTimer){
          this.timer = 120;
          this.isTimerRunning = false;
        }else {
          this.timer = savedTimer - timeDifferenceInSeconds;
          this.startTimer()
        }
      }
    },
    removeErrorMsg (){
      this.phoneNumberExist = true;
    }
  },
  mounted() {
    this.calculateTimer();
  },
}
</script>

<style scoped>
.form-section-header .header {
  color: gray;
  font-size: 1.15rem;
}


input {
  color: gray;
  background-color: white;
  border: 1px solid #c4c4c4;
  margin: 0 0 0.625rem 0;
  padding: 1rem;
  width: 100%;
  outline: none;
  border-radius: 5px;
}

.phoneNumberLabel {
  color: gray;
  font-size: 1rem;
  margin-bottom: 10px;
}

</style>
<style>

.section {
  display: grid;
}

</style>