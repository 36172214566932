/* eslint-disable */
import { Amplify, Hub, Auth } from "aws-amplify";
import "@rology-aws-amplify/ui-components";
import {
  applyPolyfills,
  defineCustomElements,
} from "@rology-aws-amplify/ui-components/loader";
import Vue from "vue";
import router from "@/router";
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import axios from "axios";
import { UAParser } from "ua-parser-js";

export function amplifyConfigure(response) {
  Amplify.configure({
    Auth: {
      region: response.data.region,
      userPoolId: response.data.poolId,
      userPoolWebClientId: response.data.clientId,
      mandatorySignIn: false,
      cookieStorage: {
        domain: response.data.endpoint,
        path: "/",
        expires: 15,
        secure: true,
      },
      authenticationFlowType: "USER_SRP_AUTH",
      clientMetadata: { myCustomKey: "myCustomValue" },
      // oauth: {
      //   domain: response.data.endpoint,
      //   scope: [
      //     "phone",
      //     "email",
      //     "profile",
      //     "openid",
      //     "aws.cognito.signin.user.admin"
      //   ],
      //   redirectSignIn: [response.data.redirectSignIn],
      //   redirectSignOut: [response.data.redirectSignOut],
      //   responseType: "code",
      // },
    },
  });
}

export function amplifySetup() {

  Vue.config.ignoredElements = [/amplify-\w*/];
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });

  const listener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        Auth.currentUserInfo().then(async (data) => {
          $(".new-user").hide();
          $(".reserved").hide();

          // call backend to authenticate user
          const url = `${apiEndpoints.authenticateCognitoUser}`;
          const response = await API.get(url);

          if (response.data.success == "true") {

            localStorage.role = response.data.role;
            localStorage.subjectId = response.data.subjectId;
            await axios.get("https://ipinfo.io/", {timeout: 1000}).then(async function (responseloc) {
              logUserDetails(responseloc.data);
            }).catch(async function (error) {
              console.log('Error get location', error.message);
              await axios.get("https://api.ipify.org?format=json", {timeout: 1000}).then(async function (responseip) {
                logUserDetails(responseip.data);
              })
              .catch(function (error) {
                console.log('Error detect ip address', error.message);
              });
            });

            if (localStorage.role == "R")
              router.push('/radiologist-studies');
            else
              window.location.href = "/";

          }
        });
        break;
      case "signOut":
        localStorage.role = "";
        window.location.href = "/portal/login";
        break;
      case 'tokenRefresh':
        console.log("Token Refresh succeeded");
        break;
      case 'tokenRefresh_failure':
        console.log('token refresh failed');
        break;
    }
  };

  Hub.listen("auth", listener);
}

async function logUserDetails(data){
  // get user location and log this info
  let parser = new UAParser();
  var location = data;
  location['browser_name'] = parser.getBrowser().name;
  location['os_name'] = parser.getOS().name;
  location['eventType'] = "LOGIN";
  const loggingUrl = `${apiEndpoints.logUserInfoDetails}`;
  await API.post(loggingUrl, location).catch(function (error) {
    console.log('Error save log event', error.message);
  });
}