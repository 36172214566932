<template>
  <v-dialog v-model="dialog" width="900" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-card>
      <v-card-title class="pt-5 pb-5">
        <v-row>
          <span>Manage Shorthand</span>
          <v-spacer />
          <v-select
            dense
            item-text="name"
            item-value="id"
            :items="shorthandList"
            label="Shorthands List"
            outlined
            v-model="selectedShorthand"
            @change="loadShorthand"
            value="Select One"
          />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Shorthand Name" outlined v-model="shorthandName" />
        <div>
          <label>Shorthand Contents</label>
          <Quill type="shorthand" @onEditorChange="onEditorChange" :contents="shorthandContents" />
        </div>
      </v-card-text>
      <v-card-actions class="buttonsDiv">
        <v-spacer />
        <v-btn class="shorthandBtn" color="primary" @click="clear" :disabled="disableClear">New shorthand</v-btn>
        <v-btn class="shorthandBtn" color="success" @click="save" :disabled="disableSave">Save Changes</v-btn>
        <v-btn class="shorthandBtn" color="error" @click="remove" :disabled="disableRemove">Delete Shorthand</v-btn>
        <v-btn class="shorthandBtn" color="secondary" @click="cancel">Cancel</v-btn>
      </v-card-actions>
      <v-progress-linear :active="apiCall" indeterminate absolute bottom color="light-blue darken-4" />
    </v-card>
  </v-dialog>
</template>
<script>
import Quill from "@/components/Quill";
import { mapState, mapActions } from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
import dialogMixins from "@/shared/dialog-mixins";

export default {
  name: "ManageShorthand",
  components: { Quill },
  mixins: [dialogMixins],
  data() {
    return {
      dialog: true,
      shorthandName: "",
      shorthandContents: "",
      shorthandContentsBuffer: "",
      selectedShorthand: null,
    };
  },
  mounted() {
    this.getAllShorthandList()
  },
  methods: {
    ...mapActions(["saveShorthand", "deleteShorthand", "getShorthandList", "toggleShorthandDialog"]),
    onEditorChange(contents) {
      this.shorthandContentsBuffer = contents;
    },
    clear() {
      this.shorthandName = "";
      this.shorthandContentsBuffer = "";
      this.shorthandContents = "";
      this.selectedShorthand = null;

      var element = document.getElementsByClassName("ql-editor");
      element[0].innerHTML = "";
    },
    async save() {
      let data = { name: this.shorthandName, text: this.shorthandContentsBuffer };
      await this.saveShorthand({ url: apiEndpoints.saveShorthand, data });
      await this.getShorthandList(apiEndpoints.shorthandList);
      this.clear();
    },
    async remove() {
      let url = apiEndpoints.deleteShorthand + this.shorthandName;
      await this.deleteShorthand(url);
      await this.getShorthandList(apiEndpoints.shorthandList);
      this.clear();
    },
    cancel() {
      this.toggleShorthandDialog();
    },
    loadShorthand() {
      let selectedItem = this.shorthandList.find((item) => item.id === this.selectedShorthand);
      this.shorthandName = selectedItem.name;
      this.shorthandContents = selectedItem.value;
    },
    async getAllShorthandList() {
      this.shorthandList = await this.getShorthandList(apiEndpoints.shorthandList);
    },
  },
  computed: {
    ...mapState(["shorthandList", "apiCall", "isShorthandsDialogOpen"]),
    disableSave() {
      return this.shorthandName.length === 0 || this.shorthandContentsBuffer.length === 0;
    },
    disableRemove() {
      if (this.selectedShorthand === null || !this.shorthandName.length) {
        return true;
      } else if (this.selectedShorthand && this.shorthandName.length === 0) {
        return true;
      }
      return false;
    },
    disableClear() {
      return this.shorthandName.length === 0 && this.shorthandContentsBuffer.length === 0;
    },
  },
};
</script>
<style>


@media only screen and (max-device-width: 680px) {

  .buttonsDiv {
    display: grid !important;
  }

  .shorthandBtn {
    width: 100%;
    margin: 0% 0% 2% 0% !important;
  }
}

</style>