import { Auth } from "aws-amplify";

export function setupAuthorization(http) {
  http.interceptors.request.use(
    async function (config) {
      const res = await Auth.currentSession();
      let accessToken = res.getAccessToken();
      config.headers.Authorization = 'Bearer ' + accessToken.getJwtToken();
      return await Promise.resolve(config);
    },
    function (err) {
      console.log("err");
      console.log(err);
      return Promise.reject(err);
    }
  );
}

export function setupErrorHandler(http) {
  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let requiresAuth = true;
      // TODO case when status undefined
      if (error.response.status === 401 && requiresAuth) {
        return Promise.resolve(Auth.signOut());
      }
      return Promise.reject(error);
    }
  );
}
