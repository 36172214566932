<template>
  <v-dialog v-model="model" max-width="400">
    <v-card>
      <v-card-title class="px-5" style="color: red">Warning</v-card-title>
      <v-card-text class="px-5" style="text-align: center;">
        <p> You have left this browser idle for {{ idleTime / ( 60*1000 ) }} minutes, the browser will logout after:
        </p>
        {{ time / 1000 }} second
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "IdleComponent",
  props: ["signOut", "idleTime"],
  data() {
    return {
      model: true,
      time: 10000,
    }
  },
  methods: {
    isIdle() {
      const lastActivity = parseInt(localStorage.lastActivity, 10);
      return Date.now() - lastActivity > this.idleTime;
    },
  },
  mounted() {
    this.time = 10000;
    let timerId = setInterval(async () => {
      console.log(this.time, this.isIdle())
      this.time -= 1000;
      if (!this.isIdle()) clearInterval(timerId);

      if (this.time < 1) {
        clearInterval(timerId);
        await this.signOut();
      }
    }, 1000);
  },
}
</script>

<style scoped>

</style>