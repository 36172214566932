import * as mutationTypes from "@/store/mutation-types";
import * as mutations from "@/store/mutation-types";

export default {
  namespaced: true,
  state: {
    isLocalStorageAvailable: false,
  },
  actions: {
    setLocalStorageFlag: ({ commit }, value) => {
      commit(mutationTypes.SET_LOCAL_STORAGE_FLAG, value);
    },
    triggerStorageActions: ({ dispatch }) => {
      setTimeout(() => {
        dispatch("Layout/toggleReportWindow", null, { root: true });
        dispatch("Layout/popInSidebar", null, { root: true });
      }, 500);
    },
  },
  mutations: {
    [mutations.SET_LOCAL_STORAGE_FLAG](state, value) {
      state.isLocalStorageAvailable = value;
    },
  },
};
