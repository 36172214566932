<template>
  <div class="image" :style="style" />
</template>

<script>
import { RologyDark } from "@/plugins/themes";

export default {
  name: "PixelDataToImage",
  props: {
    imageObject: {
      type: Object,
      default: () => {},
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageSrc: "",
    };
  },
  computed: {
    style() {
      const { imageSrc, isHighlighted } = this;
      return {
        backgroundImage: `url(${imageSrc})`,
        borderColor: `${isHighlighted ? RologyDark.primary : "#ccc"}`,
      };
    },
  },
  methods: {
    buildImage() {
      const { width, height, getPixelData } = this.imageObject;
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const pixelData = getPixelData();
      const ctx = canvas.getContext("2d");
      const imageData = ctx.getImageData(0, 0, width, height);

      for (let i = 0; i < pixelData.length; i++) {
        const newIndex = i * 4;
        imageData.data[newIndex] = pixelData[i]; // red
        imageData.data[newIndex + 1] = pixelData[i]; // green
        imageData.data[newIndex + 2] = pixelData[i]; // blue
        imageData.data[newIndex + 3] = 255; // alpha
      }
      ctx.putImageData(imageData, 0, 0);
      canvas.toBlob((blobFile) => {
        this.imageSrc = URL.createObjectURL(blobFile);
      });
    },
  },
  created() {
    if (this.imageObject) {
      this.buildImage();
    }
  },
};
</script>
<style scoped lang="scss">
.image {
  background-color: black;
  display: inline-block;
  width: 250px;
  height: 130px;
  border-radius: 4px;
  border: 2px solid;
  background-position: center;
  background-size: contain;
}
</style>
