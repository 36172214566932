<template>
  <v-dialog v-model="dialog" max-width="420" persistent>
    <v-card>
      <v-card-title class="headline" style="text-transform: capitalize">{{ action.title }}</v-card-title>
      <v-card-text>
        Are you sure that you want to mark the case of
        <strong style="text-transform: capitalize" class="red--text text--lighten-1">{{
            study.patientName.toLowerCase()
        }}</strong>
        with request type
        <strong style="text-transform: capitalize" class="blue--text text--darken-1">{{
            studyInfo.requestType.replaceAll('_', ' ').replaceAll(',', " , ").toLowerCase()
        }}</strong>
        as <strong style="text-transform: capitalize">{{ action.status }}</strong>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
        <v-btn color="blue-grey darken-4" @click="confirm">Yes</v-btn>
      </v-card-actions>
      <v-progress-linear :active="apiCall" indeterminate absolute bottom color="light-blue darken-4" />
    </v-card>
  </v-dialog>
</template>
<script>
// TODO: change status api is not secure, status can be manipulated
import {mapState, mapActions, mapGetters} from "vuex";
import apiEndpoints from "@/util/apiEndpoints";
import API from "@/shared/API";


export default {
  name: "Invalidate",
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    action: {
      type: Object,
      default: () => ({ title: "", status: "", to: "", url: "" }),
    },
  },
  methods: {
    ...mapActions(["updateStudy", "getStudyReportsList"]),
    cancel() {
      this.resetAction();
    },
    async confirm() {
      // extract study data
      let data = {
        studyId: this.study.id,
        from: this.study.status,
      };
      if (this.action.to !== undefined) {
        data.to = this.action.to;
      }
      if (this.action.status === "emergency" || this.action.status === "normal") {
        await this.updateStudy({ url: `${this.action.url}/${this.study.id}/emergency` });
      } else if (this.action.status === "release" && this.study.status.toLowerCase() === "parked") {
        await this.updateStudy({ url: this.action.fromParkedUrl, data });
      } else if (this.action.status === "finished") {
        await this.updateStudy({ url: this.action.url, data });
      } else if (this.action.status === "finish without review") {
        await this.updateStudy({ url: `${this.action.url}${this.study.id}` });
        this.$root.$emit("showGameBallRadsForm");
      } else if (this.action.status === "review") {
        this.dialog = false;
        let report = await API.get(apiEndpoints.getReportsForMa + this.study.id);
        let reportHasHtml = report.data[0].reportData.isHTML
        if(!reportHasHtml) {
          this.clearReportsLocalStorage(data.studyId)
          await this.updateStudy({url: this.action.url, data});
        } else {
          this.$root.$emit("showChangeViewerDialog", data);
        }
      } else {
        await this.updateStudy({ url: this.action.url, data });
      }
      this.resetAction();
    },
    resetAction() {
      this.$emit("resetAction");
    },
    clearReportsLocalStorage(studyId) {
      Object.keys(localStorage).forEach(element => {
        if(element.includes("STUDY_REPORT_"))
          if(localStorage.getItem(element).includes(studyId))
            localStorage.removeItem(element);
      });
      localStorage.removeItem(`STUDY_REPORT_NUMBER_${studyId}`);
    },
  },
  computed: {
    ...mapGetters(["studyInfo"]),
    ...mapState(["study", "apiCall"]),
  },
};
</script>

<style scoped>
</style>
