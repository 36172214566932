<template>
  <div class="quill-editor-container" ref="quillEditorContainer">
    <quillEditor
      :class="{ invalid: invalid || isEditorInvalid, 'is-disabled': disableEditor }"
      ref="quillInstance"
      :options="options"
      :content="contents"
      @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
      style="white-space: pre-line"
    />
    <span class="pl-3 caption error--text" v-if="invalid || isEditorInvalid">Field is required</span>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import "quill-mention";
import {
  textEventsHandler,
  disableTabKey,
  declareCustomIcons,
  getQuillBindings,
  quillFullScreenClassName,
} from "@/util/common-Quill";
import { mapGetters } from "vuex";
const evenAbortController = new AbortController();

let toolbar = declareCustomIcons();
export default {
  name: "Quill",
  components: { quillEditor },
  props: {
    type: {
      type: String,
      default: "",
    },
    contents: {
      type: String,
      default: "",
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    isEditorInvalid: {
      type: Boolean,
      default: false,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["quillShorthands"]),
    editor() {
      return this.$refs.quillInstance.quill;
    },
    options() {
      let mention = {
        allowedChars: /^[A-Za-z0-9@#$%^&()+=-_.\s]*$/,
        mentionDenotationChars: ["/"],
        showDenotationChar: false,
        source: this.onSourceHandler,
        onSelect: this.onSelectHandler(),
        onOpen: this.onOpenHandler(),
        positioningStrategy: "absolute",
        mentionListClass: "pa-0",
      };
      let options = {
        modules: {
          toolbar,
        },
        placeholder: `Write your ${this.type}...`,
        keyboard: {
          bindings: getQuillBindings(),
        },
      };
      if (this.type !== "shorthand") {
        options.modules.mention = mention;
      }
      return options;
    },
  },
  data() {
    return {
      startIndex: -1,
      invalid: false,
    };
  },
  methods: {
    onEditorChange({ html }) {
      this.$emit("onEditorChange", html);
    },
    onEditorBlur(quill) {
      if (this.validateOnBlur) this.invalid = quill.getLength() <= 1;
    },
    onSourceHandler(searchTerm, renderList) {
      let values = this.quillShorthands;
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
    onOpenHandler() {
      let self = this;
      return function () {
        let { editor, startIndex } = self;
        let selection = editor.getSelection(true);
        if (startIndex === -1) {
          self.startIndex = selection.index;
        }
      };
    },
    onSelectHandler() {
      let self = this;
      return function (item) {
        let { editor, quillShorthands } = self;
        let value = quillShorthands[item["id"]]["text"];
        let selection = editor.getSelection(true);
        editor.clipboard.dangerouslyPasteHTML(selection.index, value);
        editor.deleteText(self.startIndex - 1, selection.index - self.startIndex + 1);
        self.startIndex = -1;
      };
    },
    exitFullscreenHandler() {
      document.addEventListener(
        "fullscreenchange",
        () => {
          if (!document.fullscreenElement) {
            this.editor.container.parentElement.classList.remove(quillFullScreenClassName);
          }
        },
        { signal: evenAbortController.signal }
      );
    },
  },
  mounted() {
    let editor = this.editor;
    window.myEditor = editor;

    // write space and delete it, to let Quill to detect @change event to re-initialize right html of quill content
    if (editor.getText().trim()) {
      this.editor.insertText(0, " ", true);
      this.editor.deleteText(0, 1);
    }
    disableTabKey(editor);
    window.jQuery(this.editor.container).on("change keyup paste", function (e) {
      textEventsHandler(e, editor);
    });
    this.exitFullscreenHandler();
    if (this.disableEditor) {
      this.editor.enable(false);
    }
  },
  destroyed() {
    evenAbortController.abort();
  },
};
</script>
<style lang="css" src="quill/dist/quill.core.css"></style>
<style lang="css" src="quill/dist/quill.snow.css"></style>
<style lang="css" src="quill/dist/quill.bubble.css"></style>
<style lang="css" src="quill-mention/dist/quill.mention.min.css"></style>
<style lang="scss">
.theme--dark {
  .ql-picker-label {
    color: #cdd979;
  }
}

.quill-editor-container {
  width: 100%;
  margin-bottom: 15px;

  .error--text {
    font-size: 12px;
    font-weight: 400;
  }

  .ql-editor {
    background-color: #e9edef;
  }
}

.ql-toolbar {
  button[class^="ql-"] {
    padding: 0;

    span {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        color: #cdd979;
      }

      path:hover {
        fill: blue;
      }
    }
  }
}

.ql-editor.ql-blank {
  &::before {
    color: #ddd;
  }
}

.quill-editor {
  .ql-toolbar,
  .ql-container {
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.24);
  }

  .ql-toolbar {
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    font-size: 16px;
    color: rgb(28, 27, 27);
    border-radius: 0 0 4px 4px;
  }
}

.quill-editor.invalid {
  .ql-toolbar,
  .ql-container {
    border-width: 2px;
    border-color: rgb(255, 82, 82);
  }
}

.quill-editor.is-disabled * {
  outline: none;
}

.ql-exitFullscreen {
  display: none !important;
}

.in-fullscreen-mode {
  .ql-fullscreen {
    display: none !important;
  }

  .ql-exitFullscreen {
    display: block !important;
  }
}
</style>
