import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      componentName: "",
      clientXStart: 0,
      width: 0,
      noTransitionClass: "resizing-items",
    };
  },
  computed: {
    ...mapState("Layout", ["viewerBarHeight", "drawers"]),
    drawer() {
      const item = this.drawers.find(({ name }) => name === this.componentName);
      return item ? item : null;
    },
    style() {
      const { visible, isPinned } = this.drawer;
      let zIndex = 4;
      if (!isPinned && visible) {
        zIndex = 6;
      } else if (!isPinned && !visible) {
        zIndex = 2;
      }
      return { height: `calc(100vh - ${this.viewerBarHeight}px)`, maxHeight: "initial", zIndex };
    },
  },
  methods: {
    ...mapActions("Layout", ["togglePin", "toggleSidebarVisibility", "setSidebarWidth", "popOutSidebar"]),
    pin() {
      this.togglePin(this.drawer);
    },
    popOut() {
      this.popOutSidebar(this.drawer);
    },
    mouseLeave() {
      const reportForm = document.activeElement.closest("#studyReportForm");
      if (!this.drawer.isPinned && !reportForm) {
        this.toggleSidebarVisibility(this.drawer);
      }
    },
    mouseDownHandler($event) {
      $event.stopPropagation();
      this.clientXStart = $event.clientX;
      document.body.classList.add(this.noTransitionClass);
      this.registerMouseEvents();
    },
    dragEndHandler() {
      document.body.classList.remove(this.noTransitionClass);
      this.setSidebarWidth({ ...this.drawer, width: this.width });
    },
    calculateWidth(clientX) {
      const { drawer } = this;
      let width = 0;
      if (drawer.right) {
        if (clientX < this.clientXStart) {
          // increase width
          width = this.clientXStart - clientX + drawer.width;
        } else {
          width = drawer.width - (clientX - this.clientXStart);
        }
      } else {
        if (clientX < this.clientXStart) {
          // decrease width
          width = drawer.width - (this.clientXStart - clientX);
        } else {
          width = clientX - this.clientXStart + drawer.width;
        }
      }
      if (width > 250 && width < Math.round(window.outerWidth / 2)) {
        this.width = width;
      }
      window.dispatchEvent(new Event("resize"));
    },
    mousemoveHandler(event) {
      this.calculateWidth(event.clientX);
    },
    registerMouseEvents() {
      document.addEventListener("mouseup", this.mouseupHandler, false);
      document.addEventListener("mousemove", this.mousemoveHandler, false);
    },
    mouseupHandler(event) {
      document.removeEventListener("mousemove", this.mousemoveHandler, false);
      this.dragEndHandler(event);
    },
  },
  created() {
    this.width = this.drawer.width;
  },
  destroyed() {
    document.removeEventListener("mouseup", this.mouseupHandler, false);
  }
};
