<template>
  <v-dialog v-model="dialog" width="1200" @keydown.esc="cancel" v-click-outside="clickOutside">
    <v-form :disabled="disabled">
      <v-card>
        <v-card-title>Clinical Info</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined tile>
                <v-card-title>Request types</v-card-title>
                <v-card-text>
                  <v-autocomplete label="Request Type" item-text="name" item-value="value" :items="requestTypeList" outlined
                    item-color="primary" validate-on-blur required :return-object="false" v-model="formData.requestType"
                    cache-items :error="!isFormValid" hide-selected flat multiple small-chips solo>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-if="item == Object(item)" v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2">
                          {{ item.name }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <div >
                    <label for="phone">Phone Number:</label>
                    <vue-phone-number-input
                        id="phone"
                        v-model="formData.patientPhoneNumber"
                        :inputOptions="phoneOptions"
                        :default-country-code="formData.patientPhoneNumber ? countryCode : formData.countryCP.toUpperCase()"
                        color="#FF9933"
                        dark
                        :required="formData.onlineReport"
                        dark-color="#303144"
                        @update="onUpdate"
                    ></vue-phone-number-input>
                  </div>
                  <v-radio-group dense label="With contrast" row v-model="formData.contrast">
                    <v-radio label="No" value="No" />
                    <v-radio label="Yes" value="Yes" />
                  </v-radio-group>
                  <v-radio-group dense label="Previous operation(s)?" row v-model="formData.operation">
                    <v-radio label="No" value="No" />
                    <v-radio label="Yes" value="Yes" />
                  </v-radio-group>
                  <v-expand-transition>
                    <v-textarea outlined denseClinical Info auto-grow rows="2" v-if="formData.operation === 'Yes'"
                      placeholder="Please specify previous operations and date" v-model="formData.operationDetails" />
                  </v-expand-transition>
                  <v-radio-group dense label="Received medication?" row v-model="formData.medication">
                    <v-radio label="No" value="No" />
                    <v-radio label="Yes" value="Yes" />
                  </v-radio-group>
                  <v-expand-transition>
                    <v-textarea outlined dense auto-grow rows="2" v-if="formData.medication === 'Yes'"
                      placeholder="Please specify previous operations and date" v-model="formData.medicationDetails" />
                  </v-expand-transition>

                  <v-row>
                    <v-col cols="3">
                      <v-subheader class="px-0">Referral doctor</v-subheader>
                    </v-col>
                    <v-col>
                      <v-text-field outlined dense append-icon="mdi-doctor" v-model="formData.referralDoctor" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined tile>
                <v-card-text>
                  <v-subheader class="px-0">Complaint and past history</v-subheader>
                  <v-textarea outlined dense auto-grow rows="4" v-model="formData.complaint"
                    placeholder="Please specify patient's complaint, past history and age (if not provided in DICOM) " />
                  <v-card-text>
                    <FileUploader :uploadFileUrl="uploadFileUrl" :removeFileUrl="removeFileUrl"
                      :loadFilesUrl="loadFilesUrl" displayInRows />
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue-grey lighten-4" outlined text @click="cancel">Cancel</v-btn>
          <v-btn color="success" @click="save" :disabled="!isFormValid || !isPhoneNumberValidfn">Save</v-btn>
        </v-card-actions>
        <v-progress-linear :active="loading" indeterminate absolute bottom color="light-blue darken-4" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { ClinicalInfo } from "@/util/Interfaces";
import apiEndpoints from "@/util/apiEndpoints";
import dialogMixins from "@/shared/dialog-mixins";
import FileUploader from "@/components/StudyReport/FileUploader";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import parsePhoneNumber from 'libphonenumber-js';


export default {
  name: "ClinicalInfo",
  components: { FileUploader, VuePhoneNumberInput},
  data() {
    return {
      dialog: true,
      disabled: true,
      loading: true,
      formData: cloneDeep(ClinicalInfo),
      phoneOptions: {
        showDialCode: true,
      },
      results: {},
      countryCode: "",
      phoneNumber:"",
      countryCallingNumber:"",
    };
  },
  mixins: [dialogMixins],
  computed: {
    ...mapState(["requestTypeList", "clinicalInfo", "study"]),
    ...mapGetters(["studyId"]),
    uploadFileUrl() {
      return apiEndpoints.uploadAttachments + this.studyId;
    },
    removeFileUrl() {
      let qParameters = `studyId=${this.studyId}`;
      return `${apiEndpoints.deleteAttachments}/uuidPlaceholder?qqfilename=fileNamePlaceholder&${qParameters}`;
    },
    loadFilesUrl() {
      return apiEndpoints.getAttachments + this.studyId;
    },
    isFormValid() {
      return this.formData.requestType.length;
    },
    isPhoneNumberValidfn() {
      if(this.results.phoneNumber) {
        return this.results.isValid;
      }
      else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["getClinicalInfo", "saveClinicalInfo", "getRequestTypesList"]),
    cancel() {
      this.resetAction();
    },
    async save() {
      const url = apiEndpoints.saveClinicalInfo;
      this.formData._id = this.studyId;
      this.formData.patientPhoneNumber = this.results.formattedNumber;
      this.formData.requestType.sort();
      await this.saveClinicalInfo({ url, data: this.formData });
      this.resetAction();
    },
    async loadMedicalInfo() {
      const url = apiEndpoints.getClinicalInfo + this.studyId;
      await this.getClinicalInfo(url);
      this.loading = false;
      this.disabled = false;
    },
    resetAction() {
      this.$emit("resetAction");
    },
    onUpdate (payload) {
      this.results = payload
    },
  },
  mounted() {
    this.loadMedicalInfo();
  },
  watch: {
    clinicalInfo(newValue) {
      if(newValue.patientPhoneNumber){
        this.phoneNumber = parsePhoneNumber(newValue.patientPhoneNumber);
        this.countryCallingNumber = this.phoneNumber.countryCallingCode;
        this.phoneNumber = parsePhoneNumber(newValue.patientPhoneNumber, this.countryCallingNumber);
        this.countryCode = this.phoneNumber.country;
      }
      this.formData = cloneDeep(newValue);

    },
  },
};
</script>
