<template>
  <v-card :loading="loading" v-if="this.currentUser">
    <v-card-text>
      <template>
        <v-data-table
            :headers="computedHeaders"
            :items="items"
            :item-key="items.studyId"
            :item-class="rowClass"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:options="handleOptionsUpdate"
            :footer-props="{'items-per-page-options': [1, 5, 10, 25, 50, 100]}"
            :items-per-page="5"
            class="study-list-table"
            style="cursor:pointer"
            @click:row="openStudy"
        >
        <!-- TODO-->
        <!-- eslint-disable-next-line vue/valid-v-slot  -->
          <template v-slot:item.dicomViewer="{ item }">
            <v-btn :disabled="isRowsDisabled" icon @click.stop="openStudyInNewTab(item)">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <!-- TODO-->
          <!-- eslint-disable-next-line vue/valid-v-slot  -->
          <template v-slot:item.cache="{ item }" v-if="cachedStudiesAdded">
            <div style="display: flex;">
              <v-btn
                  :disabled="isRowsDisabled || (cachedStudiesAdded && cachedStudies.find((i) => i.studyId === item.studyId).disableLoadBtn)"
                  icon @click.stop="cache(item.studyId)">
                <v-icon :title="getCacheState(item.studyId).title">
                  {{ getCacheState(item.studyId).icon }}
                </v-icon>
              </v-btn>
              <v-btn
                  :disabled="isRowsDisabled || (cachedStudiesAdded && cachedStudies.find((i) => i.studyId === item.studyId).disableRemoveBtn)"
                  icon
                  @click.stop="removeCache(item.studyId)">
                <v-icon :title="cacheIcons.find((i) => i.state === 'removeCache').title">
                  {{ cacheIcons.find((i) => i.state === 'removeCache').icon }}
                </v-icon>
              </v-btn>
              <div v-if="getCacheStudy(item.studyId).showPercentage" style="display: flex; align-items: center; text-wrap: nowrap; padding: 5px;">
                {{ getCacheStudy(item.studyId).percentage }}%
              </div>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="sameStudyIsDiagnosingDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="px-5">Warning</v-card-title>
            <v-card-text class="px-5">
              This study is already under diagnosing, please close the other window to avoid any conflicts in your report before open it again
            </v-card-text>
            <v-card-actions style="justify-content: right;">
              <v-spacer />
              <v-btn color="secondary" @click="() => (this.sameStudyIsDiagnosingDialog = false)">Ok</v-btn>
              <v-btn color="blue-grey darken-4" @click="showConfirmForceOpenDialog">Force open</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmForceOpenDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="px-5">Force open</v-card-title>
            <v-card-text class="px-5">
              Are you sure you want to <b> Force open </b> the viewer?
            </v-card-text>
            <v-card-actions style="justify-content: right;">
              <v-spacer />
              <v-btn color="secondary" @click="() => (this.confirmForceOpenDialog = false)">Cancel</v-btn>
              <v-btn color="blue-grey darken-4" @click="forceOpen">Force open</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>

import ClientRoutes from '@/router/ClientRoutes';
import {mapState} from "vuex";
import AiResponseTypes from "@/util/AiResponseTypes";
import API from "@/shared/API";
import apiEndpoints from "@/util/apiEndpoints";
import localforage from "localforage";
import axios from "axios";

const dicoms = localforage.createInstance({
  driver: [localforage.INDEXEDDB],
  name: "Rology IndexedDB",
  storeName: "dicoms",
});

const cacheExpiration = localforage.createInstance({
  driver: [localforage.INDEXEDDB],
  name: "Rology IndexedDB",
  storeName: "cacheExpiration",
});

const cacheDuration = 60 * 60 * 1000; // 1 hour in milliseconds

export default {
  name: "StudyList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: [],
      sortDesc: [],
      sameStudyIsDiagnosingDialog: false,
      isStudyDiagnosing: false,
      studyClicked: "",
      confirmForceOpenDialog: false,
      isOldViewer: false,
      isRowsDisabled: false,
      cacheIcons: [
        {
          "state": "loadCache",
          "icon": "mdi-database-arrow-down",
          "title": "cache study"
        },
        {
          "state": "resumeCache",
          "icon": "mdi-play",
          "title": "resume caching"
        },
        {
          "state": "pauseCache",
          "icon": "mdi-pause",
          "title": "pause caching"
        },
        {
          "state": "removeCache",
          "icon": "mdi-database-remove",
          "title": "remove cache"
        },
      ],
      cachedStudies: [],
      cachedStudiesAdded: false,
    };
  },
  computed: {
    ...mapState([
      "currentUser",
    ]),
    computedHeaders() {
      const headers = [
        { text: "Modality", value: "modality" },
        { text: "Request Type", value: "requestType" },
        { text: "Patient Id", value: "patientId" },
        { text: "Patient Name", value: "patientName" },
        { text: "Gender", value: "patientGender" },
        { text: "Images", value: "numImages" },
        { text: 'Study Viewer', value: 'dicomViewer', sortable: false },
      ];
        if(this.cachedStudiesAdded) {
          headers.push({text: 'Cache', value: 'cache', sortable: false});
      }

      return headers;
    },
  },
  methods: {
    handleOptionsUpdate(){
      if(this.currentUser.allowAiFeedback){
        this.sortBy = ['aiResponse']
        this.sortDesc = [false]
        return
      }
    },
    openStudy: async function (study) {
      this.isOldViewer = false;
      this.studyClicked = study.studyId;
        if (await this.checkAndShowDialog(study.studyId)) {
          return;
        }
      window.open(`/rologyViewer/study/${study.studyId}`, '_blank');
    },
    openStudyInNewTab: async function(study) {
      this.isOldViewer = true;
      this.studyClicked = study.studyId;
      if (await this.checkAndShowDialog(study.studyId)) {
        return;
      }
      this.$router.push({name: ClientRoutes.studyViewer, params: {id: study.studyId}});
    },
    rowClass(item){
      let rowStyle = []
      if(this.currentUser.allowAiFeedback){
        if(item.aiResponse === AiResponseTypes.covid) rowStyle.push('covid-text')
        else if (item.aiResponse === AiResponseTypes.abnormal) rowStyle.push ('abnormal-text')
        else if(item.aiResponse === AiResponseTypes.normal)rowStyle.push ('normal-text')
      }
      if (this.isRowsDisabled) {
        rowStyle.push('disabled-row')
      }
      return rowStyle;

    },
    async isRadDiagnosingStudy(studyId) {
      const response = await API.get(apiEndpoints.isRadDiagnosingStudy + studyId);
      return response.data.isStudyDiagnosing;
    },
    forceOpen() {
      this.confirmForceOpenDialog = false
      this.isOldViewer ?
          this.$router.push({
            name: ClientRoutes.studyViewer,
            params: {id: this.studyClicked}
          }):
          window.open(`/rologyViewer/study/${this.studyClicked}`, '_blank');
    },
    showConfirmForceOpenDialog() {
      this.sameStudyIsDiagnosingDialog = false
      this.confirmForceOpenDialog = true;
    },
    checkAndShowDialog: async function(studyId) {
      if (!this.isRowsDisabled) {
        this.isRowsDisabled = true;
        setTimeout(() => {
          this.isRowsDisabled = false;
        }, 3000);
      }

      this.isStudyDiagnosing = await this.isRadDiagnosingStudy(studyId);
      if (this.isStudyDiagnosing) {
        this.sameStudyIsDiagnosingDialog = true;
        return true;
      }
      return false;
    },
    async loadCase(studyId) {
      const loadCaseResponse = await API.get(`/cases/loadCase/${studyId}`);
      return loadCaseResponse.data.dicom.seriesList;
    },
    getCacheState(studyId) {
      const cachedStudy = this.cachedStudies.find((item) => item.studyId === studyId)
      return cachedStudy ? cachedStudy.cacheState : this.cacheIcons.find((item) => item.state === "loadCache");
    },
    getCacheStudy(studyId) {
      return this.cachedStudies.find((item) => item.studyId === studyId);
    },
    cache(studyId) {
      Promise.all([dicoms.ready(), cacheExpiration.ready()]).then(() => { });

      if (this.getCacheState(studyId).state === "loadCache") {
        this.loadCache(studyId)
      } else if(this.getCacheState(studyId).state === "removeCache") {
        this.removeCache(studyId)
      } else if(this.getCacheState(studyId).state === "pauseCache") {
        this.pauseCache(studyId)
      } else if(this.getCacheState(studyId).state === "resumeCache") {
        this.resumeCache(studyId)
      }
    },
    async loadCache(studyId) {
      this.changeCacheIcon(studyId, "pauseCache");
      let cachedStudy = this.cachedStudies.find((item) => item.studyId === studyId);
      cachedStudy.disableRemoveBtn = true;
      cachedStudy.showPercentage = true;

      const seriesList = await this.loadCase(studyId);

      seriesList.forEach((series) => {
        series.instanceList.forEach((image) => {
          if (!cachedStudy.cachedImageIds || !cachedStudy.cachedImageIds.some(item => image.imageId.includes(item.split('/').pop()))) {
            cachedStudy.imageIds.push({ url: image.imageId, loaded: false });
          }
        });
      });

      let cachedStudyImageIds = cachedStudy.imageIds;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      cachedStudy.cancelTockenSource = source;

      for (const element of cachedStudyImageIds) {
        if (!element.loaded) {
          axios.get(element.url, {responseType: 'arraybuffer', cancelToken: source.token}).then(async (response) => {
            await this.saveInDB(response.request);
            element.loaded = true;

            const dicomKeys = await dicoms.keys();
            let cachedImageIds = dicomKeys.filter(item => item.includes(studyId));
            cachedStudy.percentage = Math.ceil((cachedImageIds.length / cachedStudy.instanceListLength) * 100);

            const hasNotLoadedKeys = cachedStudyImageIds.some(i => i.loaded === false);
            if (!hasNotLoadedKeys) {
              this.changeCacheIcon(studyId, "loadCache");
              cachedStudy.disableLoadBtn = true;
              cachedStudy.disableRemoveBtn = false;
              cachedStudy.showPercentage = false;
            }
          }).catch(function (error) {
            console.log('Error: ', error.message);
          });
        }
      }
    },
    async saveInDB(xhr) {
      let uriPrefix = xhr.responseURL.split("?")[0];
      let uriPrefixArr = uriPrefix.split("/");
      let recordId = uriPrefixArr[uriPrefixArr.length - 2] + "/" + uriPrefixArr[uriPrefixArr.length - 1];
      const value = xhr.response;
      if (value && value instanceof ArrayBuffer && value.byteLength > 0) {
        await dicoms.setItem(recordId, xhr.response);
        await cacheExpiration.setItem(recordId, Date.now() + cacheDuration);
      }
    },
    async removeCache(studyId) {
      const dicomKeys = await dicoms.keys();
      const dicomKeysToRemove = dicomKeys.filter(key => key.includes(studyId));
      const dicomRemovePromises = dicomKeysToRemove.map(key => dicoms.removeItem(key));

      const cacheExpirationKeys = await cacheExpiration.keys();
      const cacheExpirationKeysToRemove = cacheExpirationKeys.filter(key => key.includes(studyId));
      const cacheExpirationRemovePromises = cacheExpirationKeysToRemove.map(key => cacheExpiration.removeItem(key));

      // Wait for all removal operations to complete
      await Promise.all([...dicomRemovePromises, ...cacheExpirationRemovePromises]);

      this.changeCacheIcon(studyId, "loadCache");

      let cachedStudy = this.cachedStudies.find((item) => item.studyId === studyId);
      cachedStudy.disableLoadBtn = false;
      cachedStudy.disableRemoveBtn = true;
      cachedStudy.percentage = 0;
      cachedStudy.showPercentage = false;
    },
    pauseCache(studyId) {
      this.changeCacheIcon(studyId, "resumeCache");

      let cachedStudy = this.cachedStudies.find((item) => item.studyId === studyId);
      cachedStudy.cancelTockenSource.cancel('canceled by user');
      cachedStudy.disableRemoveBtn = false;
    },
    async resumeCache(studyId) {
      this.changeCacheIcon(studyId, "pauseCache");
      await this.loadCache(studyId);
    },
    changeCacheIcon(studyId, state) {
      this.cachedStudies.find((item) => item.studyId === studyId).cacheState = this.cacheIcons.find((item) => item.state === state);
    },
    async cacheInit(){
      const dicomKeys = await dicoms.keys();
      if(this.items.length > 0) {
        for (const element of this.items) {
          const cachedStudy = {};
          let cachedImageIds = dicomKeys.filter(item => item.includes(element.studyId));

          cachedStudy.studyId = element.studyId;
          cachedStudy.imageIds = [];
          cachedStudy.cachedImageIds = cachedImageIds;
          cachedStudy.disableLoadBtn = false;
          cachedStudy.disableRemoveBtn = true;
          cachedStudy.instanceListLength = element.instanceListLength;

          let percentage = (cachedImageIds.length / element.instanceListLength) * 100;
          cachedStudy.percentage = Math.ceil(percentage);
          cachedStudy.showPercentage = false;

          switch (cachedImageIds.length) {
            case 0:
              cachedStudy.cacheState = this.cacheIcons.find((item) => item.state === "loadCache");
              break;
            case parseInt(element.instanceListLength):
              cachedStudy.disableLoadBtn = true;
              cachedStudy.disableRemoveBtn = false;
              cachedStudy.cacheState = this.cacheIcons.find((item) => item.state === "loadCache");
              break;
            default:
              cachedStudy.disableLoadBtn = false;
              cachedStudy.disableRemoveBtn = false;
              cachedStudy.cacheState = this.cacheIcons.find((item) => item.state === "resumeCache");
              cachedStudy.showPercentage = true;
              break;
          }
          this.cachedStudies.push(cachedStudy)
        }
        this.cachedStudiesAdded = true;
      }
    }

  },
  watch: {
    items(newItems){
      if (newItems.length>0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.items = newItems;
        this.cacheInit();
      }
    }
  }
};
</script>

<style>
.study-list-table th{
  font-size: 15px !important;
}
.covid-text {
  color: red;
}
.abnormal-text {
  color: orange;
}
.normal-text {
  color: green;
}
.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}
</style>