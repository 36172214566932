// const viewerInterface = window['VIEWER_INTERFACE'];
// const viewerActions = window['VIEWER_ACTIONS'];
export const handleActiveTool = (tools, activeClass, item, prevItem) => {
  const vueButtonId = `dicom-tool-${item.text.replace(" ", "-")}`;
  const prevVueButtonId = prevItem ? `dicom-tool-${prevItem.text.replace(" ", "-")}` : "";
  const vueButton = document.getElementById(vueButtonId);
  const prevVueButton = document.getElementById(prevVueButtonId);
  const text = item.text.toLowerCase();
  const prevText = prevItem && prevItem.text.toLowerCase();

  // toggle crosshair, localizer & synchronize on/off when layout changes
  if (item.parent !== undefined && item.parent === "layout") {
    toggleSyncTools(tools, item.text, activeClass);
  }

  const doubleActions = ["localizer", "synchronize"];

  if (text !== undefined && doubleActions.includes(text)) {
    const button = window.jQuery(`#${item.target}`);
    button.trigger("click");

    if (text === "synchronize") {
      if (button.find(".fa-unlink").length) {
        // add active class to sync button
        vueButton.classList.add(activeClass);
        // remove class from localize button
        document.getElementById("dicom-tool-localizer").classList.remove(activeClass);
      } else {
        vueButton.classList.remove(activeClass);
      }
    } else if (text === "localizer") {
      if (button.find(".fa-times").length) {
        // add active class to sync button
        vueButton.classList.add(activeClass);
        // remove class from localize button
        document.getElementById("dicom-tool-synchronize").classList.remove(activeClass);
      } else {
        vueButton.classList.remove(activeClass);
      }
    }
  }

  if (!doubleActions.includes(text)) {
    if (!item.action) {
      window.jQuery(`#${item.target}`).trigger("click");
    }
    vueButton.classList.add(activeClass);

    if (vueButtonId === "dicom-tool-crosshair") {
      document.getElementById("dicom-tool-synchronize").classList.remove(activeClass);
    }
  }

  if (prevVueButton && prevVueButtonId !== vueButtonId && !doubleActions.includes(prevText)) {
    prevVueButton.classList.remove(activeClass);
  }
};

/**
 * @description disable dicom tools ( crosshair, localizer, synchronize ) if layout is not 1x1
 * @param {Array} tools
 * @param {String} layout
 * @param {String} activeClass
 */
export function toggleSyncTools(tools, layout, activeClass) {
  const button = document.getElementById("dicom-tool-layout");
  tools.forEach((tool) => {
    let target = tool.text.toLocaleLowerCase();
    if (target === "crosshair" || target === "localizer" || target === "synchronize") {
      if (layout !== "1x1") {
        tool.disabled = false;
        if (button) {
          button.classList.add(activeClass);
        }
      } else {
        tool.disabled = true;
        if (button) {
          button.classList.remove(activeClass);
        }
      }
    }
  });
}

// function enableDicomTool(){
//     // let event = new CustomEvent( action, { detail: layout });
//     // viewerInterface.dispatchEvent(event);
// }
